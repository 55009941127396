/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import "./header.css";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText=" O Espetáculo"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link to="/sobre" className={classes.dropdownLink}>
              Sobre
            </Link>,
            <Link to="/elenco" className={classes.dropdownLink}>
              Elenco
            </Link>,
            <Link to="/livrinho" className={classes.dropdownLink}>
              Livrinho
            </Link>,
            <Link to="/galeria" className={classes.dropdownLink}>
              Galeria
            </Link>,
            <Link
              to="/agenda"
              color="transparent"
              className={classes.dropdownLink}
            >
              Agenda
            </Link>,
            <Link to="/premios" className={classes.dropdownLink}>
              Prêmios e Nomeações
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Peça Interativa"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link to="/espetaculo" className={classes.dropdownLink}>
              Peça Interativa
            </Link>,

            <Link to="/painel" className={classes.dropdownLink}>
              Desafio Pangeia
            </Link>,

            <Link to="/entrevistas" className={classes.dropdownLink}>
              Conversa
            </Link>,
          ]}
        />
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <Link to="/agenda"
          color="transparent"
          className={classes.navLink}
        >
         Agenda
        </Link>
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <Link to="/contato" color="transparent" className={classes.navLink}>
          Contato
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        {/*<Tooltip title="Delete">
          <IconButton aria-label="Delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>*/}

        <Button
          href="https://www.youtube.com/channel/UCMoyViW5YQUNRUoocOW0Yhg"
          target="_blank"
          color="transparent"
          className={classes.navLink}
        >
          <i className={classes.socialIcons + " fab fa-youtube"} />
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          href="https://www.facebook.com/pangeiaespetaculo"
          target="_blank"
          className={classes.navLink}
        >
          <i className={classes.socialIcons + " fab fa-facebook"} />
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          href="https://www.instagram.com/pangeiaespetaculo"
          target="_blank"
          className={classes.navLink}
        >
          <i className={classes.socialIcons + " fab fa-instagram"} />
        </Button>
      </ListItem>
    </List>
  );
}
