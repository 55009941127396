import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Parallax from "components/Parallax/Parallax.js";
import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";
import Quote from "components/Typography/Quote.js";
import Card from "components/Card/Card.js";
import Footer from "components/Footer/Footer.js";
import foto from "./../Galeria/galeria/Foto12.jpeg";

import Carousel from "react-slick";
const useStyles = makeStyles(styles);

export default function Espetaculo() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: false,
    prevArrow: false,
  };

  return (
    <div style={{ backgroundColor: "black" }}>
      <Parallax
        filter
        image={"Foto9.jpg"}
        style={{ backgroundPosition: "center", display: "none" }}
        className={'youtubeSmart'}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div
                className={classes.brand + " bread"}
                style={{ width: "320px" }}
              >
                <a
                  className="btn"
                  href="https://www.youtube.com/watch?v=q_YpOJ5M4Ns&ab_channel=PANGEIAumape%C3%A7ainterativa"
                  target="_blank"
                >
                  <span className="btn__circle"></span>
                  <span className="btn__white-circle">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="icon-arrow-right"
                      viewBox="0 0 21 12"
                    >
                      <path
                        fill="#ffffff"
                        d="M17.104 5.072l-4.138-4.014L14.056 0l6 5.82-6 5.82-1.09-1.057 4.138-4.014H0V5.072h17.104z"
                      ></path>
                    </svg>
                  </span>
                  <span className="btn__text">ASSISTA O ESPETÁCULO</span>
                </a>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={8}
          className={classes.marginAuto + " youtubePc"}
          style={{ paddingBottom: "10em", paddingTop: "10em" }}
        >
          <div
            className="video"
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              paddingTop: 25,
              height: 0,
            }}
          >
            <iframe
              allowFullScreen="allowfullscreen"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              src="https://www.youtube.com/embed/q_YpOJ5M4Ns"
              frameBorder="0"
            />
          </div>
        </GridItem>
      </GridContainer>

      <Footer />
    </div>
  );
}
