import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Parallax from "components/Parallax/Parallax.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import Footer from "components/Footer/Footer.js";
const useStyles = makeStyles(styles);

export default function Entrevistas() {
  const classes = useStyles();

  return (
    <div>
      <div style={{ backgroundColor: "#aa96c0" }}>
        {/* <Parallax
        filter
        small
        image={"Foto6.jpg"}
        style={{ backgroundPosition: "center" }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand + " bread"} >
              <svg version="1.1" id="sobre" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 256.3 86.3"
   style={{width: "-webkit-fill-available"}}
   className={"titulo"}
   xmlns="http://www.w3.org/2000/svg"
   x="0px"
   y="0px"
   viewBox="0 0 500 300"
   enable-background="new 0 0 180 130"
  
 > 
<path fill="#FFFFFF" d="M15.2,0v8.6H8.6v30.2h6.6v8.6H8.6v30.2h6.6v8.6H0V0L15.2,0L15.2,0z"/>
<path fill="#FFFFFF" d="M35.2,0c3.3,0,6.1,2.7,6.1,6.1v80.2h-8.6V8.6h-4.6v77.7h-8.6V0h8.6v5.2c0.2-1.3,0.8-2.5,1.9-3.5
	C31.1,0.6,32.5,0,34.1,0H35.2z"/>
<path fill="#FFFFFF" d="M59.8,86.3h-8.6V8.6h-6.6V0h21.8v8.6h-6.6C59.8,8.6,59.8,86.3,59.8,86.3z"/>
<path fill="#FFFFFF" d="M85.5,0c3.4,0,6.1,2.8,6.1,6.1v30.8c0,3.3-2.6,5.8-5.5,6.1c1.6,0.2,2.8,1,3.9,2.2c1.1,1.2,1.7,2.6,1.7,4.1
	v37.1h-8.6V47.5h-4.6v38.8h-8.6V0h8.6v5.2c0.2-1.4,0.8-2.6,1.9-3.5C81.5,0.6,82.9,0,84.6,0H85.5z M83.1,8.6h-4.6v30.2h4.6V8.6z"/>
<path fill="#FFFFFF" d="M104.8,38.8h6.6v8.6h-6.6v30.1h6.6v8.7h-9c-3.4,0-6.3-2.8-6.3-6.3v-74c0-3.4,2.8-6.1,6.3-6.1h9v8.6h-6.6
	V38.8z"/>
<path fill="#FFFFFF" d="M123.8,86.3L114.9,0h8.6l4.2,40.5l0.5,8l0.6-8L132.9,0h8.6l-8.7,86.3H123.8z"/>
<path fill="#FFFFFF" d="M153,86.3h-8.6V0h8.6V86.3z"/>
<path fill="#FFFFFF" d="M173.1,0c3.4,0,6.1,2.7,6.1,6.1v24.1h-8.6V8.5H166v16.7c0,1.9,0.2,2.8,0.8,4.1l11,23.5
	c0.8,1.8,1.3,3.3,1.4,6.6V80c0,3.3-2.7,6.1-6.1,6.1h-9.4c-3.4,0-6.3-2.8-6.3-6.1V56.3h8.6v21.3h4.6V60c0-1.8-0.4-2.9-0.7-3.7
	l-10.8-23.4c-0.8-1.8-1.7-3.5-1.7-7.8V6.1c0-3.4,2.8-6.1,6.3-6.1H173.1z"/>
<path fill="#FFFFFF" d="M197.9,86.3h-8.6V8.6h-6.6V0h21.8v8.6h-6.6V86.3z"/>
<path fill="#FFFFFF" d="M223.7,0c3.4,0,6.1,2.7,6.1,6.1v80.2h-8.5V47.5h-4.6v38.8H208V6.2c0-3.4,2.8-6.1,6.3-6.1L223.7,0L223.7,0z
	 M221.3,38.8V8.6h-4.6v30.2H221.3z"/>
<path fill="#FFFFFF" d="M250.2,0c3.4,0,6.1,2.7,6.1,6.1v24.1h-8.5V8.5h-4.6v16.7c0,1.9,0.2,2.8,0.8,4.1l11,23.5
	c0.8,1.8,1.3,3.3,1.4,6.6V80c0,3.3-2.7,6.1-6.1,6.1h-9.4c-3.4,0-6.3-2.8-6.3-6.1V56.3h8.6v21.3h4.6V60c0-1.8-0.4-2.9-0.7-3.7
	l-10.8-23.4c-0.8-1.8-1.7-3.5-1.7-7.8V6.1c0-3.4,2.8-6.1,6.3-6.1H250.2z"/>
</svg>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax> */}

        <div className={classes.container}>
          <GridContainer></GridContainer>

          <GridContainer
            style={{
              paddingTop: "8em",
              paddingBotton: "8em",
              borderBotton: "2px solid #ffffff",
            }}
          >
            <GridItem
              xs={12}
              sm={12}
              md={12}
              className={classes.marginAuto}
              style={{ paddingTop: "10em", paddingBottom: "10em" }}
            >
              <div
                className="video"
                style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  paddingTop: 25,
                  height: 0,
                }}
              >
                <iframe
                  allowFullScreen="allowfullscreen"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  src="https://www.youtube.com/embed/EHNRvJL4_KQ"
                  frameBorder="0"
                />
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <Footer />
      </div>
    </div>
  );
}
