import { title } from "assets/jss/material-kit-react.js";

const productStyle = {
  section: {
    padding: "70px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    color:"#9580b2",
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  
  },
  description: {
    fontFamily: "InputMonoRegular",
    textAlign: "left",
    marginBottom: "1em",
    fontWeight: "100",
    color: "white"
  }
};

export default productStyle;
