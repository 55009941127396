import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import "./about.css";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import Footer from "components/Footer/Footer.js";
import Brasil from "../Elenco/001-brasil.svg";
import Espanhol from "../Elenco/espanhol.svg";
import Ingles from "../Elenco/ingles.svg";
import Portugal from "../Elenco/portugal.svg";
import List from "@material-ui/icons/List";
import NavPills from "components/NavPills/NavPills.js";



const useStyles = makeStyles(styles);

export default function About() {
  const classes = useStyles();

  return (
    <div style={{ backgroundColor: "#aa96c0" }}>
      <Parallax
        filter
        small
        image={"Foto4.jpg"}
        style={{ backgroundPosition: "center" }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand + " bread"}>
                <svg
                  className={"titulo"}
                  id="sobre"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 180 130"
                  enable-background="new 0 0 180 130"
                >
                  <g>
                    <path
                      d="M28.5,7c4.6,0,8.3,3.7,8.3,8.3V40H25.1V18.7h-6.2v17.8c0,2.6,0.5,4.3,1,5.4l15,42.6c0.5,1.1,1.8,4.6,1.9,9.1V116
		c0,4.6-3.7,8.3-8.3,8.3H15.7c-4.6,0-8.5-3.7-8.5-8.3V91.3h11.7v21.3h6.2V95.3c0-3.2-0.6-5-1.1-6.1L9.4,47.2C9,46,7.2,40.9,7.2,35
		V15.3C7.2,10.7,11,7,15.7,7H28.5z"
                    />
                    <path
                      d="M64.5,7c4.6,0,8.3,3.7,8.3,8.3V116c0,4.6-3.7,8.3-8.3,8.3H51.7c-4.6,0-8.5-3.8-8.5-8.3V15.3c0-4.6,3.8-8.3,8.5-8.3H64.5z
		 M61.1,112.6V18.7h-6.2v93.9H61.1z"
                    />
                    <path
                      d="M108.8,115.8c0,4.6-3.8,8.5-8.5,8.5H79.2V7h21.1c4.6,0,8.5,3.7,8.5,8.3v41.8c0,4.5-3.7,8-7.7,8.5c2.1,0.3,3.8,1.3,5.3,2.7
		c1.6,1.6,2.4,3.5,2.4,5.6V115.8z M90.9,18.7v41.1h6.2V18.7H90.9z M97.1,112.6V71.5h-6.2v41.1H97.1z"
                    />
                    <path
                      d="M136.5,7c4.6,0,8.3,3.8,8.3,8.3v41.9c0,4.5-3.5,7.8-7.5,8.3c2.1,0.3,3.8,1.3,5.3,2.9s2.2,3.5,2.2,5.6v50.2h-11.7V71.5h-6.2
		v52.8h-11.7V7h11.7v7c0.3-1.9,1.1-3.5,2.6-4.8C131,7.8,133,7,135.2,7H136.5z M133.1,18.7h-6.2v41.1h6.2V18.7z"
                    />
                    <path d="M171.8,7v11.7h-9v41.1h9v11.7h-9v41.1h9v11.7h-20.6V7H171.8z" />
                  </g>
                </svg>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      {/* <Parallax small filter image={"Foto4.jpg"} style={{backgroundPosition: "center", transform: "none !important"}}>
        <div className={classes.container} style={{textAlign:"center"}}>
          <GridContainer>
            <GridItem>
              <div className={classes.title + " bread"}>
             
              <svg className={"titulo"} id="sobre" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 180 130" enable-background="new 0 0 180 130" >
<g>
	<path d="M28.5,7c4.6,0,8.3,3.7,8.3,8.3V40H25.1V18.7h-6.2v17.8c0,2.6,0.5,4.3,1,5.4l15,42.6c0.5,1.1,1.8,4.6,1.9,9.1V116
		c0,4.6-3.7,8.3-8.3,8.3H15.7c-4.6,0-8.5-3.7-8.5-8.3V91.3h11.7v21.3h6.2V95.3c0-3.2-0.6-5-1.1-6.1L9.4,47.2C9,46,7.2,40.9,7.2,35
		V15.3C7.2,10.7,11,7,15.7,7H28.5z"/>
	<path d="M64.5,7c4.6,0,8.3,3.7,8.3,8.3V116c0,4.6-3.7,8.3-8.3,8.3H51.7c-4.6,0-8.5-3.8-8.5-8.3V15.3c0-4.6,3.8-8.3,8.5-8.3H64.5z
		 M61.1,112.6V18.7h-6.2v93.9H61.1z"/>
	<path d="M108.8,115.8c0,4.6-3.8,8.5-8.5,8.5H79.2V7h21.1c4.6,0,8.5,3.7,8.5,8.3v41.8c0,4.5-3.7,8-7.7,8.5c2.1,0.3,3.8,1.3,5.3,2.7
		c1.6,1.6,2.4,3.5,2.4,5.6V115.8z M90.9,18.7v41.1h6.2V18.7H90.9z M97.1,112.6V71.5h-6.2v41.1H97.1z"/>
	<path d="M136.5,7c4.6,0,8.3,3.8,8.3,8.3v41.9c0,4.5-3.5,7.8-7.5,8.3c2.1,0.3,3.8,1.3,5.3,2.9s2.2,3.5,2.2,5.6v50.2h-11.7V71.5h-6.2
		v52.8h-11.7V7h11.7v7c0.3-1.9,1.1-3.5,2.6-4.8C131,7.8,133,7,135.2,7H136.5z M133.1,18.7h-6.2v41.1h6.2V18.7z"/>
	<path d="M171.8,7v11.7h-9v41.1h9v11.7h-9v41.1h9v11.7h-20.6V7H171.8z"/>
</g>
</svg>
               
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax> */}
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <div className={classes.profile}>
              <div>
                <svg
                  version="1.1"
                  id="pangeialogo"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 414.3 223"
                >
                  <path
                    fill="#9580B2"
                    d="M179.1,107.4c0,0,0-1.4,0-2.1c0-17.2,0-34.5,0-51.7c0-4.8,1.3-6.1,6.1-6.1c2.1,0,4.3,0,6.4,0
	c2.6,0.1,3.9,1.2,4.3,3.8c0.1,0.8,0.1,1.6,0.1,2.3c0,35.1,0,70.1,0,105.2c0,4.8-1.3,6.1-6.1,6.1c-2.1,0-4.3,0-6.4,0
	c-3.4,0-5.7-0.9-7-4.1c-2.6-6.5-23.9-62.6-28.2-73.9V111c0,15.8,0,31.6,0,47.4c0,0.9,0,1.8-0.1,2.7c-0.3,2.3-1.7,3.7-3.9,3.8
	c-3,0.1-6,0.1-9,0c-2-0.1-3.3-1.1-3.7-3.1c-0.2-0.9-0.2-1.8-0.2-2.7c0-35.2,0-70.4,0-105.6c0-4.7,1.3-6,6-6c2.3,0,4.6,0,6.8,0
	c3.1,0,5.2,0.2,6.4,3.5c9.4,24.7,27.1,71.2,28.4,74.7V107.4z"
                  />
                  <path
                    fill="#9580B2"
                    d="M234,146.9V110c-0.2-0.1-0.2-0.3-0.4-0.3c-4.2-0.5-5-1.4-5-5.8c0-2.9,0-5.7,0-8.6c0-3.3,1.6-4.9,5-4.9
	c4.7,0,9.4,0,14.1,0c3.1,0,4.5,1.4,4.7,4.5c0,0.5,0,0.9,0,1.4c0,20.9,0,41.9,0,62.8c0,0.7,0,1.4-0.1,2.1c-0.4,2.4-1.9,3.6-4.3,3.7
	c-2.4,0.1-4.8,0.1-7.2,0c-2.4-0.1-4.3-0.9-5.3-3.4c-1.3,2.7-3.6,3.5-6.4,3.4c-4-0.1-8.1,0.2-12.1-0.3c-6.4-0.8-10.1-4.9-11-11.3
	c-0.3-1.9-0.4-3.9-0.4-5.8c0-28.3,0-56.6,0-84.9c0-9.4,5.4-15.2,14.8-15.4c6.9-0.2,13.8,0.1,20.7,0.3c1.4,0,2.8,0.4,4.2,0.8
	c3.2,0.9,6,2.3,5.7,7.3c-0.2,3.2,0,6.4-0.1,9.6c-0.1,3-1.3,3.9-4.2,3.4c-0.4-0.1-0.9-0.2-1.3-0.4c-5.8-3.1-12.1-2.3-18.3-2.3
	c-1.8,0-2.8,1.1-3.1,2.9c-0.1,0.6,0,1.3,0,1.9c0,23.8,0,47.6,0,71.4c0,3.9,0.8,4.7,4.7,4.7C230.3,146.9,232,146.9,234,146.9"
                  />
                  <path
                    fill="#9580B2"
                    d="M104.6,122.8c0-3.3,0.3-6.6-0.1-9.8c-0.7-7.4-1.7-14.8-2.5-22.3c-0.5-4.6-1.1-9.2-1.7-13.7
	c-0.1-0.8-0.4-1.5-0.7-2.3c-0.3,0-0.6,0.1-0.9,0.1c-1.4,15.9-2.9,31.9-4.3,47.9H104.6z M105,141.3H94.9v2.1c0,5.4,0,10.8,0,16.2
	c0,4.1-1.4,5.4-5.6,5.5c-2.8,0-5.6,0-8.4,0c-2.7,0-4.2-1.4-4.2-4.1c0.1-16.1-0.1-32.3,0.7-48.4c0.4-9.1,2.6-18.1,4.1-27.1
	c1.8-11,3.5-22,5.4-33.1c0.6-3.3,2.1-4.7,5.4-4.8c5-0.1,10-0.1,15,0c3.2,0.1,4.9,1.7,5.4,4.8c2.6,15.4,4.9,30.9,7.9,46.2
	c3.1,15.4,2.7,30.9,2.7,46.4c0,5,0,10,0,15c0,3.4-1.6,4.9-4.9,4.9c-2.7,0-5.5,0-8.2,0c-3.7,0-5.1-1.3-5.1-5c-0.1-5.4,0-10.8,0-16.2
	C105,143,105,142.2,105,141.3"
                  />
                  <path
                    fill="#9580B2"
                    d="M375.2,74.9c-1.4,15.9-2.9,20.3-4.3,36.3h10.3C381.7,104.5,378.9,89.4,375.2,74.9 M381.3,129.7h-10.1V145
	c0,4.9,0,9.8,0,14.6c0,4-1.4,5.4-5.4,5.4c-2.8,0-5.6,0-8.4,0c-2.9,0-4.4-1.4-4.4-4.3c0-14.5-0.3-29,0.3-43.5
	c0.4-8.6,2.2-17.1,3.5-25.6c1.9-12.5,4.1-25,6-37.5c0.8-5.3,2.2-6.5,7.5-6.5c4,0,7.9,0,11.9,0c4.5,0,6.1,1.3,6.8,5.7
	c3.2,18.8,6.5,37.6,9.5,56.4c0.9,5.6,0.9,11.3,1,16.9c0.1,10.9,0.1,21.9,0,32.8c0,4.3-1.3,5.6-5.6,5.6c-2.5,0-4.9,0-7.4,0
	c-3.9,0-5.2-1.3-5.3-5.3c0-5.4,0-10.8,0-16.2V129.7z"
                  />
                  <path
                    fill="#9580B2"
                    d="M33.1,64.7v49.5c1.9,0,15.9,0,17.7,0c3.5,0,4.5-1,4.5-4.5c0-7.7,0-15.5,0-23.2c0-5.8,0-11.7,0-17.5
	c0-3.2-1-4.1-4.2-4.2C49.1,64.7,35.1,64.7,33.1,64.7 M33,131.4v8.2c0,6.7,0,13.4,0,20.1c0,3.9-1.4,5.3-5.3,5.4c-2.8,0-5.6,0-8.4,0
	c-3,0-4.5-1.5-4.7-4.5c0-0.2,0-0.4,0-0.6c0-35.7,0-71.4,0-107.1c0-3.7,1.5-5.2,5.2-5.3c0.5,0,0.9,0,1.4,0c8,0,28.2,0.2,36.2,0
	c11.7-0.3,16,5.7,16.2,16c0.2,7.2,0,14.3,0,21.5c0,9.8,0.1,19.5-0.1,29.3c-0.1,3.1-0.5,6.2-1.5,9.2c-1.5,4.6-5.1,7.2-9.9,7.6
	c-4.8,0.4-9.6,0.3-14.4,0.4C47,131.4,34,131.4,33,131.4"
                  />
                  <path
                    fill="#9580B2"
                    d="M279.5,96.7c4.4,0,26,0,30.4,0c4.1,0,5.5,1.4,5.5,5.5c0,2.9,0.1,5.7,0,8.6c-0.1,2.9-1.5,4.2-4.4,4.3
	c-4.7,0.1-26.5,0.1-31.2,0.1c-0.1,0-0.1,0.1-0.3,0.2v31.3h2.1c4.9,0,26.9,0,31.8,0c3.2,0,4.7,1.5,4.8,4.6c0.1,3.1,0.1,6.2,0,9.4
	c-0.1,2.9-1.5,4.4-4.3,4.4c-10.4,0-38,0-48.4,0c-2.5,0-4-1.4-4.3-3.9c-0.1-0.7,0-1.4,0-2.1c0-35.1,0-70.3,0-105.4
	c0-4.7,1.3-6.1,6-6.1c9.5,0,36.2,0,45.7,0c4,0,5.4,1.4,5.4,5.4c0,2.9,0.1,5.9,0,8.8c-0.1,2.8-1.5,4.2-4.3,4.2
	c-5.7,0.1-28.5,0-34.3,0V96.7z"
                  />
                  <path
                    fill="#9580B2"
                    d="M325.4,106.4c0-17.6,0-35.3,0-52.9c0-4.6,1.3-5.9,5.9-5.9c2.5,0,4.9,0,7.4,0c3.5,0,5,1.5,5,5
	c0,9.8,0,19.6,0,29.5c0,25.7,0,51.4,0,77.1c0,4.6-1.3,5.9-5.9,5.9c-2.6,0-5.2,0-7.8,0c-2.9,0-4.4-1.5-4.6-4.4c0-0.8,0-1.7,0-2.5
	C325.4,140.9,325.4,123.6,325.4,106.4"
                  />
                  <path
                    fill="#9580B2"
                    d="M377.7,174.9c17.6,0-1.7,0,16,0c4.6,0,5.9,1.3,5.9,5.9c0,2.5,0,4.9,0,7.4c0,3.5-1.5,5-5,5
	c-9.8,0-5.8,0-15.6,0c-25.7,0-22,0-47.7,0c-4.6,0-5.9-1.3-5.9-5.9c0-2.6,0-5.2,0-7.8c0-2.9,1.5-4.4,4.4-4.6c0.8,0,1.7,0,2.5,0
	C349.6,174.9,360.5,174.9,377.7,174.9"
                  />
                  <path
                    fill="#9580B2"
                    d="M19.3,185.6l-1-3.9l-1.1,3.9H19.3z M20.3,189.2l-0.6-2.3h-2.8l-0.6,2.3h-1.6l2.7-9h2l2.7,9H20.3z"
                  />
                  <path
                    fill="#9580B2"
                    d="M35.8,185h1.6c0.5,0,0.9-0.1,1.1-0.3c0.2-0.2,0.3-0.5,0.3-0.9v-1c0-0.5-0.1-0.8-0.3-1
	c-0.2-0.2-0.6-0.3-1.1-0.3h-1.6V185z M34.1,189.2v-9h3.3c1,0,1.7,0.2,2.2,0.6c0.5,0.4,0.8,1,0.8,1.9v1.2c0,0.8-0.3,1.4-0.8,1.8
	c-0.5,0.4-1.3,0.6-2.2,0.6h-1.7v2.9H34.1z"
                  />
                  <path
                    fill="#9580B2"
                    d="M46.1,185.6l-1-3.9l-1.1,3.9H46.1z M47.1,189.2l-0.6-2.3h-2.8l-0.6,2.3h-1.6l2.7-9h2l2.7,9H47.1z"
                  />
                  <path
                    fill="#9580B2"
                    d="M51.4,184.6h1.7c0.5,0,0.9-0.1,1.1-0.3c0.2-0.2,0.3-0.5,0.3-0.9v-0.7c0-0.4-0.1-0.7-0.3-0.9
	c-0.2-0.2-0.6-0.3-1.1-0.3h-1.7V184.6z M51.4,189.2h-1.7v-9h3.6c1,0,1.7,0.2,2.2,0.6c0.5,0.4,0.7,1,0.7,1.8v0.8
	c0,0.7-0.2,1.2-0.5,1.6c-0.3,0.4-0.8,0.7-1.4,0.8l2.1,3.4h-1.8l-2-3.3h-1.1V189.2z"
                  />
                  <polygon
                    fill="#9580B2"
                    points="59.8,189.2 59.8,181.6 57.2,181.6 57.2,180.2 64.1,180.2 64.1,181.6 61.5,181.6 61.5,189.2 "
                  />
                  <polygon
                    fill="#9580B2"
                    points="65.4,189.2 65.4,187.9 67.6,187.9 67.6,181.6 65.4,181.6 65.4,180.2 71.5,180.2 71.5,181.6 
	69.3,181.6 69.3,187.9 71.5,187.9 71.5,189.2 "
                  />
                  <path
                    fill="#9580B2"
                    d="M74.9,184.6h1.7c0.5,0,0.9-0.1,1.1-0.3c0.2-0.2,0.3-0.5,0.3-0.9v-0.7c0-0.4-0.1-0.7-0.3-0.9
	c-0.2-0.2-0.6-0.3-1.1-0.3h-1.7V184.6z M74.9,189.2h-1.7v-9h3.6c1,0,1.7,0.2,2.2,0.6c0.5,0.4,0.7,1,0.7,1.8v0.8
	c0,0.7-0.2,1.2-0.5,1.6c-0.3,0.4-0.8,0.7-1.4,0.8l2.1,3.4H78l-2-3.3h-1.1V189.2z"
                  />
                  <path
                    fill="#9580B2"
                    d="M93.8,187.9h1.1c0.6,0,1.1-0.2,1.4-0.5c0.3-0.3,0.4-0.8,0.4-1.5v-2.3c0-0.7-0.1-1.2-0.4-1.5
	c-0.3-0.3-0.7-0.5-1.4-0.5h-1.1V187.9z M92.1,189.2v-9h2.9c1,0,1.9,0.3,2.5,0.9c0.6,0.6,0.9,1.4,0.9,2.4v2.5c0,1-0.3,1.8-0.9,2.3
	c-0.6,0.6-1.4,0.9-2.5,0.9H92.1z"
                  />
                  <path
                    fill="#9580B2"
                    d="M103,188c0.5,0,0.9-0.1,1.2-0.4c0.2-0.3,0.3-0.7,0.3-1.4v-2.8c0-0.7-0.1-1.2-0.3-1.4
	c-0.2-0.3-0.6-0.4-1.2-0.4c-0.5,0-0.9,0.1-1.2,0.4c-0.2,0.3-0.4,0.8-0.4,1.4v2.8c0,0.7,0.1,1.1,0.4,1.4
	C102.1,187.8,102.5,188,103,188 M103,189.3c-1.1,0-1.8-0.3-2.4-0.8c-0.5-0.5-0.8-1.3-0.8-2.3v-3.1c0-1,0.3-1.8,0.8-2.3
	c0.5-0.5,1.3-0.8,2.4-0.8c1.1,0,1.8,0.3,2.4,0.8c0.5,0.5,0.8,1.3,0.8,2.3v3.1c0,1-0.3,1.7-0.8,2.3
	C104.9,189.1,104.1,189.3,103,189.3"
                  />
                  <path
                    fill="#9580B2"
                    d="M110.8,189.3c-0.7,0-1.3-0.1-1.9-0.3c-0.6-0.2-1.1-0.5-1.5-0.9l0.7-1.2c0.4,0.3,0.8,0.6,1.2,0.8
	c0.5,0.2,1,0.3,1.6,0.3c0.5,0,0.9-0.1,1.1-0.3c0.3-0.2,0.4-0.5,0.4-0.9c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.2-0.8-0.4-1.5-0.6
	c-1.1-0.3-1.8-0.6-2.2-1c-0.4-0.4-0.6-0.9-0.6-1.7c0-0.8,0.3-1.4,0.8-1.9c0.5-0.5,1.3-0.7,2.2-0.7c0.6,0,1.2,0.1,1.7,0.3
	c0.6,0.2,1,0.5,1.4,0.9l-0.6,1.2c-0.4-0.3-0.8-0.6-1.2-0.7c-0.4-0.2-0.9-0.3-1.3-0.3c-0.4,0-0.7,0.1-1,0.2c-0.2,0.2-0.3,0.4-0.3,0.8
	c0,0.3,0.1,0.6,0.4,0.8c0.3,0.2,0.8,0.4,1.6,0.6c1,0.3,1.7,0.6,2.1,1c0.4,0.4,0.6,1,0.6,1.7c0,0.9-0.3,1.5-0.9,2
	C112.6,189.1,111.8,189.3,110.8,189.3"
                  />
                  <path
                    fill="#9580B2"
                    d="M129.9,189.3c-1.1,0-1.9-0.3-2.5-0.8s-0.8-1.3-0.8-2.3v-3.1c0-1,0.3-1.8,0.8-2.3c0.6-0.5,1.4-0.8,2.5-0.8
	c1.1,0,1.9,0.2,2.5,0.7c0.5,0.5,0.8,1.3,0.9,2.4l-1.7,0.1c-0.1-0.8-0.2-1.3-0.4-1.5c-0.2-0.3-0.6-0.4-1.2-0.4c-0.6,0-1,0.1-1.3,0.4
	c-0.2,0.3-0.4,0.8-0.4,1.4v2.8c0,0.7,0.1,1.1,0.4,1.4c0.2,0.3,0.7,0.4,1.3,0.4c0.6,0,1-0.1,1.2-0.4c0.2-0.3,0.4-0.8,0.4-1.5l1.7,0.1
	c-0.1,1.1-0.3,1.9-0.9,2.4C131.8,189.1,131,189.3,129.9,189.3"
                  />
                  <path
                    fill="#9580B2"
                    d="M137.6,188c0.5,0,0.9-0.1,1.2-0.4s0.3-0.7,0.3-1.4v-2.8c0-0.7-0.1-1.2-0.3-1.4c-0.2-0.3-0.6-0.4-1.2-0.4
	c-0.5,0-0.9,0.1-1.2,0.4c-0.2,0.3-0.4,0.8-0.4,1.4v2.8c0,0.7,0.1,1.1,0.4,1.4C136.7,187.8,137,188,137.6,188 M137.6,189.3
	c-1,0-1.8-0.3-2.4-0.8c-0.5-0.5-0.8-1.3-0.8-2.3v-3.1c0-1,0.3-1.8,0.8-2.3c0.5-0.5,1.3-0.8,2.4-0.8c1.1,0,1.8,0.3,2.4,0.8
	c0.5,0.5,0.8,1.3,0.8,2.3v3.1c0,1-0.3,1.7-0.8,2.3C139.4,189.1,138.6,189.3,137.6,189.3"
                  />
                  <polygon
                    fill="#9580B2"
                    points="146.4,189.2 143.8,182.5 143.8,189.2 142.3,189.2 142.3,180.2 144.3,180.2 147,187.3 147,180.2 
	148.5,180.2 148.5,189.2 "
                  />
                  <polygon
                    fill="#9580B2"
                    points="152.4,189.2 152.4,181.6 149.7,181.6 149.7,180.2 156.7,180.2 156.7,181.6 154,181.6 154,189.2 "
                  />
                  <path
                    fill="#9580B2"
                    d="M161,188c0.5,0,0.9-0.1,1.2-0.4c0.2-0.3,0.3-0.7,0.3-1.4v-2.8c0-0.7-0.1-1.2-0.3-1.4
	c-0.2-0.3-0.6-0.4-1.2-0.4c-0.5,0-0.9,0.1-1.2,0.4c-0.2,0.3-0.4,0.8-0.4,1.4v2.8c0,0.7,0.1,1.1,0.4,1.4
	C160.1,187.8,160.5,188,161,188 M161,189.3c-1.1,0-1.8-0.3-2.4-0.8c-0.5-0.5-0.8-1.3-0.8-2.3v-3.1c0-1,0.3-1.8,0.8-2.3
	c0.5-0.5,1.3-0.8,2.4-0.8c1.1,0,1.8,0.3,2.4,0.8c0.5,0.5,0.8,1.3,0.8,2.3v3.1c0,1-0.3,1.7-0.8,2.3
	C162.8,189.1,162.1,189.3,161,189.3"
                  />
                  <path
                    fill="#9580B2"
                    d="M168.8,189.3c-0.7,0-1.3-0.1-1.9-0.3c-0.6-0.2-1.1-0.5-1.5-0.9l0.7-1.2c0.4,0.3,0.8,0.6,1.2,0.8
	c0.5,0.2,1,0.3,1.6,0.3c0.5,0,0.9-0.1,1.1-0.3c0.3-0.2,0.4-0.5,0.4-0.9c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.2-0.8-0.4-1.5-0.6
	c-1.1-0.3-1.8-0.6-2.2-1c-0.4-0.4-0.6-0.9-0.6-1.7c0-0.8,0.3-1.4,0.8-1.9c0.5-0.5,1.3-0.7,2.2-0.7c0.6,0,1.2,0.1,1.7,0.3
	c0.6,0.2,1,0.5,1.4,0.9l-0.6,1.2c-0.4-0.3-0.8-0.6-1.2-0.7c-0.4-0.2-0.9-0.3-1.3-0.3c-0.4,0-0.7,0.1-1,0.2c-0.2,0.2-0.3,0.4-0.3,0.8
	c0,0.3,0.1,0.6,0.4,0.8c0.3,0.2,0.8,0.4,1.6,0.6c1,0.3,1.7,0.6,2.1,1c0.4,0.4,0.6,1,0.6,1.7c0,0.9-0.3,1.5-0.9,2
	C170.6,189.1,169.8,189.3,168.8,189.3"
                  />
                  <path
                    fill="#9580B2"
                    d="M186.4,187.9h1.1c0.6,0,1.1-0.2,1.4-0.5c0.3-0.3,0.4-0.8,0.4-1.5v-2.3c0-0.7-0.1-1.2-0.4-1.5
	c-0.3-0.3-0.7-0.5-1.4-0.5h-1.1V187.9z M184.7,189.2v-9h2.9c1,0,1.9,0.3,2.5,0.9c0.6,0.6,0.9,1.4,0.9,2.4v2.5c0,1-0.3,1.8-0.9,2.3
	c-0.6,0.6-1.4,0.9-2.5,0.9H184.7z"
                  />
                  <path
                    fill="#9580B2"
                    d="M195.6,188c0.5,0,0.9-0.1,1.2-0.4c0.2-0.3,0.3-0.7,0.3-1.4v-2.8c0-0.7-0.1-1.2-0.3-1.4
	c-0.2-0.3-0.6-0.4-1.2-0.4c-0.5,0-0.9,0.1-1.2,0.4c-0.2,0.3-0.4,0.8-0.4,1.4v2.8c0,0.7,0.1,1.1,0.4,1.4
	C194.6,187.8,195,188,195.6,188 M195.6,189.3c-1.1,0-1.8-0.3-2.4-0.8c-0.5-0.5-0.8-1.3-0.8-2.3v-3.1c0-1,0.3-1.8,0.8-2.3
	c0.5-0.5,1.3-0.8,2.4-0.8c1.1,0,1.8,0.3,2.4,0.8c0.5,0.5,0.8,1.3,0.8,2.3v3.1c0,1-0.3,1.7-0.8,2.3
	C197.4,189.1,196.6,189.3,195.6,189.3"
                  />
                  <path
                    fill="#9580B2"
                    d="M203.4,189.3c-0.7,0-1.3-0.1-1.9-0.3c-0.6-0.2-1.1-0.5-1.5-0.9l0.7-1.2c0.4,0.3,0.8,0.6,1.2,0.8
	c0.5,0.2,1,0.3,1.6,0.3c0.5,0,0.9-0.1,1.1-0.3c0.3-0.2,0.4-0.5,0.4-0.9c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.2-0.8-0.4-1.5-0.6
	c-1.1-0.3-1.8-0.6-2.2-1c-0.4-0.4-0.6-0.9-0.6-1.7c0-0.8,0.3-1.4,0.8-1.9c0.5-0.5,1.3-0.7,2.2-0.7c0.6,0,1.2,0.1,1.7,0.3
	c0.6,0.2,1,0.5,1.4,0.9l-0.6,1.2c-0.4-0.3-0.8-0.6-1.2-0.7c-0.4-0.2-0.9-0.3-1.3-0.3c-0.4,0-0.7,0.1-1,0.2c-0.2,0.2-0.3,0.4-0.3,0.8
	c0,0.3,0.1,0.6,0.4,0.8c0.3,0.2,0.8,0.4,1.6,0.6c1,0.3,1.7,0.6,2.1,1c0.4,0.4,0.6,1,0.6,1.7c0,0.9-0.3,1.5-0.9,2
	C205.1,189.1,204.3,189.3,203.4,189.3"
                  />
                  <polygon
                    fill="#9580B2"
                    points="219.2,189.2 219.2,187.9 221.5,187.9 221.5,181.6 219.2,181.6 219.2,180.2 225.4,180.2 225.4,181.6 
	223.2,181.6 223.2,187.9 225.4,187.9 225.4,189.2 "
                  />
                  <path
                    fill="#9580B2"
                    d="M228.7,184.6h1.7c0.5,0,0.9-0.1,1.1-0.3c0.2-0.2,0.3-0.5,0.3-0.9v-0.7c0-0.4-0.1-0.7-0.3-0.9
	c-0.2-0.2-0.6-0.3-1.1-0.3h-1.7V184.6z M228.7,189.2H227v-9h3.6c1,0,1.7,0.2,2.2,0.6s0.7,1,0.7,1.8v0.8c0,0.7-0.2,1.2-0.5,1.6
	c-0.3,0.4-0.8,0.7-1.4,0.8l2.1,3.4h-1.8l-2-3.3h-1.1V189.2z"
                  />
                  <polygon
                    fill="#9580B2"
                    points="237.3,186.7 236,182.8 236.1,189.2 234.6,189.2 234.6,180.2 236.4,180.2 238,185 239.5,180.2 
	241.3,180.2 241.3,189.2 239.8,189.2 239.9,182.8 238.6,186.7 "
                  />
                  <path
                    fill="#9580B2"
                    d="M246.8,185.6l-1-3.9l-1.1,3.9H246.8z M246.7,179c-0.3,0-0.6-0.1-0.7-0.2c-0.2-0.1-0.3-0.3-0.5-0.4
	c-0.1-0.1-0.2-0.3-0.4-0.4c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.4-0.1,0.8v0.1h-1.1v-0.2
	c0-0.6,0.1-1.1,0.4-1.5c0.3-0.4,0.7-0.6,1.2-0.6c0.3,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.4c0.1,0.1,0.2,0.3,0.3,0.4
	c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.4,0.1-0.8v-0.1h1.1v0.2c0,0.6-0.1,1.1-0.4,1.5
	C247.7,178.8,247.3,179,246.7,179 M247.8,189.2l-0.6-2.3h-2.8l-0.6,2.3h-1.6l2.7-9h2l2.7,9H247.8z"
                  />
                  <path
                    fill="#9580B2"
                    d="M253.6,188c0.5,0,0.9-0.1,1.2-0.4s0.3-0.7,0.3-1.4v-2.8c0-0.7-0.1-1.2-0.3-1.4c-0.2-0.3-0.6-0.4-1.2-0.4
	c-0.5,0-0.9,0.1-1.2,0.4c-0.2,0.3-0.4,0.8-0.4,1.4v2.8c0,0.7,0.1,1.1,0.4,1.4C252.6,187.8,253,188,253.6,188 M253.6,189.3
	c-1,0-1.8-0.3-2.4-0.8c-0.5-0.5-0.8-1.3-0.8-2.3v-3.1c0-1,0.3-1.8,0.8-2.3c0.5-0.5,1.3-0.8,2.4-0.8c1,0,1.8,0.3,2.4,0.8
	c0.5,0.5,0.8,1.3,0.8,2.3v3.1c0,1-0.3,1.7-0.8,2.3C255.4,189.1,254.6,189.3,253.6,189.3"
                  />
                  <path
                    fill="#9580B2"
                    d="M261.4,189.3c-0.7,0-1.3-0.1-1.9-0.3c-0.6-0.2-1.1-0.5-1.5-0.9l0.7-1.2c0.4,0.3,0.8,0.6,1.2,0.8
	c0.5,0.2,1,0.3,1.6,0.3c0.5,0,0.9-0.1,1.1-0.3c0.3-0.2,0.4-0.5,0.4-0.9c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.2-0.8-0.4-1.5-0.6
	c-1.1-0.3-1.8-0.6-2.2-1c-0.4-0.4-0.6-0.9-0.6-1.7c0-0.8,0.3-1.4,0.8-1.9c0.5-0.5,1.3-0.7,2.2-0.7c0.6,0,1.2,0.1,1.7,0.3
	c0.6,0.2,1,0.5,1.4,0.9l-0.6,1.2c-0.4-0.3-0.8-0.6-1.2-0.7c-0.4-0.2-0.9-0.3-1.3-0.3c-0.4,0-0.7,0.1-1,0.2c-0.2,0.2-0.3,0.4-0.3,0.8
	c0,0.3,0.1,0.6,0.4,0.8c0.3,0.2,0.8,0.4,1.6,0.6c1,0.3,1.7,0.6,2.1,1c0.4,0.4,0.6,1,0.6,1.7c0,0.9-0.3,1.5-0.9,2
	C263.1,189.1,262.3,189.3,261.4,189.3"
                  />
                  <path
                    fill="#9580B2"
                    d="M280.4,189.3c-1.1,0-1.9-0.3-2.5-0.8c-0.6-0.5-0.9-1.3-0.9-2.3v-2.8c0-1,0.3-1.8,0.8-2.4
	c0.6-0.6,1.4-0.8,2.4-0.8c1,0,1.8,0.2,2.3,0.7c0.5,0.5,0.8,1.2,0.9,2.1l-1.7,0.1c-0.1-0.6-0.2-1-0.4-1.2c-0.2-0.2-0.6-0.4-1-0.4
	c-0.6,0-1,0.1-1.2,0.4c-0.2,0.3-0.4,0.8-0.4,1.5v2.6c0,0.7,0.1,1.2,0.4,1.5c0.2,0.3,0.7,0.4,1.3,0.4c0.6,0,1-0.2,1.2-0.5
	c0.2-0.3,0.3-0.8,0.3-1.6h-1.6v-1.3h3.3v1.5c0,1-0.3,1.8-0.8,2.4C282.3,189.1,281.5,189.3,280.4,189.3"
                  />
                  <path
                    fill="#9580B2"
                    d="M286.7,184.6h1.7c0.5,0,0.9-0.1,1.1-0.3c0.2-0.2,0.3-0.5,0.3-0.9v-0.7c0-0.4-0.1-0.7-0.3-0.9
	c-0.2-0.2-0.6-0.3-1.1-0.3h-1.7V184.6z M286.7,189.2H285v-9h3.6c1,0,1.7,0.2,2.2,0.6s0.7,1,0.7,1.8v0.8c0,0.7-0.2,1.2-0.5,1.6
	c-0.3,0.4-0.8,0.7-1.4,0.8l2.1,3.4h-1.8l-2-3.3h-1.1V189.2z"
                  />
                  <polygon
                    fill="#9580B2"
                    points="292.8,189.2 292.8,187.9 295.1,187.9 295.1,181.6 292.8,181.6 292.8,180.2 299,180.2 299,181.6 
	296.8,181.6 296.8,187.9 299,187.9 299,189.2 "
                  />
                  <polygon
                    fill="#9580B2"
                    points="303.1,186.7 301.8,182.8 301.9,189.2 300.4,189.2 300.4,180.2 302.2,180.2 303.8,185 305.3,180.2 
	307,180.2 307,189.2 305.6,189.2 305.7,182.8 304.4,186.7 "
                  />
                  <polygon
                    fill="#9580B2"
                    points="310.9,186.7 309.6,182.8 309.7,189.2 308.2,189.2 308.2,180.2 310,180.2 311.6,185 313.1,180.2 
	314.8,180.2 314.8,189.2 313.4,189.2 313.5,182.8 312.2,186.7 "
                  />
                </svg>
              </div>

            </div>
          </GridItem>
        </GridContainer>


        <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} lg={6}>
       
          <NavPills
            color="rose"
            horizontal={{
              tabsGrid: { xs: 12, sm: 2, md: 2 },
              contentGrid: { xs: 12, sm: 8, md: 8 }
            }}
            tabs={[
              {
                tabButton: "",
                tabIcon: Brasil,
                tabContent: (
                  <span>
                    <h5
                      className={classes.description + " descricao"}
                      style={{ marginBottom: "3em", fontFamily: "InputMonoCBold" }}
                    >
                      PANGEIA é uma viagem sonora e visual pelo universo dos irmãos
                      Grimm, em que o palco se transforma em museu vivo de objetos
                      curiosos através de sons escutados em headphones. Os objetos
                      remetem ao imaginário dos contos fantásticos, como a floresta
                      cheia de armadilhas, a magia da bola de cristal ou os feitiços da
                      bruxa má. Nesta viagem acompanhada pelos dois cientistas vamos
                      descobrir o ponto de vista dos objetos que ilustram os contos.
                      Para isso teremos de seguir as pistas, como fizeram Hansel e
                      Gretel com as migalhas que deixaram no caminho para poderem depois
                      voltar a casa.
            </h5>
                    <h5
                      className={classes.description + " descricao"}
                      style={{ marginBottom: "3em", fontFamily: "InputMonoCBold" }}
                    >
                      PANGEIA é um espetáculo para o público juvenil que reúne em palco
                      várias linguagens como o teatro, a dança e as artes visuais,
                      recuperando, assim a ideia dos Gabinetes de Curiosidades criados
                      no século XVI que reuniam objetos raros e artefatos da biologia,
                      sendo considerados os percursores dos museus de arte.
            </h5>
                    <h5
                      className={classes.description + " descricao"}
                      style={{ marginBottom: "3em", fontFamily: "InputMonoCBold" }}
                    >
                      As histórias que ouvimos durante a nossa infância surgem no
                      PANGEIA de um outro ponto de vista, questionando o espectador
                      sobre sua origem e de que forma o universo dos irmãos Grimm foi
                      sofrendo alterações tanto na tradição oral como na escrita dos
                      contos.
            </h5>
                    <h5
                      className={classes.description + " descricao"}
                      style={{ marginBottom: "3em", fontFamily: "InputMonoCBold" }}
                    >
                      Em pleno século XXI, não um, mas dois, dois jovens pesquisadores
                      mergulharam na mais inóspita e talvez perigosa aventura científica
                      de que se tem memória. Os investigadores ousaram recuar ao século
                      XIX para contar todas as possíveis e iminentes ameaças dos contos
                      narrados pelos sinistros Irmãos Grimm, os irmãos responsáveis pela
                      coleta de contos tradicionais. Todos sabem, ou deviam saber, o que
                      acontece quando se juntam novamente não 100, mas os 200 objetos
                      dos Irmãos Grimm.
            </h5>
                  </span>
                )
              },
              {
                tabButton: "",
                tabIcon: Ingles,
                tabContent: (
                  <span>
<h5
              className={classes.description + " descricao"}
              style={{ marginBottom: "3em", fontFamily: "InputMonoCBold" }}
            >
              PANGEA is a sound and visual trip through the universe
              of the Grimm Brothers. The stage is transformed in a
              live museum of curious objects and sounds that can
              be heard through headphones. The objects refer to the
              universe of fantastic tales, like the forest full of
              traps, the crystal ball magic or the spells of a bad
              witch. In this trip made by the two main characters,
              we will discover the point of view of the objects that
              illustrate the narratives. For that we need to follow
              clues, just like Hansel and Gretel with the pieces of
              bread that were left on their path so they could go
              back home.
            </h5>
            <h5
              className={classes.description + " descricao"}
              style={{ marginBottom: "3em", fontFamily: "InputMonoCBold" }}
            >
              PANGEA is a play for the whole family that gathers
              on a stage many languages like theatre, dance and
              visual arts, bringing back the idea of the cabinets of
              curiosities created during the 16th and that showed
              rare objects side by side with biology artifacts -
              they can be interpreted as the the precursor of art
              museums.
            </h5>
            <h5
              className={classes.description + " descricao"}
              style={{ marginBottom: "3em", fontFamily: "InputMonoCBold" }}
            >
              The stories that we heard during our childhood are
              present in PANGEA through another point of view,
              inquiring the audience about their origins and
              inviting us to think about the ways that the Grimm
              Brothers’s universe changed from the oral tradition to
              the textual narrative.
            </h5>
            <h5
                      className={classes.description + " descricao"}
                      style={{ marginBottom: "3em", fontFamily: "InputMonoCBold" }}
                    >
              In the course of the 21st century, not one but two, two
young researchers dive in the most strange and perhaps
dangerous scientific adventure that there’s memory of. Our
researchers dared to return to the 19th century to tell all
the possible and eminent threats from the tales narrated by
the sinister Brothers Grimm, the brothers responsible for
collecting traditional tales. Everyone knows, or should have
known, what happens when someone gathers again, not 100, but
the 200 objects of the Brothers Grimm.
            </h5>
                  </span>
                )
              },{
                tabButton: "",
                tabIcon: Espanhol,
                tabContent: (
                  <span>
                    <h5
                      className={classes.description + " descricao"}
                      style={{ marginBottom: "3em", fontFamily: "InputMonoCBold" }}
                    >
                     PANGEA es un viaje visual y sonoro a través del
universo de los Hermanos Grimm. El escenario se
convierte en un museo vivo con objectos raros
y sonidos que solo se escuchan a través de los
audífonos. Los objectos hacen referencia a cuentos
fantásticos como los de los bosques llenos de trampas,
de la magia del globo de cristal o del hechizo de
la bruja. En un viaje seguido por los personajes
encontraremos los puntos de vista de los objectos
que ilustran esas narrativas. Para tal necesitaremos
seguir pistas, así como hicieron Hansel y Gretel con
las migas de pan para volver a la casa.
            </h5>
                    <h5
                      className={classes.description + " descricao"}
                      style={{ marginBottom: "3em", fontFamily: "InputMonoCBold" }}
                    >
                     PANGEA es un espectáculo para toda la família que
reúne muchos lenguajes como el teatro, la danza y
las artes visuales trayendo de vuelta la idea de los
gabinetes de curiosidades creados en el siglo XVI y
que mostraban objectos raros al lado de artefactos
biológicos - el precursor de los museos de arte.
            </h5>
                    <h5
                      className={classes.description + " descricao"}
                      style={{ marginBottom: "3em", fontFamily: "InputMonoCBold" }}
                    >
                   Las historias que escuchamos en nuestra infancia están
presentes en PANGEA a través de otro punto de vista
que cuestiona el público sobre sus origines y nos
invita a pensar sobre las maneras como el universo
de los Hermanos Grimm cambió desde la tradición oral
hasta la narrativa textual.
            </h5>
                    <h5
                      className={classes.description + " descricao"}
                      style={{ marginBottom: "3em", fontFamily: "InputMonoCBold" }}
                    >
                  A mediados del siglo XXI, no uno, sino dos, dos jóvenes
investigadores se sumergieron en la aventura científica más
inhóspita y quizás peligrosa de la que tenemos memoria. Los
investigadores se atrevieron a volver al siglo XIX para
contar todas las amenazas posibles e inminentes de los
cuentos narrados por los siniestros hermanos Grimm, los
hermanos responsables por recopilar cuentos tradicionales.
Todos saben, o deberían saber, lo que sucede cuando se
juntan de nuevo, no 100, sino los 200 objetos de los
Hermanos Grimm.
            </h5>
                  </span>
                )
              }
            ]}
          />
        </GridItem>
        </GridContainer>

      </div>
      <Footer />
    </div>
  );
}


