import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Parallax from "components/Parallax/Parallax.js";
import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";
import Quote from "components/Typography/Quote.js";
import Card from "components/Card/Card.js";
import Footer from "components/Footer/Footer.js";
import ImageGallery from "react-image-gallery";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

import { Gallery, Item } from "react-photoswipe-gallery";

export default function Galeria() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const group = "Pangeia";
  const thumb = "Pangeia";

  const images = [
    {
      original:
        "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto1.jpeg",
      thumbnail:
        "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto1.jpeg",
    },
    {
      original: "./galeria/Foto1.jpeg",
      thumbnail: "./galeria/Foto1.jpeg",
    },
    {
      original: "./galeria/Foto1.jpeg",
      thumbnail: "./galeria/Foto1.jpeg",
    },
  ];

  return (
    <div style={{ backgroundColor: "black" }}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem>
            <div className={classes.title} style={{ marginTop: "9em" }}>
              <h2
                style={{
                  color: "#FFFFFF",
                  fontWeight: "100",
                  fontSize: "5em",
                  fontFamily: "NSW",
                  margin: "0px",
                  zIndex: "2",
                  textAlign: "center",
                }}
              >
                GALERIA
              </h2>
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
          <Gallery>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Item
                  original="https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto1.jpeg"
                  thumbnail="https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto1.jpeg"
                  width="1280"
                  height="623"
                >
                  {({ ref, open }) => (
                    <img
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                      ref={ref}
                      onClick={open}
                      src="https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto1.jpeg"
                    />
                  )}
                </Item>
                <Item
                  original="https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto10.jpeg"
                  thumbnail="https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto10.jpeg"
                  width="920"
                  height="1280"
                >
                  {({ ref, open }) => (
                    <img
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                      ref={ref}
                      onClick={open}
                      src="https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto10.jpeg"
                      className={navImageClasses}
                    />
                  )}
                </Item>
                <Item
                  original="https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto3.jpeg"
                  thumbnail="https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto3.jpeg"
                  width="1280"
                  height="652"
                >
                  {({ ref, open }) => (
                    <img
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                      ref={ref}
                      onClick={open}
                      src="https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto3.jpeg"
                      className={navImageClasses}
                    />
                  )}
                </Item>
              </GridItem>

              <GridItem xs={12} sm={12} md={4}>
                <Item
                  original="https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto7.jpeg"
                  thumbnail="https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto7.jpeg"
                  width="720"
                  height="1280"
                >
                  {({ ref, open }) => (
                    <img
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                      ref={ref}
                      onClick={open}
                      src="https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto7.jpeg"
                    />
                  )}
                </Item>
                <Item
                  original="https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto9.jpeg"
                  thumbnail="https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto9.jpeg"
                  width="919"
                  height="1280"
                >
                  {({ ref, open }) => (
                    <img
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                      ref={ref}
                      onClick={open}
                      src="https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto9.jpeg"
                      className={navImageClasses}
                    />
                  )}
                </Item>
              </GridItem>

              <GridItem xs={12} sm={12} md={4}>
                <Item
                  original="https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto11.jpeg"
                  thumbnail="https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto11.jpeg"
                  width="920"
                  height="1280"
                >
                  {({ ref, open }) => (
                    <img
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                      ref={ref}
                      onClick={open}
                      src="https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto11.jpeg"
                    />
                  )}
                </Item>
                <Item
                  original="https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto8.jpeg"
                  thumbnail="https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto8.jpeg"
                  width="914"
                  height="1280"
                >
                  {({ ref, open }) => (
                    <img
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                      ref={ref}
                      onClick={open}
                      src="https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/images/Foto8.jpeg"
                      className={navImageClasses}
                    />
                  )}
                </Item>
              </GridItem>
            </GridContainer>
          </Gallery>
        </GridItem>
      </GridContainer>

      <Footer />
    </div>
  );
}
