import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Footer from "components/Footer/Footer.js";
import Logos from "components/logos/logos.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import WorkSection from "./Sections/WorkSection.js";
import Caurosel from "../../components/Caroussel/Caroussel";

const useStyles = makeStyles(styles);

const mystyle = {
  order: "0",
  height: "90vh",
  margin: "0",
  display: "flex",
  padding: "0",
  overflow: "hidden",
  position: "relative",
  "max-height": "1000px",
  "align-items": "center",
  "background-size": "cover",
  "background-position": "center center",
};

export default function LandingPage() {
  const classes = useStyles();
  return (
    <div style={{ backgroundColor: "#054e3b" }}>
      <div style={{ backgroundColor: "black" }}>
        <Caurosel style={mystyle} />
      </div>
      {/* <div className={classNames(classes.main, classes.mainRaised)}> */}
      <div className={classes.container}>
        <ProductSection />

        {/* <WorkSection /> */}
      </div>
      {/* </div> */}
      <TeamSection style={{ backgroundColor: "#9580b2" }} />
      <Logos />
      <Footer />
    </div>
  );
}
