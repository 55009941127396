import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Parallax from "components/Parallax/Parallax.js";
import "./elenco.css";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import foto from "./Foto13.png";
import Footer from "components/Footer/Footer.js";
import Brasil from "./002-brasil.svg";
import Portugal from "./001-portugal.svg";
import List from "@material-ui/icons/List";
import NavPills from "components/NavPills/NavPills.js";


const useStyles = makeStyles(styles);

export default function Elenco() {
  const classes = useStyles();

  return (
    <div style={{ backgroundColor: "rgb(0 44 71)" }}>
      <Parallax small  image={"Foto11.jpg"} >
        <div className={classes.container} style={{ textAlign: "center" }}>
          <GridContainer>
            <GridItem>
              <div className={classes.title + " bread"} style={{ zIndex: "1" }}>
                <svg version="1.1" id="Camada_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  viewBox="0 0 203.9 123" enable-background="new 0 0 203.9 123" >
                  <g>
                    <path fill="#FFFFFF" d="M15.5,17.5v8.8H8.8v30.8h6.7v8.8H8.8v30.8h6.7v8.8H0v-88H15.5z" />
                    <path fill="#FFFFFF" d="M35.4,105.5H19.9v-88h8.8v79.2h6.7V105.5z" />
                    <path fill="#FFFFFF" d="M54.5,17.5v8.8h-6.7v30.8h6.7v8.8h-6.7v30.8h6.7v8.8H39v-88H54.5z" />
                    <path fill="#FFFFFF" d="M81.1,105.5h-8.6l-4.8-39.2v39.2h-8.8v-88h8.6l4.8,35.8V17.5h8.8V105.5z" />
                    <path fill="#FFFFFF" d="M101.8,17.5c3.5,0,6.2,2.8,6.4,6.2v30.5h-8.8v-28h-4.7v70.4h4.7V65.9h8.8v33.2c0,3.5-3,6.4-6.4,6.4h-9.5
		c-3.5,0-6.4-2.9-6.4-6.4V23.8c0-3.5,3-6.2,6.4-6.2H101.8z"/>
                    <path fill="#FFFFFF" d="M128.9,17.5c3.5,0,6.2,2.8,6.2,6.2v75.5c0,3.5-2.8,6.2-6.2,6.2h-9.6c-3.5,0-6.4-2.9-6.4-6.2V23.8
		c0-3.5,2.9-6.2,6.4-6.2H128.9z M126.4,96.7V26.3h-4.7v70.4H126.4z"/>
                  </g>
                </svg>


              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.section}>

        <GridContainer justify="center" style={{ marginTop: "8em" }} >



          <GridItem xs={12} sm={12} md={12} lg={6}>
            <NavPills
              color="rose"
              horizontal={{
                tabsGrid: { xs: 12, sm: 2, md: 2 },
                contentGrid: { xs: 12, sm: 8, md: 8 }
              }}
              tabs={[
                {
                  tabButton: "Versão Brasileira",
                  tabIcon: Brasil,
                  tabContent: (
                    <span>

                      <div className={"titulotexto"} >

                        <svg version="1.1" id="vbrasileira" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                          viewBox="0 0 282.3 223" enable-background="new 0 0 282.3 223" >
                          <path fill="#AA96C0" d="M19.6,111l-9.1-88h8.8l4.3,41.3l0.5,8.2l0.6-8.2L28.8,23h8.8l-8.9,88H19.6z" />
                          <path fill="#AA96C0" d="M49.1,62.6h6.7v8.8h-6.7v30.7h6.7v8.9h-9.2c-3.5,0-6.4-2.9-6.4-6.4V29.3c0-3.5,2.9-6.2,6.4-6.2h9.2v8.8h-6.7
	V62.6z"/>
                          <path fill="#AA96C0" d="M73.2,47.7v-16h-4.7V111h-8.8V23h8.8v5.3c0.2-1.4,0.8-2.6,1.9-3.6c1.2-1.1,2.6-1.7,4.3-1.7h1
	c3.5,0,6.2,2.8,6.2,6.2v18.5H73.2z"/>
                          <path fill="#AA96C0" d="M101.8,23c3.5,0,6.2,2.8,6.2,6.2v24.6h-8.8V31.8h-4.7v17c0,1.9,0.2,2.9,0.8,4.2l11.2,24
	c0.8,1.8,1.3,3.4,1.4,6.7v21c0,3.4-2.8,6.2-6.2,6.2h-9.6c-3.5,0-6.4-2.9-6.4-6.2V80.5h8.8v21.7h4.7V84.3c0-1.8-0.4-3-0.7-3.8
	l-11-23.9c-0.8-1.8-1.7-3.6-1.7-8V29.3c0-3.5,2.9-6.2,6.4-6.2H101.8z"/>
                          <path fill="#AA96C0" d="M126.3,111v-5.2c-0.2,1.6-1.2,2.6-2.2,3.6c-1.2,1.1-2.5,1.6-4.1,1.6h-1c-3.5,0-6.2-2.9-6.2-6.2V60.1
	c0-3.5,2.8-6.4,6.2-6.4h1c3,0,5.9,2.4,6.2,5.4V31.8h-4.7v15.8h-8.8V29.3c0-3.5,2.8-6.2,6.2-6.2h9.7c3.5,0,6.4,2.8,6.4,6.2V111H126.3
	z M114.9,17.6v-4.2c0-4.3,2.3-5.4,4.6-5.4c2.6,0,5.9,2.8,7.4,2.8c0.6,0,1-0.4,1-1.2V8h5v4.1c0,4.3-2.2,5.5-4.4,5.5
	c-2.5,0-6.1-2.9-7.4-2.9c-0.6,0-1,0.4-1,1.2v1.7H114.9z M126.3,102.2V62.6h-4.7v39.6H126.3z"/>
                          <path fill="#AA96C0" d="M155.9,23c3.5,0,6.2,2.8,6.2,6.2v75.5c0,3.5-2.8,6.2-6.2,6.2h-9.7c-3.5,0-6.4-2.9-6.4-6.2V29.3
	c0-3.5,2.9-6.2,6.4-6.2H155.9z M153.4,102.2V31.8h-4.7v70.4H153.4z"/>
                          <path fill="#AA96C0" d="M33.9,208.6c0,3.5-2.9,6.4-6.4,6.4H11.7v-88h15.8c3.5,0,6.4,2.8,6.4,6.2v31.3c0,3.4-2.8,6-5.8,6.4
	c1.6,0.2,2.9,1,4,2c1.2,1.2,1.8,2.6,1.8,4.2V208.6z M20.4,135.8v30.8h4.7v-30.8H20.4z M25.1,206.2v-30.8h-4.7v30.8H25.1z"/>
                          <path fill="#AA96C0" d="M54.6,127c3.5,0,6.2,2.9,6.2,6.2v31.4c0,3.4-2.6,5.9-5.6,6.2c1.6,0.2,2.9,1,4,2.2c1.1,1.2,1.7,2.6,1.7,4.2
	V215h-8.8v-39.6h-4.7V215h-8.8v-88h8.8v5.3c0.2-1.4,0.8-2.6,1.9-3.6c1.2-1.1,2.6-1.7,4.3-1.7H54.6z M52.1,135.8h-4.7v30.8h4.7V135.8
	z"/>
                          <path fill="#AA96C0" d="M81.6,127c3.5,0,6.2,2.8,6.2,6.2V215h-8.8v-39.6h-4.7V215h-8.8v-81.7c0-3.5,2.9-6.2,6.4-6.2H81.6z
	 M79.1,166.6v-30.8h-4.7v30.8H79.1z"/>
                          <path fill="#AA96C0" d="M108.6,127c3.5,0,6.2,2.8,6.2,6.2v18.5h-8.8v-16h-4.7v13.3c0,1.9,0.4,3.2,0.7,4.1l11.3,31.9
	c0.4,0.8,1.3,3.5,1.4,6.8v16.8c0,3.5-2.8,6.2-6.2,6.2H99c-3.5,0-6.4-2.8-6.4-6.2v-18.5h8.8v16h4.7v-13c0-2.4-0.5-3.7-0.8-4.6
	l-10.9-31.6c-0.4-0.8-1.7-4.7-1.7-9.1v-14.8c0-3.5,2.9-6.2,6.4-6.2H108.6z"/>
                          <path fill="#AA96C0" d="M135.4,127v8.8h-4.3v70.4h4.3v8.8h-17.5v-8.8h4.4v-70.4h-4.4V127H135.4z" />
                          <path fill="#AA96C0" d="M154.2,215h-15.5v-88h8.8v79.2h6.7V215z" />
                          <path fill="#AA96C0" d="M173.3,127v8.8h-6.7v30.8h6.7v8.8h-6.7v30.8h6.7v8.8h-15.5v-88H173.3z" />
                          <path fill="#AA96C0" d="M194.1,127v8.8h-4.3v70.4h4.3v8.8h-17.5v-8.8h4.4v-70.4h-4.4V127H194.1z" />
                          <path fill="#AA96C0" d="M213.6,127c3.5,0,6.2,2.9,6.2,6.2v31.4c0,3.4-2.6,5.9-5.6,6.2c1.6,0.2,2.9,1,4,2.2c1.1,1.2,1.7,2.6,1.7,4.2
	V215h-8.8v-39.6h-4.7V215h-8.8v-88h8.8v5.3c0.2-1.4,0.8-2.6,1.9-3.6c1.2-1.1,2.6-1.7,4.3-1.7H213.6z M211.1,135.8h-4.7v30.8h4.7
	V135.8z"/>
                          <path fill="#AA96C0" d="M240.6,127c3.5,0,6.2,2.8,6.2,6.2V215h-8.8v-39.6h-4.7V215h-8.8v-81.7c0-3.5,2.9-6.2,6.4-6.2H240.6z
	 M238.1,166.6v-30.8h-4.7v30.8H238.1z"/>
                        </svg>
                      </div>






                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Criação e Instalação Creation and Instalation / Creación e Instalación</span>
                        <br />TIAGO CADETE
</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Interpretação Interpretation / Interpretación</span>
                        <br />JOÃO VELHO E PRISCILA MAIA
</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Vozes Voice-off / Voz off
</span>
                        <br />ALESSANDRA COLASANTI, CESAR AUGUSTO, CISSA GUIMARÃES, DANI LIMA,
DENISE STUTZ, ENRIQUE DIAZ, FABIANO DE FREITAS, FELIPE ROCHA,
ISABÉL ZUAA, JEFFERSON SCHROEDER, JOSÉ LORETO, LUCIANA FROES,
MATEUS SOLANO, NANDA FÉLIX, PEDROCA MONTEIRO, PAULO CESAR PERÉIO,
PEDRO HENRIQUE MULLER, POLIANA PAIVA, RAPHAEL LOGAM, RENATO
LINHARES, STELLA RABELLO, VERA HOLTZ

</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Pesquisa de Projeto Project Researchers / Trabajo de investigación</span>
                        <br />TIAGO CADETE, JONAS LOPES, LEONOR CABRAL E BERNARDO DE ALMEIDA
</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Figurinos Costumes / Trajes</span>
                        <br />CARLOTA LAGIDO

</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Assistente de Ensaios Rehearsal Assistant / Asistente de Ensayo
</span>
                        <br />POLIANA PAIVA
</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Produção de Figurinos e Objetos Costumes and Objects Production / Producción de
Trajes y Objetos</span>
                        <br />CARLA FERRAZ
</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Assistente de Produção de Figurinos e Objetos Costumes and Objects Assistant
Producer / Asistente Producción de Trajes y Objetos</span>
                        <br />MARCIO NEWLANDS
</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Cabelos Hair / cabello
</span>
                        <br />MAX FARIAS
</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Coordenação de Produção Production Coordinator / Coordinacion de Produccion</span>
                        <br />FIAR, ONDISC E A VOZ DO OPERÁRIO
</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Produção e Difusão production and diffusion / Producción y difusión</span>
                        <br />JULIA BAKER
</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Produtor Producer / Productor</span>
                        <br />GUSTAVO CANELLA
</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Assistente de Produção Production Assistant / Asistente de Producción</span>
                        <br />JULIANA PONTIGO
</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Programador Visual Visual Programmer / Programador Visual</span>
                        <br />MARCELLO TALONE

</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Assistente de Comunicação Communication Assistant / Assistente de Comunicação</span>
                        <br />LARISSA VAZ
</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Assessoria de Imprensa Press Agent / Agente de Prensa</span>
                        <br />LYVIA RODRIGUES/AQUELA QUE DIVULGA

</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Fotógrafa Photographer / Fotografa</span>
                        <br />ELISA MENDES
</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Filmagem e Teaser Video Maker and Teaser / Rodaje y Teaser</span>
                        <br />LUIZ GUILHERME GUERREIRO
</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Operador de Som Sound Operator / Operador de Sonido</span>
                        <br />THIAGO KROPF
</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Operadora de luz Light Operator / Operador de Luz</span>
                        <br />GIULIA DEL-PENHO
</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Coordenação Financeira e Administrativa Financial and Administrative Coordination
/ Coordinación Financiera y Administrativa</span>
                        <br />RENATA PIMENTA
</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Formação de público Audience Formation / Formación de audiencia</span>
                        <br />JULIANA PEREIRA
</h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Realização Project Execution / Realización</span>
                        <br />BOMBA CRIATIVA
</h5>
                    </span>
                  )
                },
                {
                  tabButton: "Versão Portuguesa",
                  tabIcon: Portugal,
                  tabContent: (
                    <span>
                      <div className={"titulotexto"}>

                        <svg version="1.1" id="vportuguesa" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                          viewBox="0 0 282.3 223" enable-background="new 0 0 282.3 223" >
                          <path fill="#AA96C0" d="M16.2,111.7l-9.1-88h8.8L20.2,65l0.5,8.2l0.6-8.2l4.2-41.3h8.8l-8.9,88H16.2z" />
                          <path fill="#AA96C0" d="M45.8,63.3h6.7v8.8h-6.7v30.7h6.7v8.9h-9.2c-3.5,0-6.4-2.9-6.4-6.4V30c0-3.5,2.9-6.2,6.4-6.2h9.2v8.8h-6.7
V63.3z"/>
                          <path fill="#AA96C0" d="M69.9,48.5v-16h-4.7v79.2h-8.8v-88h8.8V29c0.2-1.4,0.8-2.6,1.9-3.6c1.2-1.1,2.6-1.7,4.3-1.7h1
c3.5,0,6.2,2.8,6.2,6.2v18.5H69.9z"/>
                          <path fill="#AA96C0" d="M98.4,23.7c3.5,0,6.2,2.8,6.2,6.2v24.6h-8.8V32.5h-4.7v17c0,1.9,0.2,2.9,0.8,4.2l11.2,24
c0.8,1.8,1.3,3.4,1.4,6.7v21c0,3.4-2.8,6.2-6.2,6.2h-9.6c-3.5,0-6.4-2.9-6.4-6.2V81.2h8.8v21.7h4.7V85.1c0-1.8-0.4-3-0.7-3.8
l-11-23.9c-0.8-1.8-1.7-3.6-1.7-8V30c0-3.5,2.9-6.2,6.4-6.2H98.4z"/>
                          <path fill="#AA96C0" d="M122.9,111.7v-5.2c-0.2,1.6-1.2,2.6-2.2,3.6c-1.2,1.1-2.5,1.6-4.1,1.6h-1c-3.5,0-6.2-2.9-6.2-6.2V60.8
c0-3.5,2.8-6.4,6.2-6.4h1c3,0,5.9,2.4,6.2,5.4V32.5h-4.7v15.8h-8.8V30c0-3.5,2.8-6.2,6.2-6.2h9.7c3.5,0,6.4,2.8,6.4,6.2v81.7H122.9z
M111.5,18.3v-4.2c0-4.3,2.3-5.4,4.6-5.4c2.6,0,5.9,2.8,7.4,2.8c0.6,0,1-0.4,1-1.2V8.7h5v4.1c0,4.3-2.2,5.5-4.4,5.5
c-2.5,0-6.1-2.9-7.4-2.9c-0.6,0-1,0.4-1,1.2v1.7H111.5z M122.9,102.9V63.3h-4.7v39.6H122.9z"/>
                          <path fill="#AA96C0" d="M152.6,23.7c3.5,0,6.2,2.8,6.2,6.2v75.5c0,3.5-2.8,6.2-6.2,6.2h-9.7c-3.5,0-6.4-2.9-6.4-6.2V30
c0-3.5,2.9-6.2,6.4-6.2H152.6z M150,102.9V32.5h-4.7v70.4H150z"/>
                          <path fill="#AA96C0" d="M24.3,127.7c3.5,0,6.2,2.8,6.2,6.2v35.9c0,3.5-2.8,6.2-6.2,6.2h-7.2v39.6H8.3v-88H24.3z M21.8,136.5h-4.7
v30.8h4.7V136.5z"/>
                          <path fill="#AA96C0" d="M50.7,127.7c3.5,0,6.2,2.8,6.2,6.2v75.5c0,3.5-2.8,6.2-6.2,6.2h-9.6c-3.5,0-6.4-2.9-6.4-6.2V134
c0-3.5,2.9-6.2,6.4-6.2H50.7z M48.2,206.9v-70.4h-4.7v70.4H48.2z"/>
                          <path fill="#AA96C0" d="M77.3,127.7c3.5,0,6.2,2.9,6.2,6.2v31.4c0,3.4-2.6,5.9-5.6,6.2c1.6,0.2,2.9,1,4,2.2c1.1,1.2,1.7,2.6,1.7,4.2
v37.7h-8.8v-39.6h-4.7v39.6h-8.8v-88h8.8v5.3c0.2-1.4,0.8-2.6,1.9-3.6c1.2-1.1,2.6-1.7,4.3-1.7H77.3z M74.8,136.5h-4.7v30.8h4.7
V136.5z"/>
                          <path fill="#AA96C0" d="M102.3,215.7h-8.8v-79.2h-6.7v-8.8H109v8.8h-6.7V215.7z" />
                          <path fill="#AA96C0" d="M134.8,209.5c0,3.5-2.8,6.2-6.2,6.2h-9.7c-3.4,0-6.2-2.8-6.2-6.2v-81.7h8.8v79.2h4.7v-79.2h8.8V209.5z" />
                          <path fill="#AA96C0" d="M161.8,167.3v48.4H153v-5.5c-0.2,1.6-1.1,2.9-2,3.8c-1.2,1.1-2.5,1.7-4.1,1.7h-1c-3.5,0-6.4-2.9-6.4-6.2V134
c0-3.5,2.9-6.2,6.4-6.2h9.6c3.5,0,6.2,2.8,6.2,6.2v18.4H153v-15.8h-4.7v70.4h4.7v-30.8h-2.3v-8.8H161.8z"/>
                          <path fill="#AA96C0" d="M188.8,209.5c0,3.5-2.8,6.2-6.2,6.2h-9.7c-3.4,0-6.2-2.8-6.2-6.2v-81.7h8.8v79.2h4.7v-79.2h8.8V209.5z" />
                          <path fill="#AA96C0" d="M209.1,127.7v8.8h-6.7v30.8h6.7v8.8h-6.7v30.8h6.7v8.8h-15.5v-88H209.1z" />
                          <path fill="#AA96C0" d="M229.5,127.7c3.5,0,6.2,2.8,6.2,6.2v18.5h-8.8v-16h-4.7v13.3c0,1.9,0.4,3.2,0.7,4.1l11.3,31.9
c0.4,0.8,1.3,3.5,1.4,6.8v16.8c0,3.5-2.8,6.2-6.2,6.2h-9.6c-3.5,0-6.4-2.8-6.4-6.2V191h8.8v16h4.7v-13c0-2.4-0.5-3.7-0.8-4.6
l-10.9-31.6c-0.4-0.8-1.7-4.7-1.7-9.1V134c0-3.5,2.9-6.2,6.4-6.2H229.5z"/>
                          <path fill="#AA96C0" d="M256.5,127.7c3.5,0,6.2,2.8,6.2,6.2v81.7h-8.8v-39.6h-4.7v39.6h-8.8V134c0-3.5,2.9-6.2,6.4-6.2H256.5z
M253.9,167.3v-30.8h-4.7v30.8H253.9z"/>
                        </svg>
                      </div>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Criação e Instalação Creation and Instalation / Creación e
              Instalación </span>
                        <br />
              TIAGO CADETE
            </h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Pesquisa de Projeto Research Assistants/ trabajo de investigación</span>

                        <br />
              TIAGO CADETE, JONAS LOPES, LEONOR CABRAL E BERNARDO DE ALMEIDA
            </h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Interpretação Interpretation / Interpretación</span>

                        <br />
              LEONOR CABRAL E BERNARDO DE ALMEIDA E/OU JOÃO DE BRITO
            </h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Figurinos Costumes / Trajes</span>

                        <br /> CARLOTA LAGIDO
            </h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Direção Técnica Technical Direction / Dirección técnica</span>

                        <br /> NUNO PATINHO
            </h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}> Produtora Producer / Productor</span>

                        <br /> PATRICIA LAGES
            </h5>
                      <h5 className={classes.description + " descricao"}>

                        <span className={"descricao1"}> Vozes Voice-off / voz off</span>

                        <br />
              ALFREDO MARTINS, ANA ROSA MENDES, BRUNO HUCA, CATARINA VIEIRA,
              CELIA JORGE, CRISTA ALFAIATE, DAVID MARQUES, FABÍ LEBRE LAGO,
              ISABÉL ZUAA MUTANGE, JOANA BARROS, JOÃO DE BRITO, JOAO SOUSA, JOÃO
              VILLAS-BOAS, LUIS PUTO, MARIA ANA FILIPE, MIGUEL DAMIÃO, PAULA
              DIOGO DE CARVALHO, RAQUEL ANDRÉ, SOLANGE FREITAS, TÂNIA ALVES,
              TIAGO CUNHA BÔTO E WAGNER BORGES
            </h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Projeto financiado por Funded By / proyecto financiado por</span>

                        <br />
              FUNDAÇÃO GDA – APOIO DE CRIAÇÃO; FUNDAÇÃO GDA-APOIO À
              INTERNACIONALIZAÇÃO; INSTITUTO CAMÕES; DIREÇÃO-GERAL DAS ARTES-
              APOIO À INTERNACIONALIZAÇÃO
            </h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}>Co-produção Co-production / Co-produccíon CULTURGEST -</span>

                        <br />
              FUNDAÇÃO CGD
            </h5>
                      <h5 className={classes.description + " descricao"}>

                        <span className={"descricao1"}> Apoios Supports / Apoya</span>

                        <br />
              FIAR, ONDISC E A VOZ DO OPERÁRIO
            </h5>
                      <h5 className={classes.description + " descricao"}>
                        <span className={"descricao1"}> Produção e Difusão production and diffusion / Producción y
              difusión EIRA-</span>

                        <br />
              ESTRUTURA FINANCIADA PELO MINISTÉRIO DA CULTURA/DIREÇÃO- GERAL DAS
              ARTES
            </h5>
                    </span>
                  )
                }
              ]}
            />
          </GridItem>


        </GridContainer>
      </div>
      <Footer />
    </div>
  );
}
