import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import "./premios.css";
import Small from "components/Typography/Small.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function Premios() {
  const classes = useStyles();

  return (
    <div style={{ backgroundColor: "#2F503F" }}>
      <Parallax filter small image={"Foto5.jpg"} >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand + " bread"}>
              <svg version="1.1" id="premios" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 259 241" enable-background="new 0 0 259 241" >
<path fill="#FFFFFF" d="M23.9,23c3.5,0,6.2,2.8,6.2,6.2v35.9c0,3.5-2.8,6.2-6.2,6.2h-7.2V111H7.9V23H23.9z M21.3,31.8h-4.7v30.8h4.7
	V31.8z"/>
<path fill="#FFFFFF" d="M50.5,23c3.5,0,6.2,2.9,6.2,6.2v31.4c0,3.4-2.6,5.9-5.6,6.2c1.6,0.2,2.9,1,4,2.2c1.1,1.2,1.7,2.6,1.7,4.2
	V111H48V71.4h-4.7V111h-8.8V23h8.8v5.3c0.2-1.4,0.8-2.6,1.9-3.6c1.2-1.1,2.6-1.7,4.3-1.7H50.5z M48,31.8h-4.7v30.8H48V31.8z"/>
<path fill="#FFFFFF" d="M79.9,17.6h-7.3l-3.4-3.8l-3.4,3.8h-7.3l6.6-8.8h8.2L79.9,17.6z M70.1,62.6h6.7v8.8h-6.7v30.7h6.7v8.9h-9.2
	c-3.5,0-6.4-2.9-6.4-6.4V29.2c0-3.5,2.9-6.2,6.4-6.2h9.2v8.8h-6.7V62.6z"/>
<path fill="#FFFFFF" d="M110.5,23c3.5,0,6.2,2.8,6.2,6.2V111H108V31.8h-4.7V111h-8.8V31.8h-4.7V111h-8.8V23h8.8v5.4
	c0.2-1.4,0.8-2.6,1.9-3.7c1.2-1.1,2.6-1.7,4.3-1.7h1c1.8,0,3.2,0.6,4.4,1.8c1.1,1.1,1.7,2.4,1.8,3.8c0.1-1.4,0.7-2.8,1.8-3.8
	c1.2-1.2,2.6-1.8,4.4-1.8H110.5z"/>
<path fill="#FFFFFF" d="M129.3,111h-8.8V23h8.8V111z"/>
<path fill="#FFFFFF" d="M150.1,23c3.5,0,6.2,2.8,6.2,6.2v75.5c0,3.5-2.8,6.2-6.2,6.2h-9.7c-3.5,0-6.4-2.9-6.4-6.2V29.2
	c0-3.5,2.9-6.2,6.4-6.2H150.1z M147.6,102.2V31.8h-4.7v70.4H147.6z"/>
<path fill="#FFFFFF" d="M177.1,23c3.5,0,6.2,2.8,6.2,6.2v24.6h-8.8V31.8h-4.7v17c0,1.9,0.2,2.9,0.8,4.2l11.2,24
	c0.8,1.8,1.3,3.4,1.4,6.7v21c0,3.4-2.8,6.2-6.2,6.2h-9.6c-3.5,0-6.4-2.9-6.4-6.2V80.5h8.8v21.7h4.7V84.3c0-1.8-0.4-3-0.7-3.8
	l-11-23.9c-0.8-1.8-1.7-3.6-1.7-8V29.2c0-3.5,2.9-6.2,6.4-6.2H177.1z"/>
<path fill="#FFFFFF" d="M235.8,111h-9.2l-1.7-4.7c0,2.4-2.6,4.7-5.2,4.7h-9.8c-3.4,0-6.1-2.8-6.1-6.2V64.5c0-2.9,1.4-5.2,4.7-6
	l-3.8-10.3c-0.5-1.3-0.8-2.9-0.8-5.9V29.2c0-3.5,2.8-6.2,6.1-6.2h9.8c3.4,0,6.2,2.8,6.2,6.2v13h-8.8V31.8h-4.8v12l10.3,29.4l1.4-8.4
	l8.5,1.6l-4.2,23.4L235.8,111z M212.4,102.2h5.3l1.9-11.3l-7.2-21V102.2z"/>
<path fill="#FFFFFF" d="M30.1,225h-8.6l-4.8-39.2V225H7.9v-88h8.6l4.8,35.8V137h8.8V225z"/>
<path fill="#FFFFFF" d="M50.6,137c3.5,0,6.2,2.8,6.2,6.2v75.5c0,3.5-2.8,6.2-6.2,6.2H41c-3.5,0-6.4-2.9-6.4-6.2v-75.5
	c0-3.5,2.9-6.2,6.4-6.2H50.6z M48.1,216.2v-70.4h-4.7v70.4H48.1z"/>
<path fill="#FFFFFF" d="M90.9,137c3.5,0,6.4,2.8,6.4,6.2V225h-8.8v-79.2h-4.7V225h-8.8v-79.2h-4.7V225h-8.8v-81.7
	c0-3.5,2.8-6.2,6.2-6.2h5.3c1.6,0,3,0.6,4.2,1.7c1.1,1.1,1.8,2.4,2,4c0.4-3,3-5.6,6.4-5.6H90.9z"/>
<path fill="#FFFFFF" d="M117.6,137v8.8h-6.7v30.8h6.7v8.8h-6.7v30.8h6.7v8.8h-15.5v-88H117.6z"/>
<path fill="#FFFFFF" d="M138,137c3.5,0,6.2,2.8,6.2,6.2V225h-8.8v-39.6h-4.7V225H122v-81.7c0-3.5,2.9-6.2,6.4-6.2H138z M135.5,176.6
	v-30.8h-4.7v30.8H135.5z"/>
<path fill="#FFFFFF" d="M164.9,137c3.5,0,6.2,2.8,6.4,6.2v30.5h-8.8v-28h-4.7v70.4h4.7v-30.8h8.8v33.2c0,3.5-3,6.4-6.4,6.4h-9.5
	c-3.5,0-6.4-2.9-6.4-6.4v-75.4c0-3.5,3-6.2,6.4-6.2H164.9z M164.9,225l-5.8,9.8h-5.8l2.3-9.8H164.9z"/>
<path fill="#FFFFFF" d="M192,137c3.5,0,6.2,2.8,6.2,6.2v75.5c0,3.5-2.8,6.2-6.2,6.2h-9.6c-3.5,0-6.4-2.9-6.4-6.2v-75.5
	c0-3.5,2.9-6.2,6.4-6.2H192z M178.1,131.6v-4.2c0-4.3,2.3-5.4,4.6-5.4c2.6,0,5.9,2.8,7.4,2.8c0.6,0,1-0.4,1-1.2V122h5v4.1
	c0,4.3-2.2,5.5-4.4,5.5c-2.5,0-6.1-2.9-7.4-2.9c-0.6,0-1,0.4-1,1.2v1.7H178.1z M189.5,216.2v-70.4h-4.7v70.4H189.5z"/>
<path fill="#FFFFFF" d="M218.5,137v8.8h-6.7v30.8h6.7v8.8h-6.7v30.8h6.7v8.8H203v-88H218.5z"/>
<path fill="#FFFFFF" d="M238.9,137c3.5,0,6.2,2.8,6.2,6.2v18.5h-8.8v-16h-4.7v13.3c0,1.9,0.4,3.2,0.7,4.1l11.3,31.9
	c0.4,0.8,1.3,3.5,1.4,6.8v16.8c0,3.5-2.8,6.2-6.2,6.2h-9.6c-3.5,0-6.4-2.8-6.4-6.2v-18.5h8.8v16h4.7v-13c0-2.4-0.5-3.7-0.8-4.6
	l-10.9-31.6c-0.4-0.8-1.7-4.7-1.7-9.1v-14.8c0-3.5,2.9-6.2,6.4-6.2H238.9z"/>
</svg>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.container}>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem  xs={12} sm={12} md={12} style={{marginTop: "8em"}}>
            <div className={classes.typo}>
              {/* <div className={classes.note}>Small Tag</div> */}
              <h3  style={{color: "white"}}>
              6º PRÊMIO-CENTRO BRASILEIRO DE TEATRO PARA A INFÂNCIA E JUVENTUDE (VENCEDOR)
                <br />
                <Small className={"smalltext"}>PROGRAMAÇÃO VISUAL MARCELLO TALONE</Small>
              </h3>
            </div>
            </GridItem>
            <GridItem  xs={12} sm={12} md={12} style={{marginTop: "8em"}}>
            <div className={classes.typo}>
              {/* <div className={classes.note}>Small Tag</div> */}
              <h3 style={{color: "white"}}>
              6º PRÊMIO-CENTRO BRASILEIRO DE TEATRO PARA A INFÂNCIA E JUVENTUDE (VENCEDORA)
                <br />
                <Small className={"smalltext"}>ADEREÇOS CARLA FERRAZ</Small>
              </h3>
            </div>
            </GridItem>
            <GridItem  xs={12} sm={12} md={12} style={{marginTop: "8em", marginBottom: "8em"}}>
            <div className={classes.typo}>
              {/* <div className={classes.note}>Small Tag</div> */}
              <h3 style={{color: "white"}}>
              8º PRÊMIO BOTEQUIM CULTURAL DE TEATRO (NOMEAÇÃO)
                <br />
                <Small className={"smalltext"}>MELHOR ATOR JOÃO VELHO</Small>
              </h3>
            </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}


