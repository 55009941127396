import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Parallax from "components/Parallax/Parallax.js";
import "./agenda.css";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import Footer from "components/Footer/Footer.js";
const useStyles = makeStyles(styles);

export default function Agenda() {
  const classes = useStyles();

  return (
    <div style={{ background: "#054e3b" }}>
      {/* <Parallax small filter image={"Foto6.jpg"}>
        <div className={classes.container} style={{ textAlign: "center" }}>
          <GridContainer>
            <GridItem>
              <div className={classes.title + " bread"}>
                <svg
                  version="1.1"
                  id="agenda"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 203.9 123"
                  enable-background="new 0 0 203.9 123"
                >
                  <path
                    fill="#FFFFFF"
                    d="M20.4,17.5c3.5,0,6.2,2.8,6.2,6.2v81.7h-8.8V65.9h-4.7v39.6H4.5V23.8c0-3.5,2.9-6.2,6.4-6.2H20.4z
	 M17.9,57.1V26.3h-4.7v30.8H17.9z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M53.7,57.1v48.4h-8.8V100c-0.2,1.6-1.1,2.9-2,3.8c-1.2,1.1-2.5,1.7-4.1,1.7h-1c-3.5,0-6.4-2.9-6.4-6.2V23.8
	c0-3.5,2.9-6.2,6.4-6.2h9.6c3.5,0,6.2,2.8,6.2,6.2v18.4h-8.8V26.3h-4.7v70.4h4.7V65.9h-2.3v-8.8H53.7z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M74,17.5v8.8h-6.7v30.8H74v8.8h-6.7v30.8H74v8.8H58.5v-88H74z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M100.6,105.5H92l-4.8-39.2v39.2h-8.8v-88H87l4.8,35.8V17.5h8.8V105.5z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M121.2,17.5c3.5,0,6.4,2.8,6.4,6.2v75.4c0,3.5-2.9,6.4-6.4,6.4h-15.8v-88H121.2z M118.8,96.6V26.3h-4.7v70.3
	H118.8z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M148.4,17.5c3.5,0,6.2,2.8,6.2,6.2v81.7h-8.8V65.9h-4.7v39.6h-8.8V23.8c0-3.5,2.9-6.2,6.4-6.2H148.4z
	 M145.8,57.1V26.3h-4.7v30.8H145.8z"
                  />
                </svg>

              
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax> */}
      <div>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 461.5 290.6"
          enable-background="new 0 0 461.5 290.6"
        >
          <g id="mapa">
            <path
              fill="#003046"
              d="M331.7,112.1c0,0,0-0.1,0-0.1c0.1-0.2,0.2-0.4,0.3-0.6c0,0,0,0,0,0c-0.1,0-0.1,0,0-0.1c0,0,0.1,0.1,0.1,0.1
		c0,0,0,0,0,0c0.1,0.1,0.3,0.2,0.5,0.1c0.1-0.1,0.3-0.2,0.3-0.3c0.2-0.4,0.3-0.9,0.6-1.3c0.1-0.2,0.2-0.3,0.2-0.5
		c0.1-0.3,0.2-0.6,0.3-0.9c0.6-1.3,0.8-2.7,0.6-4.2c0-0.1,0-0.2,0.1-0.3c0,0,0.1-0.1,0.1-0.1c0.1,0,0.2,0,0.2,0.1
		c0.2,0.5,0.3,1.1,0.4,1.6c0,0.3,0,0.7,0.2,1c0.2,0.3,0.1,0.6-0.2,0.9c-0.1,0.1-0.2,0.3-0.1,0.5c0.1,0.2,0.2,0.3,0,0.5
		c-0.4,0.7-0.9,1.3-1.5,1.9c-0.3,0.2-0.5,0.5-0.5,0.8c0,0.3-0.2,0.5-0.5,0.7c-0.3,0.2-0.6,0.3-0.9,0.3
		C331.8,112.2,331.7,112.1,331.7,112.1 M327.9,113.7c0,0.1,0,0.1-0.1,0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2-0.1-0.3
		c0,0,0-0.1,0.1-0.1c0.1,0,0.2,0,0.2,0.2C327.9,113.5,327.9,113.6,327.9,113.7 M326.8,103.5C326.7,103.5,326.7,103.4,326.8,103.5
		c0.1-0.1,0.1,0,0.1,0C326.8,103.5,326.8,103.5,326.8,103.5 M326.8,104.9c0,0-0.1,0-0.1,0c-0.2-0.2-0.5-0.4-0.6-0.8c0,0,0-0.1,0-0.1
		c0-0.1,0.1-0.2,0.2,0c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.2,0.2,0.3,0.4,0.4C326.8,104.8,326.8,104.9,326.8,104.9 M321,117.7L321,117.7
		L321,117.7l0.1,0L321,117.7z M320.5,119.5c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.2,0.1c0,0,0-0.1-0.1-0.1c0,0,0-0.1,0-0.1
		c0.1-0.2,0.3-0.3,0.3-0.2C320.5,119.3,320.5,119.4,320.5,119.5 M319.9,116c-0.1,0.1-0.3,0.1-0.4,0c0-0.1-0.1-0.3-0.1-0.4
		c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.3,0,0.4,0.1c0,0.1,0,0.2,0.1,0.3C320,115.9,319.9,115.9,319.9,116 M312.3,64.2
		C312.2,64.2,312.2,64.2,312.3,64.2L312.3,64.2L312.3,64.2C312.2,64.1,312.2,64.2,312.3,64.2C312.3,64.2,312.3,64.2,312.3,64.2
		C312.3,64.3,312.3,64.2,312.3,64.2 M312.2,120.5c-0.1,0.1-0.2,0.2-0.4,0.1c-0.4-0.2-0.9-0.4-1.3-0.6c-0.2-0.1-0.2-0.2-0.2-0.5
		c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.2,0.1,0.3,0.2c0.2,0.3,0.5,0.5,0.8,0.5c0.2,0.1,0.4,0.2,0.6,0.3c0,0,0,0.1,0.1,0.1
		C312.3,120.5,312.3,120.5,312.2,120.5 M310.2,58.6L310.2,58.6L310.2,58.6L310.2,58.6z M308.2,58.8c-0.4,0.2-0.7,0.4-1,0.7
		c-0.1,0.1-0.2,0.1-0.3,0.1c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0-0.1c0,0,0,0,0.1,0c0.1-0.1,0.2-0.3,0.3-0.4
		c0.2-0.2,0.4-0.5,0.6-0.7c0,0,0,0,0,0c0,0,0,0,0,0c-0.3,0-0.6-0.1-0.7-0.4c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1,0,0.1,0
		c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.3,0.3,0.4c0,0,0,0,0.1,0c0,0,0,0,0,0c0.1,0,0.3,0,0.4,0.1c0.1,0,0.1,0.1,0.2,0.2
		C308.3,58.7,308.2,58.8,308.2,58.8 M306.5,59.9C306.5,59.9,306.5,59.9,306.5,59.9c-0.1,0.3-0.2,0.6-0.2,0.9c0,0.2-0.2,0.2-0.3,0.2
		c-0.3,0-0.4,0.3-0.4,0.6c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3l0,0.1l0,0c-0.1-0.1-0.2-0.2-0.3-0.3
		c-0.1-0.1-0.2-0.2-0.3-0.3c0,0,0,0,0-0.1c-0.2-0.2-0.6-0.2-0.7-0.5c-0.1-0.2-0.2-0.5-0.2-0.8c0-0.1,0-0.2-0.1-0.4c0,0,0,0,0,0
		l-0.1,0l0.1,0c0,0,0,0,0,0c0.1,0.1,0.3,0.2,0.4,0.3c0.3,0.1,0.6,0.2,0.9,0C306.1,60,306.2,59.9,306.5,59.9
		C306.5,59.9,306.5,59.9,306.5,59.9C306.5,59.8,306.5,59.8,306.5,59.9C306.5,59.8,306.5,59.9,306.5,59.9 M303.4,51.7L303.4,51.7
		L303.4,51.7L303.4,51.7z M301.6,123.7c-0.3,0.3-0.5,0.7-0.6,1.1c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.3-0.1-0.3-0.3c0,0,0-0.1,0-0.1
		c0-0.4,0.1-0.8,0.3-1.1c0.2-0.2,0.3-0.3,0.5-0.5c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2,0.1,0.2,0.2
		C301.8,123.3,301.7,123.5,301.6,123.7 M301.2,109.1c0,0,0,0.1,0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1-0.1
		C301,109.2,301.1,109.1,301.2,109.1 M301.3,56.4c0.1,0.1,0.2,0.2,0.3,0.3l0,0.1l0,0C301.5,56.6,301.4,56.5,301.3,56.4
		C301.3,56.4,301.3,56.4,301.3,56.4C301.3,56.4,301.3,56.4,301.3,56.4 M295.3,76.2c-0.1,0.2-0.3,0.2-0.3,0.1c0,0,0-0.1,0-0.1
		c0.1-0.2,0.2-0.2,0.3-0.2c0,0,0,0.1,0.1,0.1C295.3,76.1,295.3,76.1,295.3,76.2 M294.6,132.4l0-0.1L294.6,132.4L294.6,132.4z
		 M292.5,83.2c0,0-0.1,0-0.1-0.1C292.4,83,292.4,83,292.5,83.2C292.5,83.1,292.5,83.2,292.5,83.2 M292.1,82
		C292.1,82.1,292,82.1,292.1,82c-0.4-0.5-0.8-0.9-1.2-1.4c0,0,0-0.1,0-0.1c0.1,0,0.2-0.1,0.2-0.1c0.2,0.1,0.4,0.1,0.5,0.2
		c0.2,0.2,0.4,0.4,0.6,0.6C292.3,81.4,292.3,81.8,292.1,82 M286.1,127.2c-0.1,0.2-0.6,0.5-0.8,0.4c-0.3-0.1-0.6-0.2-0.9-0.3
		c-0.2-0.1-0.2-0.3-0.3-0.5c-0.1-0.9,0.9-1.8,1.7-1.7c0.2,0,0.4,0.1,0.4,0.3c0.1,0.2,0.2,0.4,0.3,0.6
		C286.3,126.5,286.2,126.8,286.1,127.2 M283.6,78.4L283.6,78.4L283.6,78.4L283.6,78.4z M280.5,138.9c-0.6,0.1-1.2,0.1-1.8,0.2
		c-0.1,0-0.3,0-0.4,0c-0.7-0.1-1.3-0.1-1.8-0.7c-0.2-0.2-0.4-0.3-0.6-0.3c-0.2,0-0.4-0.1-0.6-0.1c-0.1,0-0.3-0.1-0.3-0.3
		c-0.3-0.6-0.5-1.2-0.2-1.9c0.1-0.3,0.3-0.6,0.3-0.9c0-0.5,0.3-0.8,0.7-1c0.1-0.1,0.1-0.2,0-0.2c-0.1-0.1-0.1-0.1-0.2-0.2
		c-0.5-0.2-0.8-0.5-1-0.9c-0.2-0.5-0.6-0.9-1-1.2c-0.6-0.4-1-1-1.1-1.7c-0.1-0.6-0.4-1.1-0.9-1.5c-0.3-0.2-0.3-0.5-0.2-0.8
		c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.2,0.2-0.4,0.5-0.5c0.5-0.2,0.9-0.4,1.2-0.9c0.1-0.1,0.2-0.2,0.3-0.3c0.5-0.3,1.1-0.6,1.6-0.9
		c0.1-0.1,0.3-0.1,0.4-0.1c0.5,0.2,0.9,0.2,1.4,0.1c0.2,0,0.4,0.1,0.5,0.4c0.1,0.2,0.2,0.4,0.1,0.7c0,0.3-0.1,0.6-0.1,0.8
		c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.5,0-0.7,0c-0.6,0.1-0.8,0.3-0.7,1c0,0.3,0,0.3-0.2,0.4c0,0-0.1,0-0.1,0c-0.4,0.1-0.5,0.2-0.2,0.4
		c0.3,0.3,0.6,0.6,0.8,1c0.4,0.7,0.9,1.1,1.6,1.3c0.2,0.1,0.3,0.2,0.3,0.4c0,0.1,0,0.2,0,0.3c0,0.4,0,0.9,0.3,1.2
		c0.3,0.4,0.4,0.8,0.3,1.3c0,0.2,0,0.4,0.1,0.6c0.1,0.3,0.2,0.5,0.6,0.5c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0,0.3,0,0.4c0,0,0,0.1,0,0.1
		c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.2,0.5,0.6,0.5,1c0,0.7,0.3,1.4,0.5,2.1C280.6,138.8,280.6,138.9,280.5,138.9 M271,110.6l0-0.1
		L271,110.6L271,110.6z M265.2,132.7c-0.2,0.1-0.3,0.2-0.5,0.3c-0.3,0.2-0.6,0.3-0.9,0.1c-0.4-0.2-0.8-0.1-1.3,0.1
		c-0.3,0.1-0.6,0.1-0.8,0c-0.5-0.2-1.1-0.3-1.6-0.5c-0.1,0-0.2-0.2-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c-0.5-0.1-1-0.3-1.5-0.4
		c-1-0.2-1.9,0-2.8,0.4c-0.4,0.1-0.7,0.4-0.9,0.7c-0.2,0.2-0.4,0.3-0.6,0.2c-0.5-0.2-1-0.1-1.6-0.1c-0.7,0-1.3-0.3-1.6-1
		c-0.1-0.2-0.2-0.4-0.3-0.6c0-0.1,0-0.3,0-0.4c0.1-0.3,0.1-0.7,0.4-0.9c0.3-0.2,0.4-0.5,0.4-0.9c0-0.3,0-0.6,0.1-0.9
		c0-0.2,0.1-0.4,0.3-0.4c0.4-0.1,0.5-0.4,0.6-0.7c0-0.2,0.1-0.4,0.2-0.5c0.3-0.4,0.6-0.8,0.9-1.3c0.1-0.2,0.4-0.2,0.6-0.1
		c0,0,0.1,0,0.1,0.1c0.5,0.4,1,0.6,1.7,0.6c0.1,0,0.2,0.2,0.1,0.3c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0.1-0.1,0.3,0.1,0.4
		c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1,0.4,0.3,0.5,0.7c0.1,0.4,0.4,0.5,0.7,0.4c0.1-0.1,0.3-0.1,0.4-0.2c0.3-0.1,0.5-0.2,0.8-0.4
		c0.3-0.2,0.7-0.3,1.1-0.3c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.2,0.1-0.4c0-0.1-0.2-0.3-0.3-0.2c0,0-0.1,0-0.1,0
		c-1.1,0.1-1.1,0.1-1.7-0.7l-0.1,0l0.1-0.1c0,0,0,0,0,0l0,0c0.4-0.4,0.8-0.8,1.4-0.9c0.3,0,0.6-0.2,0.9-0.4c0.5-0.4,1.1-0.5,1.7-0.5
		c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0l0.1-0.1l0,0.1c0,0,0,0-0.1,0c-0.2,0.2-0.4,0.5-0.7,0.7c-0.2,0.2-0.2,0.3-0.1,0.5
		c0.1,0.1,0.1,0.3,0,0.4c-0.2,0.4-0.4,0.8-0.8,1.1c0,0-0.1,0.2,0,0.3c0.3,0.4,0.6,0.7,1.1,0.9c0.6,0.2,1.1,0.5,1.6,0.9
		c0.5,0.6,1.2,0.9,1.9,1.3c0.7,0.3,1,1,1.1,1.7C265.6,132.3,265.5,132.6,265.2,132.7 M251.5,134.2c-0.1,0.1-0.2,0.2-0.3,0.2
		c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.2,0c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3-0.1-0.3-0.2c0-0.1,0.1-0.3,0.2-0.3c0.4-0.2,0.8-0.2,1.2-0.1
		C251.7,133.7,251.7,133.9,251.5,134.2 M249.7,101.2c0-0.1-0.1-0.2-0.1-0.3c0.1-0.3,0.2-0.6,0.5-0.8c0,0,0.1,0,0.1,0
		c0.1,0,0.2,0.1,0.3,0.1c0.3,0.2,0.6,0.5,0.9,0.7c0.1,0.1,0.2,0.1,0.2,0.2c0.6,0.6,0.5,1.3-0.3,1.7c0,0-0.1,0.1-0.1,0.1
		c-0.2,0.1-0.4,0.1-0.5,0C250.3,102.3,250,101.7,249.7,101.2 M249.9,91.9C249.9,91.9,249.9,91.9,249.9,91.9
		C249.9,92,249.9,92,249.9,91.9C249.9,92,249.9,92,249.9,91.9C249.9,91.9,249.9,91.9,249.9,91.9L249.9,91.9L249.9,91.9z M250.1,87.8
		c0-0.1,0.1-0.1,0.1-0.2C250.2,87.7,250.2,87.8,250.1,87.8C250.1,88,250.1,88,250.1,87.8C250.1,87.9,250.1,87.9,250.1,87.8
		 M253.1,95.8c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.2,0.1,0.2c0,0-0.1,0-0.1,0.1c-0.1-0.1-0.2-0.1-0.2-0.2
		C253.1,96,253.1,95.9,253.1,95.8 M254.7,121.2c0,0-0.1,0-0.1,0c0,0,0-0.1-0.1-0.1C254.6,121.1,254.6,121.1,254.7,121.2
		C254.7,121.1,254.7,121.2,254.7,121.2 M253.9,99c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0,0.1,0,0.1
		c0.1,0.5,0.4,1,0.7,1.4c0.1,0.1,0.1,0.3,0.2,0.5c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0-0.2-0.2
		c0-0.4-0.2-0.7-0.6-0.9c-0.3-0.2-0.5-0.4-0.6-0.7C253.6,99.2,253.7,99,253.9,99 M257.4,123.5C257.4,123.5,257.4,123.6,257.4,123.5
		c-0.1,0.1-0.1,0.1-0.1,0c0,0,0-0.1,0-0.1C257.3,123.5,257.4,123.5,257.4,123.5 M257.2,102.4c0,0,0.1,0,0.1,0c0.1,0.1,0.1,0.2,0,0.2
		c0,0-0.1,0-0.1-0.1C257.1,102.5,257.2,102.5,257.2,102.4 M258.1,105C258.1,105,258.1,105,258.1,105c0.1,0,0.1,0,0.2,0
		c0,0,0,0,0,0.1c0.2,0.2,0.4,0.4,0.7,0.7c0.1,0.1,0,0.3-0.1,0.4c-0.3,0.1-0.5,0-0.6-0.2c-0.1-0.1-0.1-0.2-0.1-0.4c0,0,0-0.1,0-0.1
		c0-0.1,0-0.2,0.1-0.4c0,0,0,0,0,0C258.2,105,258.1,105,258.1,105c0,0-0.1,0-0.1,0C257.9,104.9,257.9,104.9,258.1,105 M259,185
		c0.4,0,0.6,0.2,0.8,0.5c0,0.1,0.1,0.2,0,0.3c-0.2,0.5-0.5,1-0.9,1.4c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.1,0,0.2-0.1,0.3
		c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0-0.6,0c-0.1,0-0.1-0.1-0.2-0.2c0-0.1,0-0.2,0-0.3
		c0.1-0.6,0.2-1.1,0.2-1.7c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.2-0.3,0.3-0.3C258.1,185.2,258.6,185.1,259,185 M258.7,183.5L258.7,183.5
		L258.7,183.5L258.7,183.5z M260.1,197.8c0.1,0.3,0.1,0.6,0.2,0.8c0.2,0.4,0.2,0.7,0,1.1c-0.2,0.4-0.2,0.7-0.1,1.1
		c0.1,0.3,0.1,0.6,0.2,0.8c0,0.1,0,0.2,0,0.3c-0.1,0.1-0.2,0-0.2-0.1c-0.3-0.5-0.4-1-0.6-1.6c0-0.2,0-0.4,0-0.6
		c0.2-0.5,0.2-1,0.1-1.5c-0.1-0.3,0-0.6-0.1-0.8c0,0,0-0.1,0.1-0.1c0.1,0,0.2,0.1,0.2,0.1C260,197.4,260,197.6,260.1,197.8
		 M257.3,149.7C257.3,149.7,257.3,149.7,257.3,149.7C257.3,149.7,257.4,149.7,257.3,149.7C257.4,149.8,257.3,149.8,257.3,149.7
		C257.3,149.7,257.3,149.7,257.3,149.7L257.3,149.7z M257.9,150.4c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1
		C257.9,150.5,257.9,150.5,257.9,150.4C257.8,150.5,257.9,150.4,257.9,150.4 M263.5,170.7c0.1,0,0.1,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2
		C263.6,170.9,263.5,170.8,263.5,170.7C263.5,170.7,263.5,170.7,263.5,170.7 M256.1,183.1L256.1,183.1L256.1,183.1L256.1,183.1
		L256.1,183.1z M255.8,195.3c0,0-0.1,0-0.1,0c-0.2-0.2-0.2-0.4-0.1-0.6c0,0,0,0,0,0c0,0,0,0,0-0.1c-0.2-0.2-0.2-0.5-0.4-0.6
		c-0.2-0.2-0.4-0.4-0.6-0.6c-0.1-0.2-0.3-0.4-0.3-0.6c-0.1-0.8-0.4-1.7-0.3-2.5c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.1
		c0.1,0.5,0.2,1.1,0.4,1.7c-0.2,0.6,0,1,0.5,1.3c0.2,0.2,0.3,0.5,0.4,0.7c0,0.2,0.1,0.4,0.1,0.6c0,0,0,0,0,0c0,0,0,0,0,0
		C255.8,194.7,255.9,195,255.8,195.3C255.9,195.2,255.9,195.3,255.8,195.3 M254.4,198.3C254.4,198.4,254.4,198.4,254.4,198.3
		c0,0.1,0,0.2,0,0.2c0,0,0,0-0.1,0C254.3,198.5,254.3,198.4,254.4,198.3C254.4,198.4,254.4,198.3,254.4,198.3L254.4,198.3
		L254.4,198.3z M253.4,196c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1-0.1-0.1-0.1c0-0.2,0.2-0.3,0.3-0.2c0,0,0,0.1,0.1,0.1
		C253.4,195.9,253.4,196,253.4,196 M254,187.5L254,187.5L254,187.5L254,187.5z M252.6,205.1C252.6,205,252.6,205,252.6,205.1
		c0.1-0.1,0.1,0,0.1,0C252.7,205.1,252.7,205.1,252.6,205.1 M248.7,222.5L248.7,222.5l0.1,0L248.7,222.5z M220,176.2
		c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.2-0.1c0,0,0,0.1,0,0.1C220.1,176.2,220.1,176.2,220,176.2 M219.2,175.1c0,0-0.1-0.1-0.1-0.1
		C219.1,175.1,219.2,175.1,219.2,175.1L219.2,175.1L219.2,175.1z M218.9,174.9L218.9,174.9c0.1,0,0.1,0.1,0.1,0.1
		C219,175,219,174.9,218.9,174.9 M247.8,107.1L247.8,107.1L247.8,107.1L247.8,107.1L247.8,107.1z M247.4,106c0,0-0.1,0.1-0.1,0.1
		c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.3,0.1-0.4c0,0,0.1,0,0.1,0C247.4,105.6,247.5,105.8,247.4,106 M248.5,98.8c0,0,0-0.1,0-0.1
		c0,0,0-0.1,0.1-0.1C248.6,98.7,248.6,98.7,248.5,98.8C248.6,98.8,248.6,98.8,248.5,98.8 M248.1,97C248.2,96.9,248.2,96.9,248.1,97
		c0.2-0.1,0.2,0,0.3,0c0,0,0,0.1,0.1,0.1c0,0-0.1,0.1-0.1,0.1C248.4,97.1,248.3,97,248.1,97 M247.8,100.4c-0.1,0.1-0.2,0.2-0.1,0.4
		c0.1,0.1,0.1,0.3-0.1,0.3c-0.1,0-0.3-0.1-0.4-0.1c-0.2-0.1-0.2-0.3-0.1-0.5c0.2-0.2,0.3-0.3,0.5-0.5c0,0,0.1,0,0.1,0
		c0,0,0.1,0.1,0.1,0.1C247.9,100.2,247.8,100.3,247.8,100.4 M247.4,99.2L247.4,99.2l-0.1,0L247.4,99.2z M246,95.1
		c-0.1,0-0.2,0.1-0.2,0.1c-0.1-0.1-0.2-0.2-0.3-0.3c0,0,0.1-0.1,0.1-0.2c0.2,0,0.3,0.1,0.4,0.3C246,95,246,95.1,246,95.1
		 M245.3,98.2c0,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.3-0.2c0,0,0.1-0.1,0.1,0c0.1,0,0.2,0.1,0.2,0.1c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0.1,0.1
		C245.4,98.2,245.3,98.2,245.3,98.2 M244.7,85.1c0-0.1,0.1-0.2,0.2-0.3c0,0,0,0,0-0.1l0.1-0.1l-0.1,0.1c0,0,0,0,0,0
		c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.1-0.2,0.2c0,0-0.1,0-0.1,0C244.8,85.3,244.7,85.2,244.7,85.1 M244.5,100.2l0.1-0.1L244.5,100.2
		L244.5,100.2z M242.1,81.6C242.1,81.6,242.1,81.6,242.1,81.6c0,0.1-0.1,0.1-0.1,0.1c0,0.2,0,0.4,0,0.6c0,0,0,0.1-0.1,0.1
		c0,0-0.1-0.1-0.1-0.1c0-0.2,0-0.4,0.1-0.6C242.1,81.7,242.1,81.6,242.1,81.6C242.1,81.6,242.1,81.6,242.1,81.6
		C242.2,81.5,242.2,81.5,242.1,81.6C242.2,81.5,242.2,81.6,242.1,81.6 M240.1,82.2C240.1,82.2,240.1,82.2,240.1,82.2
		c-0.1,0.1-0.1,0.2-0.2,0.2c0,0,0-0.1,0-0.1C240,82.3,240,82.3,240.1,82.2C240.1,82.2,240.1,82.2,240.1,82.2L240.1,82.2L240.1,82.2z
		 M238.7,83.1c0,0.1-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0c0-0.1-0.1-0.2-0.1-0.2c0,0,0.1-0.1,0.2-0.1C238.6,82.9,238.7,83.1,238.7,83.1
		 M236.4,104.6c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0.1,0,0.1c-0.1,0-0.2,0-0.3,0C236.4,104.7,236.4,104.7,236.4,104.6
		 M234.1,106.7C234.1,106.7,234.1,106.7,234.1,106.7c0,0.1,0,0.1-0.1,0.2c0,0,0,0.1,0,0.1C234,106.9,234.1,106.8,234.1,106.7
		C234.1,106.7,234.1,106.7,234.1,106.7c0-0.1,0-0.2,0.1-0.2c0,0,0,0.1,0,0.1C234.2,106.6,234.2,106.6,234.1,106.7 M233.2,106
		c0,0.3-0.2,0.4-0.4,0.5c-0.2,0-0.4,0-0.7,0v0c0,0,0,0,0,0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.3,0-0.6,0.4-0.8
		c0.1,0,0.1-0.2,0.2-0.3c0.1-0.2,0.2-0.3,0.3-0.2c0.1,0.1,0.2,0.2,0.2,0.3C233.2,105.7,233.2,105.8,233.2,106 M229.5,97.2
		L229.5,97.2L229.5,97.2L229.5,97.2z M313.1,54.1C313.1,54.1,313.1,54.2,313.1,54.1C313.1,54.2,313.1,54.2,313.1,54.1L313.1,54.1
		L313.1,54.1z M342.5,169.4C342.5,169.5,342.5,169.5,342.5,169.4C342.5,169.6,342.4,169.6,342.5,169.4
		C342.4,169.5,342.5,169.4,342.5,169.4 M342.9,60.2c0.2,0,0.4,0.1,0.5,0.1c0,0,0,0.1,0.1,0.1c0,0.1-0.1,0.2-0.1,0.2
		c-0.2,0.1-0.4,0-0.6-0.2C342.7,60.3,342.8,60.2,342.9,60.2 M347.6,55.1c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0.1-0.1,0.1
		c-0.1,0.1-0.2,0.2-0.3,0.3c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.2-0.1C347.2,55.2,347.3,55.1,347.6,55.1 M347.6,115.3
		C347.5,115.3,347.5,115.3,347.6,115.3c-0.1-0.1,0-0.2,0-0.3c0,0,0.1,0,0.1,0C347.6,115.2,347.6,115.2,347.6,115.3 M348.8,55.9
		l0-0.1L348.8,55.9C348.9,55.9,348.9,55.9,348.8,55.9c0.3,0.1,0.4,0.2,0.3,0.5c0,0.2-0.1,0.3-0.2,0.5c0,0-0.1,0-0.1,0
		c0,0-0.1-0.1-0.1-0.1C348.8,56.5,348.8,56.2,348.8,55.9C348.8,55.9,348.8,55.9,348.8,55.9 M349.4,148
		C349.4,148,349.4,148,349.4,148c0.2,0,0.4,0,0.6-0.2c0.1-0.1,0.1,0,0.1,0.1c0,0.1-0.1,0.2-0.1,0.2
		C349.8,148.2,349.6,148.2,349.4,148C349.4,148,349.4,148,349.4,148L349.4,148C349.4,148,349.4,148,349.4,148 M355.1,103.3
		C355.2,103.3,355.2,103.3,355.1,103.3c0.1,0,0.2-0.1,0.2-0.1c0,0,0,0,0,0.1C355.3,103.3,355.3,103.3,355.1,103.3
		C355.2,103.3,355.2,103.3,355.1,103.3L355.1,103.3L355.1,103.3z M368,58.4C368,58.4,368.1,58.4,368,58.4c0.1,0,0.2,0,0.3,0.2
		c0,0-0.1,0.1-0.1,0C368.1,58.6,368.1,58.5,368,58.4C368,58.5,368,58.5,368,58.4c-0.1,0-0.1,0-0.1,0C368,58.4,368,58.4,368,58.4
		 M392.1,66.8c0.2,0,0.4-0.1,0.5-0.1c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.2c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0-0.3-0.2
		C391.9,66.9,392,66.8,392.1,66.8 M366.7,120.2c0.1,0,0.3,0.1,0.3,0.2c0,0.1,0,0.3,0.1,0.4c0,0.1-0.1,0.3-0.2,0.4c0,0-0.2,0-0.2,0
		c-0.1-0.2-0.2-0.4-0.3-0.6C366.4,120.4,366.6,120.2,366.7,120.2 M354.3,147.6C354.4,147.6,354.4,147.6,354.3,147.6
		c0.1,0.2,0.1,0.2,0.1,0.3c0,0-0.1,0-0.1,0.1c-0.1-0.1-0.1-0.2-0.1-0.3C354.2,147.6,354.3,147.6,354.3,147.6 M355.7,141.2
		C355.8,141.2,355.8,141.2,355.7,141.2c0,0.1,0,0.1,0,0.1c0,0-0.1,0-0.1,0C355.6,141.2,355.6,141.2,355.7,141.2 M358.2,144.2
		C358.2,144.2,358.3,144.3,358.2,144.2c0.1,0.2,0.1,0.2,0.1,0.3c0,0,0,0,0,0l0.1,0.1l-0.1,0c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1
		C358,144.3,358.1,144.2,358.2,144.2 M333.5,164.8c0.1,0,0.2-0.1,0.3,0c0.3,0.3,0.6,0.4,0.7,0.9c0.1,0.3,0.1,0.7,0.2,1
		c0.3,0.8,0.4,1.7,0.9,2.4c0.1,0.1,0.2,0.3,0.2,0.5c0.1,0.2,0.1,0.4,0.1,0.6c0.2,0.7,0.2,1.4,0,2.1c-0.1,0.2-0.1,0.4-0.1,0.6
		c0.2,0.5,0.1,1-0.1,1.5c-0.2,0.6-0.1,1,0.5,1.3c0,0,0.1,0,0.1,0.1c0.6,0.7,1.1,1.4,1.7,2.1c0.3,0.4,0.2,0.8,0.3,1.2
		c0,0.1,0.1,0.2,0.1,0.3c0.2,0.3,0.2,0.5,0.2,0.8c0,0.3,0.1,0.6,0.3,0.8c0.3,0.3,0.6,0.6,0.7,1.1c0,0.1,0.2,0.2,0.3,0.3
		c0.1,0.1,0.3,0.2,0.4,0.4c0.2,0.4,0.6,0.6,1,0.8c0.5,0.2,0.9,0.5,1.4,0.7l0,0c0,0,0.1,0,0.1,0c0,0,0,0-0.1,0
		c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.1,0.2-0.3,0.1-0.5c-0.2-0.4-0.3-0.8-0.6-1.1c-0.3-0.4-0.4-0.8-0.4-1.3c0-0.3,0-0.6,0-0.8
		c0-0.3-0.1-0.7-0.3-0.9c-0.4-0.4-0.8-0.8-1.2-1.2c-0.6-0.2-0.9-0.8-1.5-1c-0.4-0.2-0.7-0.5-0.7-1c0-0.3-0.1-0.6-0.3-0.8
		c-0.3-0.3-0.5-0.7-0.6-1.1c-0.4-0.1-0.6-0.3-0.7-0.8c0-0.3,0-0.6,0.1-0.8c0.3-1,0.7-2.1,0.8-3.2c0,0,0-0.1,0.1-0.1
		c0.1-0.1,0.2-0.1,0.2-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.2c0.1,0.5,0.4,0.7,0.9,0.7c0.1,0,0.3,0,0.4,0.1
		c0.5,0.4,1.1,0.6,1.4,1.2c0.3,0.7,1,1.1,1.5,1.6c0.1-0.1,0.3-0.2,0.4-0.1c0.3,0.2,0.6,0.3,0.6,0.7c0,0.4,0,0.8,0,1.1
		c0,0.1,0.3,0.2,0.4,0.1c0,0,0.1,0,0.1-0.1c0.5-0.5,1.1-1,1.6-1.6c0.1-0.1,0.3-0.3,0.5-0.3c0.7-0.2,1.3-0.6,1.8-1.1
		c0.3-0.3,0.5-0.7,0.6-1.1c0.2-0.7,0.3-1.3,0-1.9c0,0,0-0.1,0-0.1c-0.1-0.9-0.4-1.7-0.8-2.4c-0.2-0.3-0.4-0.5-0.6-0.8
		c-0.5-0.5-1-0.8-1.6-1.2c-0.4-0.2-0.7-0.5-0.8-1c0-0.2-0.2-0.4-0.4-0.6c-0.1-0.1-0.3-0.2-0.4-0.4c-0.1-0.1-0.1-0.2-0.1-0.2
		c0-0.5-0.2-1,0.3-1.4c0.2-0.2,0.4-0.4,0.4-0.7c0-0.2,0.1-0.4,0.3-0.5c0.5-0.3,1.1-0.6,1.6-0.9c0.1-0.1,0.3-0.1,0.4,0
		c0.1,0,0.3,0.1,0.4,0.1c0.3,0.1,0.6,0.2,0.7,0.6c0,0.3,0.2,0.5,0.5,0.7c0.2,0.2,0.6,0.1,0.7-0.3c0-0.1,0-0.3,0-0.4l0,0l0-0.1l0,0
		c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.2-0.2c0.7-0.1,1.2-0.5,1.9-0.7c0.5-0.1,1-0.5,1.3-1c0.1-0.2,0.2-0.2,0.3-0.1
		c0.2,0.1,0.3,0.2,0.5,0.1c0.4-0.2,0.8-0.3,1.2-0.4c0.3,0,0.6-0.2,0.9-0.3c0.3-0.1,0.6-0.3,0.7-0.7c0-0.2,0.2-0.2,0.3-0.3
		c0.5-0.3,1-0.6,1.2-1.2c0,0,0-0.1,0.1-0.1c0.4-0.2,0.6-0.6,0.7-1c0.1-0.2,0.2-0.1,0.3-0.2c0.2-0.1,0.3-0.2,0.2-0.4
		c-0.1-0.4-0.1-0.7-0.1-1.1c0-0.2-0.1-0.4,0.2-0.6c0.4-0.2,0.7-0.7,0.7-1.2c0-0.3,0.1-0.6,0.4-0.8c0.3-0.3,0.4-0.6,0.4-1
		c0-0.2,0-0.5,0.2-0.7c0.3-0.4,0.1-0.7-0.2-1c-0.1-0.2-0.3-0.3-0.5-0.5c-0.1-0.1-0.3-0.1-0.5-0.1c0,0,0,0,0,0l-0.1,0l0,0
		c0,0,0,0,0,0c0-0.1,0-0.3,0.1-0.4c0.1-0.2,0.3-0.3,0.5-0.5c0.1-0.1,0.1-0.3,0.1-0.5c-0.2-0.3-0.4-0.5-0.7-0.7c0,0,0,0,0,0l-0.1-0.1
		l0.1,0c0,0,0,0,0,0c0.1,0,0.2,0,0.2-0.1c0.3-0.2,0.4-0.5,0.2-0.7c-0.2-0.2-0.3-0.4-0.5-0.5c-0.4-0.2-0.6-0.5-0.8-0.8
		c-0.2-0.5-0.5-0.9-0.7-1.4c-0.2-0.3-0.4-0.6-0.7-0.7c-0.2-0.1-0.3-0.2-0.5-0.3c-0.4-0.2-0.5-0.5-0.5-1c0-0.3,0-0.6,0.2-0.8
		c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.4,0.5-0.7,0.9-0.9c0.4-0.2,0.8-0.4,1.2-0.4c0.2,0,0.2-0.1,0.3-0.3c0-0.1,0-0.3,0-0.4
		c0-0.2-0.2-0.5-0.4-0.5c-0.2,0-0.5,0-0.7,0c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4,0-0.5,0
		c-0.3,0.2-0.6,0.5-0.8,0.8c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0.1-0.5,0-0.6-0.3c-0.1-0.4-0.3-0.7-0.8-0.8c-0.4-0.1-0.7-0.4-1-0.8
		c-0.2-0.3,0-0.9,0.3-0.9c0.1,0,0.3,0,0.4,0c0.3,0,0.5-0.2,0.6-0.5c0.2-0.5,0.2-1.1,0.9-1.4c0.1-0.1,0.2-0.3,0.3-0.5
		c0.1-0.2,0.3-0.5,0.4-0.7c0.1-0.1,0.2-0.2,0.3-0.2c0.4-0.1,0.6,0.1,0.9,0.4c0.2,0.2,0.1,0.4,0,0.7c0,0.1-0.1,0.2-0.1,0.3
		c-0.2,0.3-0.2,0.6-0.1,1c0.1,0.1,0.1,0.3,0.1,0.4c0.1,0.2-0.1,0.3-0.2,0.5c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.2,0.1,0.2
		c0.1,0,0.2,0.1,0.3,0.1c0.3-0.1,0.5-0.4,0.7-0.7c0.5-0.7,1.2-1,2.1-1.1c0.1,0,0.3,0,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1
		c0.6,0.2,0.6,0.2,0.6,0.8c0,0.5,0,0.9,0,1.4c0,0.3,0.1,0.5,0.3,0.6c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.3-0.1,0.4-0.1
		c0.2-0.1,0.5,0,0.7,0.2c0.2,0.2,0.3,0.3,0.5,0.5c0.2,0.2,0.1,0.3-0.1,0.6c-0.2,0.2-0.2,0.3-0.1,0.5c0.5,0.5,0.7,1.1,0.6,1.8
		c0,0.2,0,0.4,0,0.6c0,0.4,0.2,0.7,0.4,1c0,0,0.1,0,0.1,0c0.3,0,0.4-0.2,0.6-0.3c0.3-0.2,0.5-0.4,0.9-0.3c0.1,0,0.3,0,0.4,0
		c0.3,0.1,0.5-0.2,0.7-0.4c0.4-0.5,0.5-1,0.3-1.6c-0.1-0.3-0.1-0.6-0.2-0.8c-0.3-1.1-0.3-1.1-1.1-2c-0.3-0.3-0.6-0.6-0.8-1
		c-0.2-0.4-0.4-0.6-0.8-0.8c-0.2-0.2-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.7-0.1-0.9c0.1-0.2,0.2-0.3,0.4-0.4c0.3-0.2,0.5-0.5,0.7-0.7
		c0.6-0.6,0.8-1.3,0.5-2.1c-0.2-0.6,0-1.1,0.5-1.6c0.1-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.3-0.3,0.4-0.4c0.2-0.2,0.2-0.6,0.5-0.7
		c0.4-0.1,0.6,0.3,0.9,0.4c0.3,0.1,0.6,0.2,1,0c0.3-0.1,0.6-0.3,0.8-0.6c0.4-0.5,0.7-1,0.8-1.5c0.3-0.8,0.8-1.6,1-2.5
		c0-0.2,0.1-0.4,0.2-0.5c0.6-0.8,0.6-1.7,0.7-2.6c0.1-0.8,0.4-1.5,0.4-2.2c0-0.1,0-0.3,0.1-0.4c0.3-0.5,0.2-1,0.2-1.5
		c-0.1-1-0.4-1.9-0.9-2.7c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.8,0-1.6-0.1-2.4c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1,0,0.1,0.1
		c0.3,0.4,0.7,0.8,1,1.2c0.4,0.4,0.3,1,0.5,1.5c0.1,0.2,0.2,0.4,0.3,0.6c0.3,0.5,0.5,1,0.5,1.6c0,0.1,0,0.2,0,0.3
		c0.2,0.6,0.2,1.3,0.7,1.9c0.2,0.2,0.3,0.6,0.3,0.9c0,0.5,0.2,0.9,0.4,1.4c0.2,0.3,0.2,0.6,0.2,1c0,0.1,0,0.3,0,0.4
		c0.1,0.3,0.3,0.6,0.6,0.8c0.1,0,0.2,0,0.2-0.1c0-0.2,0-0.4,0-0.6c0-0.3,0.1-0.4,0.4-0.3c0.3,0,0.5,0.3,0.7,0.4c0,0,0.1,0,0.1,0
		c0-0.1,0.1-0.2,0.1-0.2c-0.1-0.3-0.2-0.7-0.5-0.8c-0.6-0.3-0.7-1-1.3-1.3c-0.1-0.4-0.3-0.7-0.4-1.1c-0.1-0.5-0.1-0.9-0.2-1.4
		c0-0.1,0-0.3,0-0.4c0-0.2,0.2-0.4,0.3-0.4c0.2,0,0.5,0,0.7-0.1c0.1-0.2,0-0.5-0.1-0.7c-0.9-1.5-1.6-3-2.7-4.4
		c-0.3-0.4-0.6-0.9-0.6-1.4c0-0.2,0-0.5-0.2-0.7c-0.4-0.7-0.6-1.4-1.2-1.9c-0.2-0.2-0.3-0.5-0.4-0.7c-0.1-0.3-0.4-0.4-0.7-0.4
		c-0.1,0-0.2,0.1-0.2,0.1c0,0,0,0.1,0,0.1c0,0.1,0.1,0.2,0.1,0.2c0.5,0.6,0.5,0.6,0,1.3c0,0.1-0.1,0.2-0.1,0.3
		c0,0.2,0.1,0.4,0.2,0.5c0.3,0.4,0.4,1,0.5,1.5c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.1-0.3,0-0.3-0.2c-0.1-0.3-0.1-0.6-0.3-0.8
		c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.4-0.2-0.7-0.5-0.9c-0.6-0.4-1.2-0.6-1.7-1.1c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.5,0.1-0.7,0.1
		c-0.3,0-0.4,0.1-0.5,0.4c-0.1,0.3-0.2,0.6-0.4,0.9c0,0-0.1,0-0.1,0c-0.2-0.4-0.4-0.8-0.6-1.1c0,0-0.1-0.1-0.1-0.1
		c-0.1,0-0.2,0.1-0.2,0.2c-0.2,0.2-0.1,0.3-0.1,0.5c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0-0.2-0.1c-0.1-0.4-0.3-0.7-0.4-1.1
		c-0.1-0.3-0.3-0.4-0.7-0.3c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.4,0c-0.3,0-0.5-0.3-0.4-0.5c0.1-0.1,0.1-0.3,0.2-0.4
		c0.2-0.4,0.5-0.8,0.7-1.3c0.3-0.5,0.5-0.9,0.6-1.5c0.1-1,0.4-1.8,0.8-2.7c0.3-0.6,0.5-1.2,0.4-1.9c-0.1-0.3,0-0.6,0.2-0.8
		c0.3-0.4,0.3-0.8,0.2-1.2c-0.1-0.2,0-0.4,0.1-0.5c0.2-0.4,0.5-0.6,1-0.7c0.6-0.1,1.1-0.4,1.6-0.6c0.2-0.1,0.5-0.1,0.7,0.1
		c0.2,0.1,0.5,0.1,0.6-0.2c0.2-0.3,0.5-0.5,0.8-0.4c0.6,0.1,1-0.2,1.5-0.2c0.1,0,0.2-0.1,0.2-0.2c0.1-0.2,0.1-0.5,0.2-0.7
		c0.1-0.2,0.3-0.4,0.5-0.4c0.7,0.1,1.3,0.1,2,0.2c0.1,0,0.3,0.1,0.4,0.2c0.1,0,0.1,0.2,0.1,0.2c0,0.1-0.1,0.2-0.1,0.2
		c-0.1,0.2-0.1,0.4,0.1,0.5c0.1,0.1,0.3,0.3,0.5,0.2c0.5-0.3,1-0.5,1.4-1c0.1-0.2,0.3-0.2,0.5-0.1c0.2,0.1,0.4,0,0.5-0.2
		c0.2-0.2,0.5-0.3,0.5-0.7c-0.1-0.4-0.4-0.3-0.7-0.4c0,0-0.1,0-0.1,0c-0.2,0-0.3-0.1-0.3-0.3c-0.2-0.5-0.3-1,0-1.5
		c0-0.1,0-0.2,0.1-0.3c0.2-0.4,0.2-0.8,0.2-1.3c0-0.6,0-1.1,0-1.7c0-0.4,0.2-0.7,0.6-0.9c0.3-0.1,0.6-0.2,0.9-0.4
		c0.3-0.2,0.6-0.2,1-0.1c0.2,0.1,0.4-0.1,0.5-0.2c0.2-0.1,0.3,0,0.3,0.1c0,0.3,0.1,0.7,0.1,1c0,0.3,0.2,0.5,0.4,0.7
		c0.3,0.2,0.6,0.3,0.8,0.5c0,0,0.2,0,0.2-0.1c0.1-0.1,0.2-0.2,0.2-0.4c0.1-0.6,0.2-1.1,0.3-1.7c0-0.4,0.2-0.6,0.6-0.7
		c0.2-0.1,0.2-0.3,0.2-0.5c0,0-0.1,0-0.1,0c-0.6-0.5-0.8-1.2-1-2c0,0,0-0.1,0.1-0.1c0.2-0.3,0.6-0.5,1-0.3c0.1,0,0.2,0.2,0.1,0.4
		c-0.3,0.4-0.2,0.8,0,1.2c0.3,0.5,0.6,1,0.6,1.6c0,0.1,0.1,0.2,0.1,0.2c0.3,0.4,0.1,0.9,0.3,1.4c-0.1,0.3-0.4,0.6-0.5,0.8
		c0,0-0.1,0.1-0.1,0.1c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.8,0.1,1.5-0.2,2.2c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.7-0.1,1.4,0,2.1
		c0,0.6,0,1.1-0.5,1.6c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4,0.3-0.4,0.3-0.2,0.6c0.3,0.4,0.3,0.8,0.2,1.2
		c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.3-0.1,0.6-0.1,1c0.1,0.6,0.3,1.1,0.5,1.6c0.5,1.8,1.6,3.4,2.6,4.9c0.3,0.4,0.6,0.9,0.9,1.3
		c0.2,0.3,0.3,0.6,0.6,0.8c0.3,0.3,0.5,0.7,0.7,1c0.2,0.4,0.3,0.8,0.5,1.2c0.2,0.4,0.6,0.6,0.9,0.8c0,0,0.1,0,0.1,0
		c0.3-0.2,0.3-0.6,0.5-0.9c0.4-0.7,0.4-1.5,0.1-2.3c-0.3-0.9-0.4-1.1,0.6-1.6c0,0,0.1,0,0.1-0.1c0.1-0.1,0.2-0.2,0.1-0.3
		c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.4-0.2-0.5c0-0.4,0-0.9,0.1-1.3c0-0.3,0.2-0.5,0.5-0.6c0.2-0.1,0.4-0.2,0.6-0.3
		c0.2-0.1,0.3-0.5,0.1-0.6c-0.2-0.3-0.4-0.5-0.6-0.8c-0.6-0.6-0.8-1.4-0.7-2.2c0-0.1,0.2-0.3,0.3-0.2c0.1,0,0.2,0.1,0.3,0.1
		c0.3,0,0.6-0.3,0.4-0.6c-0.2-0.3-0.4-0.6-0.6-1c-0.1-0.3-0.3-0.4-0.7-0.4c-0.1,0-0.2-0.1-0.2-0.1c-0.2-0.4-0.4-0.8-0.2-1.2
		c0.1-0.1,0-0.3,0-0.4c0-0.1-0.1-0.3-0.2-0.4c-0.2-0.2-0.5-0.3-0.8-0.4c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1-0.1,0.2-0.2,0.3
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0.1,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.3-0.1-0.4-0.3-0.6-0.6
		c-0.2-0.5-0.3-1-0.2-1.5c0-0.8,0-1.6-0.5-2.3c-0.1-0.1-0.1-0.2-0.1-0.3c-0.2-0.5,0.1-0.9,0.6-1.1c0.1,0,0.2,0,0.3,0
		c0.3,0,0.3-0.1,0.4-0.3c0-0.2,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.1,0.3,0c0.1,0.2,0.2,0.4,0.4,0.6c0,0.1,0.2,0.1,0.2,0.1
		c0.1,0,0.2-0.1,0.2-0.2c0.1-0.2,0.1-0.5,0.1-0.7c0.1-0.3,0.3-0.6,0.5-0.9c0.3-0.3,0.7-0.5,1.1-0.5c0.3-0.1,0.5,0.1,0.8,0.3
		c0.2,0.2,0.5,0.3,0.7,0.5c0.1,0.1,0.2,0.1,0.2,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3-0.1,0.3-0.3c0-0.3-0.3-0.6-0.2-1
		c0.1-0.3,0.1-0.7,0.2-1c0.1-0.5,0.2-0.9,0.2-1.4c0.1-0.7,0.2-1.4,0.6-2c0.2-0.4,0.4-0.7,0.6-1.1c0.3-0.6,0.6-1.1,1.1-1.6
		c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.7,0,1,0c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.2-0.2c0-0.6,0.1-1.2-0.5-1.7
		c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.1-0.2-0.3-0.3-0.3c-0.5-0.2-0.9-0.7-1.5-0.8c0,0,0,0,0,0l-0.1,0l0.1-0.1c0,0,0,0,0,0
		c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.3-0.3-0.6-0.7-0.6c-0.1,0-0.2,0-0.2-0.1c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.4-0.3-0.5-0.8-0.4
		c-0.4,0.1-0.7-0.1-1-0.4c-0.1-0.1-0.1-0.3,0.1-0.4c0.2-0.1,0.4-0.1,0.5-0.2c0.5-0.1,0.8-0.5,0.8-1c0-0.6,0.1-1.1,0-1.7
		c0-0.2-0.1-0.4-0.1-0.5c-0.1-0.2-0.3-0.4-0.3-0.6c-0.1-0.3-0.4-0.5-0.7-0.6c-0.4-0.1-0.6-0.3-0.8-0.6c-0.1-0.1-0.1-0.2-0.1-0.4
		c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.2,0.3-0.3,0.4-0.1c0.1,0.3,0.3,0.6,0.7,0.7c0.9,0.4,0.9,0.4,1.4-0.5c0-0.1,0.1-0.2,0.1-0.3
		c0.2-0.3,0.4-0.4,0.7-0.4c0.2-0.1,0.4,0.1,0.5,0.2c0.2,0.2,0.3,0.3,0.5,0.5c0.3,0.3,0.6,0.4,0.9,0.2c0.3-0.2,0.6-0.3,0.9-0.2
		c0.2,0,0.5,0.1,0.7,0c0.2,0,0.4-0.1,0.6-0.1c0.1,0,0.2-0.1,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2c-0.1-0.5,0-1-0.7-1
		c-0.2,0-0.3-0.2-0.3-0.4c0-0.1,0-0.2,0-0.3c0-0.5-0.1-0.8-0.5-1.1c-0.2-0.1-0.3-0.3-0.5-0.5c-0.2-0.2-0.2-0.3,0-0.5
		c0.4-0.3,0.5-0.7,0.6-1.1c0-0.1,0-0.3-0.1-0.4c-0.4-0.5-0.5-1-0.5-1.6c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.2,0.1-0.3,0
		c-0.5-0.1-1-0.3-1.5-0.4c-0.2,0-0.3-0.2-0.5-0.3c-0.3-0.1-0.6-0.1-0.8,0.1c-0.3,0.3-0.6,0.6-1,0.7c-0.1,0-0.1,0.1-0.2,0.2
		c-0.1,0.2-0.1,0.4,0,0.5c0.2,0.3,0.5,0.5,0.6,0.8c0.1,0.2,0.1,0.4,0,0.5c0,0.1-0.1,0.1-0.2,0.2c0,0-0.1,0-0.1,0
		c-0.3-0.7-1-1.1-1.4-1.8c-0.5-0.4-1-0.9-1.8-0.7c-0.3,0.1-0.6,0.1-0.8,0c-0.4-0.1-0.8-0.1-1.3-0.1c-0.5,0-1,0-1.5-0.2
		c-0.4-0.2-0.9-0.2-1.4,0c-0.4,0.1-0.6,0-0.9-0.2c-0.3-0.2-0.6-0.3-0.9-0.4c-1-0.1-2-0.4-3.1-0.3c-0.1,0-0.3,0-0.4,0
		c-0.7-0.2-1.3,0-1.9,0.3c-0.4,0.2-0.9,0.5-1.3,0.5c-0.5,0-1,0.2-1.5,0.2c-0.2,0-0.5,0-0.7,0.1c-0.1,0-0.1,0.1-0.1,0.2
		c0,0.5,0.5,0.8,0.4,1.3c0,0.1,0.2,0.2,0.3,0.3c0.4,0.1,0.7,0.2,1,0.5c0.3,0.3,0.5,0.7,0.8,1c0.1,0.1,0.1,0.4,0,0.5
		c-0.1,0.1-0.3,0.3-0.4,0.4c-0.2,0.2-0.5,0.2-0.6,0c-0.3-0.6-0.8-0.8-1.4-0.8c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.3-0.1-0.3-0.2
		c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.1,0-0.2,0-0.3c0.3-0.3,0.2-0.6,0-0.9c-0.2-0.3-0.5-0.4-0.9-0.3c-0.2,0-0.5,0.3-0.5,0.5
		c0,0.4,0,0.8,0,1.1c0,0.2-0.2,0.5-0.4,0.5c-0.5,0.1-1,0.1-1.4,0.2c-0.3,0.1-0.6,0.2-1,0.1c-0.1,0-0.2,0-0.3,0
		c-0.5,0.3-1.1,0.6-1.6,0.9c-0.8,0.5-1.6,0.7-2.4,0.6c-0.2,0-0.3-0.1-0.4-0.3c0,0,0-0.1-0.1-0.1c-0.2-0.9-0.7-1.5-1.5-2
		c-0.1,0-0.1-0.1-0.2-0.2c-0.8-0.5-1.7-0.9-2.7-0.3c-0.2,0.1-0.4,0.1-0.5,0.2c-0.8,0.3-1.5,0.6-2.1,1.1c-0.3,0.3-0.7,0.5-1.1,0.7
		c-0.3,0.2-0.6,0.1-0.7-0.2c-0.1-0.3-0.4-0.5-0.7-0.7c-0.2-0.1-0.4-0.2-0.6-0.3c-0.3-0.1-0.4,0-0.6,0.1c-0.2,0.2-0.2,0.2-0.5,0
		c-0.2-0.1-0.3-0.2-0.5-0.2c-0.1,0-0.3,0.1-0.4,0c-0.2-0.2-0.5-0.3-0.8-0.3c-0.1,0-0.1-0.1,0-0.2c0.2-0.4,0.2-0.6,0-0.9
		c0,0-0.1-0.1-0.1-0.1c-0.5-0.3-1.1-0.6-1.8-0.4c-0.3,0.1-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.1-0.5,0.3c-0.3,0.4-0.6,0.4-1.1,0.3
		c-0.5-0.1-1-0.1-1.6-0.1c-0.6,0-1.2,0.1-1.9,0.2c-0.6,0.1-1.2,0.2-1.8,0c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.1,0.2-0.1,0.2
		c0,0,0,0.1,0.1,0.1c0.2,0.1,0.4,0.2,0.7,0.3c0.1,0,0.1,0.1,0.2,0.2c0,0.1-0.1,0.3-0.2,0.3c-0.1,0.1-0.3,0.1-0.4,0.1
		c-0.3,0.2-0.4,0.4-0.4,0.7c0,0.2,0.2,0.5,0.4,0.5c0.2,0,0.4,0,0.6,0c0,0,0,0,0,0l0-0.1l0,0.1c0,0,0,0,0,0c0,0.2,0,0.4,0.1,0.6
		c0.2,0.3,0.4,0.6,0.8,0.8c0.1,0.1,0.1,0.2,0,0.4c-0.1,0.1-0.2,0.2-0.4,0.1c-0.3-0.2-0.6-0.3-0.9-0.1c-0.2,0.1-0.4,0.2-0.6,0.4
		c-0.2,0.2-0.5,0.3-0.8,0.2c-0.2,0-0.5-0.1-0.7,0c-0.3,0-0.7,0.2-0.8,0.5c-0.1,0.4-0.3,0.5-0.7,0.6c-0.7,0.1-1.3-0.1-1.8-0.6
		c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.1c0,0.4,0.1,0.8,0.3,1.2c0.2,0.5,0.4,1.1,0.5,1.6c0,0.2-0.1,0.3-0.3,0.2
		c-0.3,0-0.6-0.1-0.8-0.2c-0.8-0.3-1.4-1-2.1-1.4c0,0,0-0.1,0-0.1c-0.1-0.4-0.1-0.4-0.4-0.2c-0.3,0.2-0.4,0.2-0.6,0
		c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.3-0.1c0,0,0.1-0.1,0.1,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0.1,0
		c0,0,0,0,0,0c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0,0.1-0.2,0.1-0.3c0-0.3-0.3-0.6-0.5-0.9c-0.2-0.2-0.4-0.2-0.7-0.2
		c-0.2,0-0.3-0.1-0.2-0.3c0.1-0.2,0.1-0.4,0-0.5c-0.4-0.4-0.7-0.7-1.1-1.1c-0.3-0.3-1.6-0.6-2-0.6c-0.1,0-0.2,0-0.3,0.1
		c-0.2,0.1-0.4,0.2-0.6,0.1c-0.6-0.2-1.2-0.2-1.8-0.1c-0.2,0-0.3,0.2-0.5,0.3c-0.3,0.2-0.4,0.5-0.2,0.7c0.2,0.3,0.4,0.6,0.6,1
		c0.1,0.1,0,0.3-0.1,0.4c-0.6,0.2-1.1,0.5-1.7,0.7c-0.4,0.1-0.7,0.1-1.1-0.2c-0.2-0.2-0.2-0.2-0.5,0c-0.6,0.5-1.2,0.3-1.4-0.5
		c0-0.1,0-0.2,0-0.3c0-0.2-0.3-0.4-0.5-0.3c-0.7,0.2-1.5,0.3-2.2,0.5c-0.3,0.1-0.6,0.1-0.8,0.3c-0.3,0.1-0.6,0.3-0.8,0.5
		c-0.3-0.1-0.6-0.3-0.9-0.4c0,0-0.1,0-0.1,0c0,0.1,0,0.2-0.1,0.2c-0.2,0.5-0.7,0.7-1.1,0.3c-0.1-0.1-0.2-0.3-0.3-0.5
		c-0.1-0.1-0.2-0.2-0.3-0.1c-0.2,0.1-0.4,0.1-0.7,0.1c-0.3,0-0.4,0.1-0.5,0.4c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.4,0.3,0.5
		c0.2,0.1,0.3,0.1,0.5,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.1,0.2-0.1c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1
		c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0.1,0,0.3-0.1,0.4c-0.3,0.2-0.5,0.5-0.6,0.8c-0.3,0.6-0.8,0.8-1.4,0.8
		c-0.2,0-0.3,0-0.2-0.2c0.1-0.3,0.2-0.5,0.2-0.8c0-0.3,0.4-0.4,0.5-0.6c0.1-0.1,0.2-0.3,0.1-0.4c-0.1-0.5,0-0.8,0.2-1.2
		c0.1-0.2,0.1-0.4,0.1-0.5c0-0.2,0-0.4,0.1-0.5c0.2-0.4,0.4-0.9,0.5-1.3c0.1-0.5,0.1-0.9,0.3-1.4c0.2-0.4,0.1-0.9-0.1-1.3
		c-0.2-0.4-0.5-0.8-0.6-1.3c0-0.1-0.1-0.2-0.2-0.2c-0.5-0.2-0.8-0.7-1.1-1.1c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.5-0.3,0.1
		c-0.3-0.4-0.6-0.6-1-0.8c-0.3-0.2-0.7-0.3-1-0.5c-0.3-0.2-0.6-0.3-0.9-0.1c-0.6,0.4-1.2,0.6-1.8,0.8c-0.1,0-0.2,0.2-0.2,0.3
		c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.3,0,0.4-0.2,0.6c-0.3,0.2-0.6,0-0.6-0.3c0-0.2,0-0.5,0-0.7c0-0.2,0-0.5-0.1-0.7
		c0-0.2-0.3-0.4-0.6-0.4c-0.2,0-0.4,0.1-0.5,0.1c-0.3,0.1-0.6,0-0.8-0.1c-0.2-0.1-0.3-0.2-0.3-0.4c0.1-0.5-0.2-0.7-0.5-0.9
		c-0.5,0.1-1-0.3-1.5-0.3c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4,0.8-0.5,1.6-0.5,2.5c0,0.5,0,1.2,0.6,1.5
		c0.3,0.2,0.2,0.5,0.3,0.8c0.1,0.3,0.1,0.4-0.2,0.4c-0.3,0-0.7,0-1,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0.1,0.3,0.1,0.4
		c-0.1,0.3-0.3,0.6-0.5,0.9c0,0.1-0.1,0.1-0.2,0.2c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0-0.1-0.1-0.1-0.2c0-0.2,0.1-0.4,0.1-0.5
		c0-0.2-0.1-0.4-0.4-0.3c-0.3,0-0.6,0.1-0.7,0.4c0,0.2-0.1,0.3-0.3,0.4c-0.2,0-0.4,0.1-0.6,0.1c-0.3,0-0.5,0.2-0.7,0.4
		c-0.2,0.3-0.3,0.4,0,0.7c0.1,0.1,0.1,0.3,0,0.4c-0.4,0.2-0.7,0.5-1.1,0.7c-0.3,0.2-0.7,0.3-0.9,0.6c-0.2,0.3-0.6,0.5-0.7,0.9
		c-0.1,0.3-0.3,0.5-0.6,0.6c-0.4,0.1-0.3,0.6-0.6,0.8c-0.2,0.1-0.2,0.3-0.3,0.5c-0.2,0.3,0,0.6,0,1c0,0.2,0.2,0.3,0.3,0.4
		c0.3,0.3,0.7,0.5,1.1,0.7c0.4,0.1,0.9,0.3,1.2,0.7c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.2-0.1,0.2c-0.2,0.1-0.2-0.1-0.3-0.2
		c-0.1-0.1-0.1-0.3-0.3-0.2c-0.2,0-0.4,0-0.5,0.1c-0.4,0.4-1,0.5-1.5,0.8c-0.5,0.2-1.1,0.4-1.6,0.6c-0.2,0.1-0.4,0.1-0.5,0.2
		c-0.5,0.2-0.7,0.6-0.4,1.1c0.1,0.2,0.2,0.4,0.4,0.6c0.2,0.3,0.4,0.5,0.5,0.9c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.3,0.4,0.4
		c0.4,0.2,0.7,0.1,1.1,0.1c0.3,0.3,0.6,0.6,0.9,0.9c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0.1,0.4,0.2,0.7,0.3c0.2,0.1,0.3,0.2,0.4,0.4
		c0.1,0.6,0.3,1.2,0.7,1.7c0.1,0.1,0,0.3-0.1,0.3c-0.2,0.1-0.3,0.2-0.5,0.3c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0.1,0,0.1
		c0-0.1,0-0.2,0.1-0.2c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c-0.4-0.5-0.5-1-0.2-1.6c0-0.1,0-0.2,0-0.3c0-0.3-0.2-0.6-0.5-0.6
		c-0.2,0-0.5,0.1-0.7,0.1c-0.4,0.1-0.8,0-1.2-0.3c-0.2-0.2-0.4-0.4-0.7-0.5c-0.4-0.2-0.9-0.4-1.3-0.5c-0.5-0.1-0.9-0.3-1.4-0.4
		c-0.1,0-0.3,0-0.4,0.1c-0.2,0.2-0.4,0.4-0.4,0.7c0,0.1,0.1,0.2,0.1,0.2c0.1,0.1,0.3,0.1,0.4,0.1c0,0,0,0,0,0l0.1-0.1l0,0.1
		c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0.1,0.2,0.3,0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.5-0.2
		c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.1,0.3-0.1,0.4c0.2,0.5,0.6,1,1.2,1.1c0.3,0.1,0.6,0.2,1,0.2
		c0.1,0,0.3,0.1,0.4,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.2-0.1,0.1c-0.2-0.1-0.4-0.2-0.7-0.2c-0.4,0.1-0.9,0-1.3,0
		c-0.2,0-0.5-0.1-0.6-0.4c0-0.1-0.1-0.2-0.1-0.3c-0.3-0.5-0.6-1-0.6-1.6c0-0.5-0.3-0.9-0.5-1.3c-0.1-0.1-0.2-0.2-0.3-0.3
		c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.1c0.1,0.4,0.2,0.7,0.3,1.1c0.1,0.3,0.1,0.6-0.1,0.8c-0.1,0.1-0.1,0.2-0.2,0.2
		c-0.2,0.3-0.4,0.5-0.2,0.9c0.1,0.3-0.1,0.7,0.1,1c0.2,0.3,0.5,0.5,0.7,0.7c0.2,0.2,0.3,0.3,0.5,0.5c0.3,0.2,0.4,0.5,0.4,0.9
		c0,0.4,0,0.8,0.1,1.1c0.1,0.7,0.6,1.3,0.8,2c0.1,0.2,0.3,0.2,0.5,0.2c0.4,0,0.7-0.1,1-0.4c0.3-0.4,0.7-0.2,1-0.1
		c0,0,0.1,0.1,0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.4,0.3-0.8,0.6-1.1,0.9c-0.3,0.3-0.4,0.8-0.1,1.2c0.1,0.2,0.3,0.3,0.5,0.5
		c0.2,0.3,0.3,0.6,0.2,0.9c-0.2,0.5-0.3,0.9-0.2,1.4c0,0.2-0.1,0.5-0.2,0.7c-0.3,0.4-0.6,0.8-0.7,1.4c0,0.1-0.2,0.1-0.2,0.1
		c-0.6-0.1-1.1-0.1-1.7-0.2c-0.1,0-0.2-0.1-0.2-0.1c0-0.1,0-0.3,0-0.4c0,0,0,0,0,0c-0.1,0-0.1,0,0-0.1c0,0,0,0.1,0,0.1v0
		c0.1,0.1,0.2,0.1,0.4,0.2c0.5,0.2,0.8,0,1-0.4c0.3-0.8,0.5-1.7,0.7-2.6c0-0.1,0-0.3-0.1-0.4c-0.2-0.3-0.3-0.7-0.2-1.1
		c0-0.3-0.1-0.5-0.3-0.6c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.2-0.5-0.5-0.6-0.8c-0.4-1.1-0.8-2.1-1.1-3.2c-0.2-0.7-0.5-1.2-1.1-1.6
		c-0.3-0.2-0.4-0.5-0.4-0.9c0-1-0.1-1.9-0.4-2.8c0-0.2-0.2-0.3-0.4-0.4c-0.7-0.3-1.5-0.5-2.2-0.2c-0.2,0.1-0.4,0-0.6,0
		c-0.1,0-0.2,0.2-0.3,0.3c0,0.2,0,0.5,0,0.7c0,0.8,0.1,1.5,0.1,2.3c0,0.4,0,0.8-0.3,1.1c-0.3,0.3-0.4,0.7-0.4,1.2
		c0,0.3,0,0.6,0.3,0.7c0.2,0.2,0.5,0.4,0.5,0.7c0.1,0.4,0.4,0.8,0.2,1.2c0,0.1,0,0.2,0,0.3c0.2,0.4,0.4,0.7,0.6,1.1c0,0,0.2,0,0.2,0
		c0.2-0.1,0.5-0.2,0.6,0c0.4,0.4,0.8,0.9,1.3,1c0.1,0,0.1,0.2,0.1,0.2c0,0.2,0,0.5,0,0.7c0,0.2-0.2,0.2-0.3,0.2
		c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.2-0.7-0.3-1.1-0.4c-0.3-0.1-0.6-0.3-0.9-0.4c-0.5,0.1-0.7-0.3-1-0.5
		c-0.7-0.3-1.3-0.5-2.1-0.4c-0.1,0-0.2,0-0.3,0c-0.8,0-1.6-0.1-2.3-0.7c-0.3-0.3-0.7-0.5-1.1-0.7c-0.1-0.1-0.3,0-0.4,0.1
		c0,0-0.1,0.1-0.1,0.1c-0.1,0.4,0,0.7,0.3,1c0.4,0.3,0.9,0.3,1.2,0.7c0,0.1,0.2,0.1,0.3,0.1c0.2,0,0.4,0.1,0.5,0.3
		c0.3,0.3,0.5,0.7,0.8,1c0.1,0.2,0.1,0.4,0,0.5c-0.3,0.2-0.6,0.4-0.7,0.8c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2-0.1-0.2-0.2
		c-0.1-0.2-0.1-0.4-0.1-0.5c-0.1-0.2-0.4-0.4-0.6-0.3c-0.3,0.2-0.6,0.4-1,0.4c0,0-0.1,0-0.1,0.1c-0.1,0.4-0.4,0.5-0.8,0.4
		c-0.4,0-0.7,0.1-0.9,0.3c-0.3,0.3-0.6,0.4-0.9,0.6c-0.1,0.1-0.3,0-0.4,0c0-0.1,0-0.2,0-0.3c0.1-0.4-0.1-0.7-0.3-1
		c-0.3,0-0.6,0-0.7,0.4c0,0.1-0.1,0.2-0.2,0.2c-0.3,0-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.1-0.7,0.2c-0.3,0.1-0.6,0.3-0.8,0.5
		c-0.5,0.6-1.1,1.2-1.7,1.7c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.2-0.1,0.5-0.1,0.7c0,0.1-0.1,0.2-0.2,0.2c-0.4,0.4-1.1,0.4-1.4,0.1
		c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.2-0.5,0-0.6c0.2-0.2,0.5-0.3,0.7-0.5c0.1-0.1,0.2-0.4,0.1-0.5c-0.2-0.2-0.4-0.4-0.6-0.6
		c-0.3-0.4-0.7-0.6-1.2-0.5c-0.4,0.1-0.7,0.1-1.1,0c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.1,0.2,0,0.2c0.7,0.5,0.9,1.2,0.8,2
		c0,0.2,0,0.4,0,0.6c0,0.2,0.1,0.4,0.3,0.5c0.4,0.2,0.5,0.5,0.5,0.8c0,0.2,0,0.4,0,0.6c0,0.2-0.2,0.4-0.4,0.3
		c-0.3-0.1-0.6-0.1-0.8-0.2c-0.4-0.1-0.7-0.1-1,0.3c-0.1,0.2-0.3,0.4-0.5,0.5c-0.3,0.2-0.5,0.5-0.7,0.7c-0.4,0.6-0.7,0.8,0,1.6
		c0.1,0.1,0.1,0.2,0.1,0.2c0.1,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.3,0.3-0.5,0.2c-0.7-0.4-1.4-0.5-2-1c-0.1-0.1-0.3-0.1-0.4-0.1
		c-0.1,0-0.3,0-0.4,0.2c-0.1,0.2-0.1,0.4-0.2,0.5c-0.1,0.2,0,0.4,0.1,0.5c0.3,0.3,0.6,0.6,1.1,0.6c0.1,0,0.2,0.1,0.3,0.1
		c0.1,0.1,0.2,0.2,0.2,0.4c0,0.2-0.3,0.5-0.5,0.4c-0.5-0.1-0.9-0.1-1.3-0.6c-0.2-0.2-0.5-0.3-0.8-0.4c-0.2-0.1-0.3-0.2-0.4-0.4
		c-0.1-0.4-0.2-0.8-0.5-1.2c-0.2-0.2-0.2-0.5-0.1-0.8c0.1-0.7-0.1-1.1-0.8-1.3c-0.3-0.1-0.5-0.2-0.7-0.4c-0.2-0.2-0.5-0.4-0.7-0.7
		c0,0,0,0-0.1,0c0,0,0,0,0-0.1c-0.1,0-0.1-0.1-0.2-0.2c0,0,0,0-0.1,0l0-0.1l0,0c0,0,0,0,0,0.1c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0,0.1,0
		c0,0,0,0,0,0c0.4,0,0.8,0.1,1.2,0.3c1,0.4,2.1,0.7,3.1,0.9c0.8,0.1,1.6,0,2.4-0.3c0.1-0.1,0.3-0.1,0.4-0.2c0.5-0.5,0.8-1.1,0.9-1.7
		c0-0.2,0-0.4-0.1-0.5c-0.3-0.4-0.5-0.9-1-1.1c-0.2-0.1-0.4-0.3-0.6-0.4c-0.2-0.2-0.4-0.3-0.7-0.4c-0.5-0.1-0.9-0.3-1.3-0.6
		c-0.4-0.3-0.8-0.6-1.2-0.8c-1.2-0.7-1.5-0.8-2.8-0.8c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0-0.5,0-0.7-0.1c-0.2,0-0.1-0.2-0.1-0.3
		c0.1-0.1,0-0.3-0.1-0.4c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.1-0.5,0.1c-0.2,0.2-0.4,0.4-0.8,0.2c-0.3-0.1-0.6-0.1-0.8,0
		c-0.1,0-0.3-0.1-0.4-0.2c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.5-0.1,0.7-0.2c0.2-0.2,0.4-0.3,0.5-0.5
		c0.1-0.1,0.1-0.3-0.1-0.4c-0.6-0.4-1.3-0.6-2-0.8c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.1
		c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.3,0-0.4c0-0.2-0.2-0.5-0.4-0.5c-0.2-0.1-0.4,0-0.5,0.2c-0.1,0.1-0.3,0.3-0.3,0.5
		c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.1-0.2,0.1c0,0-0.1-0.1-0.1-0.1c-0.1-0.2-0.1-0.4-0.4-0.4c-0.2,0-0.2-0.2-0.2-0.3
		c-0.1-0.3-0.4-0.4-0.5-0.2c-0.3,0.4-0.8,0.4-1,0.8c-0.4,0.1-0.6,0.4-1,0.5c0,0-0.1,0-0.1,0c0,0,0,0,0,0l-0.1,0.1l0-0.1c0,0,0,0,0,0
		c0-0.1,0-0.1,0-0.2c0,0-0.1,0-0.1,0c-0.6,0-0.7,0.1-0.5,0.6c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0l0.1,0.1l-0.1,0c0,0,0,0,0,0
		c-0.6,0-1,0.3-1.5,0.5c0,0-0.1,0.2,0,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.2,0.1-0.4,0.1c-0.2,0.1-0.4,0.1-0.7,0.2l0,0h0
		c0,0-0.1,0-0.1,0c0,0,0.1,0,0.1,0c0-0.1,0-0.3,0-0.4c0-0.2-0.2-0.2-0.3-0.2c-0.1,0-0.2,0.1-0.2,0.1c0.1,0.2,0.1,0.3,0.2,0.5h0l0,0
		c-0.4,0-0.7,0.1-0.9,0.5h0c-0.1,0,0.1,0.2,0,0c0,0,0,0,0,0c-0.1,0-0.3,0-0.4,0c-0.3,0-0.4,0.1-0.5,0.4c-0.1,0.3-0.1,0.6,0.2,0.8
		c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.3-0.1,0.4c-0.2,0.1-0.3,0.3-0.5,0.2c-0.2-0.1-0.5-0.1-0.7,0c-0.2,0-0.3-0.1-0.2-0.3
		c0.1-0.2,0.2-0.4,0.2-0.7c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.3,0.3-0.7,0.7-1,1c-0.1,0.1,0,0.3,0.1,0.4c0.1,0.2,0.1,0.3-0.1,0.5
		c-0.1,0.1-0.1,0.1-0.1,0.2c0.2,0,0.3,0,0.5,0c0.3-0.1,0.6-0.2,1-0.2c0.2,0,0.4,0,0.6,0.1c0,0,0.1,0,0.1,0.1c0,0-0.1,0.1-0.1,0.1
		c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0-0.2,0-0.3,0.1c-0.3,0-0.3,0.2-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.2,0,0.4,0,0.5
		c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.3-0.1,0.5-0.3,0.6c-0.3,0.1-0.5,0.3-0.5,0.6c-0.1,0.3-0.3,0.5-0.3,0.8
		c0,0.3,0,0.4-0.3,0.5c-0.1,0.1-0.2,0.2-0.2,0.3c0.1,0.3,0.1,0.5,0,0.8c-0.1,0.3-0.1,0.6-0.2,0.8c0,0.2-0.2,0.3-0.4,0.4
		c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0-0.1,0.1-0.1,0.2c0,0.2,0,0.5-0.3,0.6c-0.5,0.3-0.8,0.8-1,1.3c-0.1,0.1-0.1,0.3-0.2,0.4
		c-0.1,0.2-0.3,0.3-0.5,0.3c-0.2,0-0.4-0.1-0.6-0.1c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.1,0.2,0,0.3c0,0,0.1,0.1,0.1,0.1
		c0.1,0.1,0.1,0.3-0.1,0.4c-0.4,0-0.7,0.5-1.1,0.5c-0.3,0.2-0.6,0.4-0.9,0.7c0,0,0,0.1,0,0.1c0.1,0.3-0.1,0.4-0.4,0.5
		c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.2,0,0.4-0.1,0.6c-0.1,0.5,0.1,1-0.1,1.5c0,0,0,0.1,0,0.1
		c0.1,0.4,0.4,0.6,0.5,1c0.1,0.2,0.2,0.4,0.3,0.7c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.2,0.3-0.4,0.4c-0.1,0.1,0,0.3,0.1,0.4
		c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.2,0.2,0.1,0.3c-0.1,0.1-0.2,0.3-0.4,0.4c-0.1,0.1,0,0.4,0.1,0.5c0.5,0.5,1,0.8,1.7,1
		c0.1,0,0.2,0.1,0.3,0c0.6-0.2,1.2-0.3,1.6-0.9c0.1-0.2,0.3-0.5,0.5-0.6c0.4-0.3,0.9-0.4,1.1-0.9c0,0,0.1,0,0.1,0
		c0.1,0.1,0.3,0.2,0.4,0.4c0.1,0.3,0.1,0.6,0.1,0.8c0.1,0.3,0.3,0.6,0.3,0.9c0,0.6,0.3,1,0.6,1.4c0.2,0.4,0.5,0.8,0.8,1.2
		c0.1,0.1,0,0.3,0,0.4c0,0.1-0.1,0.3-0.1,0.4c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0.3,1,0.5,0.7,1.3,0.6
		c0.2,0,0.3-0.2,0.4-0.4c0.1-0.3,0.3-0.5,0.6-0.5c0.1,0,0.3-0.1,0.4,0c0.4,0,0.8-0.1,0.9-0.5c0.7,0.1,0.6-0.4,0.7-0.8
		c0-0.1,0-0.2,0-0.3c0-0.2-0.1-0.2-0.3-0.2c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1c0-0.5,0-0.9,0.1-1.4c0.1-0.2,0.1-0.5,0.1-0.7
		c0-0.4,0.2-0.7,0.6-0.9c0.2-0.2,0.5-0.3,0.7-0.5c0.2-0.2,0.4-0.5,0.4-0.9c0-0.2,0.1-0.4,0-0.6c0-0.1,0-0.3-0.1-0.4
		c-0.3-0.5-0.6-0.9-1.2-1.2c-0.3-0.1-0.4-0.4-0.4-0.7c0-0.8,0-1.5,0.1-2.3c0-0.1,0.1-0.3,0.1-0.4c0.4-0.6,0.8-1.3,1.5-1.7
		c0.3-0.2,0.6-0.5,0.9-0.7c0.5-0.5,0.5-0.5,0.8-1.3c0.1-0.2,0.1-0.4,0.1-0.7c-0.1-0.6,0-1.2,0.2-1.6c0.2-0.4,0.5-0.8,1.1-0.7
		c0.7-0.1,1.3,0,1.9,0.3c0.2,0.1,0.3,0.5,0.1,0.6c-0.2,0.1-0.4,0.2-0.4,0.5c0,0.2-0.1,0.4-0.2,0.5c-0.5,0.7-0.8,1.4-1.4,2
		c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.3-0.3,0.4-0.7,0.4c-0.2,0-0.3,0.1-0.3,0.3c0,0.2,0,0.5-0.1,0.7c0,0.2,0,0.4,0,0.6
		c0,0.2,0.1,0.4,0.2,0.5c0.2,0.7,0.4,1.5,0.3,2.2c0,0.1,0,0.3,0,0.4c0,0.3,0.2,0.4,0.4,0.5c0.2,0.1,0.3,0.2,0.5,0.3l0,0c0,0,0,0,0,0
		l0.1,0l-0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.2c0.3,0,0.5,0.1,0.7,0.4c0.2,0.3,0.5,0.3,0.7,0.2c0.3-0.1,0.5-0.2,0.8-0.3
		c0.9-0.4,1.8-0.7,2.7-0.7c0.3,0,0.7-0.1,1-0.1c0.1,0,0.2,0,0.3,0.1c0.2,0.4,0.6,0.3,0.9,0.5c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1
		c-0.2,0.1-0.3,0.2-0.5,0.3c-0.3,0.1-0.6,0.2-0.7,0.4c-0.3,0.5-0.7,0.5-1.2,0.3c-0.3-0.1-0.6-0.2-1-0.2c-0.1,0-0.3,0-0.4,0
		c-0.5,0.1-0.9,0.3-1.3,0.4c-0.5,0.2-0.8,0.5-0.8,1c0,0.3-0.2,0.4-0.4,0.5c-0.2,0.1-0.4,0.1-0.5,0.1c-0.4,0.1-0.4,0.4-0.2,0.8
		c0,0,0,0.1,0.1,0.1c0.4,0,0.7-0.3,1-0.5c0.2-0.2,0.3-0.2,0.5,0c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0,0.4,0,0.8,0,1.1
		c0,0.2-0.2,0.4-0.3,0.4c-0.1,0-0.3,0-0.4-0.1c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.3-0.3-0.6-0.2c-0.4,0.1-0.7,0.3-0.8,0.7
		c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.4-0.2,0.7-0.1,1.1c0.1,0.5,0.2,0.9,0.2,1.4c0,0.1-0.1,0.2-0.1,0.2c-0.2,0.1-0.4,0.2-0.7,0.3
		c-0.2,0.1-0.4,0.2-0.4,0.5c-0.1,0.5-0.9,0.5-1.2,0.2c-0.1-0.1-0.1-0.2-0.1-0.2c-0.1-0.2-0.3-0.3-0.6-0.3c-0.5,0-0.9,0.2-1.3,0.4
		c-0.6,0.3-1.1,0.7-1.7,1c-0.2,0.1-0.3,0.3-0.5,0.5c-0.1,0.1-0.3,0.1-0.4,0c-0.2-0.2-0.5-0.5-0.7-0.7c-0.1-0.1,0-0.3,0-0.4
		c0-0.1-0.2-0.2-0.3-0.1c-0.2,0.2-0.4,0.3-0.7,0.4c-0.4,0-0.8,0.3-1.2,0.5c-0.1,0-0.2,0-0.2-0.1c-0.2-0.2-0.3-0.4-0.5-0.4
		c-0.2-0.1-0.5-0.1-0.7-0.2c-0.2-0.1-0.2-0.4-0.3-0.6c0-0.1,0-0.2,0.1-0.2c0,0,0.1,0,0.1,0c0.4,0.2,0.8,0.2,1.2,0.5
		c0.4,0.3,0.8,0.2,1-0.3c0.3-0.6,0.5-1.2,0.4-1.8c0,0,0,0,0-0.1c0,0,0,0,0,0c-0.5,0-0.9,0.2-1.2,0.5c-0.1,0.1-0.2,0.4-0.1,0.5
		c0.1,0.2,0.2,0.3,0.3,0.5c0,0.1,0,0.2,0,0.2c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1-0.1c-0.3-0.3-0.4-0.8-0.9-0.9c0,0,0-0.2,0-0.3
		c0-0.4,0.2-0.6,0.6-0.7c0.1,0,0.1-0.2,0-0.2c0,0-0.1-0.1-0.1-0.1c-0.5-0.2-0.6-0.6-0.4-1.1c0.1-0.2,0.1-0.4,0.1-0.7
		c0-0.1-0.2-0.3-0.3-0.3c-0.1,0-0.2,0-0.3,0.1c-0.5,0.3-0.9,0.8-1.5,1c-0.1,0-0.1,0.2-0.1,0.2c0,0.6-0.4,1.1-0.2,1.6
		c0,0.1,0,0.2-0.1,0.3c-0.1,0.2,0,0.4,0.1,0.5c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.6,0.3,1.2,0.4,1.8c0,0.3-0.4,0.8-0.7,0.7
		c-0.4-0.1-0.7-0.1-1.1,0.1c-0.3,0.2-0.6,0.3-0.9,0.4c-0.4,0.1-0.8,0.3-1.2,0.5c-0.4,0.2-0.6,0.4-0.7,0.8c-0.4,1.3-0.4,1.3-1.4,2.1
		c-0.2,0.2-0.5,0.3-0.7,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.2-0.1,0.4-0.1,0.5c-0.1,0.3-0.3,0.5-0.6,0.6c-0.1,0-0.3,0.1-0.4,0.1
		c-0.2,0.1-0.4,0.2-0.5,0.4c-0.2,0.3-0.4,0.3-0.7,0.3c-0.2,0-0.4-0.1-0.6-0.3c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3,0.2-0.3,0.3
		c0.1,0.3,0.1,0.5,0.2,0.8c0,0.2-0.1,0.4-0.3,0.4c-0.3,0-0.7,0-1-0.1c-0.2-0.1-0.4-0.1-0.5-0.1c-0.4,0.1-0.7,0.2-1.1,0.3
		c-0.1,0-0.2,0.2-0.2,0.3c0.1,0.5,0.1,0.9,0.8,1c0.3,0,0.5,0.2,0.8,0.3c0.5,0.1,0.8,0.4,0.9,0.8c0.1,0.4,0.4,0.8,0.8,1
		c0.3,0.2,0.4,0.4,0.4,0.7c0,0.8-0.1,1.5-0.1,2.3c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.4,0.5-0.7,0.4c-0.5-0.2-1,0.1-1.5-0.2
		c-0.1-0.1-0.3-0.1-0.4,0c-0.5,0.2-0.9,0.1-1.4,0c-0.5-0.1-1-0.2-1.5-0.1c-0.1,0-0.3,0-0.4,0c-0.6-0.2-1.1-0.2-1.5,0.3
		c-0.1,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.1-0.3,0.3-0.2,0.5c0.3,0.5,0.4,0.9,0.3,1.5c0,0,0,0.1,0,0.1
		c0.2,1.1,0,2.1-0.4,3c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.2-0.1,0.4,0.1,0.5c0.4,0.5,0.6,1.1,0.5,1.7c0,0.1,0,0.2,0,0.3
		c0,0.1,0.1,0.2,0.2,0.2c0.4,0.1,0.7,0.3,1.1,0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.5,0.1,0.6,0.3c0.2,0.3,0.3,0.6,0.5,0.8
		c0.2,0.3,0.6,0.4,0.9,0.2c0.2-0.1,0.4-0.2,0.6-0.4c0.5-0.3,1-0.5,1.6-0.4c0.4,0,0.9,0,1.3-0.1c0.3,0,0.5-0.1,0.6-0.3
		c0.2-0.3,0.4-0.4,0.7-0.5c0.3-0.1,0.5-0.3,0.6-0.6c0.1-0.4,0.3-0.8,0.7-1.1c0.1-0.1,0.2-0.4,0.1-0.5c-0.6-0.7-0.3-1.2,0.2-1.8
		c0.1-0.1,0.3-0.3,0.4-0.4c0.4-0.4,0.7-0.9,1.3-0.9c0.6-0.3,1.1-0.7,1.1-1.5c0-0.3,0-0.6,0.1-0.8c0-0.1,0.1-0.3,0.1-0.4
		c0.1-0.3,0.5-0.7,0.9-0.6c0.6,0.1,1.3,0.1,1.9,0.5c0.2,0.2,0.6,0.1,0.8-0.1c0.4-0.5,0.8-0.8,1.4-1c0.2-0.1,0.3-0.2,0.4-0.4
		c0.3-0.4,0.7-0.4,1.2-0.2c0.4,0.2,0.8,0.4,0.9,0.8c0.2,0.3,0.3,0.7,0.5,1c0.6,0.5,1.2,1,1.7,1.7c0.4,0.5,0.9,0.8,1.4,0.9
		c0.7,0.2,1.1,0.7,1.7,1c0.2,0.4,0.5,0.6,0.8,0.7c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.4,0.3,0.7,0.4,1.1c0,0.1,0,0.2,0,0.3
		c-0.1,0.4-0.3,0.8-0.8,0.9c-0.8,0.2-1.7,0.3-2.5,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.3
		c0.3,0.2,0.6,0.3,0.9,0.5c0.6,0.3,1.2,0.6,1.8,0.9c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2-0.1-0.4-0.1-0.6
		c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.6-0.1,0.7-0.4c0.2-0.4,0.5-0.8,0.7-1.2c0.1-0.3,0.1-0.5,0-0.8c-0.1-0.1-0.2-0.2-0.2-0.4
		c0-0.2,0-0.4,0-0.6c0.1-0.3,0.4-0.4,0.7-0.3c0.2,0.2,0.4,0.4,0.7,0.5c0,0,0.2,0,0.2-0.1c0.2-0.3,0.2-0.6,0-0.8
		c-0.7-0.4-1.4-1.1-2.2-1.3c-0.3-0.1-0.3-0.3-0.4-0.5c0-0.1-0.1-0.2-0.2-0.2c-0.2-0.1-0.4-0.1-0.6-0.1c-0.5,0-0.8-0.2-1.1-0.6
		c-0.2-0.3-0.4-0.5-0.6-0.8c-0.2-0.6-0.7-0.9-1.1-1.3c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.2,0-0.5,0-0.7
		c0-0.2,0.1-0.3,0.2-0.3c0.2-0.1,0.4-0.2,0.7-0.3c0.1,0,0.2,0.1,0.2,0.1c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.2,0.4,0.4,0.3
		c0.3-0.1,0.5,0.2,0.8,0.2c0.2,0,0.4,0.1,0.4,0.3c0.2,0.6,0.6,1,1.2,1.4c0.4,0.3,0.8,0.7,1.4,0.7c0.2,0.2,0.4,0.4,0.7,0.4
		c0.4,0.1,0.6,0.3,0.8,0.5c0.3,0.3,0.5,0.6,0.8,0.8c0.1,0.1,0.2,0.3,0.2,0.5c0,0.3,0,0.6,0,0.9c0,0.5,0,0.9,0.5,1.3
		c0,0,0.1,0.1,0.1,0.1c-0.1,0.4,0.2,0.6,0.4,0.8c0.2,0.2,0.4,0.4,0.6,0.6c0.1,0.4,0.5,0.7,0.5,1.1c0.1,0.1,0.1,0.2,0,0.3
		c0,0.1,0,0.2,0,0.3c0.4,0.3,0.7,0.6,0.8,1.1c0,0.1,0.2,0.2,0.3,0.3c0.3,0.2,0.7,0.3,1.1,0.2c0.2,0,0.3-0.1,0.2-0.3
		c-0.2-0.5,0.3-0.8,0.3-1.2c0,0,0.1-0.1,0.1-0.1c0.4,0.1,0.6-0.2,0.9-0.3c0.1,0,0.1-0.1,0.1-0.2c-0.1-0.4-0.2-0.7-0.6-0.9
		c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.5-0.7-1-1-1.5c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0.1-0.1,0.1-0.1,0.2,0c0.1,0.1,0.1,0.1,0.2,0.2
		c0.4,0.3,0.8,0.2,1-0.3c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.2-0.1,0.2-0.1c0.2,0.1,0.4,0.1,0.5,0c0.2-0.2,0.5-0.2,0.8-0.2
		c0.4,0.1,0.7,0.4,0.7,0.8c0,0.6,0,1.1-0.1,1.7c0,0.1,0.1,0.3,0.3,0.3c0.1,0,0.3,0,0.4,0c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1
		c0,0.1,0,0.2,0,0.3c-0.2,0.3-0.5,0.2-0.7,0.4c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.2,0.1,0.2c0.2,0.1,0.3,0.2,0.5,0.3
		c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1-0.2,0.3,0,0.4c0.2,0.2,0.5,0.3,0.6,0.6c0.1,0.3,0.4,0.4,0.6,0.6c0.1,0.1,0.3,0.2,0.5,0.1
		c0.4-0.1,0.7,0,1,0.4c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.1c0.5-0.1,1-0.1,1.3-0.5c0.2-0.3,0.6-0.3,0.9-0.2
		c0.3,0.1,0.6,0.2,0.9,0.5c0.4,0.4,0.8,0.4,1.3,0.3c0.5-0.1,1-0.3,1.3-0.8c0.1-0.1,0.3-0.2,0.5-0.1c0.4,0.1,0.7,0.1,1.1,0.1
		c0,0,0,0,0,0l0,0l0,0.1c0,0,0,0,0,0c-0.2,0.5-0.1,1,0,1.5c0.1,0.5,0.1,1-0.1,1.4c-0.1,0.4-0.2,0.7-0.4,1.1c-0.2,0.5-0.4,1-0.5,1.5
		c-0.1,0.5-0.3,0.9-0.6,1.3c-0.1,0.2-0.3,0.3-0.5,0.3c-0.7,0-1.4,0.1-2.1-0.2c-0.3-0.1-0.6-0.1-0.8-0.1c-0.6-0.1-1.2,0.1-1.8,0.5
		c-0.3,0.2-0.5,0.2-0.8,0.1c-1-0.2-2-0.5-3.1-0.6c-0.4,0-0.8-0.1-1.1-0.3c-0.5-0.3-1-0.4-1.5-0.5c-0.4-0.1-0.8-0.2-1.1-0.5
		c-0.1-0.1-0.3-0.2-0.5-0.3c-0.8-0.2-1.6-0.2-2.4,0.2c-0.5,0.2-0.9,1-0.7,1.6c0.1,0.2,0.1,0.4,0.2,0.5c0,0.2,0,0.4-0.1,0.5
		c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.2-0.6,0.3-0.9,0.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.2-0.5-0.3-0.7-0.5c-0.6-0.3-1.2-0.6-1.9-0.5
		c-0.3,0-0.5-0.2-0.7-0.5c-0.1-0.2-0.1-0.4-0.2-0.5c-0.1-0.3-0.3-0.5-0.6-0.6c-0.8-0.3-1.6-0.5-2.5-0.4c-0.5,0.1-0.9-0.2-1.4-0.3
		c0,0-0.1,0-0.1-0.1c-0.3-0.4-0.7-0.6-1.2-0.7c-0.2-0.1-0.3-0.5-0.1-0.6c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.3,0.3-0.7,0.1-1.1
		c-0.1-0.2-0.3-0.4-0.4-0.6c0-0.1-0.1-0.2,0-0.3c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.1-0.1-0.3-0.2-0.3c-0.2,0.1-0.5,0.1-0.6-0.1
		c-0.3-0.4-0.7-0.3-1-0.2c-0.4,0.1-0.7,0.3-1.1,0.3c-0.2,0.1-0.5,0-0.7,0.1c-0.4-0.3-0.8,0.1-1.1-0.1c-0.4-0.2-0.7,0.1-1.1,0.2
		c-0.3,0-0.6,0.1-0.8,0c-0.7-0.1-1.4-0.1-2.1,0.2c-0.4,0.1-0.8,0.1-1.1,0.1c-0.3,0-0.7,0.1-1,0.3c-0.5,0.3-1,0.5-1.5,0.8
		c-0.4,0.2-0.8,0.3-1.1,0.6c-0.4,0.4-0.9,0.4-1.3,0.2c-0.4-0.2-0.7-0.2-1.1-0.1c-0.4,0.1-0.8,0-1.1,0c-0.2,0-0.3-0.1-0.5-0.2
		c-0.1,0-0.1-0.1-0.2-0.2c-0.4-0.4-0.8-0.3-0.9,0.2c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.7-0.6,1.1-1.1,1.4c-0.5,0.3-1,0.6-1.5,0.9
		c-0.4,0.3-0.9,0.6-0.9,1.2c0,0.2-0.2,0.4-0.3,0.6c-0.2,0.3-0.3,0.7-0.3,1.1c0,0.3,0,0.6,0,0.9c0,0.2-0.1,0.5-0.2,0.7
		c-0.2,0.3-0.4,0.6-0.6,0.8c-0.1,0.1-0.3,0.3-0.4,0.4c-0.2,0.2-0.5,0.3-0.7,0.5c-0.3,0.2-0.6,0.4-1,0.5c-0.4,0-0.6,0.3-0.8,0.5
		c-0.1,0.2-0.2,0.3-0.3,0.5c-0.2,0.4-0.4,0.6-0.8,0.8c-0.4,0.2-0.6,0.6-0.8,1c-0.4,0.7-0.7,1.4-1.2,1.9c-0.1,0.1-0.2,0.3-0.3,0.5
		c-0.3,0.7-0.7,1.5-1.2,2.1c-0.2,0.3-0.3,0.6-0.3,0.9c0,0.4,0,0.7,0.4,1c0.3,0.2,0.4,0.5,0.3,0.9c-0.1,0.3-0.1,0.6,0,1
		c0.2,0.8,0.3,1.7-0.1,2.5c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.9-0.4,1.7-1,2.3c0,0-0.1,0.2,0,0.3c0.1,0.2,0.3,0.5,0.5,0.7
		c0.2,0.2,0.3,0.5,0.1,0.8c-0.2,0.4,0,0.8,0.1,1.2c0.1,0.3,0.4,0.5,0.7,0.7c0.2,0.1,0.5,0.2,0.8,0.3c0,0,0,0,0,0l0-0.1l0,0.1
		c0,0,0,0,0,0c0,0.3,0.1,0.5,0.4,0.7c0.3,0.2,0.4,0.4,0.5,0.7c0,0.2,0.2,0.3,0.4,0.4c0.4,0.2,0.7,0.5,1,0.8c0.1,0.1,0.3,0.3,0.3,0.5
		c0,0.6,0.3,1.1,0.6,1.6c0.3,0.5,0.7,0.9,1.2,1.1c0.4,0.2,0.7,0.4,1.1,0.7c0.8,0.6,1.5,1.3,2.4,1.8c0.4,0.2,0.8,0.4,1.3,0.6
		c0.1,0,0.3,0,0.4,0c0.3-0.1,0.6-0.3,0.9-0.4c0.5-0.3,1.1-0.4,1.7-0.4c0.4,0,0.8,0,1.1-0.1c0.6-0.1,1.1,0,1.6,0.2
		c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0.1,0.6,0.1,0.8,0c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.5-0.2,0.8-0.4c0.4-0.3,0.8-0.4,1.2-0.5
		c0.4-0.1,0.8-0.3,1.2-0.4c0.6-0.1,1.2-0.4,1.8-0.2c0.1,0,0.3,0,0.4,0.1c0.5,0.2,1,0.6,1.2,1.1c0.1,0.3,0.2,0.5,0.3,0.8
		c0.2,0.5,0.6,0.7,1.2,0.7c0.1,0,0.3,0,0.4,0c0.6-0.1,1.2-0.2,1.8-0.3c0.4-0.1,0.6-0.1,0.9,0.3c0.1,0.2,0.3,0.3,0.4,0.4
		c0.5,0.3,0.6,0.9,0.6,1.4c0,0.3-0.1,0.7-0.2,1c-0.1,0.3-0.3,0.5-0.4,0.8c-0.1,0.3,0,0.7,0,1c-0.1,0.5-0.2,1-0.6,1.4
		c-0.1,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1c0.6,1.5,0.8,1.9,2.1,3c0.2,0.2,0.4,0.4,0.6,0.6c0.4,0.3,0.6,0.7,0.8,1.1
		c0.1,0.2,0.2,0.4,0.2,0.7c0.2,0.5,0.4,1.1,0.7,1.5c0.4,0.6,0.4,1.3,0.5,1.9c0,0.1-0.1,0.3-0.1,0.4c0,0.2-0.1,0.4,0,0.6
		c0.1,0.4,0.2,0.8,0.4,1.2c0.1,0.2,0.2,0.3,0.2,0.5c0.2,0.8,0.2,1.5-0.5,2.1c-0.3,0.2-0.5,0.6-0.8,0.9c-0.3,0.3-0.4,0.6-0.5,1
		c-0.1,0.6-0.2,1.2-0.5,1.8c-0.2,0.3-0.2,0.6-0.2,1c0,0.6,0,1.1,0,1.7c0,0.3,0.1,0.6,0.3,0.8c0.5,0.6,0.9,1.2,1.2,1.9
		c0.3,0.8,0.6,1.5,1,2.2c0.3,0.5,0.5,1,0.5,1.6c0,0.6,0.1,1.2,0.3,1.8c0.1,0.2,0.2,0.5,0.1,0.7c0,0.7,0.2,1.3,0.3,2
		c0.1,0.7,0.5,1.3,0.9,1.9c0.2,0.3,0.4,0.6,0.7,0.9c0.3,0.3,0.4,0.6,0.5,1c0.2,0.7,0.4,1.5,0.8,2.1c0.1,0.2,0.2,0.3,0.2,0.5
		c0.2,0.4,0.2,0.8-0.1,1.2c-0.1,0.1-0.1,0.4,0,0.5c0.3,0.4,0.3,0.8,0.3,1.2c0,0.1,0.1,0.2,0.1,0.3c0.5,0.3,1,0.6,1.5,0.8
		c0.1,0.1,0.3,0,0.4,0c0.5-0.3,0.9-0.4,1.5-0.4c0.2,0,0.5-0.1,0.7-0.2c0.4-0.2,0.8-0.2,1.3-0.2c0.5,0,0.9-0.2,1.4,0.1
		c0.1,0.1,0.3,0.1,0.4,0c0.4-0.2,1,0,1.3-0.4c0.1-0.1,0.3-0.1,0.4-0.1c0.7,0,1.3-0.3,1.8-0.7c0.8-0.5,1.5-1.2,2.2-1.9
		c0.5-0.4,0.9-0.9,1.2-1.4c0.3-0.5,0.7-1,1.1-1.5c0.2-0.3,0.4-0.6,0.7-0.7c0.6-0.4,0.9-1,1.1-1.6c0.1-0.5,0.3-0.9,0.4-1.4
		c0.1-0.2,0.1-0.5-0.1-0.7c-0.2-0.3,0-0.6,0.2-0.8c0.3-0.3,0.7-0.5,1.1-0.6c0.5-0.1,0.9-0.2,1.3-0.5c0.3-0.2,0.6-0.5,0.6-0.9
		c0-0.5,0.1-0.9,0.2-1.4c0-0.2,0-0.5,0-0.7c-0.2-0.7-0.4-1.5-0.6-2.2c-0.1-0.3,0-0.5,0.2-0.7c1-0.8,2-1.7,3.1-2.6
		c0.3-0.2,0.6-0.4,1-0.5c0.4-0.2,0.8-0.4,1.1-0.6c0.8-0.5,1.4-1.2,1.7-2c0.2-0.4,0.3-0.8,0.2-1.2c-0.1-0.4,0-0.9,0-1.3
		c0-0.3,0.1-0.7,0-1c-0.1-0.6,0-1.1,0.1-1.7c0.1-0.3,0-0.6-0.3-0.7c-0.5-0.3-0.7-0.8-0.8-1.3c-0.2-0.6-0.1-1.3-0.2-2
		c0-0.1,0-0.3,0.1-0.4c0.1-0.3,0.1-0.6-0.1-0.8c-0.5-0.4-0.6-0.8-0.3-1.4c0.2-0.4,0.3-0.9,0.7-1.2c0.4-0.4,0.5-1,0.8-1.5
		c0.1-0.3,0.3-0.5,0.6-0.6c0.5-0.3,0.9-0.6,1.2-1c0.8-1.1,1.8-2.1,2.8-3c0.6-0.5,1.1-1.2,1.9-1.5c0.1,0,0.2-0.1,0.2-0.2
		c1.8-1.4,3.2-3.1,4.1-5.2c0.3-0.8,0.6-1.6,1.2-2.3c0.4-0.4,0.5-1,0.7-1.6c0.2-0.7,0.2-1.5,0.2-2.3c0-0.2-0.4-0.4-0.5-0.3
		c0,0-0.1,0-0.1,0.1c-0.5,0.4-1.2,0.6-1.9,0.7c-0.9,0.2-1.9,0.2-2.8,0.5c-0.1,0-0.3,0.1-0.4,0c-0.6-0.1-1,0.1-1.5,0.4
		c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0.1-0.4,0-0.5-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.3-0.7-0.7-1-1c-0.1-0.1-0.1-0.2,0-0.4
		c0.3-0.5,0.3-0.7-0.1-1c-0.3-0.2-0.5-0.4-0.7-0.7c-0.4-0.6-1-1-1.5-1.6c-0.3-0.3-0.7-0.5-1.1-0.7c-0.7-0.3-1.3-0.8-1.4-1.6
		c-0.1-0.4-0.2-0.8-0.4-1.2c-0.1-0.4-0.4-0.8-0.7-1c-0.3-0.2-0.6-0.5-0.9-0.7c-0.2-0.2-0.4-0.4-0.4-0.7c0-0.8-0.3-1.6-0.4-2.4
		c-0.1-0.8-0.6-1.3-1.2-1.7c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.5-0.1-0.9-0.4-1.3
		c-0.1-0.2-0.2-0.4-0.4-0.6c-0.6-0.9-1.2-1.7-1.4-2.8c0-0.2-0.2-0.3-0.3-0.5c-0.1-0.1,0-0.3,0.2-0.3c0.2,0,0.4,0.1,0.5-0.1
		c0.1-0.1,0.3-0.3,0.5-0.3c0.3,0,0.4,0.2,0.6,0.4c0.1,0.1,0.2,0.3,0.3,0.5c0.5,0.7,0.9,1.5,1.4,2.1c0.3,0.4,0.5,0.8,0.6,1.3
		c0.1,0.3,0.2,0.5,0.5,0.6c0.6,0.2,1,0.6,1.2,1.2c0.1,0.2,0.2,0.4,0.2,0.5c0.2,0.3,0.2,0.6,0.2,1c0,0.1,0,0.3,0,0.4
		c0,0.8,0.4,1.3,1,1.8c0.2,0.2,0.5,0.3,0.7,0.5c0.4,0.3,0.7,0.6,0.8,1.1c0.2,0.6,0.5,1,0.9,1.5c0.3,0.3,0.6,0.7,0.9,1.1
		c0.3,0.5,0.6,1,0.4,1.6c0,0.2-0.1,0.4,0,0.6c0.3,0.6,0.4,1.4,0.6,2c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.2,0.3,0.3,0.6,0.3
		c0.2,0,0.5,0,0.7-0.1c0.3,0,0.7-0.1,0.9-0.4c0.3-0.4,0.7-0.5,1.1-0.4c0.5,0.1,1.1,0,1.5-0.4c0.3-0.2,0.6-0.3,0.9-0.3
		c0.3,0,0.6-0.2,0.8-0.4c0.2-0.3,0.5-0.4,0.8-0.5c0.5-0.2,1-0.3,1.5-0.5c0.4-0.2,0.8-0.4,1.2-0.5c0.2-0.1,0.3-0.2,0.4-0.4
		c0.1-0.5,0.4-0.7,0.9-0.9c0.6-0.2,1.2-0.4,1.8-0.5c0.4-0.1,0.7-0.2,0.8-0.7c0.1-0.3,0.2-0.4,0.5-0.5c0.5-0.1,0.9-0.3,1-0.8
		c0.1-0.2,0.3-0.4,0.5-0.4c0.2-0.1,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0-0.3,0-0.6,0-0.9c0-0.2,0.1-0.5,0.3-0.6
		c0.7-0.5,1.1-1.1,1.5-1.9c0.2-0.5,0.2-0.7-0.2-1.1c-0.3-0.3-0.5-0.6-0.7-0.9c-0.2-0.3-0.4-0.4-0.7-0.4c-0.4,0-0.8-0.1-1.1-0.2
		c-0.2-0.1-0.4-0.2-0.6-0.3c-0.3-0.3-0.6-0.7-0.7-1.2c-0.1-0.4-0.1-0.8-0.2-1.3c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.3,0.1
		c-0.5,0.6-1.1,1.1-1.6,1.8c-0.3,0.5-0.8,0.8-1.4,0.8c-0.5,0-0.9,0-1.4,0.2c-0.2,0.1-0.4,0-0.5-0.2c-0.2-0.2-0.3-0.4-0.3-0.6
		c0-0.5-0.1-0.9-0.1-1.4c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.2,0-0.2,0.1c-0.1,0.2-0.2,0.3-0.3,0.5
		c-0.1,0.2-0.3,0.2-0.4,0c-0.2-0.2-0.3-0.5-0.4-0.8c-0.1-0.5-0.3-0.9-0.7-1.2c-0.2-0.2-0.5-0.5-0.7-0.7c-0.1-0.1-0.3-0.2-0.3-0.3
		c-0.3-0.5-0.6-1-0.8-1.5c-0.1-0.1-0.1-0.3,0-0.4c0-0.2,0.1-0.4,0.1-0.6c0-0.1,0.1-0.2,0.2-0.2c0.3,0,0.6-0.1,0.8-0.1
		c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.3,0.4,0.4,0.6c0.4,0.6,0.7,1.1,1.1,1.7c0.2,0.3,0.4,0.4,0.7,0.5c0.1,0,0.3,0.1,0.4,0.1
		c0.3,0.1,0.6,0.2,0.8,0.5c0.1,0.2,0.4,0.3,0.6,0.4c0.5,0.4,1.1,0.5,1.7,0.6c0.2,0.1,0.5,0,0.7-0.1c0.3-0.2,0.7-0.4,1-0.5
		c0.4-0.2,0.8,0,1,0.4c0.1,0.3,0.3,0.6,0.4,0.9c0.1,0.3,0.4,0.4,0.7,0.4c1.3,0.1,2.6,0.4,3.9,0.6c0.5,0.1,0.9,0.1,1.4,0.1
		c0.8-0.1,1.6-0.3,2.4-0.1c0.2,0,0.4,0,0.6-0.1c0.6-0.2,1.1-0.2,1.7-0.1c0.2,0,0.4,0.1,0.5,0.3c0.1,0.2,0.2,0.3,0.4,0.4
		c0.2,0.1,0.4,0.3,0.4,0.5c0.1,0.5,0.4,0.7,0.9,0.8c0.3,0.1,0.6,0.3,0.8,0.6c0.4,0.5,0.9,0.7,1.5,0.7c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0
		l0.1,0l0,0.1c0,0,0,0,0,0c-0.2,0.3-0.5,0.5-0.9,0.4c-0.1,0-0.2,0-0.2,0.1c0,0.1,0,0.2,0,0.3c0.4,0.4,0.8,0.9,1.3,1.3
		c0.8,0.7,1.2,0.8,2.1,0.1c0.4-0.2,0.6-0.5,0.6-1c0,0,0,0,0,0l0-0.1l0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0.2,0.6,0.5,1.2,0.4,1.9
		c0,0.3,0,0.6,0.1,0.8c0.3,0.7,0.4,1.4,0.5,2.1c0.2,0.7,0.6,1.3,0.6,2c0,0.1,0.1,0.3,0.2,0.4c0.2,0.4,0.4,0.9,0.7,1.2
		c0.3,0.5,0.5,1,0.7,1.5c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.6,0.4,1.1,0.8,1.5c0.2,0.2,0.4,0.5,0.6,0.8c0.3,0.7,0.6,1.3,0.7,2
		c0.1,0.4,0.3,0.8,0.5,1.2c0.1,0.2,0.3,0.3,0.4,0.4c0.4,0.3,0.9,0.2,1.1-0.1c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.2,0.3-0.4,0.5-0.5
		c0.3-0.1,0.4-0.3,0.5-0.6c0.1-0.4,0.3-0.7,0.7-0.9c0.2-0.1,0.3-0.4,0.3-0.6c0-0.2-0.1-0.5-0.1-0.7c-0.1-0.3-0.1-0.6,0.1-0.8
		c0.1-0.2,0.1-0.4,0.2-0.5c0.2-0.4,0.2-0.8,0-1.2c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.5,0-1,0-1.6c0-0.3,0.2-0.5,0.5-0.6
		c0.1,0,0.3-0.1,0.4-0.2c0.2-0.4,0.6-0.5,1-0.7c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.3-0.6c0.6-0.3,1-0.9,1.5-1.3
		c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.3,0.4-0.5,0.6-0.8c0.1-0.2,0.3-0.3,0.6-0.4c0.8-0.4,1.3-0.9,1.3-1.8c0-0.3,0.2-0.4,0.4-0.5
		c0.3-0.2,0.7-0.2,1.1-0.2c0.9,0,1.7-0.2,2.4-0.7c0.3-0.2,0.7-0.2,1-0.5c0,0,0.2,0.1,0.2,0.1c0.1,0.3,0.2,0.5,0.3,0.8
		c0.3,0.9,0.8,1.7,1.7,2.2c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.2,0.3,0.2,0.6c-0.1,0.4,0,0.6,0.4,0.8c0.3,0.1,0.5,0.3,0.6,0.5
		c0,0,0,0.1,0.1,0.1c0.3,0.6,0.5,1.2,0.2,1.9c-0.1,0.2,0,0.5,0,0.7c0,0.2,0.3,0.5,0.5,0.5c0.5,0,1,0,1.5-0.4
		C332.8,165.2,333.2,165,333.5,164.8"
            />
            <path
              fill="#003046"
              d="M337.6,174C337.6,174,337.7,174,337.6,174C337.7,174,337.7,174,337.6,174L337.6,174z"
            />
            <path
              fill="#003046"
              d="M243,137.7C243,137.7,243,137.7,243,137.7L243,137.7C243,137.7,243,137.7,243,137.7
		C243,137.7,243,137.7,243,137.7"
            />
            <path
              fill="#003046"
              d="M162,185.2C162,185.2,162,185.2,162,185.2C162,185.2,162,185.2,162,185.2C162,185.2,162,185.2,162,185.2
		C162,185.2,162,185.2,162,185.2"
            />
            <path
              fill="#003046"
              d="M131.1,75.3C131.1,75.3,131.1,75.3,131.1,75.3C131.1,75.3,131.1,75.3,131.1,75.3
		C131.1,75.3,131.1,75.3,131.1,75.3C131.1,75.3,131.1,75.3,131.1,75.3"
            />
            <path
              fill="#003046"
              d="M136,86.2C136,86.2,136,86.2,136,86.2L136,86.2c-0.1,0.3-0.2,0.5-0.2,0.8c-0.3,0.9-0.8,1.6-1,2.5
		c0,0.2-0.2,0.3-0.4,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.2-0.1,0.5,0.1,0.6c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.3,0.2,0.3,0.4
		c0,0.1,0,0.2,0,0.3c0,0.3,0.2,0.5,0.6,0.4c0.2,0,0.4-0.1,0.5-0.2c0.5-0.5,1.2-0.7,1.5-1.4c0,0,0.2-0.1,0.2-0.1
		c0.3,0.2,0.6,0.3,0.6,0.7c0,0.2,0.1,0.3,0.2,0.3c0.4,0.1,0.7,0.3,1.1,0.4c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.4-0.3,0.5-0.5
		c0.1-0.2,0-0.5-0.2-0.6c0,0-0.1,0-0.1,0c-0.3-0.1-0.5-0.3-0.5-0.6c0-0.4-0.1-0.7-0.4-1c-0.2-0.2-0.4-0.3-0.4-0.6
		c-0.2-0.6-0.9-0.7-1.1-1.3c0-0.1-0.2-0.1-0.3-0.1c-0.3-0.1-0.3-0.1-0.1-0.3c0.2-0.2,0.3-0.3,0.5-0.5c0-0.1,0-0.2,0-0.3
		c0-0.1-0.1-0.1-0.2,0c-0.2,0.1-0.3,0.2-0.5,0.1c-0.2-0.1-0.4,0-0.6,0C136.2,86,136.1,86.1,136,86.2C136,86.2,136,86.2,136,86.2"
            />
            <path
              fill="#003046"
              d="M143.7,259.8C143.7,259.8,143.7,259.9,143.7,259.8C143.7,259.9,143.7,259.9,143.7,259.8
		C143.7,259.9,143.7,259.8,143.7,259.8C143.7,259.8,143.7,259.8,143.7,259.8"
            />
            <path
              fill="#003046"
              d="M142.3,76.8C142.3,76.8,142.3,76.8,142.3,76.8C142.3,76.7,142.3,76.7,142.3,76.8
		C142.3,76.7,142.3,76.7,142.3,76.8C142.3,76.7,142.3,76.7,142.3,76.8"
            />
            <path
              fill="#003046"
              d="M151.4,125.1C151.4,125.1,151.4,125.1,151.4,125.1C151.4,125.1,151.4,125.1,151.4,125.1
		C151.4,125.1,151.4,125.1,151.4,125.1C151.4,125.1,151.4,125.1,151.4,125.1"
            />
            <path
              fill="#003046"
              d="M146,261.6L146,261.6C146,261.6,146,261.6,146,261.6L146,261.6z"
            />
            <path
              fill="#003046"
              d="M68.4,86.7L68.4,86.7L68.4,86.7C68.4,86.8,68.5,86.8,68.4,86.7C68.5,86.7,68.5,86.7,68.4,86.7"
            />
            <path
              fill="#003046"
              d="M163.4,207.4L163.4,207.4L163.4,207.4L163.4,207.4z M162.5,190.8C162.5,190.8,162.4,190.8,162.5,190.8
		c-0.2-0.1-0.2-0.2-0.3-0.3c-0.1-0.1,0-0.2,0.1-0.2c0,0,0.1,0,0.1,0.1c0.1,0.1,0.1,0.2,0.2,0.3C162.5,190.7,162.5,190.8,162.5,190.8
		 M162.1,187.6C162.1,187.6,162.1,187.5,162.1,187.6c0.1-0.1,0.2-0.2,0.3-0.2c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1-0.1,0.1
		C162.3,187.6,162.2,187.6,162.1,187.6 M160.7,186.1c-0.1,0.1-0.3,0.2-0.5,0.1c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.1,0.1-0.2,0.2-0.3
		c0,0.1,0,0.1,0,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0,0.2-0.1C160.8,185.9,160.7,186,160.7,186.1 M155.1,179.3
		C155.1,179.3,155.1,179.3,155.1,179.3c0-0.1,0.1-0.2,0.2-0.2c0,0,0,0.1,0,0.1C155.3,179.4,155.2,179.4,155.1,179.3 M148.5,223.5
		c-0.1,0-0.2-0.1-0.2-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1C148.6,223.4,148.6,223.4,148.5,223.5
		 M146.4,176.7L146.4,176.7L146.4,176.7L146.4,176.7z M145.2,259.6C145.2,259.6,145.2,259.6,145.2,259.6c0.2,0.2,0.4,0.3,0.5,0.4
		c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.2-0.1c0,0,0.1,0,0.1,0.1c0.1,0.1,0,0.2-0.1,0.2
		c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.2
		C145.2,259.7,145.2,259.7,145.2,259.6C145.2,259.6,145.1,259.6,145.2,259.6C145.1,259.6,145.1,259.6,145.2,259.6
		c-0.2,0-0.3,0-0.3-0.1c0,0,0.1,0,0.1,0C145,259.4,145.1,259.5,145.2,259.6C145.1,259.5,145.1,259.5,145.2,259.6 M146.1,258.5
		c0.1-0.1,0.2,0,0.2,0.1c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c0,0-0.1,0-0.1,0C145.8,258.8,145.9,258.6,146.1,258.5
		 M144,257.8C144,257.8,144,257.8,144,257.8C144,257.9,144,257.9,144,257.8c-0.1,0.2-0.2,0.2-0.3,0.3c0,0,0,0,0,0.1l-0.1,0.1l0-0.1
		c0,0,0,0,0.1,0C143.8,258,143.9,257.9,144,257.8C143.9,257.9,144,257.8,144,257.8C144,257.8,144,257.8,144,257.8
		C144,257.8,144,257.8,144,257.8C144.1,257.8,144.1,257.8,144,257.8 M143.3,258.9C143.3,258.9,143.3,258.9,143.3,258.9
		C143.3,258.8,143.4,258.8,143.3,258.9C143.4,258.8,143.4,258.9,143.3,258.9C143.4,258.9,143.4,258.9,143.3,258.9 M143.1,257.2
		c-0.1,0-0.2,0.1-0.2,0.1c0,0-0.1,0-0.1-0.1c-0.1-0.1,0-0.2,0.1-0.2c0.1,0,0.2,0,0.3,0C143.1,257.1,143.1,257.2,143.1,257.2
		 M142.3,255.8C142.3,255.8,142.3,255.8,142.3,255.8l-0.1,0.1L142.3,255.8C142.3,255.8,142.3,255.8,142.3,255.8
		C142.3,255.8,142.3,255.8,142.3,255.8C142.4,255.8,142.4,255.8,142.3,255.8 M139.7,204.9C139.7,204.9,139.6,204.9,139.7,204.9
		c-0.5,0-0.7-0.3-0.9-0.6c0-0.1,0-0.2,0-0.2c0,0,0.1,0,0.1,0C139.2,204.3,139.5,204.5,139.7,204.9
		C139.7,204.9,139.7,204.9,139.7,204.9c0.1,0,0.1,0,0.1,0C139.7,205,139.7,204.9,139.7,204.9 M139.3,250.4
		C139.3,250.4,139.2,250.4,139.3,250.4c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1-0.1,0.1,0.1C139.3,250.3,139.3,250.4,139.3,250.4
		 M136.5,173.9c0,0.1-0.2,0.3-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.2c-0.2-0.4-0.2-0.5,0.2-0.9c0-0.1,0.2-0.1,0.2-0.1c0,0,0,0.1,0,0.1
		c0,0.1,0,0.3,0,0.5C136.5,173.6,136.5,173.8,136.5,173.9 M120.4,171.5c0,0-0.1,0.1-0.1,0.1c-0.3,0.1-0.6-0.2-0.5-0.4
		c0,0,0.1-0.1,0.1-0.1c0.2,0,0.4,0.1,0.5,0.3C120.4,171.4,120.4,171.5,120.4,171.5 M105.6,97.8c-0.1,0-0.2,0-0.2-0.1
		c0,0,0-0.1,0-0.1c0.1-0.2,0.2-0.3,0.3-0.4c0.5-0.3,1-0.6,1.6-0.9c0.1,0,0.2,0,0.3,0c0.3,0.2,0.5,0.4,0.9,0.4c0,0,0.1,0.1,0.1,0.2
		c0,0,0,0.1-0.1,0.1c-0.3,0-0.6,0.1-1,0.1c0,0,0,0,0-0.1c-0.1,0-0.3,0-0.4,0c-0.5,0-0.8,0.1-1.1,0.5
		C105.8,97.6,105.7,97.7,105.6,97.8 M105.4,103.7C105.4,103.7,105.3,103.7,105.4,103.7c-0.1-0.1-0.1-0.2-0.1-0.3
		C105.3,103.5,105.3,103.6,105.4,103.7C105.4,103.6,105.5,103.7,105.4,103.7 M104.4,91.5c-0.2,0.2-0.4,0.4-0.7,0.4
		c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.7-0.1-0.9-0.4c-0.1-0.2-0.3-0.4-0.5-0.5c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0
		c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0,0.3,0,0.4-0.1c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.3,0.4-0.3,0.7-0.3c0.1,0,0.2,0,0.3,0
		c0.2,0,0.3-0.2,0.3-0.4c0-0.1,0-0.3,0-0.4c-0.1-0.3-0.1-0.6-0.2-0.8c0-0.1,0-0.2,0.1-0.2c0,0,0.1,0,0.1,0.1c0.4,0.3,0.7,0.8,1,1.2
		c0.1,0.2,0.4,0.3,0.6,0.3c0.7,0.1,1.2-0.1,1.7-0.5c0.5-0.4,1-0.6,1.6-0.7c0.2,0,0.5,0,0.7,0c0,0,0,0,0,0c0-0.1,0-0.1,0.1,0
		c0,0,0,0-0.1,0.1c0,0,0,0,0,0c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.1-0.4,0.2-0.6,0.3c-0.5,0.2-0.9,0.5-1.3,0.8
		c-0.5,0.4-1.1,0.6-1.6,0.9c-0.1,0-0.2,0-0.3,0C105,91,104.7,91.2,104.4,91.5 M103.9,97.8C103.8,97.9,103.8,97.9,103.9,97.8
		c-0.1,0-0.1-0.1-0.2-0.2c0,0,0.1-0.1,0.1,0c0,0,0.1,0.1,0.1,0.1C103.9,97.8,103.9,97.8,103.9,97.8 M101.6,93.5L101.6,93.5l0.1,0
		L101.6,93.5z M102.7,86.1c-0.1,0.1-0.1,0.1-0.1,0.1c-0.4-0.1-0.5-0.3-0.7-0.7c0,0,0.1-0.2,0.2-0.2c0.1,0,0.2,0,0.3,0
		C102.4,85.6,102.5,85.8,102.7,86.1 M103.8,81.7c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2
		c-0.1-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.1,0-0.2,0.2-0.2C103.8,81.6,103.8,81.7,103.8,81.7 M100.5,80.8
		c-0.4,0.3-0.7,0.3-1.2,0c-0.2-0.1-0.2-0.2-0.1-0.3c0.2-0.1,0.4-0.2,0.6-0.4c0.2-0.3,0.4-0.5,0.8-0.6c0.2-0.1,0.4-0.3,0.4-0.5
		c0-0.1,0.1-0.1,0.2-0.1c0.2,0,0.4,0.2,0.7,0.1c0.2,0,0.3-0.3,0.2-0.4c-0.1-0.1-0.2-0.3-0.4-0.4c-0.3-0.2-0.6-0.2-1-0.2
		c-0.6,0-1.2-0.1-1.8-0.1c0,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.2,0.1-0.2c0.1,0,0.2-0.1,0.3-0.1c0.7,0,1.4-0.1,2.1-0.1
		c0.5,0,1-0.1,1.5-0.2c0.5-0.1,0.9-0.2,1.4-0.3c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.2
		c-0.4,0.2-0.8,0.3-1.2,0.5c-0.1,0-0.2,0.3-0.1,0.4c0,0,0,0.1,0.1,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0.6,0.5,1.3,0.6,1.9,0.1
		c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.1,0.2c-0.1,0.2-0.1,0.4-0.3,0.5c-0.2,0.1-0.4,0.3-0.6,0.4c-0.1,0.1-0.1,0.2-0.2,0.4
		c-0.2,0.5-0.3,0.5-0.7,0.2c-0.4-0.3-0.8-0.4-1.2-0.2c-0.1,0.1-0.3,0.2-0.3,0.3c-0.1,0.5-0.4,0.7-0.8,0.8c0,0,0,0,0,0
		c0,0-0.1,0-0.1,0c-0.1,0.1-0.3,0.2-0.5,0.2c0,0,0,0,0,0.1c0,0,0,0-0.1,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0
		c0-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1-0.1,0.3-0.2,0.5-0.2c0,0,0,0,0.1,0c0,0,0,0,0,0c0.1-0.2,0.1-0.4,0.2-0.6
		c0.1-0.2-0.2-0.5-0.4-0.4c-0.3,0.1-0.5,0.2-0.8,0.3C100.8,80.4,100.7,80.6,100.5,80.8 M100.2,90.5
		C100.2,90.5,100.2,90.4,100.2,90.5C100.2,90.4,100.2,90.4,100.2,90.5L100.2,90.5L100.2,90.5z M99.1,74.7
		C99.1,74.7,99.1,74.7,99.1,74.7C99.1,74.7,99.1,74.7,99.1,74.7C99.2,74.7,99.2,74.7,99.1,74.7C99.1,74.7,99.1,74.7,99.1,74.7
		L99.1,74.7L99.1,74.7z M110,80c0.1,0,0.2,0,0.2,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c-0.1,0-0.2,0-0.2,0
		C109.9,80.2,109.8,80,110,80 M109.2,91.4L109.2,91.4L109.2,91.4L109.2,91.4z M108.7,101.2c0,0-0.1,0-0.1-0.1c0,0,0-0.1,0-0.1
		c0.1-0.2,0.3-0.3,0.6-0.2c0,0,0,0.1,0,0.1C109.1,101.1,108.9,101.2,108.7,101.2 M108.8,105.8C108.7,105.8,108.7,105.8,108.8,105.8
		c-0.1-0.2-0.1-0.2,0-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0.1,0.1,0.1C109.1,105.7,109,105.9,108.8,105.8 M98.4,67.2
		c0,0-0.1,0.1-0.1,0.1c-0.5,0-0.9,0-1.4,0c0,0-0.1,0-0.1,0c-0.1,0.1-0.2,0.2-0.3,0.3c0,0,0,0-0.1,0c0,0.1-0.1,0.1-0.1,0.1
		c0,0-0.1,0-0.1,0c0-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0.1,0,0.1,0C97.4,67,97.9,67.1,98.4,67.2
		C98.4,67.1,98.4,67.2,98.4,67.2 M98.1,103.5c0,0-0.1,0-0.1,0C98,103.5,98.1,103.5,98.1,103.5c0.1-0.1,0.1-0.1,0.1,0
		C98.2,103.5,98.1,103.5,98.1,103.5 M95.9,91.9L95.9,91.9L95.9,91.9L95.9,91.9L95.9,91.9z M95.4,68.2C95.3,68.2,95.3,68.2,95.4,68.2
		C95.3,68.2,95.3,68.2,95.4,68.2L95.4,68.2L95.4,68.2z M93.4,68.8c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.3,0-0.2c0-0.3,0-0.4,0-0.4
		c0,0,0-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0,0.2,0,0.4,0,0.7C93.5,68.7,93.5,68.8,93.4,68.8 M80.7,99C80.6,98.9,80.6,98.9,80.7,99
		c-0.1-0.1,0-0.2,0-0.3c0.1,0.1,0.1,0.1,0.2,0.2C80.8,98.9,80.8,99,80.7,99 M80.3,99.9c-0.1,0.1-0.2,0-0.2,0
		c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2,0-0.4,0.2-0.6c0,0,0,0,0-0.1c0,0,0,0,0,0c0-0.1,0-0.2,0.1-0.2c0,0,0.1,0.1,0,0.1
		c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0.1c0,0,0,0,0,0c0.1,0.3,0.3,0.6,0.4,1C80.3,99.9,80.3,99.9,80.3,99.9 M80.1,92.4
		C80.1,92.4,80,92.4,80.1,92.4c-0.1-0.1-0.1-0.1,0-0.2c0,0,0,0.1,0,0.1C80.1,92.3,80.1,92.3,80.1,92.4 M79.9,94.2
		C79.9,94.2,79.9,94.1,79.9,94.2c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0-0.1,0-0.1C79.9,93.9,79.9,94,79.9,94.2
		C79.9,94.1,79.9,94.1,79.9,94.2c0.1,0,0.1,0,0.1,0.1C79.9,94.2,79.9,94.2,79.9,94.2 M79.1,96.3L79.1,96.3L79.1,96.3
		c0-0.1,0-0.1,0-0.1C79.1,96.2,79.1,96.2,79.1,96.3 M110.9,84.4c0,0,0.1,0,0.1,0c0.1,0.1,0.1,0.1,0,0.2c0,0-0.1,0-0.1,0
		C110.9,84.5,110.9,84.5,110.9,84.4 M111.8,81.6L111.8,81.6L111.8,81.6L111.8,81.6z M111.9,103.2c0.2,0,0.3-0.1,0.5-0.1
		c0,0,0.1,0,0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0,0,0,0c0,0,0-0.1,0-0.1c-0.3-1.2-0.4-1.3,0.9-2c0.1,0,0.2,0,0.2,0
		c0.1,0.1,0.1,0.2,0.1,0.2c0,0,0,0.1,0,0.1c-0.2,0.2-0.5,0.5-0.6,0.7c-0.2,0.2-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.2,0.2c0,0,0,0,0,0
		c-0.1,0.1-0.2,0.2-0.3,0.3c0,0-0.1,0-0.1,0c-0.2,0.1-0.3,0.2-0.5,0.3c0,0-0.1,0-0.1-0.1C111.8,103.3,111.9,103.2,111.9,103.2
		 M112.4,85.9c0.1,0,0.2,0.1,0.1,0.1c0,0-0.1,0-0.1,0c0,0,0-0.1-0.1-0.1C112.3,86,112.3,85.9,112.4,85.9 M112.6,99.7
		c0-0.2,0.2-0.3,0.4-0.4c0.1,0,0.2,0,0.2,0.1c0,0,0,0.1,0,0.1c-0.2,0.3-0.4,0.5-0.7,0.6c0,0,0,0,0,0.1c0,0-0.1,0-0.1,0
		c0,0,0,0.1-0.1,0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0C112.5,100,112.5,99.8,112.6,99.7 M113.4,86.4
		c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.1,0.2c0,0-0.1,0-0.1,0C113.3,86.5,113.3,86.4,113.4,86.4 M113.8,115.1
		C113.8,115.1,113.8,115.2,113.8,115.1c-0.2,0.1-0.3,0.1-0.3,0.1c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.1,0.1-0.2,0.1-0.2c0,0,0,0,0,0
		c0-0.1,0-0.1,0.1-0.2c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c0.1,0.3,0.3,0.5,0.4,0.8
		C113.8,114.9,113.8,115,113.8,115.1 M113.2,111.3c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.1-0.1,0.1-0.2,0c0,0,0-0.1,0-0.1
		c0,0,0-0.1,0-0.1c0.1-0.3,0.3-0.5,0.2-0.8c-0.1-0.3-0.4-0.3-0.6-0.5c0,0-0.1-0.1,0-0.1c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.3,0
		c0.2,0,0.3,0.1,0.5,0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.2-0.3-0.4-0.4-0.7c-0.2-0.4-0.2-0.7,0-1.1c0.1-0.1,0.2-0.3,0.3-0.3
		c0.1,0,0.3,0.1,0.4,0.1c0.2,0.2,0.3,0.4,0.4,0.6c0,0,0,0,0,0l0-0.1l0,0.1c0,0,0,0,0,0c-0.4,0.3-0.4,0.7,0,1c0.1,0.1,0,0.2-0.1,0.2
		c-0.2,0-0.3,0.1-0.5,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0.1,0.2,0.1,0.3,0.2,0.5C113.2,110.9,113.2,111.1,113.2,111.3 M114.8,109.3
		c0-0.1,0.2-0.1,0.3,0c0.3,0.1,0.5,0.2,0.6,0.5c0.1,0.3,0.2,0.6,0.1,1c0,0.5,0,0.9-0.1,1.3c0.1,0.2,0.1,0.4,0.1,0.5
		c0,0.1,0,0.3-0.1,0.4c-0.2,0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.3-0.3-0.3c-0.3-0.1-0.4-0.2-0.5-0.5c0-0.1-0.1-0.3-0.1-0.4
		c-0.1-0.4-0.2-0.7,0-1.1c-0.1-0.2-0.2-0.3-0.3-0.5C114.5,109.7,114.6,109.5,114.8,109.3 M115.7,108.1
		C115.8,108.1,115.8,108.1,115.7,108.1c0.1,0.1,0.1,0.2,0,0.2c0,0-0.1,0-0.1,0C115.6,108.1,115.6,108.1,115.7,108.1 M115.9,113.8
		c0.1,0.5,0,0.9-0.5,1.2c0,0,0,0,0,0.1l-0.1,0l0,0c0,0,0,0,0,0c-0.1-0.5,0.1-0.9,0.3-1.3c0,0,0.1,0,0.1,0
		C115.9,113.7,115.9,113.8,115.9,113.8 M116.1,103c0.1,0,0.2,0,0.2,0.1c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.1-0.1-0.2-0.1
		C116,103.1,116,103,116.1,103 M116.5,107.5L116.5,107.5L116.5,107.5L116.5,107.5z M116.8,117.7c0.1-0.2,0.1-0.4,0.3-0.6
		c0,0,0,0,0-0.1c0,0,0,0,0.1-0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0.2,0.1,0.3,0.1,0.5c0,0,0,0.1,0,0.1c-0.1,0.1-0.1,0.1-0.2,0.2
		c0,0-0.1,0-0.1,0C116.8,117.8,116.7,117.8,116.8,117.7 M117.2,102.4L117.2,102.4L117.2,102.4L117.2,102.4z M117.9,91.4L117.9,91.4
		l-0.1,0L117.9,91.4z M118.2,96.1C118.2,96.1,118.3,96.1,118.2,96.1c0.1-0.1,0.1-0.2,0.1-0.2c0,0,0,0,0.1,0
		C118.4,96,118.3,96,118.2,96.1C118.3,96.1,118.3,96.1,118.2,96.1c-0.1,0.1-0.2,0.3-0.3,0.4c0,0,0,0-0.1,0.1c0,0,0-0.1,0-0.1
		C118,96.4,118.1,96.3,118.2,96.1 M118.6,89.2c0.2-0.1,0.4-0.1,0.5-0.1c0.1,0,0.1,0.1,0.2,0.1c-0.3,0.3-0.2,0.7-0.4,1
		c-0.1,0.3-0.3,0.3-0.5,0.1C117.9,89.9,118,89.5,118.6,89.2 M119.3,109.5c-0.1,0-0.1-0.1-0.1-0.2c0,0,0.1,0,0.1,0
		c0,0.1,0.1,0.1,0.1,0.2C119.4,109.5,119.3,109.5,119.3,109.5 M120.8,120.6c0.6-0.2,1.2-0.4,1.7-0.8c0.4-0.4,1.1-0.4,1.5-0.8
		c0.1-0.1,0.3-0.1,0.5-0.1c0.1,0,0.3,0.1,0.4,0.1c0.5,0.1,0.9,0.4,1,0.9c0.1,0.3,0.3,0.5,0.6,0.6c0.4,0.1,0.4,0.3,0.4,0.6
		c0,0.4,0.1,0.8,0.1,1.3c0,0.2-0.2,0.3-0.4,0.2c-0.2-0.1-0.3-0.2-0.5-0.2c-0.4,0-0.8,0-1.1,0.1c-0.5,0.1-0.8,0-1.1-0.4
		c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.3-0.1-0.3-0.2c0-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0-0.2c0,0-0.1,0-0.1-0.1
		c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.4-1.2,0.6-1.8,0.8c-0.4,0.1-0.7,0.2-1-0.2c-0.2-0.3-0.5-0.2-0.7-0.2
		c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0
		C119.8,121.1,120.3,120.8,120.8,120.6 M119.7,115.6C119.7,115.6,119.7,115.6,119.7,115.6C119.7,115.7,119.7,115.7,119.7,115.6
		c0.3,0.1,0.6,0.1,0.9,0.2c0.1,0,0.1,0.1,0,0.2c0,0-0.1,0.1-0.1,0.1C120.2,116,119.9,115.9,119.7,115.6
		C119.7,115.7,119.7,115.7,119.7,115.6C119.6,115.7,119.6,115.6,119.7,115.6 M120.2,107.7c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1
		C120.2,107.9,120.2,107.8,120.2,107.7C120.2,107.8,120.2,107.8,120.2,107.7 M120.7,90.1c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1
		C120.6,90.3,120.6,90.3,120.7,90.1C120.6,90.2,120.7,90.2,120.7,90.1 M121.6,91.1c0-0.1,0.2-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.1
		c0.1,0.1,0.1,0.3,0.1,0.4c-0.1,0.1-0.1,0.2-0.2,0.1c-0.1-0.1-0.2-0.2-0.3-0.3C121.6,91.2,121.6,91.1,121.6,91.1 M122.5,114.6
		L122.5,114.6l-0.1,0L122.5,114.6z M123.9,116.7c0,0,0.1,0,0.1,0c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.2,0.1,0.4-0.1,0.6
		c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.2-0.1C123.6,117.1,123.7,116.9,123.9,116.7 M127.4,110.8C127.4,110.8,127.4,110.7,127.4,110.8
		c0-0.2,0-0.3,0-0.4c0-0.1,0.1-0.2,0.2-0.1c0,0,0,0.1,0,0.1C127.6,110.5,127.5,110.6,127.4,110.8
		C127.4,110.8,127.4,110.8,127.4,110.8L127.4,110.8L127.4,110.8z M127.7,123.3c1,0.1,2,0.4,2.9,0.7c0.1,0,0.3,0.1,0.3,0.3
		c0.2,0.4,0.4,0.8,0.6,1.1c0.1,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.1c-0.3-0.1-0.5-0.4-0.7-0.7c0,0-0.2,0-0.2,0
		c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.3-0.1,0.4c-0.4,0.6-0.6,1.3-0.8,2c0,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.3,0-0.3-0.1
		c-0.1-0.3-0.1-0.6-0.1-0.8c0-0.2-0.3-0.4-0.6-0.3c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.1-0.2,0c0,0,0-0.1,0-0.1
		c0.1-0.2,0.3-0.4,0.4-0.6c0.3-0.4,0.3-0.8,0.1-1.2c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.2-0.5-0.3-0.7-0.5c-0.1-0.1-0.2-0.2-0.2-0.3
		c0,0,0,0,0,0c0,0,0,0,0,0c-0.3,0.2-0.5,0.4-0.7,0.7c-0.1,0.2-0.3,0.4-0.6,0.4c-0.3,0-0.5,0.2-0.7,0.5c-0.4,0.8-0.8,1.6-0.7,2.6
		c0,0.9-0.4,1.5-1.1,2c-0.2,0.1-0.5,0-0.5-0.2c-0.1-0.8-0.1-1.6,0.3-2.3c0.3-0.5,0.3-1,0.5-1.5c0.1-0.2,0.1-0.5,0-0.7
		c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0.1-0.1c1.2-1.3,0.9-1.1,2.5-1.4c0.4-0.1,0.6,0.1,0.9,0.3c0,0,0,0,0,0c0,0,0,0,0,0
		c0.3-0.1,0.5-0.2,0.8-0.2c0.1,0,0.1-0.1,0.2-0.2C127.6,123.4,127.7,123.3,127.7,123.3 M131.8,129.1c0,0.1-0.1,0.2-0.1,0.2
		c-0.5,0.3-1,0.5-1.5,0.8c-0.4,0.2-0.8,0.3-1.2,0.5c-0.4,0.2-0.8,0.3-1.3,0.3c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.1-0.4,0.1-0.5
		c0,0,0.1-0.1,0.1-0.1c0.7-0.1,1.3-0.4,1.8-0.8c0.2-0.1,0.4-0.2,0.7-0.1c0.3,0.1,0.5,0.1,0.8-0.1c0.2-0.1,0.4-0.2,0.7-0.2
		C131.5,128.9,131.8,129,131.8,129.1 M132,127.8c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0.4-0.2,0.7-0.2c0.5-0.1,0.9-0.1,1.4-0.1
		c0.3,0,0.5-0.1,0.8-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0.1,0.1,0.1c0.2,0.3,0.1,0.6,0,0.8c-0.3,0.4-0.7,0.7-1.2,0.5
		c-0.7-0.3-1.3-0.1-2-0.1c-0.1,0-0.2-0.1-0.2-0.2C131.9,128,131.9,127.9,132,127.8 M133,119.4c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0.1
		c0,0.1,0,0.1-0.1,0.1c0,0-0.1,0-0.1-0.1C132.9,119.4,132.9,119.4,133,119.4 M122.6,86.9C122.6,86.9,122.6,86.9,122.6,86.9
		C122.6,86.9,122.6,87,122.6,86.9C122.4,86.9,122.4,86.9,122.6,86.9 M125.3,87.9c0,0,0.1,0,0.1,0c0.2,0.1,0.4,0.2,0.5,0.4
		c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0,0.3,0.1,0.5,0.2c0,0,0,0,0.1,0l0,0.1l0-0.1c0,0,0,0,0,0c-0.2,0-0.3-0.1-0.5-0.2c0,0-0.1,0-0.1,0
		c0,0,0,0,0,0c-0.4,0.1-0.5,0.1-0.6-0.1C125.2,88,125.3,87.9,125.3,87.9 M132.8,85.6c0,0,0.1,0,0.1,0c0.4,0.3,0.6,0.7,0.8,1.1
		c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.3-0.1-0.4-0.2c-0.3-0.3-0.4-0.6-0.6-1C132.6,85.7,132.7,85.6,132.8,85.6 M138.5,120
		c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0C138.5,120.1,138.4,120.1,138.5,120C138.4,120,138.5,120,138.5,120 M141,116
		c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1c-0.2,0.1-0.4,0.2-0.6,0.3c0,0,0-0.1-0.1-0.1C140.6,116.2,140.7,116,141,116 M143.3,109.2
		C143.4,109.2,143.4,109.3,143.3,109.2c-0.1,0-0.1,0-0.1,0C143.2,109.2,143.2,109.2,143.3,109.2 M145.9,110.6
		c0.1-0.1,0.2-0.2,0.3-0.3c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0.1,0.4c0,0,0,0.2,0,0.3c0,0-0.1,0-0.1,0
		C145.8,111.1,145.7,110.9,145.9,110.6 M146.8,110.1C146.8,110.1,146.9,110.2,146.8,110.1c0.1,0.1,0.1,0.2,0.1,0.3
		C146.9,110.4,146.9,110.3,146.8,110.1C146.8,110.2,146.8,110.2,146.8,110.1 M150.3,113.9L150.3,113.9L150.3,113.9L150.3,113.9z
		 M150.6,111.5c0.2,0,0.5,0.1,0.7-0.1c0.5-0.2,0.9-0.1,1.3,0.1c0,0,0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.1c-0.2,0.1-0.3,0.1-0.5,0.2
		c-0.4-0.2-0.8-0.2-1.2-0.1c-0.1,0-0.2-0.1-0.4-0.2c0,0-0.1-0.1-0.1-0.1C150.6,111.6,150.6,111.5,150.6,111.5 M151,114.2L151,114.2
		L151,114.2L151,114.2z M157.1,112.3c0,0,0.1,0.1,0.1,0.1c-0.1,0.4-0.5,0.4-0.7,0.6c-0.1-0.1-0.1-0.1-0.1-0.2
		C156.5,112.5,156.8,112.4,157.1,112.3 M178.9,191.8c0-0.1-0.2-0.2-0.3-0.3c-0.2-0.1-0.5-0.2-0.7-0.2c-0.4,0-0.7-0.1-1-0.4
		c-0.4-0.3-0.9-0.4-1.2-0.8c-0.4-0.7-1.2-1-1.8-1.4c-0.3-0.2-0.6-0.3-1-0.2c-1.2,0-2.3-0.2-3.4-0.5c-0.1,0-0.3,0-0.4,0
		c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.3,0-0.3-0.2c-0.1-0.2-0.1-0.5-0.3-0.7c-0.1-0.2-0.3-0.3-0.4-0.4c-0.6,0-1.1-0.4-1.7-0.7
		c-0.4-0.2-0.9-0.3-1.4-0.4c-0.4,0-0.8,0.1-1,0.5c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3
		c0.1-0.4-0.1-0.6-0.4-0.7c-0.3-0.1-0.7-0.2-1.1-0.3c0,0.1,0,0.2-0.1,0.2c0,0,0,0,0,0l0,0l-0.1,0c0,0,0,0,0,0
		c-0.4-0.1-0.8,0-1.1,0.3c-0.1-0.2-0.3-0.2-0.5-0.3c0.3-0.3,0.7-0.5,1.1-0.6c0.1-0.4,0.2-0.7,0.3-1.1c0.1-0.2,0-0.5-0.1-0.7
		c-0.2-0.2-0.3-0.5-0.5-0.7c-0.4-0.4-0.7-0.9-0.8-1.5c0-0.3-0.2-0.5-0.4-0.8c-0.8-0.8-1.5-1.6-2.7-1.8c-0.1,0-0.3-0.1-0.4-0.2
		c-0.5-0.3-1-0.3-1.5-0.3c-0.5,0-1,0-1.6,0c-0.1,0-0.3-0.1-0.4-0.1c-0.5-0.3-0.9-0.7-1.5-0.8c-0.1,0-0.1-0.1-0.2-0.2
		c-0.1-0.2-0.2-0.4-0.3-0.6c-0.3-0.3-0.6-0.6-0.9-0.9c-0.2-0.2-0.5-0.3-0.7-0.4c-0.2-0.1-0.5-0.1-0.7-0.1c-0.2,0-0.3-0.1-0.3-0.3
		c0-0.4-0.2-0.7-0.6-0.9c-0.4-0.1-0.7-0.3-1-0.5c-0.1-0.1-0.3-0.2-0.3-0.3c0-0.2,0.1-0.2,0.3-0.2c0,0,0.1,0,0.1-0.1
		c0.1,0,0.1-0.1,0.1-0.2c0,0-0.1-0.1-0.1-0.1c-0.4-0.1-0.8-0.1-1.3-0.1c-0.3,0-0.5-0.1-0.6-0.3c-0.1-0.1-0.2,0-0.2,0.1
		c-0.1,0.1-0.1,0.3-0.2,0.4c-0.4,0.4-1.1,0.7-1.7,0.3c-0.3-0.2-0.7-0.3-1.1-0.2c-0.4,0-0.9,0.1-1.3,0.1c-0.2,0-0.3-0.1-0.4-0.3
		c0,0,0-0.1,0-0.1c-0.1-0.3-0.3-0.5-0.6-0.6c-0.2,0-0.4-0.1-0.6-0.1c-0.4,0-0.6-0.2-0.7-0.6c0-0.1-0.1-0.2-0.3-0.3
		c-0.1,0-0.4,0.1-0.5,0.3c0,0.1-0.1,0.2-0.1,0.3c0,0.3-0.2,0.5-0.5,0.6c-0.2,0.1-0.4,0.2-0.7,0.3c-0.1,0.1-0.2,0-0.3-0.2
		c-0.1-0.1-0.1-0.3,0-0.4c0.2-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0-0.4-0.1-0.5c-0.1-0.1-0.3-0.1-0.4-0.1c-0.3,0.1-0.6,0.2-0.9,0.4
		c-0.3,0.3-0.7,0.5-1.1,0.7c-0.5,0.1-0.9,0.1-1.3,0.5c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6,0.1-0.8,0.3c-0.3,0.3-0.5,0.6-0.6,1
		c0,0.4-0.2,0.6-0.5,0.8c-0.3,0.2-0.5,0.4-0.7,0.7c-0.1,0.1-0.1,0.3-0.2,0.5c0,0,0,0,0,0l0,0.1l0-0.1c0,0,0,0,0,0
		c-0.1,0-0.2,0-0.2-0.1c-0.3-0.2-0.5-0.4-0.7-0.7c-0.3-0.3-0.7-0.5-1.1-0.6c-0.6-0.1-1.1-0.1-1.7,0.2c-0.2,0.1-0.3,0.2-0.5,0.3
		c-0.4,0.2-0.8,0.4-1.2,0.1c-0.8-0.1-1.3-0.6-1.7-1.2c-0.3-0.3-0.5-0.7-0.7-1.1c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.5,0-0.9,0.3-1.4
		c0.1-0.1,0.2-0.4,0.2-0.5c0-0.5,0.1-0.9,0.3-1.4c0.1-0.4,0.1-0.9,0.1-1.4c0-0.1-0.1-0.2-0.1-0.2c-0.2-0.1-0.3-0.1-0.5-0.2l0,0
		c0,0,0,0,0,0l-0.1,0l0-0.1c-0.3-0.3-0.6-0.5-1.1-0.5c-0.4,0-0.9-0.1-1.3-0.2c-0.5-0.1-0.9,0.1-1.4,0.1c-0.4,0-0.8,0.1-1.1,0.1
		c-0.2,0-0.5-0.1-0.7-0.1c-0.1,0-0.2-0.1-0.1-0.2c0.4-0.4,0.6-0.9,0.6-1.5c0-0.3,0-0.6,0.1-0.8c0-0.1,0.1-0.3,0.3-0.3
		c0.3,0,0.5-0.2,0.6-0.5c0.1-0.3,0.1-0.7,0.1-1c0-0.5,0.1-0.8,0.4-1.2c0.2-0.2,0.4-0.4,0.5-0.7c0.1-0.1,0.1-0.3,0.2-0.4
		c0.1-0.2-0.2-0.6-0.4-0.6c-0.6,0-1.1-0.1-1.7,0.1c-0.4,0.2-0.9,0.2-1.4,0.2c-0.4,0-0.7,0.3-0.9,0.6c-0.1,0.2-0.2,0.4-0.2,0.7
		c-0.2,0.7-0.4,1.4-1,1.8c-0.2,0.6-0.5,0.5-1,0.4c-0.2-0.1-0.5,0-0.7,0c-0.5,0.1-0.9,0.3-1.4,0.4c-0.4,0.1-0.8,0.1-1.1-0.2
		c-0.2-0.3-0.5-0.4-0.8-0.5c-0.4-0.1-0.6-0.4-0.8-0.8c-0.2-0.5-0.5-0.9-0.8-1.3c-0.2-0.2-0.2-0.4-0.3-0.6c-0.1-0.4-0.2-0.8-0.2-1.3
		c0-0.3-0.1-0.6-0.1-0.9c0.1-1,0.2-2,0.4-3c0-0.2,0.1-0.4,0.2-0.5c0.3-0.4,0.4-0.8,0.4-1.3c-0.1-0.7,0.1-1.3,0.3-2
		c0.2-0.6,0.6-1.1,1.2-1.2c0.7-0.1,1.3-0.4,1.8-0.8c0.4-0.3,0.8-0.4,1.3-0.6c0.4-0.1,0.8-0.1,1.1,0.1c0.3,0.1,0.6,0.2,0.8,0
		c0.3-0.1,0.6-0.1,0.8,0.2c0.3,0.6,0.9,0.6,1.4,0.5c0.3,0,0.5-0.2,0.8-0.3c0.1-0.3,0.2-0.5,0.2-0.8c0-0.3,0.2-0.4,0.4-0.5
		c0.2-0.1,0.4-0.1,0.6-0.1c0.7-0.1,1.3,0.1,2,0c0.8,0,1.4,0.1,1.9,0.7c0.2,0.2,0.4,0.2,0.7,0.1c0.2-0.1,0.3-0.2,0.5-0.2
		c0.2-0.1,0.4,0,0.5,0.1c0.3,0.4,0.6,0.7,0.9,1.1c0.2,0.2,0.2,0.4,0.1,0.7c-0.1,0.1-0.1,0.3-0.1,0.4c-0.1,0.3-0.1,0.5,0.1,0.8
		c0.2,0.8,0.6,1.5,0.9,2.2c0.1,0.3,0.4,0.6,0.7,0.9c0.2,0.3,0.7,0.2,0.9-0.1c0.4-0.6,0.6-1.2,0.6-1.9c0-0.1,0-0.2,0-0.3
		c-0.1-0.4-0.3-0.7-0.3-1.1c-0.1-0.4-0.1-0.7-0.1-1.1c0,0,0-0.1,0-0.1c-0.4-1.1-0.4-2.2-0.3-3.4c0-0.2,0.1-0.4,0.2-0.5
		c0.5-0.6,1-1.2,1.8-1.4c0.1,0,0.2-0.1,0.3-0.1c0.3-0.1,0.5-0.3,0.7-0.5c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.1,0.3-0.3,0.4-0.3
		c0.3-0.1,0.6-0.3,0.8-0.5c0.4-0.4,0.8-0.6,1.4-0.7c0.3,0,0.4-0.2,0.4-0.5c0-0.2,0.1-0.2,0.3-0.3c0.2-0.1,0.3-0.2,0.4-0.3
		c0.1-0.1,0.2-0.3,0.1-0.4c-0.1-0.2-0.2-0.4-0.1-0.6c0.2-0.3,0.2-0.7,0-1.1c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.4,0.1-0.8,0.1-1.3
		c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0.2,0.2,0.2,0.4,0.2,0.7c0,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0,0.1-0.1c0.2-0.3,0.5-0.7,0.7-1.1
		c0.1-0.2,0.1-0.4,0.1-0.5c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.2,0.2-0.2c0.3,0,0.4-0.1,0.6-0.3c0.4-0.4,0.7-0.9,0.8-1.5
		c0.1-0.5,0.4-0.6,0.8-0.6c0.3,0,0.6-0.1,0.8-0.1c0.1,0,0.3-0.1,0.4-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1-0.1-0.1
		c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.2-0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.8,0.1,1.6-0.3,2.4-0.3c0.1,0,0.2-0.1,0.4-0.2
		c0.3-0.2,0.3-0.3-0.1-0.6c-0.3-0.2-0.4-0.5-0.4-0.7c0.1-0.3,0.2-0.6,0.3-0.9c0.2-0.5,0.6-1,1.2-1.2c0.3-0.1,0.6-0.2,0.9-0.5
		c0.2-0.2,0.4-0.3,0.7-0.2c0.6,0.1,1.2-0.2,1.8-0.4c0.2,0,0.3-0.2,0.3-0.4c0-0.1,0.2-0.2,0.3-0.3c0.5-0.1,0.9-0.2,1.4-0.3
		c0,0,0.1,0,0.1,0c0,0.1,0,0.2-0.1,0.2c-0.2,0.1-0.4,0.1-0.5,0.2c-0.4,0.2-0.7,0.5-0.9,0.9c-0.1,0.2-0.1,0.4-0.1,0.6
		c0.1,0.3,0.2,0.5,0.5,0.7c0.1,0,0.2,0.1,0.3,0c0.6-0.3,1.1-0.6,1.5-1.1c0.2-0.3,0.5-0.4,0.8-0.4c0.7-0.2,1.4-0.5,2.2-0.7
		c0.2-0.1,0.4-0.2,0.7-0.2c0.1-0.5,0.6-0.4,0.9-0.5c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,0.2-0.3,0.1-0.4
		c-0.3-0.3-0.5-0.5-0.4-1c0,0,0-0.1,0-0.1c0-0.1-0.3-0.2-0.4-0.1c-0.5,0.4-0.8,0.9-1.2,1.4c-0.1,0.2-0.2,0.3-0.5,0.2
		c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0.1-0.1,0.2-0.3,0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.3,0-0.4-0.1c-0.3,0-0.5-0.2-0.5-0.5
		c-0.5,0-0.8-0.3-0.9-0.9c-0.1-0.5-0.1-0.9,0.1-1.4c0.1-0.2,0-0.3-0.3-0.4c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1-0.1
		c0.1,0,0.2-0.1,0.3-0.1c0.4-0.1,0.7-0.3,1-0.6c0.2-0.1,0.2-0.5,0.1-0.7c-0.1-0.2-0.2-0.3-0.3-0.4c-0.4-0.3-0.8-0.5-1.3-0.3
		c-1.3,0.3-2.6,0.6-3.7,1.3c0,0,0,0,0,0.1c0,0-0.1,0-0.1,0c-0.2,0.2-0.3,0.3-0.5,0.5c0,0,0,0,0,0.1c-0.2,0.1-0.4,0.2-0.6,0.2
		c-0.1,0.1-0.2,0.2-0.3,0.3l0,0.1l0-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0.4-0.1,0.6-0.2c0,0,0,0,0.1-0.1c0.1-0.4,0.1-0.4,0.5-0.5
		c0,0,0,0,0,0c0,0,0,0,0,0c0-0.2,0.1-0.4,0.3-0.6c0.5-0.4,1-0.7,1.6-0.9c0.4-0.1,0.7-0.3,0.9-0.7c0-0.1,0.1-0.2,0.2-0.2
		c0.3-0.3,0.6-0.5,0.9-0.7c0.3-0.2,0.6,0,1,0c1.3,0,2.6,0.2,4,0.4c0.6,0.1,1.1-0.2,1.7-0.1c0.1,0,0.3,0,0.4-0.1s0.3-0.1,0.4-0.2
		c0.4-0.2,0.7-0.5,1-0.8c0.2-0.3,0.5-0.4,0.8-0.5c0.2,0,0.5-0.1,0.7-0.1c0.7,0,1.3-0.4,1.8-0.8c0.3-0.2,0.5-0.5,0.3-0.9
		c-0.1-0.2-0.1-0.4,0-0.7c0.1-0.2,0.2-0.4,0.2-0.5c0-0.1,0-0.3-0.1-0.4c-0.2-0.4-0.5-0.7-1-0.6c0,0,0,0,0,0l-0.1,0l0.1-0.1
		c0,0,0,0,0,0c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.2-0.4-0.5-0.5c-0.1,0-0.3,0-0.4,0c0,0-0.1,0-0.1,0c0.1,0,0.1-0.1,0.2-0.1
		c0.1-0.1,0.3-0.1,0.4-0.1c0.2-0.1,0.2-0.3,0.1-0.5c-0.1-0.2-0.3-0.4-0.6-0.4c-0.1,0-0.2,0-0.3,0c-0.3-0.4-0.9-0.6-1.3-0.8
		c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.5-0.5-0.8-0.8-1.1c-0.2-0.2-0.3-0.4-0.5-0.5c-0.1-0.2-0.2-0.4,0-0.5c0.2-0.2,0.3-0.3,0.5-0.5
		c0.1-0.2,0.2-0.3,0-0.5c-0.2-0.3-0.4-0.7-0.3-1.1c0-0.1-0.1-0.3-0.2-0.4c-0.2-0.2-0.1-0.5-0.3-0.6c-0.2-0.1,0-0.3-0.1-0.5
		c-0.1-0.2,0-0.5-0.1-0.7c-0.1-0.3-0.1-0.6-0.2-0.8c-0.2-0.6-0.4-1.3-0.4-1.9c0-0.2,0-0.4-0.1-0.6c0-0.1-0.1-0.1-0.2-0.1
		c0,0-0.1,0-0.1,0c-0.3,0.6-0.8,1-1.1,1.7c-0.3,0.6-0.6,1.2-1.3,1.4c-0.2,0.1-0.3,0.2-0.4,0.3c-0.2,0.1-0.4,0.2-0.6,0.3
		c-0.2,0.1-0.5,0-0.7-0.2c-0.2-0.1-0.3-0.3-0.3-0.5c-0.1-0.2-0.3-0.4-0.5-0.5c-0.3-0.2-0.3-0.5-0.3-0.7c0-0.5,0.3-0.9,0.1-1.4
		c0-0.1,0.1-0.3,0.1-0.4c0.2-0.4,0.3-0.9,0.6-1.3c0.1-0.1,0-0.3-0.2-0.3c-0.4-0.1-0.7-0.5-1.2-0.5c-0.2,0-0.3-0.2-0.5-0.2
		c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0-0.3-0.1-0.4c-0.2-0.3-0.4-0.6-0.6-1c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.3-0.5-0.5-1-0.3
		c-0.2,0.1-0.4,0.1-0.6,0.1c-0.5-0.1-1.1-0.1-1.6-0.5c-0.2-0.1-0.4-0.2-0.7-0.3c-0.6-0.2-1.2,0.2-1.4,0.7c-0.1,0.3-0.1,0.6,0,1
		c0.1,0.2,0.2,0.4,0,0.7c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.4,0.2,0.8,0.1,1.2c0,0.2,0,0.4,0.1,0.6c0.1,0.3-0.1,0.5-0.3,0.7
		c-0.1,0.1-0.3,0.1-0.3,0.2c-0.3,0.6-0.8,0.9-1.2,1.4c-0.2,0.2-0.2,0.5,0,0.8c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.2,0.3,0.2,0.5
		c0.1,0.5,0.1,1,0.2,1.6c0.1,0.6-0.3,1.1-0.1,1.7c0,0.1-0.1,0.2-0.1,0.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.6,0.6-1.3,1-2,1.3
		c-0.4,0.1-0.7,0.3-1.1,0.4c-0.3,0.1-0.4,0.3-0.4,0.6c0,0.6,0.1,1.1,0,1.7c-0.1,0.4-0.1,0.8-0.1,1.1c0,0.4,0,0.7,0.1,1.1
		c0,0.1,0,0.3-0.1,0.4c-0.1,0.3-0.3,0.5-0.4,0.8c0,0.1-0.2,0.1-0.2,0.1c-0.3-0.1-0.5,0.2-0.7,0.4c-0.1,0.1-0.4,0-0.5-0.1
		c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.2-0.1-0.5-0.3-0.6c-0.5-0.4-0.6-1-1-1.6c-0.1-0.2-0.1-0.4,0-0.5c0.3-0.6,0.6-1.3,0.6-2
		c0-0.2,0.1-0.5,0.2-0.7c0.1-0.2,0.1-0.5,0.2-0.7c0-0.1-0.1-0.3-0.2-0.4c-0.8-0.1-1.5-0.4-2.2-0.4c-0.2,0-0.4-0.1-0.5-0.2
		c-0.3-0.5-0.8-0.8-1.3-1.1c-0.5-0.2-0.8-0.6-1.1-1.1c-0.3-0.5-0.7-0.9-1.2-1.2c-0.4-0.3-0.9-0.5-1.4-0.8c-0.2-0.1-0.5-0.2-0.7-0.1
		c-0.4,0.1-0.8,0.3-1.2,0.2c0,0,0,0,0,0l0,0l0-0.1c0,0,0,0,0.1,0c0.2-0.4,0.4-0.7,0.3-1.2c-0.1-0.5,0-1,0-1.6c0-0.2,0-0.4-0.1-0.6
		c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.4-0.3c0-0.1-0.1-0.3,0-0.4c0.1-0.3,0.2-0.5,0.3-0.8
		c0.3-1.2,0.7-2.2,1.7-3c0.2-0.2,0.4-0.4,0.5-0.7c0.5-0.7,1-1.4,1.9-1.7c0.4-0.3,0.8-0.6,1-1.2c0-0.1,0.1-0.2,0.3-0.2
		c0.2,0,0.4,0.1,0.6,0.1c0.5,0,0.8-0.3,0.9-0.8c0-0.2,0-0.4,0-0.7c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0
		c0.6,0,1.2-0.1,1.4-0.8c0.1-0.1,0.2-0.2,0.4-0.1c0.2,0.1,0.4,0.2,0.7,0.3c0.2,0.1,0.5,0.1,0.7-0.1c0.7-0.7,1.4-1.4,2.2-2
		c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2,0-0.4,0.2-0.5c0.3-0.3,0.7-0.5,1-0.8c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0,0,0,0c0.1-0.1,0.1-0.1,0.2-0.2
		c0.4-0.4,0.4-0.4,0.1-0.8c0,0,0-0.1-0.1-0.1c0-0.1,0-0.2,0.1-0.2c0.4-0.1,0.9-0.1,1,0.4c0,0,0.1,0.1,0.1,0.1c0.7,0.3,1.4,0.4,2.1,0
		c0.5-0.2,0.9-0.5,1.3-0.8c0.1-0.1,0.3-0.3,0.5-0.3c0.4-0.1,0.5-0.4,0.7-0.7c0.1-0.2,0.2-0.4,0.1-0.7c-0.1-0.2-0.1-0.4-0.2-0.5
		c-0.1-0.3-0.1-0.5,0-0.8c0.1-0.3,0.1-0.5,0.2-0.8c0,0,0,0,0,0l-0.1,0l0.1,0c0,0,0,0,0,0c0.2,0,0.3,0.1,0.5,0.1
		c0.5,0,0.7-0.2,0.8-0.8c0-0.2,0-0.4,0.2-0.5c0.2-0.3,0.1-0.5-0.1-0.7c-0.4-0.1-0.6-0.4-0.6-0.8c0-0.3-0.1-0.5-0.5-0.5
		c-0.3,0-0.3-0.2-0.4-0.4c-0.3,0-0.6-0.1-0.8-0.3c0,0,0,0,0,0c0,0,0,0,0,0l0,0c-0.1,0-0.2,0.1-0.3,0.1c-0.4,0-0.7,0.2-0.8,0.6
		c-0.1,0.4-0.4,0.8-0.2,1.2c0,0.1,0,0.2-0.1,0.3c-0.2,0.2-0.3,0.3-0.5,0.5c-0.4,0-0.6,0.4-0.8,0.7c-0.1,0.2-0.2,0.4-0.3,0.6
		c-0.1,0.2-0.3,0.2-0.5,0.2c0,0,0,0,0,0c0,0,0,0-0.1,0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c-0.1-0.2-0.1-0.3-0.3-0.4
		c-0.2,0-0.3,0.2-0.4,0.5c0,0.2-0.1,0.4,0,0.6c0.1,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.4,0.3-0.5,0.2c-0.1-0.1-0.3-0.2-0.3-0.3
		c-0.1-0.3-0.1-0.6-0.2-0.8c-0.1-0.5,0-1,0.5-1.4c0.2-0.1,0.2-0.3,0.2-0.5c0.1-0.6-0.1-1.2-0.4-1.8c-0.1-0.1-0.3-0.1-0.4,0
		c-0.3,0.3-0.6,0.6-0.7,1.1c-0.1,0.2-0.2,0.4-0.4,0.6c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2-0.1-0.1-0.2c0.2-0.5,0.3-0.9,0.2-1.4
		c0-0.3,0.2-0.3,0.4-0.3c0.3-0.1,0.3-0.4,0.1-0.6c-0.2-0.2-0.4-0.4-0.7-0.4c-0.3,0-0.4-0.1-0.6-0.3c-0.1-0.1-0.1-0.2-0.1-0.2
		c0.3-0.3,0.5-0.6,0.8-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.4-0.1,0.4-0.2,0.4-0.5c-0.2-0.9-0.3-1.8,0-2.7c0-0.1,0.1-0.3,0-0.4
		c-0.2-0.5-0.1-1.1-0.4-1.7c-0.1-0.2,0-0.3,0.2-0.5c0.3-0.2,0.7-0.5,1-0.8c0.2-0.2,0.4-0.4,0.4-0.7c0-0.1,0-0.2,0.1-0.3
		c0.1-0.1,0.2-0.1,0.2-0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.2,0.6,0.1,0.8-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.7-0.6,1.3-1.2,2-1.9
		c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0-0.7-0.2-0.8c0,0-0.1-0.1-0.1-0.1c-0.6,0-1.1-0.3-1.5-0.7c-0.1-0.1-0.3-0.2-0.5-0.2
		c-0.4-0.1-0.9-0.1-1.3,0c-0.1,0-0.3,0.1-0.4,0.2c-0.2,0.2-0.4,0.6-0.7,0.7c-0.2,0.3-0.4,0.6-0.5,0.9c-0.3,0.5-0.5,0.9-0.7,1.4
		c-0.1,0.3-0.3,0.5-0.3,0.8c0,0.6-0.3,1.2-0.5,1.8c-0.1,0.1-0.1,0.2-0.3,0.3c-0.3,0.2-0.5,0.4-0.8,0.6c-0.2,0.2-0.2,0.4-0.2,0.6
		c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0.1-0.5,0.1-0.6,0.2c-0.6,0.4-1,1-1.1,1.7
		c0,0.3-0.1,0.5-0.3,0.7c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0.3-0.3,0.6-0.3,0.9c0,0.3,0.1,0.6,0.3,0.9c0.1,0.1,0.2,0.3,0.2,0.5
		c0.1,0,0.3,0.1,0.4,0.1c0.3,0.2,0.5,0.4,0.8,0.5c0,0,0.1,0.1,0.1,0.1c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.3,0.1-0.4,0.1
		c-0.2,0.2-0.4,0.5-0.6,0.8c-0.1,0.1,0,0.3,0.2,0.4c0.1,0,0.2,0,0.2,0.1c0,0,0,0,0,0l0.1,0l-0.1,0.1c0,0,0,0,0,0
		c0,0.2,0,0.4-0.1,0.5c-0.2,0.2-0.5,0.5-0.7,0.7c-0.3,0.3-0.6,0.4-1,0.4c-0.5,0-0.7,0.4-0.9,0.8c-0.1,0.3-0.1,0.7-0.2,1
		c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1-0.1-0.1-0.1c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.3-0.2-0.4,0-0.6c0.3-0.3,0.5-0.7,0.8-1
		c0.1-0.1,0-0.3-0.1-0.3c-0.5,0-0.6-0.4-0.7-0.8c0,0-0.1-0.1-0.1-0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0l0,0c0.2,0,0.4,0.1,0.5,0.1
		c0.2,0.1,0.4,0.1,0.6,0c0.3-0.1,0.6-0.3,0.9-0.4c0.1,0,0.1-0.1,0.1-0.2c0-0.3,0-0.7-0.1-1c0-0.3,0-0.3,0.3-0.4
		c0.4-0.1,0.5-0.4,0.5-0.7c-0.1,0-0.2,0-0.2,0c-0.6,0-1.1-0.2-1.3-0.8c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.1-0.3-0.1-0.4-0.1
		c-0.2,0.2-0.4,0.3-0.5,0.5c-0.4,0.5-0.8,0.9-1.5,1c-0.1,0-0.2,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.3,0,0.4
		c0.5,0.4,1,0.7,1.3,1.3l0,0c0,0,0,0,0,0c-0.2,0-0.5,0.1-0.7,0.1c-0.3,0-0.4,0-0.6,0.3c-0.1,0.2-0.2,0.3-0.4,0.4
		c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0,0.3-0.1,0.4c-0.2,0.1-0.4,0.1-0.5,0c-0.2-0.1-0.3-0.2-0.5-0.2c-0.3-0.1-0.6-0.2-1,0
		c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.4,0-0.6-0.1c-0.3-0.2-0.6-0.3-0.7-0.6c-0.2-0.4-0.5-0.6-1-0.7c-0.2,0-0.3-0.2-0.4-0.4
		c-0.1-0.4-0.4-0.9-0.3-1.4c0-0.1,0-0.3,0-0.4c0-0.3-0.4-0.6-0.7-0.6c-0.7,0.1-1.3,0.2-2,0.2c-0.3,0-0.5,0.3-0.6,0.5
		c-0.1,0.2,0,0.5,0.2,0.6c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.1-0.1,0.2-0.2c0.3-0.1,0.6-0.1,0.8-0.1c0.3-0.1,0.5-0.2,0.8-0.3
		c0,0,0.1,0.1,0.2,0.1c0,0,0,0.1-0.1,0.1c-0.3,0.1-0.6,0.2-0.8,0.3c-0.3,0.2-0.6,0.4-1,0.4c-0.2,0-0.4,0.1-0.5,0.2
		c-0.3,0.1-0.5,0.3-0.6,0.6c-0.1,0.5-0.3,0.9-0.3,1.4c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2-0.1-0.1-0.2,0-0.4
		c0.1-0.2,0.1-0.5,0-0.7c-0.1-0.3-0.3-0.6-0.4-0.9c-0.1-0.3-0.3-0.5-0.4-0.8c-0.1-0.1-0.3-0.3-0.5-0.2c-0.2,0-0.4,0.1-0.6,0.1
		c-1.4,0.3-2.7,0-3.9-0.9c-0.1-0.1-0.1-0.3,0-0.4c0.2-0.3,0.4-0.4,0.7-0.5c0.1,0,0.3,0,0.4,0c0.6,0,0.9-0.3,0.8-0.9
		c-0.1-0.3-0.2-0.5-0.3-0.8c-0.2-0.4-0.5-0.6-0.9-0.6c-0.5,0.1-0.9-0.1-1.3-0.4c-0.5-0.4-1-0.8-1.6-1.2c-0.4-0.3-0.7-0.6-0.7-1
		c-0.1-0.6-0.5-0.9-1-1.1c-0.5-0.2-0.8-0.1-1.1,0.2c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.3-0.2-0.2-0.3
		c0.4-0.5,0.5-1,0.6-1.6c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1,0c-0.1,0.1-0.3,0.1-0.4,0.2c-0.4,0.3-0.8,0.7-1.2,1
		c-0.1,0.1-0.2,0.1-0.3,0c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.4-0.1-0.8,0.1-1.1c0.2-0.6,0.2-1.3,0.2-2c0-0.1-0.1-0.3-0.1-0.4
		c0,0-0.2-0.1-0.2,0c-0.2,0.1-0.3,0.4-0.3,0.6c0,0.3-0.1,0.5-0.3,0.6c-0.3,0.2-0.6,0.3-0.9,0.5c-0.2,0.1-0.4,0-0.5-0.1
		c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.1,0.1-0.2,0.1-0.2,0.2c0,0-0.1,0-0.1,0c-0.1-0.1-0.1-0.1,0.1-0.1
		c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0-0.3-0.2-0.3c-0.3-0.1-0.6-0.2-1,0
		c-0.7,0.3-1.4,0.5-2.2,0.5c-0.2,0-0.5,0.1-0.7,0.2c-0.4,0.3-0.8,0.3-1.3,0.2c0,0,0,0,0,0l0,0.1l0-0.1c0,0,0,0,0,0c0,0,0,0,0.1-0.1
		c0.1-0.2,0.2-0.3,0-0.5c-0.1-0.2-0.2-0.2-0.5,0c-0.2,0.1-0.3,0.2-0.5,0.2c-0.3,0-0.6,0.1-1,0.2c-0.4,0.1-0.7,0.4-0.7,0.8
		c0,0.2-0.2,0.3-0.3,0.2c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.2-0.5-0.5-0.6-0.8c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.2-0.2-0.4-0.3-0.6
		c-0.3-0.4-0.7-0.6-1.1-0.8c-0.6-0.4-1.2-0.8-1.4-1.5c0-0.1-0.1-0.2-0.2-0.2c-0.1-0.2-0.3-0.3-0.5-0.3c-0.2,0-0.4,0-0.6,0
		c-0.4,0-0.7-0.1-1-0.4c-0.4-0.4-0.9-0.7-1.4-0.9c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.4-0.5-0.6-0.8c-0.1-0.3-0.5-0.5-0.8-0.4
		c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0-0.3,0-0.4-0.2c-0.2-0.4-0.2-0.8-0.4-1.2c0,0,0-0.2,0.1-0.2c0.1-0.1,0.2-0.3,0.1-0.4
		c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.1-0.4-0.2-0.7-0.2c-0.1,0-0.3,0-0.4-0.1c-0.2,0-0.3-0.1-0.2-0.4c0-0.2,0.1-0.5-0.2-0.6
		c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0c0,0.1,0,0.2-0.1,0.2
		c-0.1,0.2-0.3,0.3-0.4,0.1c-0.1,0-0.1-0.2-0.1-0.2c0,0,0.1-0.1,0.1-0.1c0.2,0,0.3,0,0.5-0.1c0,0,0,0,0,0c0.1,0,0.1,0,0.2-0.1
		c0.1-0.1,0.3-0.1,0.4-0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c-0.1-0.4-0.3-0.7-0.6-0.9c-0.2-0.1-0.3-0.2-0.5-0.1
		c-0.3,0.1-0.6,0.2-0.9,0.4c-0.4,0.3-0.9,0.3-1.4,0.2c-0.3-0.1-0.5-0.2-0.8-0.2c-0.5-0.1-0.8,0.1-1.2,0.4c-0.1,0.1-0.1,0.3-0.2,0.4
		c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4,0.1-0.7,0.2-1.1,0.1c-0.5-0.2-1.1,0-1.5,0.3c-0.8,0.5-1.7,1-2.5,1.7C68.1,57.9,68,58,67.9,58
		c-0.9,0.5-1.9,1-2.9,0.4c0,0-0.1,0-0.1-0.1c-0.4-0.2-0.7-0.1-1,0.3c-0.2,0.3-0.4,0.6-0.6,0.8c-0.4,0.3-0.5,0.7-0.3,1.1
		c0.1,0.3,0.2,0.6,0.3,1c0.3,0.9,0.4,1.8-0.1,2.6c-0.1,0.2,0,0.5,0.2,0.6c0.2,0.2,0.5,0.3,0.7,0.4c0.1,0,0.1,0.2,0.1,0.2
		c0,0-0.1,0-0.1,0c-0.2,0-0.3,0.1-0.3,0.2c0,0.4-0.1,0.8-0.1,1.1c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2,0,0.4,0c0,0,0,0,0-0.1
		c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0l-0.1-0.1l0.1,0c0,0,0,0,0,0c0.4-0.1,0.8,0,1.2,0.1c0.1,0,0.2,0.2,0.1,0.4
		c0,0.1-0.1,0.1-0.2,0.1c-0.4,0-0.8-0.2-1.1-0.4c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.1,0.2,0,0.4-0.3,0.4c-0.3,0-0.6,0.1-0.8,0.3
		c-0.3,0.2-0.5,0.2-0.8,0c-0.3-0.1-0.6-0.3-0.9-0.5c-0.2-0.1-0.2-0.3-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.3-0.1-0.7-0.4-0.7
		c-0.4,0-0.8,0-1.1,0.1c-0.2,0-0.5,0.1-0.6,0.3c-0.2,0.3-0.5,0.3-0.7,0.2c-0.2,0-0.5,0.1-0.7,0.1c-0.7,0.1-1.2,0.6-1.8,0.8
		c-0.2,0.1-0.3,0.4-0.2,0.6c0.2,0.3,0.4,0.6,0.7,0.9c0.1,0.1,0,0.2-0.2,0.3c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.5-0.4,0.9-0.5,1.3
		c0,0.2,0,0.4,0.1,0.5c0.3,0.5,0.7,0.9,1.4,0.8c0.5-0.1,1,0,1.5,0.3c0.2,0.2,0.5,0.2,0.8,0.1c0.5-0.2,1-0.4,1.4-0.6
		c0.1,0,0.3,0,0.4,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1c-0.1,0.1-0.2,0.1-0.2,0.1c-0.2,0.1-0.4,0.1-0.4,0.5c0,0.1,0,0.3,0,0.4
		c-0.2,0.4-0.4,0.8-0.4,1.2c-0.1,0.5-0.8,1-1.3,1c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.2-0.3-0.3-0.3
		c-0.2,0-0.1,0.2-0.2,0.3c-0.2,0.5-0.6,0.8-1.2,0.9c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.2-0.2c-0.3-0.4-0.5-0.5-0.8-0.3
		c-0.5,0.3-1.1,0.6-1.3,1.3c0,0.1-0.1,0.2-0.2,0.2c-0.8,0.5-1.4,1.3-2.2,1.8c-0.5,0.4-0.7,0.9-0.5,1.5c0.1,0.2,0.1,0.5,0.2,0.7
		c0.1,0.2,0,0.5-0.1,0.7c-0.4,0.4-0.5,0.9-0.3,1.4c0.1,0.3,0.2,0.6,0.2,1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.1,0.5,0.1
		c0.4-0.1,0.7-0.2,1.1-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0.1-0.1,0.1,0,0.1,0.1
		c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0.2-0.1,0.4-0.1,0.7c0,0.4-0.2,0.6-0.4,0.8c-0.2,0.2-0.2,0.4-0.1,0.6
		c0.1,0.2,0,0.4,0,0.5c-0.1,0.1-0.2,0.2-0.2,0.4c-0.1,0.2-0.1,0.3,0.1,0.3c0.4,0.1,0.8,0.2,1.1-0.3c0,0,0.1-0.1,0.1-0.1
		c0.4,0.4,0.9,0.6,1,1.2c0,0.2,0.2,0.2,0.3,0c0,0,0.1-0.1,0.1-0.1c0.2-0.3,0.2-0.3,0.7-0.1c0.1,0,0.3,0.1,0.4,0.1
		c0.1,0,0.3-0.1,0.4-0.1c0,0,0.1,0,0.1,0c0.1,0.1,0.1,0.1,0,0.2c-0.4,0.3-0.6,0.7-0.9,1.1c-0.5,1-1.1,1.9-2.2,2.4
		c-0.3,0.2-0.6,0.4-1,0.6c-0.6,0.2-1.1,0.6-1.6,0.9c-0.4,0.2-0.6,0.4-0.9,0.7c-0.1,0.2-0.3,0.2-0.5,0.1c-0.4-0.2-0.9,0-1.2,0.2
		c-0.3,0.2-0.6,0.4-0.9,0.7c-0.5,0.4-1,0.7-1.7,0.7c-0.2,0-0.5,0.1-0.7,0.1c-0.5,0-0.7,0.4-1,0.7c0,0.1,0,0.2,0,0.3
		c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.3,0,0.4-0.1c0.3-0.1,0.6,0,0.8-0.1c0.2,0,0.4-0.1,0.5-0.2c0.3-0.4,0.7-0.5,1.1-0.5
		c0.6,0,1.1-0.3,1.3-0.9c0,0,0,0,0,0l0.1,0l-0.1,0.1c0,0,0,0,0,0c0.1,0.2,0.2,0.4,0.4,0.6c0.1,0.1,0.1,0.1,0.2-0.1
		c0-0.1,0.1-0.2,0.1-0.2c0.4-0.1,0.8-0.3,1.2-0.2c0.2,0,0.4,0.1,0.5,0c0.2-0.2,0.5-0.3,0.7-0.4c0.4-0.2,0.9-0.3,1.1-0.8
		c0.3-0.2,0.6-0.4,1-0.6c0.1-0.1,0.3,0,0.4,0c0.1-0.1,0.1-0.3,0.2-0.3c0.3-0.1,0.6-0.3,0.9-0.4c0.3-0.1,0.6-0.3,0.7-0.7
		c0-0.1,0.2-0.2,0.3-0.3c0.6-0.4,1.3-0.8,2-1c0.6-0.1,0.9-0.4,1.3-0.8c0.1-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.3-0.2,0.5-0.3
		c0.3-0.2,0.4-0.5,0.2-0.7c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.2-0.1-0.4,0.1-0.5c0.3-0.3,0.6-0.4,1-0.4c0.3,0.1,0.5,0,0.7-0.2
		c0.3-0.2,0.6-0.4,0.7-0.8c0.1-0.2,0.3-0.4,0.5-0.4c0.2,0,0.4-0.1,0.5-0.3c0.5-0.8,1.4-1.1,2.2-1.5c0.3-0.1,0.6,0.1,0.6,0.4
		c0,0.1,0,0.3,0,0.4c0,0.1-0.2,0.1-0.2,0.1c-0.6-0.3-1.1-0.2-1.6,0.1C65.2,84.8,65,84.9,65,85c-0.3,0.7-0.8,1.2-1.3,1.7
		c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.1,0.2,0,0.2c-0.3,0.1-0.5,0.3-0.8,0.4c-0.1,0.1-0.2,0.3-0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.2
		c0.2,0,0.5,0,0.7-0.2c0.4-0.2,0.9-0.4,1.3-0.5c0.2-0.1,0.4-0.1,0.5-0.2c0.4-0.2,0.8-0.5,1.3-0.4c0.1,0,0.3-0.1,0.4-0.1
		c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.3-0.1,0.5,0c0.1,0,0.2,0,0.3,0c0.1-0.2,0.1-0.4-0.1-0.6c-0.1-0.1-0.2-0.3-0.3-0.5
		c-0.1-0.3,0-0.5,0.2-0.7c0.1-0.1,0.3-0.1,0.3,0c0.1,0.3,0.2,0.5,0.6,0.4c0.2,0,0.4,0,0.7-0.1v0l0.1,0l0,0.1c0,0,0,0,0,0
		c0,0.1,0,0.3,0,0.4c0.1,0.5,0.3,1,0.9,1.2c0.3,0.1,0.5,0.4,0.7,0.7c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.2,0.2,0.3,0.4,0.3
		c0.2,0,0.4,0.1,0.6,0.1c0.5,0,0.8,0.1,1.2,0.4c0.1,0.1,0.2,0.1,0.4,0.2c0,0,0,0,0,0l0.1,0l0,0.1c0,0,0,0,0,0
		c0.1,0.1,0.2,0.2,0.2,0.3c0.3,0.3,0.7,0.6,1.1,0.4c0,0,0.1,0,0.1,0c0.1,0,0.2,0.2,0.1,0.2c-0.3,0.3-0.3,0.6,0,0.9
		c0.1,0.1,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.2,0.4,0.5c0,0.2,0,0.4,0.1,0.6c0.1,0.4,0.3,0.8,0.6,1.1c0.2,0.2,0.4,0.3,0.6,0.2
		c0.2,0,0.4-0.2,0.3-0.4c0-0.1-0.1-0.3-0.1-0.4c0,0,0-0.1,0-0.1c0.1-0.1,0.1-0.1,0.2,0.1c0.1,0.2,0.2,0.3,0.2,0.5
		c0,0.3,0.2,0.5,0.4,0.7c0,0,0,0.1,0,0.1c-0.1,0.1-0.1,0.1-0.2,0c-0.1-0.1-0.2-0.1-0.2-0.1c-0.3-0.1-0.6,0.1-0.7,0.3
		c-0.1,0.3-0.2,0.5-0.2,0.8c0.1,0.3,0.1,0.6,0.2,0.8l0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2,0.2,0.3,0.5,0.2,0.7
		c-0.2,0.5-0.2,0.9-0.1,1.4c0,0,0.1,0.1,0.1,0.1c0.1,0,0.2,0,0.2-0.1c0.2-0.3,0.3-0.6,0.5-0.9c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0
		c0.1-0.2,0.3-0.2,0.5-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0.4-0.5,0.8-1,1-1.7c0,0,0.1,0,0.2,0c0,0,0.1,0.1,0.1,0.1
		c0,0.1,0,0.3,0,0.4c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.2,0,0.4,0.1,0.6c0,0,0,0,0.1,0c0,0,0,0.1,0,0.1c0.1,0.1,0.2,0.3,0.2,0.5
		c0,0,0,0,0,0l0,0.1l-0.1,0c0,0,0,0,0-0.1c-0.2-0.1-0.2-0.3-0.2-0.5c0,0,0,0,0-0.1c0,0,0,0,0,0c-0.2,0-0.3,0-0.5-0.1
		c-0.2-0.1-0.4-0.1-0.6-0.1c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.1,0.2-0.3,0.2-0.5,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.4,0,0.8-0.2,1.2
		c-0.1,0.1,0.1,0.3,0.3,0.4c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0.1,0,0.1
		c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0.1-0.1,0.3,0,0.4c0.1,0.2,0.2,0.4,0.1,0.6c-0.1,0.2-0.1,0.4-0.1,0.5
		c0,0.1-0.1,0.3,0,0.4c0.2,0.3,0.2,0.7,0.4,1.1c0,0.1,0.2,0.1,0.2,0.1c0.1,0,0.2-0.1,0.2-0.1c0.2-0.4,0.4-0.8,0.4-1.3h0
		c0,0,0,0,0-0.1c0,0-0.1,0-0.1-0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0
		c0,0,0,0,0.1-0.1c0,0,0,0.1,0,0.1h0c0.1,0.2,0.2,0.3,0.3,0.5c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1-0.1,0
		c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c-0.2,0.1-0.3,0.3-0.2,0.6c0,0.2,0.1,0.4,0.3,0.4c0.1,0.1,0.3,0.1,0.4,0.1
		c0.2,0,0.2,0.2,0.1,0.3c-0.3,0.3-0.5,0.6-0.5,1c0,0.2-0.2,0.2-0.3,0.2c-0.2,0.1-0.2,0.2-0.1,0.3c0.3,0.4,0.3,0.5-0.1,0.7
		c-0.1,0-0.2,0.1-0.2,0.2c0.1,0.3,0.4,0.6,0.6,0.5c0.3-0.1,0.6-0.3,1-0.2c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2,0,0.2
		c-0.1,0.1-0.3,0.3-0.4,0.4c-0.3,0.2-0.4,0.5-0.4,0.9c0,0.1,0.1,0.1,0.2,0.2c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0-0.1
		c0.1,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2,0.2,0.5,0.5,0.7,0.8c0.1,0.1,0.2,0.3,0.1,0.5c-0.1,0.2-0.1,0.3-0.2,0.4c0,0,0,0,0,0
		c0,0,0,0,0,0l0,0c0,0.1-0.1,0.3,0,0.4c0.1,0.2,0.1,0.5-0.1,0.7c0,0,0,0.1-0.1,0.1l0.4,0.8c0.3,0.1,0.5,0.3,0.7,0.5
		c0.2,0.2,0.4,0.4,0.7,0.5c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0.1,0.2,0.2,0.2,0.4c-0.1,0.3-0.2,0.6-0.1,0.8c0,0.1,0.1,0.2,0.2,0.3l0.1,0
		l0,0c0,0,0,0,0,0l0,0c0,0.1,0,0.2,0.1,0.3c0.1,0,0.3,0,0.4,0.1c0.2,0,0.3,0.1,0.4,0.3c0.1,0.3,0.2,0.6,0.4,0.9
		c0.2,0.3,0,0.5-0.1,0.8c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.3,0.2-0.4,0.2c-0.4-0.1-0.8-0.2-1.1-0.5l0.1,0c0,0,0,0,0,0
		c0.1,0,0.3,0.1,0.4,0.1c0.3,0,0.4-0.2,0.4-0.4c0-0.6-0.1-1.1-0.3-1.6c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.2-0.2-0.5-0.2-0.7
		c-0.1-0.5-0.4-0.8-0.9-1c-0.4-0.1-0.7-0.3-1-0.6c-0.2-0.1-0.4-0.3-0.6-0.4c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3,0.1-0.3,0.3
		c0,0.3,0.1,0.5,0.1,0.8c0,0.1,0.1,0.3,0.1,0.4c0.2,0.3,0.4,0.5,0.6,0.8c0.5,0.8,1.3,1.5,1.8,2.4c0.1,0.1,0.2,0.2,0.3,0.3l0,0
		c0,0,0,0,0,0c-0.4,0.2-0.6,0.4-0.6,0.9c0,0.6,0,1.2,0,1.9c0,0.8-0.1,1.5-0.4,2.2c-0.3,0.8-0.6,1.5-0.8,2.3
		c-0.2,0.7-0.4,1.5-0.9,2.1c-0.4,0.6-0.5,1.2-0.5,1.9c0,0.1,0,0.3,0,0.4c0,0.8-0.1,1.5-0.5,2.2c-0.1,0.2-0.2,0.5-0.1,0.7
		c0.2,0.5,0.3,1,0.2,1.5c-0.1,0.2,0,0.5,0,0.7c0,0.1,0,0.3,0.1,0.4c0.2,0.4,0.3,0.8,0.5,1.2c0.1,0.1,0.2,0.2,0.3,0.3
		c0.3,0.2,0.3,0.2,0.2,0.4c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.3,0,0.4c0.1,0.4,0.4,0.8,0.2,1.2c0,0.1,0,0.3,0.1,0.4
		c0.2,0.5,0.5,1,0.7,1.6c0.1,0.3,0.2,0.5,0.2,0.8c0,0.3,0.1,0.5,0.3,0.5c0.8,0.1,1.5,0.7,2.1,1.1c0.6,0.3,0.9,0.8,0.9,1.5
		c0,0.5,0.2,1,0.3,1.5c0.2,0.9,0.7,1.7,0.8,2.6c0,0.3,0.2,0.5,0.4,0.7c0.5,0.4,0.7,0.9,1,1.4c0.2,0.3,0.1,0.5,0,0.8
		c-0.1,0.2-0.3,0.2-0.5,0.2c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.2-0.2,0.3c0.2,0.4,0.6,0.8,1,1c0.6,0.3,1,0.7,1.4,1.1
		c0.2,0.2,0.3,0.4,0.3,0.6c0,0.2,0,0.5-0.1,0.7c-0.1,0.3,0,0.5,0.2,0.8c0.7,0.7,1.5,1.3,2,2.2c0.1,0.2,0.3,0.2,0.5,0
		c0.2-0.2,0.3-0.4,0.2-0.6c-0.1-0.3-0.3-0.6-0.5-0.9c-0.2-0.2-0.8-1.2-0.8-1.5c-0.1-0.2-0.2-0.4-0.2-0.7c-0.1-0.4-0.1-0.8-0.2-1.1
		c-0.1-0.4-0.5-0.6-0.7-1.1c-0.2-0.6-0.5-1.2-0.8-1.8c-0.4-0.6-0.6-1.3-1.1-1.8c-0.1-0.1-0.2-0.3-0.2-0.4c-0.1-0.7-0.2-1.4,0-2.1
		c0-0.1,0.1-0.2,0.2-0.1c0.3,0.1,0.5,0.2,0.8,0.4c0.3,0.1,0.4,0.3,0.5,0.6c0.1,0.6,0.2,1.2,0.4,1.8c0.3,0.9,0.8,1.7,1.4,2.5
		c0.1,0.1,0.3,0.3,0.3,0.5c0.1,0.5,0.4,0.7,0.7,1c0.2,0.2,0.4,0.4,0.6,0.6c0.1,0.1,0.1,0.2,0,0.4c-0.1,0.1-0.1,0.2-0.2,0.2
		c-0.1,0.2-0.1,0.5,0.1,0.6c0.3,0.2,0.5,0.4,0.8,0.6c0.3,0.2,0.5,0.4,0.5,0.8c0,0.2,0.2,0.3,0.3,0.5c1.5,1.5,1.9,2.3,2.3,4
		c0,0.1,0,0.3,0,0.4c-0.1,0.2-0.1,0.4-0.2,0.5c-0.3,0.5-0.2,0.9,0,1.3c0.2,0.4,0.5,0.7,0.9,0.9c0.4,0.1,0.8,0.4,1,0.8
		c0.3,0.4,0.6,0.6,1.1,0.7c0.2,0,0.4,0.1,0.6,0.1c0.2,0.1,0.4,0.1,0.5,0.2c0.6,0.5,1.3,1,2.1,1.2c0.4,0.1,0.8,0.3,1.2,0.5
		c0.4,0.2,0.9,0.4,1.3,0.5c1.6,0.5,1.6,0.5,3.2-0.1c0.5-0.2,0.9-0.1,1.2,0.2c0.7,0.7,1.6,1.2,2.2,2c0.3,0.3,0.7,0.6,1.1,0.6
		c0.6,0.1,1.2,0.3,1.8,0.4c0.7,0.2,1.3,0.5,2.1,0.5c0.2,0,0.3,0.1,0.3,0.2c0.2,0.4,0.5,0.7,0.8,1c0.3,0.4,0.6,0.7,0.9,1.1
		c0.2,0.2,0.3,0.5,0.2,0.8c-0.1,0.5,0.1,0.7,0.5,0.8c0.2,0.1,0.4,0.1,0.5,0.1c0.1,0,0.3,0,0.4,0.1c0.3,0.3,0.7,0.5,0.8,1
		c0.1,0.5,0.5,0.6,1,0.7c0.1,0,0.3,0,0.4,0c0.5-0.2,0.9,0,1.2,0.3c0.3,0.3,0.6,0.4,0.9,0.7c0.3,0.3,0.5,0.2,0.9-0.1
		c0.2-0.1,0.2-0.3,0.1-0.5c-0.1-0.1-0.1-0.3,0-0.4c0.4-0.2,0.6-0.6,0.9-0.9c0.1-0.1,0.3-0.1,0.4,0c0.4,0.2,0.7,0.5,0.7,1
		c0,0.3,0.1,0.6,0.3,0.9c0.3,0.3,0.3,0.8,0.6,1.1c0.1,0.1,0.1,0.3,0.1,0.4c0,0.6,0,1.2,0,1.8c0,0.4,0,0.6,0.2,0.9
		c0.1,0.1,0.1,0.3,0,0.4c-0.3,0.4-0.6,0.9-1.1,1.1c-0.5,0.2-0.8,0.6-1,1.2c-0.1,0.3-0.3,0.5-0.5,0.6c-0.3,0.1-0.5,0.3-0.7,0.4
		c-0.1,0-0.1,0.2-0.1,0.2c-0.3,1.2-0.3,1.2-0.7,2c0,0.1-0.1,0.2-0.1,0.2c0,0.5-0.1,1,0,1.4c0.1,0.4,0.4,0.6,0.8,0.8
		c0,0,0.1,0,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.2-0.1,0.5-0.3,0.6c-0.3,0.2-0.6,0.4-0.8,0.6
		c-0.4,0.3-0.6,0.7-0.4,1.3c0.1,0.2,0.1,0.5,0.2,0.7c0,0.1,0,0.3,0,0.4c-0.1,0.3,0,0.5,0.2,0.6c0.3,0.2,0.6,0.3,0.9,0.5
		c0.3,0.2,0.6,0.4,0.8,0.8c0.1,0.3,0.3,0.6,0.6,0.8c0.1,0.1,0.3,0.3,0.3,0.5c0.2,0.6,0.5,1.1,0.8,1.7c0.3,0.8,0.7,1.4,1.1,2.1
		c0.5,0.7,0.9,1.5,1.3,2.2c0.1,0.1,0.1,0.3,0.1,0.4c-0.1,0.4,0.2,0.7,0.4,1c0.8,0.9,1.9,1.4,2.8,2.1c0.2,0.1,0.4,0.2,0.7,0.3
		c0.5,0.2,1.1,0.5,1.5,0.9c0.5,0.5,1.1,1,1.7,1.5c0.2,0.1,0.2,0.3,0.2,0.5c0,0.2,0,0.5,0.1,0.7c0.1,0.5,0.2,0.9,0,1.4
		c0,0.2,0,0.4,0.1,0.6c0.3,0.9,0.2,1.9,0,2.8c0,0.1-0.1,0.3,0,0.4c0.3,1.4,0.2,2.8,0.2,4.3c0,0.4-0.1,0.8-0.1,1.1
		c-0.1,0.5-0.2,0.9-0.3,1.4c-0.1,0.3-0.1,0.5,0,0.8c0.2,0.4,0.1,0.9,0,1.3c-0.2,0.4-0.2,0.8,0,1.2c0.2,0.5,0.1,1.1,0.3,1.7
		c0.1,0.2,0,0.6,0,0.8c0,0.2,0,0.5,0.1,0.7c0.1,0.2,0.1,0.5,0,0.7c-0.1,0.3-0.2,0.6-0.3,1c-0.3,1.1-0.5,2.1-0.7,3.2
		c0,0.2-0.1,0.4-0.2,0.5c-0.3,0.3-0.3,0.6-0.1,0.9c0.3,0.5,0.3,1.1,0.6,1.6c0.3,0.4,0.3,0.9,0,1.4c-0.1,0.3-0.2,0.5-0.1,0.8
		c0,0.3,0,0.6,0,0.9c0,0.3,0.1,0.7,0.3,0.9c0.1,0.2,0.2,0.3,0.4,0.5c-0.5,0-0.6,0-0.7,0.4c0,0.1-0.1,0.3-0.1,0.4
		c0.1,0.5,0.1,0.9,0.2,1.4c0,0.1,0.4,0.2,0.5,0.1c0.2-0.2,0.3-0.4,0.3-0.6c0-0.3,0-0.6,0-1c0-0.3-0.1-0.5-0.3-0.8
		c0.2,0.1,0.5,0,0.7-0.1c0.2-0.1,0.2,0,0.3,0.2c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.2-0.1,1.8c0,0.6-0.1,1.2-0.1,1.8
		c0,0.2-0.1,0.4,0.1,0.5c0.3,0.5,0.2,1.1,0,1.6c-0.1,0.1-0.1,0.2-0.2,0.1c0,0-0.1,0-0.1-0.1c-0.1-0.2-0.1-0.3-0.2-0.5
		c-0.4,0-0.8,0.2-0.8,0.6c-0.1,0.5-0.4,0.9-0.6,1.3c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.2,0.1,0.2,0.1c0.1,0,0.2-0.2,0.4-0.2
		c0.3,0,0.5,0.1,0.8,0.1c0.2,0,0.3,0.3,0.3,0.5c-0.1,0.4-0.2,0.7-0.1,1.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0.1,0,0.1,0
		c0,0.1,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.3,0-0.5,0.1-0.7,0.3c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.1,0.5
		c0,0.1,0.2,0.2,0.2,0.3c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.1,0.1,0.2,0.1v0l0.1,0l0,0.1c0,0,0,0,0,0c0,0.1,0,0.3,0,0.4
		c-0.1,0.4-0.1,0.4,0.3,0.6c0.2,0.1,0.4,0.3,0.6,0.4v0c0,0,0,0,0,0c0.2,0.3,0.2,0.3,0,0.6c0,0,0,0,0,0c0,0,0,0,0,0l0,0
		c0,0.5,0.2,0.9,0,1.4c-0.1,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3c0-0.3,0-0.7,0.2-1c0,0,0,0,0,0l-0.1-0.1l0.1,0c0,0,0,0,0,0.1
		c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2,0.1,0.4,0.2,0.5c0.1,0.2,0.2,0.3,0.1,0.5c-0.1,0.1-0.2,0.3-0.4,0.3c-0.1,0-0.2,0-0.3,0
		c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.1,0,0.2,0.1,0.2c0.1,0,0.3,0.1,0.4,0.1c0.5,0,0.6,0.4,0.8,0.7c-0.1,0.3,0,0.4,0.2,0.6
		c0.3,0.2,0.6,0.4,0.9,0.6c0,0,0,0,0.1,0c0,0,0,0,0,0.1c0.1,0,0.1,0.1,0.2,0.2c0,0,0,0,0.1,0l0,0.1l-0.1,0c0,0,0,0,0-0.1
		c-0.1,0-0.1-0.1-0.2-0.2c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.4,0-0.8,0-1.1-0.2c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0.1-0.1,0.1
		c0,0.1,0,0.2,0.1,0.3c0.6,0.4,1.2,0.8,1.6,1.3c0.2,0.2,0.5,0.3,0.7,0.4c0.2,0.1,0.4,0,0.5,0c0-0.1,0.1-0.2,0.1-0.3
		c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2c0.1,0,0.2,0.1,0.2,0.1c0.3,0.1,0.5,0.2,0.4,0.6c0,0.1,0,0.2,0.1,0.2
		c0.3,0.1,0.5,0.3,0.7,0.6c0.1,0.2,0.3,0.2,0.5,0.2c0.2-0.2,0.3-0.5,0.5-0.6c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2,0,0.3
		c0,0.2,0,0.4,0.1,0.5c0.2,0.1,0.3,0.2,0.5,0.2c0.1,0,0.3,0,0.4,0c0.2,0,0.4,0,0.6,0c0.3,0,0.3,0,0.2-0.4c0,0,0,0,0,0c0,0,0,0,0-0.1
		c0,0,0,0-0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0.3-0.1,0.5-0.2,0.8-0.2c0.2-0.1,0.3-0.2,0.3-0.4c0-0.1,0.1-0.1,0.2-0.1
		c0.2,0,0.4,0.1,0.5,0.1c0.3-0.2,0.6-0.4,0.9-0.6c0,0,0-0.1,0-0.1c-0.1-0.2-0.4-0.1-0.5-0.1c-0.6-0.3-1.2-0.5-1.8-0.8
		c-0.1,0-0.2-0.1-0.2-0.2c-0.3-0.3-0.6-0.5-0.9-0.9c-0.2-0.2-0.2-0.4-0.3-0.6c-0.1-0.6-0.4-0.9-1.1-0.9c-0.2,0-0.3,0-0.5,0
		c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0.1,0,0.3-0.1,0.3c-0.5,0.2-0.5,0.7-0.5,1.1c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1
		c0,0-0.1-0.1-0.1-0.1c0-0.2,0-0.4,0-0.6c0.1-0.5,0.3-0.8,0.8-0.9c0,0,0,0,0-0.1c0,0,0,0,0.1,0c0.1-0.2,0.2-0.5,0.6-0.4
		c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.2-0.1c0-0.1,0-0.2,0-0.2c-0.2-0.3-0.5-0.6-0.6-0.9c-0.2-0.3-0.2-0.7-0.3-1.1
		c-0.1-0.4,0-0.8,0.2-1.1c0.1-0.1,0.3-0.3,0.4-0.4c0.4-0.3,0.5-0.6,0.5-1.1c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.6,0.8-1
		c0.2-0.2,0.2-0.4,0.2-0.7c0-0.1,0-0.3-0.1-0.4c-0.1-0.4-0.2-0.7-0.7-0.7c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.5-0.3-0.7-0.5
		c-0.4-0.4-0.5-0.9-0.1-1.4c0.1-0.2,0.3-0.4,0.5-0.5c0.3-0.2,0.6-0.3,0.9-0.4c0.1,0,0.2-0.1,0.2-0.2c0-0.4,0.4-0.8,0.2-1.2
		c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.4,0-0.7,0.4-1c0.2-0.2,0.3-0.4,0.5-0.6c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.3-0.1
		c-0.1,0-0.1-0.1,0-0.2c0,0,0.1,0,0.1,0c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0
		c0.3,0,0.6-0.3,0.5-0.5c0-0.2-0.1-0.5-0.2-0.7c0-0.1-0.2-0.2-0.4-0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.2-0.9,0-1-0.5
		c-0.1-0.3-0.1-0.6-0.2-0.8c0-0.1-0.1-0.2-0.1-0.3c0-0.2,0.2-0.4,0.4-0.3c0.3,0.1,0.5,0.2,0.8,0.3c0.4,0.2,0.7,0.2,1.1,0.2
		c0.4-0.1,0.9-0.5,0.8-0.9c0-0.3-0.1-0.6-0.1-0.8c0-0.3,0.1-0.6,0.1-1c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.3
		c-0.1-0.3,0.1-0.5,0.4-0.4c1.1,0.2,2.1,0.1,3.1-0.3c0.3-0.1,0.5-0.2,0.8-0.3c0.4-0.2,0.8-0.4,0.9-1c0.1-0.3,0.3-0.6,0.4-0.9
		c0.1-0.2,0.1-0.4,0.2-0.5c0-0.2-0.1-0.5-0.3-0.6c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0-0.3,0.1-0.4
		c0.1-0.4,0-0.6-0.3-0.9c-0.3-0.3-0.7-0.4-1-0.7c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1c0,0,0,0-0.1-0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0.7,0.1,1.4,0.1,2,0.4c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.6,0,0.9,0c0.7,0,1.3-0.2,1.7-0.9
		c0.2-0.3,0.3-0.7,0.6-1c0.4-0.3,0.5-0.7,0.6-1.1c0.1-0.5,0.2-0.9,0.4-1.4l0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.4-0.1-0.6,0.2-0.7
		c0.1-0.1,0.2-0.1,0.3-0.1c0.1-0.4,0.2-0.7,0.4-1c0-0.1,0.2-0.1,0.2-0.1c0.1,0,0.1,0.1,0.2,0.2c0,0.1,0.1,0.3,0.1,0.4
		c0.3,0,0.4-0.2,0.5-0.4c0.4-0.9,0.8-1.8,1.4-2.6c0.4-0.5,0.6-1.2,0.2-1.9c0-0.1,0-0.2-0.1-0.3c-0.1-0.8,0-1.6,0.7-2.2
		c0.3-0.2,0.6-0.5,0.8-0.8c0.4-0.5,1-0.8,1.6-1c0.3-0.1,0.6-0.2,0.8-0.5c0.2-0.2,0.5-0.3,0.5-0.7c0-0.1,0.2-0.1,0.2-0.1
		c0.3,0.1,0.6,0,0.8,0c0.3,0,0.6,0,0.8,0c0.5,0,1-0.1,1.4-0.6c0.2-0.2,0.5-0.3,0.7-0.5c0.2-0.1,0.2-0.3,0.2-0.5
		c-0.1-0.2,0-0.5,0.1-0.7c0.3-0.5,0.5-1,0.8-1.5c0.3-0.4,0.4-0.8,0.3-1.2c0-0.3-0.1-0.7,0.1-1c0.4-0.5,0.4-1.2,0.5-1.8
		c0-0.8,0-1.5,0-2.3c0-0.5,0-1,0-1.6c0-0.2,0.1-0.5,0.3-0.6c0.7-0.3,1-0.8,1.3-1.5c0-0.1,0.1-0.3,0.2-0.4c0.2-0.4,0.4-0.7,0.8-1
		c0.4-0.3,0.8-0.7,1.1-1.1c0.4-0.4,0.6-0.8,0.8-1.3c0.2-0.5,0.3-1.6,0.2-2C179.4,193,179.1,192.4,178.9,191.8"
            />
            <path
              fill="#003046"
              d="M84.5,113.9C84.5,113.9,84.5,113.9,84.5,113.9C84.5,113.9,84.5,113.9,84.5,113.9
		C84.5,114,84.5,114,84.5,113.9C84.5,114,84.5,113.9,84.5,113.9"
            />
            <path
              fill="#003046"
              d="M170,83.9C170,83.9,170,83.9,170,83.9C170.1,83.9,170.1,83.9,170,83.9L170,83.9z"
            />
            <path
              fill="#003046"
              d="M194.9,78.4L194.9,78.4L194.9,78.4L194.9,78.4L194.9,78.4z M194.5,80.8L194.5,80.8L194.5,80.8L194.5,80.8z
		 M190.7,42.7c-0.1,0-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0.1
		C190.7,42.5,190.7,42.5,190.7,42.7C190.8,42.6,190.7,42.7,190.7,42.7 M190.3,42.2C190.3,42.1,190.2,42,190.3,42.2
		c0.1,0,0.1,0,0.2,0.1C190.4,42.2,190.3,42.2,190.3,42.2 M182.9,91C182.9,90.9,182.9,90.9,182.9,91c-0.1-0.1,0-0.1,0-0.1
		c0,0,0.1,0,0.1,0.1C183,90.9,182.9,90.9,182.9,91 M204.3,54.6c0-0.1,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0.1c0.1,0.1,0.1,0.2,0,0.2
		c-0.1,0.1-0.1,0.1-0.2,0.2C204.3,54.9,204.2,54.7,204.3,54.6 M205.3,54.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.1
		c0,0-0.1,0-0.1-0.1C205.3,54.2,205.3,54.2,205.3,54.1 M198.9,82.9c0,0,0-0.2,0-0.3c0,0-0.1-0.1-0.1-0.1c-0.5-0.2-1.2-0.1-1.7-0.4
		c-0.4-0.3-0.8-0.2-1.2-0.1c-0.1,0-0.2,0-0.3,0c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.2,0-0.2c0.2-0.1,0.5-0.1,0.7-0.2
		c0.3-0.1,0.4-0.4,0.4-0.7c0-0.3-0.1-0.6-0.1-0.8c0-0.1,0.2-0.3,0.3-0.3c0.1,0,0.2,0.1,0.2,0.1c0.2,0.5,0.4,1.1,0.7,1.6
		c0.2,0.3,0.5,0.6,1,0.5c0.2,0,0.4,0,0.6,0c0.5,0,0.6,0,0.6-0.5c0-0.4,0-0.9,0-1.3c0.1-0.4-0.4-0.8-0.3-1.2c0-0.1-0.1-0.2-0.2-0.4
		c-0.2-0.5-0.6-0.9-1.1-1.3c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.3-0.1-0.6-0.2-1c0-0.1,0.1-0.2,0.1-0.2
		c0.1,0,0.2,0,0.2,0c0.1,0.1,0.1,0.3,0.1,0.4c0,0.5,0.2,0.9,0.6,1.2c0.3,0.2,0.5,0.5,0.8,0.5c0.1,0,0.3,0,0.3-0.2
		c0-0.2,0-0.5,0.2-0.7c-0.1-0.3,0.1-0.4,0.2-0.6c0.1-0.2,0-0.4-0.1-0.5c-0.3-0.2-0.6-0.4-0.9-0.7c-0.1-0.1-0.1-0.2-0.1-0.3
		c0,0,0,0,0,0c0,0,0,0,0,0c-0.3,0-0.6-0.2-0.8-0.4c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0.1,0,0.2,0,0.3,0c0.2,0.1,0.3,0.3,0.4,0.5
		c0,0,0,0,0.1,0c0,0,0,0,0,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.3,0.2,0.6,0.2,0.9,0c0.3-0.2,0.7-0.3,1.1-0.4c0.1,0,0.2-0.2,0.3-0.3
		c0.1-0.2,0.1-0.4,0.2-0.5c0-0.2,0-0.4-0.2-0.5c-0.4-0.1-0.7-0.3-1-0.5c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.2-0.1
		c0.4,0.2,0.9,0.4,1.3,0c0.1-0.1,0.3,0,0.4-0.1c0.5,0,0.7-0.8,0.4-1.1c-0.2-0.2-0.4-0.4-0.4-0.7c0-0.1-0.1-0.2-0.2-0.3
		c-0.1-0.1-0.2-0.1-0.1-0.2c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.3,0,0.4,0c0.2-0.1,0.3-0.3,0.3-0.5c0-0.3,0.1-0.7,0.1-1
		c0-0.2-0.1-0.5-0.3-0.6c-0.3-0.2-0.1-0.4,0.1-0.5c0.1-0.1,0-0.3-0.1-0.4c-0.2-0.2-0.6-0.1-0.7-0.3c-0.1,0-0.2,0-0.2,0
		c-0.2-0.1-0.3-0.2-0.3-0.4c0-0.2,0.1-0.3,0.2-0.4c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0,0,0,0v-0.1c-0.1-0.1-0.2-0.2-0.3-0.3l0,0
		c0.2-0.2,0.5-0.5,0.7-0.7c0.1-0.1,0.4-0.1,0.5,0c0,0,0.1,0,0.1,0.1c0.3,0.3,0.7,0.5,0.6,1.1c-0.1,0.4,0,0.8,0.2,1.2
		c0,0,0.1,0,0.1,0c0-0.1,0.1-0.3,0.1-0.4c-0.1-0.5,0-1,0.3-1.5c0.2-0.4,0.3-0.8,0.3-1.2c0-0.2-0.2-0.5-0.4-0.5c-0.2,0-0.4,0-0.6-0.1
		c-0.1,0-0.2-0.2-0.3-0.2c0,0-0.1-0.1,0-0.1c0-0.1,0.1-0.2,0.2-0.2c0.5-0.1,0.6-0.2,0.4-0.6c-0.1-0.2-0.2-0.4-0.4-0.6
		c-0.1-0.1-0.3-0.3-0.4-0.4c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0.4,0.2,0.6,0,0.7-0.4c0.1-0.5,0.4-1,0.4-1.5
		c0-1.3,0.5-2.4,1.3-3.4c0.2-0.3,0.5-0.6,0.7-0.9c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.4-0.1-0.4,0.3-0.4c0.4-0.1,0.7-0.3,0.9-0.7
		c0.1-0.1,0-0.4-0.2-0.5c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.4,0-0.6-0.1c0,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2
		c0.2-0.1,0.5,0,0.7,0.1c0.4,0.2,0.7,0.1,1.1-0.1c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.1-0.4c0-0.1,0-0.2-0.1-0.3
		c0-0.2,0.1-0.3,0.2-0.3c0.4,0,0.7,0,0.9-0.3c0.1-0.1,0.3-0.3,0.4-0.4c0.7-0.5,0.7-0.8,0.1-1.5c-0.1-0.1-0.1-0.1-0.2-0.2
		c-0.6-0.7-1.3-0.9-2.2-0.9c-0.5,0-0.9,0.2-1.4,0.3c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.3-0.1,0.4c-0.1,0.2-0.2,0.2-0.5,0.2
		c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.3-0.1-0.3,0.1c-0.5,0.8-1.2,1.2-2,1.5c-0.4,0.1-0.9,0.2-1.3,0.5c0,0,0,0,0,0.1
		c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.3l-0.1,0.1l0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.3
		c0,0,0,0,0,0c0-0.1,0-0.3,0.1-0.3c0.3-0.4,0.6-0.7,1.1-0.9c0.5-0.2,0.9-0.6,1.1-1.1c0-0.1,0.1-0.3,0.2-0.4c0.4-1.1-0.1-1.3-1-1.3
		c0,0-0.1,0-0.1,0c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0.2-0.2,0.4-0.2,0.7c-0.1,0.3-0.4,0.5-0.7,0.4c0,0-0.1,0-0.1,0
		c-0.5-0.2-1-0.1-1.5,0c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0.3-0.1,0.5-0.2,0.8-0.3c0.2-0.1,0.4-0.3,0.5-0.5
		c0.1-0.2-0.1-0.4-0.2-0.5c-0.3-0.1-0.6-0.2-1-0.2c-1.4-0.3-1.9-0.2-3.3,0.3c-0.2,0.1-0.3,0.2-0.5,0.2c-0.3,0.1-0.5,0.2-0.8,0.3
		c0,0-0.1-0.1-0.2-0.2c0,0,0-0.1,0-0.1c0.2-0.1,0.3-0.2,0.5-0.3c0.5-0.2,1-0.3,1.5-0.6c0.9-0.4,1.7-0.5,2.6-0.2
		c0.6,0.2,1.2,0.2,1.8,0.1c0.6-0.1,1.1-0.2,1.7-0.2c0.7,0,1.1-0.4,1.6-0.8c0.3-0.2,0.3-0.7,0-0.9c-0.3-0.3-0.7-0.5-1-0.8
		c-0.6-0.4-1.2-0.6-1.9-0.3c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1-0.1c0,0,0-0.1,0.1-0.1c0.1-0.1,0.3-0.1,0.4-0.2c0.4-0.2,0.4-0.6,0.2-1
		c-0.1-0.2-0.3-0.3-0.5-0.3c-0.9,0.3-1.8,0.3-2.6-0.1c-0.2-0.1-0.6-0.1-0.8-0.1c-0.3,0-0.7,0.1-1,0.2c-0.5,0.1-0.9,0.2-1.4,0.1
		c-0.3-0.1-0.7,0-1,0.2c-0.1,0.1-0.3,0.1-0.4,0.1c0,0-0.1,0-0.1-0.1c0,0,0-0.1,0-0.1c0.2-0.1,0.3-0.2,0.5-0.2c0.4-0.1,0.8,0,1.1-0.1
		c0.7-0.2,1.4-0.2,2.1-0.3c0.6-0.1,1.1,0,1.7,0.1c0.5,0.2,1,0,1.5-0.3c0.1,0,0.1-0.2,0.1-0.2c-0.3-0.5-0.8-0.9-1.3-1
		c-1-0.2-2-0.6-3-0.9c-0.4-0.1-0.9-0.2-1.4-0.3c-0.5-0.1-1,0.2-1.5,0.2c-0.4,0.2-0.6,0.6-0.9,0.9c0,0,0,0-0.1,0.1c0,0,0,0,0,0
		c0,0,0,0,0,0l0,0c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0l0.1,0.1l-0.1-0.1c0,0,0,0,0,0c-0.4,0-0.6-0.2-0.9-0.4c-0.1-0.1-0.2-0.1-0.2,0.1
		c0,0,0,0.1,0,0.1c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.2,0.1-0.3,0c-0.4-0.1-0.8-0.3-1.1-0.6
		c-0.3-0.2-0.5-0.2-0.8,0c-0.2,0.2-0.5,0.3-0.8,0.3c-0.2,0-0.3,0.1-0.3,0.3c0,0.3,0,0.7-0.1,1c0,0.2-0.1,0.3-0.3,0.5
		c-0.2,0.1-0.3,0.3-0.4,0.4c-0.1,0-0.2,0.1-0.2,0.1c-0.3-0.1-0.6-0.3-0.9-0.3c-0.1,0-0.3,0.1-0.3,0.2c0,0.1,0,0.3,0,0.4
		c0.1,0.5,0.2,1,0.3,1.5c0.2,0.6,0.2,1.3,0.5,1.9c0.1,0.1-0.1,0.3-0.2,0.2c-0.2,0-0.5,0-0.6-0.3c-0.1-0.2-0.2-0.4-0.5-0.5
		c-0.1,0,0-0.1,0.1-0.2c0.2-0.2,0.3-0.4,0.1-0.6c-0.2-0.2-0.4-0.3-0.5-0.6c-0.1-0.5-0.4-0.9-0.6-1.3c-0.1-0.3-0.3-0.5-0.6-0.6
		c-0.2-0.1-0.5,0-0.6,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.5-0.2,1.1-0.1,1.7c0,0.2,0,0.5-0.1,0.7c-0.1,0.2-0.4,0.1-0.7,0.1
		c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.1-0.2-0.1-0.3-0.2c-0.1-0.3,0.3-0.4,0.2-0.6c-0.2-0.5-0.1-0.9-0.1-1.4
		c0-0.1-0.1-0.3-0.1-0.4c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.2,0.1c-0.2,0.2-0.1,0.4-0.2,0.7c0,0.2,0.1,0.5,0,0.7s-0.3,0.4-0.4,0.6
		c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1
		c-0.1-0.1,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0,0,0,0,0,0c-0.1-0.2-0.2-0.3-0.1-0.5
		c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.3,0.1-0.6,0.1-1c0-0.1-0.3-0.3-0.4-0.3c-0.2,0.1-0.5,0.1-0.7,0.2c-0.4,0.2-0.9,0.2-1.4,0.3
		c-0.7,0.1-1.3,0.1-2,0.2c-0.2,0-0.4,0.2-0.4,0.5c0,0.1,0,0.3,0,0.4c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0c-0.1-0.1-0.2-0.3-0.4-0.4
		c-0.2-0.1-0.5,0-0.7,0.1c-0.2,0.1-0.4,0.3-0.5,0.5c0,0.1-0.1,0.3-0.1,0.4c0,0.3,0,0.6-0.1,0.8c-0.1,0.2-0.4,0.4-0.6,0.6
		c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.2-0.4,0.2-0.5,0.1c-0.3-0.3-0.6-0.3-1-0.2c-0.5,0.1-0.9,0.2-1.2,0.6c-0.3,0.4-0.7,0.6-1.2,0.6
		c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5,0.3-0.8,0.8-1.1,1.3c-0.2,0.3-0.1,0.7,0.3,0.8c0.3,0.1,0.7,0.2,1.1,0.3c0,0,0,0,0,0l0.1-0.1l0,0.1
		c0,0,0,0,0,0c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0,0.3-0.1,0.4c-0.2,0.1-0.4,0.3-0.4,0.5c0,0.2-0.1,0.4-0.3,0.5c-0.3,0.3-0.6,0.6-0.9,0.9
		c-0.1,0.1-0.3,0.2-0.5,0.1c-0.6-0.3-1.2-0.2-1.8-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.2-0.6,0.3-0.9,0.4c-0.7,0.1-1.3,0.3-2,0.4
		c-0.5,0.1-0.9,0.4-1.2,0.9c-0.2,0.3-0.2,0.6,0,0.9c0.3,0.4,0.4,0.9,0.8,1.3c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.1
		c0,0.1-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0,0,0h0c0.1,0.3,0.2,0.5,0.4,0.8c0.2,0.3,0.4,0.4,0.7,0.4c0.2,0,0.4-0.2,0.7-0.1
		c0.3,0.1,0.6,0.1,0.8,0c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.3,0-0.4,0
		c-0.6-0.2-1.1-0.3-1.7,0c-0.2,0.1-0.5,0.1-0.7,0c-0.3,0-0.6,0-0.8,0.2c-0.4,0.2-0.6,0.8-0.3,1.1c0.3,0.3,0.5,0.7,0.9,0.9
		c0.2,0.1,0.2,0.1,0,0.2c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.2,0.3-0.1,0.4c0.2,0.6,0.6,1.1,1.1,1.4c0.4,0.2,0.5,0.2,0.8-0.2
		c0.2-0.3,0.2-0.3,0.4-0.2c0.2,0.1,0.4,0.1,0.5,0.3c0,0.1,0.2,0.1,0.2,0.1c0.6-0.3,1.2,0.2,1.8-0.2c0,0,0.1,0,0.1,0
		c0.6,0.2,1.1,0.5,1.6,0.9c0.4,0.3,0.8,0.6,1.2,1c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.5-0.2,0.7
		c-0.3,0.4-0.1,0.7,0.1,1c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2,0,0.5-0.2,0.6c-0.1,0.1-0.1,0.2,0,0.2c0.1,0.1,0.3,0.1,0.4,0.1
		c0.2,0.1,0.2,0.2,0.1,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1-0.2,0.3-0.2,0.4
		c0,0.2,0.2,0.3,0.2,0.5c0.1,0.3,0,0.6,0.1,0.8c0.1,0.3,0,0.6-0.1,0.8c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.1,0.2,0.2
		c0.2,0.2,0.1,0.4-0.2,0.5c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1,0,0.2,0,0.3c0.1,0.1,0.5,0.1,0.1,0.3c-0.3,0.2-0.4,0.5-0.4,0.8
		c0.1,0,0.2,0,0.3,0.1c0,0,0,0,0,0l0.1,0l0,0.1c0,0,0,0-0.1,0c-0.1,0.1-0.2,0.2-0.3,0.2c0,0,0,0,0,0c0,0,0,0,0,0l0,0
		c-0.1,0.4-0.2,0.7-0.5,1c-0.1,0.2-0.1,0.5,0.1,0.6c0.5,0.5,0.9,0.6,1.4,0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.3-0.1,0.5-0.1
		c0.1,0,0.1,0.1,0.2,0.1v0c0,0,0,0,0,0l0,0c0.1,0,0.2,0.1,0.2,0.1c0,0.2-0.1,0.1-0.2,0.2c-0.1,0.2-0.3,0.4-0.4,0.7
		c-0.1,0.1-0.1,0.3,0.1,0.4c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.2,0.3,0.1,0.5c0,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0c0,0,0,0,0,0
		c0.3,0,0.5,0.2,0.6,0.5c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0c-0.1-0.2-0.3-0.4-0.4-0.7c0,0,0,0-0.1,0c0,0,0,0,0,0
		c-0.4,0-0.7-0.1-1.1-0.2c-0.2-0.1-0.5-0.1-0.7-0.2c-0.3-0.1-0.6,0.2-0.5,0.5c0,0,0,0.1,0,0.1c0,0.1,0.1,0.3,0.1,0.4
		c0,0.1-0.2,0.2-0.3,0.2c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.3-0.2,0.6-0.1,0.8c0,0.5,0.3,1,0.7,1.3c0,0,0.1,0,0.1,0.1
		c0.5,0.1,1,0,1.5-0.2c0.2-0.1,0.3-0.5,0.2-0.7c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.3-0.4-0.3-0.7c0,0,0,0,0,0l0-0.1l0.1,0.1
		c0,0,0,0,0,0.1c0.6,0.3,1.2,0.7,1.9,0.7c0.2,0,0.2,0.2,0.1,0.3c-0.1,0.1-0.2,0.2-0.3,0.4c0,0,0,0,0,0c0,0,0,0,0,0.1l0,0
		c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.1,0,0.3,0,0.4c0.1,0.3,0.1,0.3-0.2,0.3c-0.1,0-0.3,0-0.3,0.2c-0.1,0.6-0.4,0.9-1.1,0.8
		c-0.2,0-0.4,0.1-0.7,0.1c0,0.3,0.1,0.5,0.2,0.8c0,0,0,0,0,0l0,0.1l-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.4-0.1c-0.3,0-0.3,0-0.4,0.2
		c-0.1,0.1-0.1,0.3-0.2,0.4c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.3-0.1,0.5-0.3,0.8c-0.1,0.1-0.1,0.4-0.1,0.5c0,0.2,0,0.4,0.2,0.5
		c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c-0.1,0-0.1,0-0.2,0.1c0,0,0,0-0.1,0.1
		c0,0,0,0,0,0c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.3-0.3,0.6-0.1,0.9c0.1,0.2,0.3,0.4,0.2,0.6c0,0.2,0,0.4,0.1,0.6
		c0.2,0.6,0.3,1.3,0.3,2c0,0.1,0,0.2,0,0.3c0,0.1,0.2,0.3,0.3,0.2c0.3-0.1,0.6-0.2,0.9-0.3c0,0,0,0,0-0.1c0,0,0.1,0,0.1,0
		c0,0,0.1-0.1,0.1-0.1c0,0.1-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0c-0.1,0.3-0.4,0.5-0.6,0.6c-0.3,0.2-0.4,0.5-0.3,0.8
		c0.1,0.5,0.1,1,0.5,1.4l0,0l0,0.1c0,0,0,0,0,0l0,0c0.1,0.1,0.1,0.2,0.3,0.3c0.2,0.2,0.3,0.4,0.2,0.6c-0.1,0.1-0.1,0.3,0,0.4
		c0.3,0.3,0.3,0.7,0.4,1c0.1,0.2,0.1,0.5,0,0.7c0,0.1,0,0.2,0,0.3c0.2,0.2,0.4,0.4,0.6,0.6c0.5,0.1,0.6,0.3,0.6,0.8
		c0,0,0.2,0.1,0.2,0.1c0.3,0,0.6-0.1,0.8-0.2c0.1,0,0.3-0.1,0.4-0.1c0.3-0.1,0.5,0,0.5,0.3c0,0.4,0.1,0.7,0.4,1
		c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.2c0.4-0.1,0.6,0.2,1,0.2c0.2,0,0.5-0.1,0.6-0.3c0.3-0.5,0.3-1.2,0.6-1.7
		c0-0.4,0.2-0.8,0.5-1.1c0.2-0.3,0.3-0.6,0.4-0.9c0.1-0.3,0.1-0.5,0.2-0.8c0,0,0,0,0,0c0,0,0,0,0-0.1c-0.1,0-0.1,0-0.2-0.1
		c0,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.2,0c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0.1,0c0,0,0,0,0,0c0.2-0.1,0.5-0.3,0.6-0.5
		c0.2-0.5,0.5-0.9,0.9-1.2c0.4-0.3,0.6-0.9,0.4-1.4c-0.1-0.2,0-0.3,0.2-0.5c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.1-0.4
		c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.3-0.2-0.4,0.2-0.4c0.3,0,0.5-0.1,0.6-0.3c0.1-0.4,0.4-0.6,0.7-0.5c0.5,0.1,1-0.3,1.6-0.1
		c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.7-0.1,1.4-0.3,1.9-0.8c0.1-0.1,0.2,0,0.3-0.1c0.4-0.2,0.8-0.4,1-0.8
		c0.3-0.6,0.8-0.9,1.1-1.5c0.3-0.6,1-0.7,1.4-1.2c0.3-0.2,0.7,0,1-0.1c0.2,0,0.4-0.1,0.5-0.2c0.1,0,0.3-0.1,0.4-0.1
		c0.3,0.1,0.6,0,0.8-0.1c0.5-0.2,1-0.4,1.5-0.5c0.8-0.1,1.7-0.4,2.3-1C197,84.2,198,83.6,198.9,82.9"
            />
            <path
              fill="#003046"
              d="M170,74.7C170,74.7,170,74.7,170,74.7C170,74.7,170,74.7,170,74.7C170,74.8,170,74.7,170,74.7
		C170,74.7,170,74.7,170,74.7"
            />
            <path
              fill="#003046"
              d="M202.1,66.5C202.1,66.5,202.1,66.5,202.1,66.5L202.1,66.5C202.1,66.4,202.1,66.4,202.1,66.5
		C202,66.5,202.1,66.5,202.1,66.5"
            />
            <path
              fill="#003046"
              d="M352,218.9C352,218.9,352,218.9,352,218.9L352,218.9C352,218.9,352,218.9,352,218.9
		C352,218.9,352,218.9,352,218.9"
            />
            <path
              fill="#003046"
              d="M398.6,218.2c-0.4,0.4-0.4,0.4-0.7-0.1c0-0.1-0.1-0.2-0.2-0.2c-0.2-0.4-0.3-0.9-0.8-1.1
		c-0.2-0.6-0.8-1-0.6-1.7c0.1-0.3-0.1-0.5-0.4-0.6c-0.3-0.1-0.5-0.3-0.8-0.3c-0.1,0-0.1-0.1-0.1-0.2c0-0.4-0.1-0.9-0.3-1.2
		c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.1-0.1-0.3-0.2-0.3c-0.7-0.4-1.2-1.1-2-1.4c-0.2-0.1-0.3-0.2-0.3-0.4c-0.1-0.4-0.2-0.8-0.1-1.1
		c0.1-0.6,0-1-0.3-1.5c-0.3-0.4-0.3-0.8-0.3-1.2c0-0.3-0.1-0.7,0-1c0-0.5-0.2-0.8-0.5-1.1c-0.2-0.2-0.4-0.3-0.8-0.2
		c-0.2,0-0.4-0.1-0.4-0.3c0-0.1-0.1-0.3-0.1-0.4c0-0.5-0.1-0.9-0.2-1.4c-0.1-0.7-0.5-1.2-0.6-1.9c0-0.1-0.2-0.2-0.3-0.3
		c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0.4-0.2,0.8-0.4,1.2c-0.2,0.4-0.2,0.8-0.3,1.2c-0.2,0.7-0.4,1.4-0.3,2.1
		c0,0.2,0,0.5-0.1,0.7c-0.1,0.5-0.3,1.1-0.5,1.6c-0.1,0.4-0.2,0.7-0.4,1.1c-0.1,0.3-0.4,0.4-0.7,0.5c-0.5,0.1-0.9,0-1.2-0.4
		c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1,0-0.2,0-0.3,0c-0.4,0-0.7-0.1-1-0.5c-0.3-0.4-0.8-0.6-1.2-0.8c-0.3-0.1-0.5-0.3-0.7-0.5
		c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.2-0.3-0.4-0.2-0.6c0.2-0.4,0.5-0.8,0.4-1.3c0-0.1,0.1-0.2,0.3-0.3c0.1,0,0.3-0.1,0.4-0.2
		c0.2-0.4,0.4-0.7,0.6-1.1c0,0-0.1-0.2-0.1-0.2c-0.2-0.2-0.4-0.3-0.7-0.2c-0.2,0.1-0.4,0.2-0.7,0c-0.1-0.1-0.3,0-0.4,0
		c-0.1,0-0.3,0.1-0.4,0.1c-0.7-0.3-1.5-0.3-2.1-0.8c-0.3-0.2-0.7-0.3-1-0.4c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0.1c0,0,0,0.1,0,0.1
		c0.1,0.1,0.3,0.2,0.4,0.4c0.2,0.1,0.1,0.4-0.1,0.5c-0.3,0.2-0.6,0.2-1,0.2c-0.2,0-0.4,0-0.6,0.1c-0.4,0.2-0.8,0.4-1,0.8
		c-0.4,0.5-0.7,1-1.1,1.5c-0.2,0.3-0.2,0.5-0.1,0.8c0.1,0.2-0.2,0.3-0.4,0.3c-0.3,0-0.5-0.2-0.8-0.1c-0.2,0.1-0.4,0-0.5-0.2
		c-0.2-0.3-0.4-0.5-0.6-0.8c-0.2-0.2-0.4-0.4-0.8-0.2c-0.3,0.1-0.7,0.2-0.9,0.4c-0.2,0.2-0.5,0.3-0.8,0.3c-0.4,0.1-0.3,0.5-0.6,0.8
		c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5,0.1-0.6,0.5-0.5,0.9c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.3-0.3,0.2c-0.1,0-0.3,0-0.4,0
		c-0.2,0-0.3,0.2-0.3,0.4c0,0.2,0.1,0.4,0.1,0.6c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.2,0.1-0.2,0c-0.1-0.2-0.3-0.4-0.4-0.6
		c-0.1-0.1-0.3-0.2-0.4-0.1c-0.2,0.2-0.4,0.4-0.7,0.6c-0.1,0.1-0.3,0.3-0.2,0.5c0.1,0.7-0.2,1.2-0.7,1.6c-0.1,0.1-0.2,0.3-0.3,0.5
		c-0.4,0.6-0.9,0.9-1.5,0.9c-0.4,0-0.8,0.1-1.3,0.2c-0.6,0.4-1.4,0.3-2,0.8c-0.1,0.1-0.3,0.1-0.4,0.1c-0.5-0.1-0.9,0.1-1.3,0.3
		c-0.5,0.3-1,0.7-1.6,0.9c-0.3,0.1-0.4,0.3-0.6,0.6c-0.2,0.2-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1-0.2,0.3-0.3,0.5
		c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.4-0.1,0.8-0.3,1.1c-0.2,0.3-0.3,0.7-0.3,1.1c0,0.5,0,1,0.3,1.5c0.1,0.1,0.1,0.3,0.1,0.4
		c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.2-0.2,0.4-0.3,0.6c0,0,0,0,0,0v0l0,0c0,0.1,0,0.3,0,0.4
		c0.1,0.3,0.2,0.6,0.2,1c-0.1,0.6,0.1,1.1,0.3,1.7c0.2,0.6,0.3,1.2,0.3,1.8c0,0.7,0,1.4,0.2,2.1c0.1,0.2,0,0.4,0,0.6
		c-0.1,0.4-0.2,0.7-0.2,1.1c-0.1,0.5-0.4,1-0.8,1.3c-0.4,0.3-0.4,0.8-0.1,1.1c0.2,0.2,0.4,0.4,0.6,0.6c0.3,0.4,0.7,0.5,1.1,0.6
		c0.3,0,0.7,0.1,1,0c0.2,0,0.4,0,0.5-0.1c0.6-0.5,1.6-0.5,2.1-1.2c0.1-0.1,0.2-0.1,0.3-0.1c0.7-0.1,1.3-0.3,2-0.1
		c0.6,0.1,1.1,0.1,1.7,0.1c0.3,0,0.6-0.1,0.8-0.3c0.6-0.6,1.1-0.9,1.9-1.3c0.2-0.1,0.4-0.2,0.6-0.4c0.3-0.2,0.6-0.3,0.9-0.3
		c0.9,0.1,1.7,0,2.5-0.4c0.6-0.3,1.3-0.4,1.9-0.4c0.5,0,1,0,1.4,0c0.3,0,0.5,0.1,0.6,0.3c0.3,0.3,0.6,0.4,1,0.5
		c0.4,0.1,0.8,0.3,1.2,0.4c0.2,0.1,0.4,0.3,0.4,0.6c0,0.3,0,0.6,0.3,0.8c0.2,0.2,0.4,0.5,0.4,0.9c0,0.3,0.1,0.7,0.1,1
		c0,0.1,0,0.3,0,0.4c0,0,0,0.1,0,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.2,0,0.3-0.1c0.4-0.4,0.8-0.9,1.2-1.3c0.2-0.2,0.5-0.3,0.7-0.5
		c0.2-0.2,0.4-0.5,0.7-0.7c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0,0.2,0,0.4-0.1,0.5c-0.3,0.4-0.6,0.8-0.7,1.2
		c-0.1,0.4-0.4,0.8-0.8,1c-0.1,0-0.1,0.2-0.1,0.2c0,0.1,0,0.2,0.1,0.2c0.1,0,0.3,0,0.4,0c0.4,0,0.7-0.2,0.9-0.6
		c0-0.1,0.1-0.2,0.1-0.3c0,0,0.1-0.1,0.1-0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.4,0,0.9-0.3,1.2c-0.1,0.1,0,0.3,0.1,0.3c0.2,0,0.5,0,0.7,0
		c0,0,0,0,0,0l0.1-0.1l0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0.3,0.8,0.4,1.6,0.1,2.4c-0.1,0.2,0,0.4,0.1,0.6c0.1,0.2,0.2,0.4,0.3,0.6
		c0.3,0.7,0.8,1.1,1.5,1.2c0.4,0.1,0.7,0.3,1,0.5c0.2,0.1,0.3,0.3,0.5,0.5c0.2,0.1,0.4,0.2,0.5,0.1c0.6-0.3,1.2-0.6,1.5-1.2
		c0-0.1,0.2-0.1,0.2,0c0,0,0.1,0.1,0,0.1c0,0.1-0.1,0.3-0.1,0.4c0.3,0.4,0.5,0.9,0.9,1.3c0.1,0.1,0.3,0.1,0.4,0.1
		c0.5-0.4,1.1-0.8,1.6-1.2c0.5-0.4,1.1-0.6,1.7-0.6c0.2,0,0.5,0,0.7,0c0.4,0,1-0.5,1-0.9c0-0.6,0.4-1.2,0.5-1.8
		c0.1-0.7,0.5-1.2,0.9-1.7c0.3-0.3,0.5-0.7,0.7-1.1c0.2-0.5,0.5-0.9,0.7-1.4c0.4-0.8,0.9-1.5,1.7-1.9c0.2-0.1,0.3-0.3,0.4-0.4
		c0.2-0.6,0.8-1,1-1.6c0.2-0.6,0.2-1.2,0.5-1.8c0.3-0.7,0.5-1.4,0.9-2.1c0.1-0.2,0.2-0.4,0.2-0.7c0.1-0.5,0-1-0.1-1.4
		c-0.1-0.3-0.1-0.6,0-1c0.1-0.2,0.1-0.5,0.1-0.7c0-0.7,0.2-1.3,0.4-1.9c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2
		C398.7,218.1,398.7,218.2,398.6,218.2"
            />
            <path
              fill="#003046"
              d="M153.1,49.9C153.1,49.9,153.2,49.8,153.1,49.9C153.2,49.8,153.2,49.8,153.1,49.9
		C153.1,49.8,153.1,49.9,153.1,49.9C153.1,49.9,153.1,49.9,153.1,49.9"
            />
            <path
              fill="#003046"
              d="M154.6,45.5C154.6,45.5,154.6,45.5,154.6,45.5C154.6,45.5,154.6,45.5,154.6,45.5
		C154.5,45.6,154.5,45.6,154.6,45.5C154.6,45.6,154.6,45.5,154.6,45.5"
            />
            <path
              fill="#003046"
              d="M160.6,49.9L160.6,49.9l-0.1,0L160.6,49.9z M156.3,43C156.3,43,156.3,43,156.3,43
		C156.2,43,156.3,43,156.3,43L156.3,43L156.3,43z M171.4,44.5c0,0,0.1,0,0.1,0C171.5,44.6,171.5,44.6,171.4,44.5
		C171.5,44.6,171.4,44.6,171.4,44.5C171.4,44.6,171.4,44.5,171.4,44.5 M153.8,56C153.8,56,153.8,56,153.8,56c0,0.1,0,0.1-0.1,0.1
		c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0.1c0.1-0.1,0.1-0.1,0.2-0.2C153.7,56.1,153.8,56.1,153.8,56c0,0,0-0.1,0-0.1
		C153.8,55.9,153.8,56,153.8,56 M152.3,52.7L152.3,52.7L152.3,52.7L152.3,52.7L152.3,52.7z M151.7,55C151.7,55,151.6,55,151.7,55
		C151.6,55,151.7,55,151.7,55L151.7,55L151.7,55z M154.7,46.1c-0.2,0.3-0.2,0.5-0.1,0.8c0.2,0.4,0.2,0.7,0.1,1.1
		c-0.1,0.3-0.1,0.6-0.2,1c0,0.1-0.1,0.2-0.2,0.2c-0.3,0.1-0.3,0.4-0.3,0.7c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0.1,0.1,0.1,0.1
		c0.1,0.1,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1-0.1,0.1
		c-0.1,0.1-0.2,0-0.2-0.1c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0-0.1-0.1
		c-0.1-0.1-0.2-0.2-0.3-0.3v0c-0.3,0-0.6,0-0.8,0.1c-0.1,0.1-0.3,0.2-0.5,0.2c-0.3,0-0.6,0.1-1,0.1c-0.1,0.5-0.4,0.9-0.9,1.2
		c-0.3,0.2-0.4,0.5-0.5,0.8c0,0.2,0.1,0.4,0.3,0.4c0.1,0,0.2,0,0.3,0c0.4,0.1,0.6,0.2,0.6,0.6c0,0.2,0,0.4,0,0.5
		c0,0.2-0.1,0.5-0.3,0.6c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1-0.3-0.1-0.7-0.2-1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.3-0.2-0.6-0.2-1,0
		c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.6,0.1,1.1,0.2,1.6c0,0,0,0,0,0l0,0c0,0,0,0,0,0c-0.1,0-0.3,0.1-0.4,0
		c-0.8-0.2-1.6,0.1-2.3,0.5c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.2,0-0.3-0.1c-0.2-0.2-0.4-0.2-0.5,0
		c-0.2,0.3-0.1,0.8,0.1,0.9c0.4,0.4,0.9,0.7,1.4,0.9c0.6,0.2,1.1,0.5,1.6,0.8c0.1,0.1,0.2,0.1,0.4,0.2c0.2,0.1,0.5,0.1,0.6-0.2
		c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0l0,0l0.1,0c0,0,0,0,0,0c0.2,0,0.4-0.1,0.7,0c0.5,0.3,1,0.5,1.6,0.4c0.1,0,0.2,0,0.3,0.1
		c0.3,0.1,0.4,0.2,0.4,0.5c0,0,0,0.1,0,0.1c0,0.2,0.2,0.4,0.4,0.3c0.3-0.1,0.6-0.2,1-0.3c0.2-0.1,0.4-0.2,0.7-0.2
		c0.5,0.1,0.8-0.3,1.2-0.7c0,0,0-0.1,0.1-0.1c0.2-0.4,0.1-0.7-0.3-0.9c-0.1-0.1-0.3-0.1-0.3-0.3c0-0.2,0-0.4,0.2-0.5
		c0.1-0.1,0.2-0.2,0.3-0.2c0.3-0.2,0.6-0.3,0.9-0.4c0.3-0.2,0.6-0.4,0.7-0.8c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0.1-0.1
		c0.1-0.2,0.3-0.3,0.5-0.2c0.5,0.2,0.9,0.1,1.4,0.1c0.2,0,0.3-0.3,0.2-0.5c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0,0-0.1,0
		c-0.1,0-0.1,0-0.2,0c0.1-0.1,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.4,0.1,0.8,0,1-0.4c0.1-0.2,0.3-0.4,0.4-0.5
		c0.2-0.2,0.3-0.3,0.4-0.5c0.2-0.3,0.2-0.5,0-0.8c-0.1-0.2-0.2-0.4-0.2-0.6c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0,0.1,0,0.1,0
		c0,0,0,0,0,0c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0.1,0.5-0.1,0.5-0.3c0-0.3,0-0.5-0.2-0.6c-0.2-0.1-0.4-0.1-0.5-0.2c0,0-0.1,0-0.1-0.1
		c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.3-0.1,0.4-0.1c0.4,0.1,0.8,0.1,1.3,0c0.3,0,0.4-0.1,0.5-0.4c0-0.1,0.1-0.2,0.1-0.3
		c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0.6,0.3,1,0.2,1.5-0.3c0.5-0.6,1-1.1,1.7-1.5c0.3-0.2,0.7-0.3,0.9-0.6
		c0.1-0.2,0.4-0.3,0.6-0.4c0.7-0.2,1.3-0.7,2.1-0.8c0.6-0.4,1.3-0.8,1.9-1.2c0.1-0.1,0.1-0.2,0.2-0.4c0-0.2-0.1-0.2-0.4-0.3
		c-0.2-0.1-0.3-0.1-0.5-0.2c0,0,0,0,0,0l0,0.1l0-0.1c0,0,0,0,0,0c0.1-0.2,0.1-0.3,0.4-0.3c0.7,0,1.4-0.1,2.1-0.2
		c0.7-0.3,1.3-0.7,2-1c0.1,0,0.2-0.1,0.2-0.1c0.2-0.2,0.3-0.3,0.4-0.6c0.1-0.3,0.1-0.6,0.1-0.8c0-0.3-0.1-0.6-0.4-0.7
		c-0.2-0.1-0.3-0.2-0.3-0.4c0-0.3,0-0.7,0-1c0-0.1-0.1-0.2-0.1-0.2c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3-0.1-0.5,0-0.8,0.1
		c-0.1,0.1-0.2,0.1-0.3,0.1c0,0,0,0-0.1,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0c0-0.1,0.1-0.1,0.2-0.1
		c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1-0.2,0.3-0.3,0.4-0.5c0.2-0.2,0.2-0.3,0-0.4c-0.1-0.1-0.2-0.1-0.2-0.2c0,0-0.1-0.1-0.1-0.1
		c-0.4,0-0.7-0.3-1-0.4c-0.3,0-0.4-0.2-0.5-0.4c-0.1-0.2-0.2-0.3-0.4-0.3c-0.4-0.1-0.8-0.1-1.1-0.2c-0.4-0.2-0.7-0.1-1.1,0.1
		c-0.2,0.2-0.4,0-0.4-0.3c0-0.1,0-0.2,0-0.2c-0.5-0.1-1-0.3-1.5-0.4c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0.2-0.4,0.4c0,0.3-0.1,0.7-0.1,1
		c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.2-0.1c0-0.1,0-0.3,0-0.4c0,0,0-0.1,0-0.1c-0.1-0.1-0.2-0.2-0.2-0.2c-0.4,0.1-0.6-0.2-0.8-0.5
		c-0.2-0.2-0.4-0.2-0.7-0.1c-0.2,0.1-0.4,0.3-0.4,0.6c0,0.2,0.1,0.4,0.1,0.6c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0
		c-0.1-0.2-0.2-0.5-0.4-0.7c-0.2-0.3-0.5-0.2-0.7,0.1c-0.2,0.3-0.2,0.3-0.4,0.2c0,0-0.1,0-0.1-0.1c-0.3-0.1-0.5,0.1-0.5,0.3
		c0.1,0.4,0,0.6-0.3,0.8c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.2-0.5,0.2-0.6,0c-0.2-0.4-0.3-0.8-0.6-1.1c-0.1-0.1-0.3-0.2-0.5-0.2
		c-0.2,0-0.4,0.1-0.4,0.3c0,0.3-0.1,0.4-0.4,0.5c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.2c0.1,0.3,0.3,0.7,0.4,1c0,0,0,0,0,0
		l0,0.1l-0.1-0.1c0,0,0,0,0,0c-0.2,0-0.4,0-0.6-0.2c-0.1-0.2-0.3-0.3-0.5-0.2c-0.2,0.1-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.2-0.2-0.3
		c-0.2-0.2-0.3-0.2-0.5-0.1c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0.1-0.2,0.1-0.3,0c-0.2-0.2-0.4-0.2-0.6-0.2c-0.5,0.1-1,0.3-1.5,0.6
		c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.1,0.6,0.1,0.7c0.1,0,0.2,0.1,0.2,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1-0.1,0.1-0.2,0.2-0.2
		c0,0,0.1,0,0.1,0c0.1,0.1,0.1,0.2,0,0.2c-0.1,0-0.2,0-0.3,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0.2,0,0.3,0.1,0.5
		c0.1,0.3,0.1,0.3,0,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.3-0.1,0.6,0.2,0.8c0.1,0,0.1,0.1,0.2,0.1c0,0-0.1,0-0.1,0.1
		c-0.1,0-0.2,0-0.3,0c-0.3,0.1-0.4,0.3-0.3,0.5c0.1,0.4,0.2,0.7,0.5,1c0.3,0.3,0.5,0.3,0.9,0.3c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1
		c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0.1,0.2,0.1,0.3,0.2,0.5c0.4,0.4,0.8,0.6,1.3,0.7c0.4,0.1,0.7-0.1,1-0.3c0,0,0,0,0,0
		c0,0,0.1-0.1,0,0c0,0,0,0,0,0c0,0.2,0,0.4,0.2,0.5c0.5,0.4,1.1,0.5,1.7,0.4c0.6-0.1,1.3-0.2,1.7-0.9c0.1-0.2,0.2-0.2,0.4-0.2
		c0,0,0,0,0,0l0,0c0,0,0,0-0.1,0c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1-0.1,0.1c-0.1,0.2,0,0.4,0.2,0.4c0.3,0.1,0.6,0,0.8,0.3
		c0,0,0,0,0.1,0l0,0.1l-0.1,0c0,0,0,0,0,0c-1-0.1-1.9-0.1-2.9,0.4c-0.4,0.2-0.8,0.2-1.3,0c-0.3-0.1-0.6-0.1-0.8-0.1
		c-0.2,0-0.4,0.2-0.4,0.3c0,0.4-0.1,0.8-0.1,1.1c0,0.1-0.1,0.2-0.1,0.1c0,0-0.1-0.1-0.1-0.1c-0.1-1-0.7-1.6-1.5-2.1
		c0,0-0.1,0-0.1-0.1c-0.2-0.1-0.5-0.1-0.7,0.1c-0.3,0.3-0.6,0.6-0.8,0.9C154.7,45.7,154.8,45.9,154.7,46.1"
            />
            <path
              fill="#003046"
              d="M142.5,72.8l0.1,0L142.5,72.8L142.5,72.8z M147.7,71c0,0,0.1-0.1,0.1-0.1C147.8,70.9,147.8,71,147.7,71
		C147.7,71,147.6,71.1,147.7,71 M156.6,78c0.1,0,0.2,0,0.2,0.1c0,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0l-0.1,0.1l0-0.1c0,0,0,0,0,0
		c0-0.1-0.1-0.2-0.1-0.3C156.5,78,156.6,78,156.6,78 M150,90.4c-0.1,0-0.2,0-0.3-0.1c0,0,0-0.1,0-0.1c0.1-0.3,0.3-0.5,0.4-0.7
		c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.3,0.1,0.3C150.5,90.1,150.3,90.3,150,90.4 M142.7,76.7
		c0.6,0.1,1,0.6,1.6,0.4c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0.2,0.2,0.4,0.4,0.7,0.5c0.1,0.1,0.3,0.1,0.4,0.1
		c0.1,0,0.2-0.1,0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0,0,0,0,0,0c-0.2,0-0.4,0-0.5-0.2c0,0,0,0-0.1,0l-0.1-0.1l0.1,0c0,0,0,0,0,0
		c0.2,0,0.4,0,0.5,0.2c0,0,0,0,0.1,0c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0,0,0,0c0.2,0,0.4-0.1,0.7-0.1c0.4-0.1,0.6-0.4,0.6-0.8
		c0-0.2,0-0.3-0.1-0.5c0,0,0,0,0,0c0,0,0,0,0-0.1c-0.2-0.2-0.2-0.3,0-0.5c0,0,0,0,0,0c0-0.1,0-0.1,0.1,0c0,0,0,0-0.1,0.1
		c0,0,0,0,0,0c0,0.2,0,0.3,0,0.5c0,0,0,0,0,0c0,0,0,0,0,0c0.3,0.1,0.6,0.2,0.9,0.4c0.1,0,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1
		c-0.1,0.2-0.2,0.5-0.2,0.7c0,0.2,0.3,0.4,0.3,0.6c0.1,0.5,0.3,0.9,0.6,1.2c0.1,0.1,0,0.3-0.1,0.3c-0.1,0.1-0.3,0.1-0.4,0.2
		c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.1,0.2c0.4,0.1,0.7-0.2,1.1,0c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0,0.2-0.1,0.2
		c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0.1-0.1,0.3,0,0.4c0.1,0.1,0.2,0.1,0.2,0.1c0.1,0,0.2,0,0.3-0.1c0.4-0.1,0.7,0,0.8,0.4
		c0,0.1,0.1,0.2,0.2,0.2c0.5,0.2,0.5,0.5,0.4,0.9c-0.1,0.5-0.1,0.9-0.1,1.4c0,0.2-0.1,0.4-0.3,0.4c-0.3,0.1-0.5,0.2-0.7,0.5
		c-0.3,0.4-0.7,0.7-1.1,0.9c-0.8,0.5-1,0.6-0.5,1.7c0,0.1-0.1,0.2-0.1,0.2c-0.5,0-0.9,0.1-1.4,0.3c-0.5,0.2-0.9,0.1-1.4-0.1
		c-0.2-0.1-0.5-0.3-0.8-0.4c-0.1,0-0.2,0-0.3,0.1c-0.2,0.3-0.5,0.6-0.8,0.8c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.2-0.1,0.6,0.1,0.8
		c0.5,0.5,1,0.8,1.6,0.4c0.2-0.1,0.4-0.1,0.5-0.2c0.1,0,0.2,0,0.3,0c0.5,0.3,1,0.1,1.5,0.2c0.3,0,0.4,0.2,0.6,0.4
		c0.2,0.4,0.4,0.9,0.7,1.2c0.2,0.3,0.3,0.4,0.1,0.7c-0.1,0.2,0,0.4,0.1,0.5c0.3,0.4,0.6,0.7,1,0.9c0,0,0,0,0,0c0,0,0,0,0,0l0,0
		c0.3,0.1,0.5,0.4,0.8,0.6c0.4,0.4,0.8,0.8,1.4,0.9c0.3,0.1,0.5,0.3,0.7,0.4c0.3,0.1,0.5,0.2,0.8,0.3c0.1,0,0.2-0.1,0.2-0.1
		c0.1-0.3,0.2-0.5,0-0.8c-0.4-0.7-0.7-1.4-1.4-2c-0.2-0.2-0.3-0.5-0.2-0.7c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0-0.1,0-0.1-0.1
		c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0.4,0.2,0.8,0.4,1.1,0.8c0.2,0.3,0.5,0.5,0.9,0.7c0.3,0.1,0.4,0.3,0.6,0.6
		c0.1,0.3,0.3,0.5,0.4,0.7c0,0.1,0.2,0.1,0.2,0.1c0.1,0,0.1-0.1,0.1-0.2c-0.2-0.4-0.2-0.7,0.1-1.1c0.2-0.2,0.3-0.5,0.5-0.6
		c0.2-0.1,0.2-0.4,0.2-0.6c0-0.1,0-0.3-0.1-0.4c-0.2-0.5-0.3-1-0.3-1.5c0-0.3-0.1-0.5-0.4-0.7c-0.3-0.2-0.5-0.4-0.8-0.6
		c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.4-0.1-0.8-0.4-1.2c0-0.1,0-0.2-0.1-0.2c0,0,0,0,0,0l0-0.1l0.1,0c0,0,0,0,0,0c0.1,0,0.2,0,0.2,0
		c0.4,0,0.5,0,0.6-0.5c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.3-0.1,0.4,0c0.2,0.3,0.4,0.5,0.6,0.8c0.3,0.3,0.4,0.7,0.5,1.2
		c0,0.2,0.1,0.4,0.1,0.6c0,0.1,0.1,0.2,0.1,0.2c0.3,0.3,0.6,0.5,0.9,0.6c0.1,0,0.2-0.1,0.2-0.1c0.1-0.3,0.2-0.5,0.2-0.8
		c0-0.2,0.1-0.3,0.4-0.4c0.1,0,0.3-0.1,0.4-0.1c0.6-0.2,0.9-1,1.5-1.3c0.2-0.1,0.3-0.3,0.4-0.6c0.1-0.1,0.1-0.3,0-0.4
		c-0.1-0.3-0.3-0.6-0.7-0.7c-0.2,0-0.4-0.1-0.5-0.2l0,0v-0.1c0,0,0,0,0,0c-0.1-0.1-0.1-0.2,0-0.3c0,0,0,0,0,0c0,0,0,0,0-0.1l0,0
		c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3-0.3-0.2-0.4c0.2-0.4,0.2-0.5-0.1-0.6
		c-0.2-0.1-0.5-0.3-0.5-0.6c-0.3-0.1-0.5-0.2-0.8-0.3c-0.2-0.1-0.4-0.3-0.4-0.5c-0.1-0.5-0.4-0.7-0.9-0.8c-0.2,0-0.2-0.2-0.1-0.3
		c0.3-0.3,0.6-0.6,0.5-1.1c0-0.1,0.1-0.1,0.2-0.1c0.2,0.1,0.4,0.2,0.7,0.2c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0-0.3,0-0.4
		c0,0,0-0.1-0.1-0.1c-0.2-0.2-0.3-0.4-0.1-0.7c0.2-0.2,0.3-0.5,0.1-0.8c-0.1-0.4-0.4-0.8-0.4-1.2c-0.2-0.7-0.5-1.1-1.2-1.2
		c-0.3-0.1-0.5-0.2-0.6-0.5c0-0.1-0.2-0.2-0.3-0.2c0,0,0,0,0,0l-0.1,0.1l0.1-0.1c0,0,0,0,0,0c0.1-0.3,0.1-0.6,0-1
		c-0.1-0.2-0.3-0.4-0.4-0.6c-0.2-0.2-0.4-0.3-0.8-0.1c-0.3,0.2-0.4,0.1-0.5-0.1c-0.1-0.4-0.4-0.3-0.7-0.3c0,0-0.1,0-0.1,0
		c-0.1-0.1-0.1-0.1,0-0.2c0.1-0.1,0.2-0.3,0.3-0.5c0-0.1,0-0.2,0-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.2,0.1-0.3,0.1-0.5
		c0-0.3-0.1-0.5-0.3-0.6c-0.4-0.3-0.9-0.7-1.3-1c0,0,0,0,0,0c0,0,0,0,0,0l0,0c-0.5-0.1-0.8,0.1-1.2,0.3c-0.4,0.2-0.8,0.2-1.1,0.1
		c-0.3-0.2-0.6-0.1-0.8,0.1c0,0,0,0,0,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1,0c0.1-0.2,0.1-0.5,0.2-0.7
		c0.2-0.6,0.4-1.2,0.4-1.9c0-0.2,0.1-0.3,0.2-0.5c0-0.1,0-0.2,0-0.3c0-0.6-0.2-0.6-0.7-0.8c-0.2-0.1-0.5,0-0.7,0
		c-0.6,0.1-1.1,0.5-1.6,0.4c-0.5,0-0.9,0.2-1.2,0.6c-0.2,0.2-0.4,0.3-0.5,0.5c-0.4,0.5-0.6,1.1-0.4,1.7c0,0.1,0.1,0.3-0.1,0.3
		c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.3,0.3-0.3,0.6c0.1,0.3,0.2,0.5,0.3,0.8c0,0.1,0,0.2,0,0.3c0,0,0,0.1-0.1,0.1
		c-0.1,0-0.2,0.1-0.2,0c-0.4-0.4-0.6-1.2-0.5-1.7c0,0,0-0.1,0.1-0.1c0.1-0.2,0.1-0.4,0.3-0.5c0.3-0.3,0.3-0.7,0.3-1.1
		c0-0.2,0.1-0.5,0.3-0.6c0.1-0.1,0.3-0.2,0.4-0.4c0.5-0.6,1.1-1.1,1.9-1.5c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.2,0-0.3
		c-0.1-0.1-0.3-0.3-0.4-0.4c-1-0.4-1.9-0.2-2.7,0.3c-0.4,0.2-0.8,0.6-1.1,0.9c-0.6,0.4-0.8,1.2-1.5,1.5c-0.1,0-0.1,0.1-0.2,0.2
		c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.2-0.1,0.4-0.3,0.5c-0.6,0.5-0.7,1.2-1,1.8c-0.2,0.3,0,0.7,0.3,0.8c0.3,0.2,0.7,0.3,1.1,0.4
		c0.1,0.1,0.2,0.2,0.3,0.3c0,0.1,0,0.2,0,0.2c-0.1,0.1-0.2,0.1-0.2,0.1c-0.3-0.1-0.5-0.3-0.7-0.4c-0.4-0.2-0.8,0.1-0.8,0.5
		c0,0.2,0,0.5,0,0.7c0,0.4,0.3,0.6,0.5,0.9c0.2,0.2,0.4,0.2,0.6,0.2c0.1,0,0.3,0,0.4,0.1c0.3,0.1,0.4,0.3,0.4,0.5
		c0.2,0.3,0.5,0.5,0.9,0.4c0.3-0.1,0.5,0.1,0.5,0.4c0.3,0.1,0.6,0.1,0.9,0.3C142.4,76.7,142.5,76.6,142.7,76.7"
            />
            <path
              fill="#003046"
              d="M117.9,60.7C118,60.7,118,60.7,117.9,60.7L117.9,60.7C117.9,60.7,117.9,60.7,117.9,60.7
		C117.9,60.7,117.9,60.7,117.9,60.7"
            />
            <path
              fill="#003046"
              d="M117.9,60.7c-0.2,0.1-0.4,0.2-0.6,0.2c-0.3,0-0.6,0-0.8,0.1c0,0.1-0.1,0.1-0.1,0.2
		c-0.6,0.4-1.3,0.6-1.9,0.9c-0.3,0.1-0.6,0.3-0.6,0.7c0,0.2-0.2,0.2-0.3,0.3c-0.2,0.1-0.4,0.2-0.6,0.4c-0.3,0.2-0.6,0.4-0.6,0.8
		c0,0.2,0.1,0.4,0.2,0.7c0,0,0,0.1,0,0.1c0.1,0.2,0.2,0.3,0.4,0.3c0.4,0.1,0.8,0.1,1.3,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.2-0.1,0.2
		c-0.5,0-0.9,0.1-1.4,0.1c-0.3,0-0.6,0.1-0.8,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.2,0,0.4,0,0.6c0,0.5,0.1,0.7,0.8,0.8
		c0.8,0.2,1.6,0.4,2.4,0.4c0.1,0,0.3,0,0.4,0.1c0.5,0.4,1.1,0.8,1.4,1.4c0.1,0.1,0.1,0.2,0,0.2c0,0-0.1,0-0.1,0
		c-0.7-0.1-1.4-0.3-2-0.6c-0.3-0.1-0.5-0.3-0.8-0.3c-0.7-0.1-1.3-0.1-2,0c-0.3,0-0.5,0.2-0.6,0.5c-0.2,0.4-0.2,0.7-0.1,1.1
		c0.1,0.7,0.4,1.2,1.1,1.4c0.3,0.1,0.6,0.2,0.9,0.3c0.1,0,0.2,0.2,0.2,0.3c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.1-0.1,0.3-0.1,0.4
		c0,0.3,0.1,0.6,0.2,0.8c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.3,0,0.4,0c0.7,0.1,1.3,0,2,0.2c0.4,0.1,0.7,0.1,1.1-0.1
		c0.2-0.1,0.4-0.2,0.6-0.3c0.3-0.2,0.6-0.2,1-0.2c0.4,0.1,0.7,0,1.1-0.3c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2,0,0.2,0.1
		c0,0,0.1,0.1,0.1,0.1c0.1,0.3,0.3,0.4,0.5,0.5c0.3,0.2,0.2,0.5,0.4,0.7c0,0,0.1,0.1,0.1,0.1c0.5,0.1,0.9,0.3,1.4,0.4
		c0.5,0.1,0.9,0.1,1.4,0c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.5,0.2-0.9-0.2-1.3c0,0,0,0-0.1,0c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.1,0-0.1,0
		c-0.1-0.1-0.1-0.1,0.1-0.2c0.1,0,0.2,0,0.4,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3-0.1,0.4,0
		c0.2,0.2,0.6,0.3,0.7,0.6c0.4-0.1,0.7-0.3,0.8-0.7c0.1-0.3,0.1-0.6-0.1-0.8c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.2-0.3-0.5-0.4-0.7
		c0-0.4-0.2-0.6-0.5-0.8c-0.3-0.2-0.3-0.7-0.5-1c-0.1-0.2-0.1-0.5,0.1-0.7c0.5-0.4,0.6-0.9,0.6-1.5c0-0.2,0-0.5,0.1-0.7
		c0.2-0.7,0.5-1.3,0.8-2c0.1-0.2,0.2-0.4,0.4-0.5c0.6-0.3,1.1-0.8,1.6-1.2c0.1-0.1,0.2-0.3,0.2-0.5c0-0.5-0.1-0.8-0.6-0.9
		c-0.3-0.1-0.5-0.2-0.8-0.2c-0.4-0.1-0.6,0.1-0.9,0.4c-0.2,0.2-0.1,0.4-0.1,0.7c0.1,0.3,0.1,0.6,0.1,0.9c0,0,0,0,0,0.1l0,0.1l-0.1,0
		c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.4-0.5-0.5c-0.1,0-0.3-0.1-0.4-0.1c-0.4-0.2-0.6,0-0.8,0.3
		c-0.1,0.2-0.3,0.4-0.3,0.6c-0.2,0.6-0.3,1.2-0.6,1.7c-0.3,0.6-0.3,1.2-0.6,1.8c0,0,0,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1
		c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.3-0.2-0.2-0.3c0.1-0.5,0.3-1,0.5-1.5c0.3-0.7,0.3-1.4,0.1-2.1c0-0.1-0.1-0.2-0.2-0.3
		c-0.1-0.1-0.4-0.1-0.5,0.1c-0.1,0.3-0.1,0.6-0.2,1c0,0.2-0.1,0.3-0.3,0.2c-0.4-0.1-0.8-0.1-1.3-0.1c-0.1,0-0.2,0-0.1-0.1
		c0,0,0.1,0,0.1-0.1c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.2-0.4,0.1-0.5c-0.2-0.4-0.4-0.6-0.7-0.9c-0.3-0.3-0.6-0.3-0.9-0.1
		c-0.2,0.1-0.3,0.3-0.4,0.4c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.1-0.1,0-0.3c0.2-0.3,0.4-0.6,0.6-0.9c0.1-0.2,0-0.6-0.2-0.8
		c-0.1-0.1-0.2-0.1-0.2-0.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0C118.6,60.7,118.2,60.7,117.9,60.7"
            />
            <path
              fill="#003046"
              d="M396.3,196.8c0,0,0-0.1-0.1-0.1c-0.2-0.2-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.5-0.7
		c-0.1-0.2-0.4-0.3-0.5-0.5c-0.2-0.2-0.4-0.4-0.5-0.7c-0.1-0.2-0.1-0.4,0.1-0.5c0.1-0.1,0.3-0.1,0.4-0.2c0.2-0.2,0.2-0.6-0.1-0.7
		c-0.1-0.1-0.2-0.1-0.4-0.2c-0.3-0.1-0.6-0.3-0.9-0.4c-0.5-0.1-0.7-0.4-0.8-0.9c0-0.2-0.2-0.3-0.3-0.4c-0.9-0.7-1.9-1.2-3-1.5
		c-0.5-0.1-1-0.3-1.5-0.5c-0.5-0.2-1-0.5-1.6-0.6c-0.8-0.2-1.6-0.5-2.3-0.8c-0.7-0.3-1.3-0.5-1.7,0.3c0,0-0.1,0.1-0.1,0.1
		c-0.7,0.3-1.2,0.8-1.6,1.4c-0.1,0.1-0.4,0.1-0.5,0c-0.3-0.3-0.5-0.5-0.8-0.8c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0-0.4,0.1-0.6
		c0.1-0.4-0.1-0.7-0.2-0.9c-0.1-0.2-0.4-0.2-0.6-0.3c-0.3-0.1-0.5-0.2-0.8-0.3c-0.3-0.1-0.6-0.1-0.8,0c-0.5,0.3-1,0.6-1.5,0.7
		c-0.1,0-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.1,0.2c0.2,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.5,0.2,0.6,0.4c0.2,0.4,0.6,0.6,1.1,0.6
		c0.3,0,0.6,0,0.9,0c0.1,0,0.1,0.1,0.2,0.1c0,0-0.1,0.1-0.1,0.1c-0.5,0-1,0.4-1.5,0.3c-0.1,0-0.2,0.1-0.1,0.2
		c0.3,0.4,0.5,0.8,0.7,1.2c0.1,0.2,0.4,0.3,0.6,0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.4-0.1c0.4,0.2,0.8,0.4,1.1,0.6
		c0.2,0.2,0.5,0.3,0.8,0.4c0.5,0.1,0.9,0.3,1.4,0.4c0.3,0.1,0.5,0.2,0.8,0.3c0.5,0.3,1,0.6,1.1,1.2c0.1,0.2,0.2,0.4,0.3,0.6
		c0.1,0.2,0.2,0.3,0.2,0.5c0.1,0.2,0,0.3-0.1,0.3c-0.5,0.1-0.7,0.4-0.9,0.8c-0.1,0.3,0,0.5,0.2,0.5c0.4,0,0.8-0.1,1.3-0.2
		c0.5-0.1,0.9,0,1.3,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.2,0.5,0.3,0.7,0.4c0.6,0,1.1,0,1.7,0.2c0.2,0.1,0.5,0,0.7-0.1
		c0.3-0.1,0.4-0.3,0.3-0.7c0-0.1-0.1-0.2,0-0.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.6-0.2,1-0.1c0.3,0.1,0.6,0.3,0.9,0.4
		c0.3,0.1,0.5,0.3,0.6,0.5c0.4,0.5,0.8,1,1.2,1.6c0.3,0.4,0.7,0.5,1.1,0.5c0.8,0,1.5,0.1,2.2,0.5c0,0,0.1,0,0.1,0
		c0.1,0,0.2,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2c0-0.2-0.1-0.4-0.3-0.4c-0.4-0.1-0.3-0.4-0.3-0.7
		C396.8,197.2,396.5,197.2,396.3,196.8"
            />
            <path
              fill="#003046"
              d="M348.7,185.1c0.2,0.5,0.3,1,0.4,1.5c0.1,0.3,0.2,0.6,0.6,0.7c0.1,0,0.2,0.1,0.2,0.2c0,0.4,0.2,0.7,0.2,1.1
		c0.1,0.4,0.3,0.6,0.7,0.6c0.2,0,0.5,0.1,0.7,0c0.3,0,0.4,0.1,0.6,0.3c0.1,0.2,0.3,0.3,0.6,0.2c0.3-0.1,0.5-0.2,0.8-0.2
		c0.1,0,0.2-0.1,0.3,0c0.4,0.1,0.8,0.3,1.2,0.5c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.3,0.3,0.5,0.2c0.3-0.1,0.5-0.2,0.8-0.3
		c0.1,0,0.3,0,0.4,0.1c0.2,0,0.4-0.1,0.4-0.3c0,0,0-0.1,0-0.1c-0.1-0.3-0.2-0.6,0.1-0.9c0.1-0.2,0.2-0.5,0.1-0.7
		c-0.1-0.4,0.1-0.8,0.3-1c0.4-0.4,0.6-1,0.8-1.5c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.2,0.2-0.3,0.4-0.3c0.1,0,0.3,0,0.4,0
		c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.3,0.1-0.4c-0.2-0.4-0.6-0.5-0.8-0.8c-0.1-0.2-0.2-0.3-0.1-0.5c0.1-0.2,0.1-0.4-0.1-0.5
		c-0.5-0.5-0.4-1-0.1-1.6c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.3-0.3,0.3-0.5c0-0.2,0.1-0.3,0.4-0.4c0.2,0,0.4-0.1,0.5-0.3
		c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c-0.3-0.2-0.7-0.4-1-0.6c-0.3-0.2-0.7-0.3-0.7-0.8c-0.2-0.2-0.4-0.4-0.6-0.6c-0.1-0.1-0.3,0-0.4,0
		c-0.2,0.1-0.4,0.2-0.6,0.4c-0.1,0.2-0.3,0.5-0.4,0.8c-0.1,0.2-0.2,0.3-0.3,0.4c-0.3,0.3-0.7,0.5-1.1,0.7c-0.6,0.2-1,0.6-1.2,1.1
		c-0.1,0.3-0.4,0.5-0.5,0.7c-0.3,0.5-0.7,0.8-1.3,0.9c-0.5,0.1-0.8,0.4-1,0.9c-0.1,0.2-0.1,0.4-0.2,0.5c-0.1,0.2-0.3,0.3-0.5,0.2
		c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3-0.1-0.5-0.1-0.7,0.2c-0.1,0.1-0.2,0.2-0.2,0.4C348.8,184.3,348.6,184.7,348.7,185.1"
            />
            <path
              fill="#003046"
              d="M277.6,199.8c-0.1-0.1-0.2-0.1-0.2,0c-0.2,0.2-0.3,0.4-0.4,0.7c0,0.3-0.2,0.5-0.4,0.7
		c0,0.1-0.1,0.2-0.1,0.2c-0.3,0.2-0.6,0.3-0.6,0.7c0,0.4-0.3,0.7-0.8,1c-0.4,0.2-0.6,0.6-1,0.8c-0.4,0.2-0.8,0.3-1.2,0.4
		c0,0-0.1,0-0.1,0c-0.4,0.2-0.8,0.3-1.2,0.5c-0.1,0.3-0.3,0.6-0.4,0.9c-0.4,0.6-0.3,1.2-0.1,1.8c0.1,0.2,0.1,0.4,0.2,0.5
		c0.2,0.5,0.2,1-0.2,1.5c-0.3,0.5-0.6,1-0.9,1.4c-0.4,0.5-0.6,1.2-0.3,1.9c0.1,0.3,0.2,0.6,0.2,1c0,0.3,0,0.6,0,0.9
		c0,0.2,0.1,0.4,0.2,0.5c0.3,0.6,0.9,0.8,1.5,1.1c0.3,0.1,0.6-0.1,0.9-0.3c0.2-0.1,0.5-0.1,0.7-0.1c0.5,0,0.9-0.3,1.1-0.8
		c0.2-0.3,0.3-0.7,0.5-1c0.3-0.8,0.6-1.7,0.9-2.5c0.5-1.2,0.9-2.5,1.4-3.7c0.2-0.3,0.2-0.7,0.3-1.1c0.1-0.5,0.2-0.9,0.4-1.4
		c0.2-0.4,0.1-0.9,0.6-1c0.1-0.2,0.2-0.4,0.2-0.7c0-0.4-0.1-0.9-0.1-1.3C278.5,201.3,278.1,200.5,277.6,199.8"
            />
            <path
              fill="#003046"
              d="M265.9,72.2c-0.4,0.4-0.3,1-0.6,1.4c-0.1,0.2-0.1,0.4,0.1,0.6c0.1,0.1,0.2,0.2,0.3,0.3
		c0.2,0.3,0.1,0.7-0.2,0.9c-0.2,0.1-0.4,0.2-0.5,0.5c-0.1,0.3-0.1,0.6,0.1,0.8c0.1,0.3,0.3,0.5,0.6,0.6c0.1,0.1,0.3,0.1,0.4,0.1
		c0.2,0,0.4-0.1,0.6-0.1c0.3-0.1,0.6,0.1,0.7,0.3c0.1,0.1,0.2,0.3,0.2,0.5c0,0.5,0.3,0.6,0.7,0.8c0.2,0.1,0.5,0.1,0.7,0.2
		c0.2,0.1,0.4,0.2,0.7,0.1c0.2,0,0.4,0,0.6,0c0,0,0,0,0,0c0.3,0,0.6,0,0.9,0c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.3,0.1-0.4
		c0.1-0.2,0-0.4-0.2-0.5c-0.3-0.2-0.6-0.3-0.9-0.5c-0.8-0.5-1.3-1.3-1.6-2.1c-0.1-0.4-0.2-0.8-0.3-1.2c-0.1-0.6,0-1.2,0-1.8
		c0-0.3,0.2-0.5,0.3-0.8c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.8,0.2-1.6,0.3-2.4c0-0.4,0.1-0.7,0.4-0.9c0.4-0.3,0.7-0.6,0.9-1.1
		c0.2-0.6,0.6-1.1,0.9-1.6c0.3-0.5,0.7-0.9,1.1-1.3c0.5-0.4,1-0.9,1.6-1.2c0.2-0.1,0.4-0.4,0.7-0.5c0.5-0.3,0.7-0.8,0.4-1.4
		c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.2-0.2-0.3-0.3-0.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.3-0.7-0.3-1.1-0.1c-0.5,0.3-1,0.6-1,1.3
		c0,0.4-0.3,0.7-0.7,0.9c-0.4,0.3-0.9,0.6-1.4,0.6c-0.9,0-1.4,0.5-1.8,1.1c0,0-0.1,0.1-0.1,0.1c-0.1,0.2-0.3,0.4-0.5,0.4
		c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.2,0.4-0.1,0.7c0,0.1-0.1,0.2-0.1,0.2c0,0-0.1,0.1-0.1,0.1
		c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.3,0.2-0.5,0.2c-0.1,0-0.2,0.2-0.2,0.3c0.2,0.3,0.4,0.6,0.5,0.9c0.1,0.4-0.1,0.7-0.1,1.1
		c0,0.3-0.1,0.5-0.2,0.7c-0.2,0.3-0.3,0.6-0.5,0.9c-0.1,0.2-0.1,0.4,0.1,0.6C266.1,71.8,266.2,71.9,265.9,72.2"
            />
            <path
              fill="#003046"
              d="M378.3,126.8L378.3,126.8L378.3,126.8L378.3,126.8L378.3,126.8z M370.9,144c0,0,0.1,0,0.1-0.1
		c0.1-0.2,0.3-0.4,0.3-0.6c0.1-0.7,0.2-1.5,0.2-2.3c-0.2-0.5-0.3-1.1-1-1.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1
		c0.1,0,0.2-0.1,0.3-0.1c0.3,0,0.6,0,0.7-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.4-0.1,0.8-0.2,1.2-0.1c0,0,0,0,0,0l0-0.1l0,0.1c0,0,0,0,0,0
		c-0.2,0.2-0.3,0.4-0.6,0.4c-0.1,0-0.2,0-0.3,0c-0.3,0.1-0.5,0.5-0.4,0.8c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0.1,0.1,0.1,0.1
		c0.2,0.2,0.6,0.2,0.7-0.1c0.1-0.2,0.1-0.5,0.3-0.6c0.2-0.2,0.5-0.1,0.7,0c0,0,0.1,0,0.1,0c0.3-0.1,0.5-0.7,0.3-1
		c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.1,0-0.3,0.1-0.4c0.1-0.1,0.2-0.1,0.4-0.2c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0,0.2
		c-0.1,0.1-0.1,0.3-0.1,0.4c0.1,0.3,0.2,0.5,0.4,0.8c0.1,0.2,0.3,0.3,0.5,0.4c0.1,0.1,0.4,0,0.5-0.1c0.3-0.5,0.6-1,0.9-1.5
		c0.1-0.1,0.2-0.2,0.3-0.2c0.2,0,0.5-0.1,0.7-0.1c0.3,0,0.5-0.2,0.7-0.4c0,0,0.2,0.1,0.2,0.1c0.2,0,0.3,0,0.4-0.1
		c0.1-0.3,0.1-0.7,0.4-0.9c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.2,0.2,0.3,0.2
		c0.6-0.3,0.9-1,0.6-1.5c-0.2-0.4-0.4-0.9-0.3-1.4c0.1-0.7,0-1.4-0.3-2.1c-0.1-0.2,0-0.5,0.2-0.6c0.2-0.2,0.3-0.3,0.3-0.6
		c0-0.2,0-0.5,0-0.7c0-0.7-0.2-1.4-0.5-2c-0.4-0.7-1-1.3-1.3-2.1c-0.1-0.1-0.4-0.2-0.5,0c-0.6,0.6-0.9,1.2-0.6,2
		c0,0.4-0.2,0.8,0.2,1c0.1,0,0.1,0.2,0.1,0.3c0.1,1,0.2,2.1-0.4,3c-0.1,0.2-0.1,0.4-0.2,0.5c-0.1,0.6-0.6,0.9-1.2,1.1
		c-0.1,0-0.3-0.1-0.3-0.2c0-0.1,0-0.2,0-0.3c0-0.2-0.1-0.3-0.2-0.3c-0.1,0.1-0.3,0.1-0.3,0.2c-0.1,0.2,0,0.4,0,0.6
		c0,0.2,0,0.4,0,0.6c-0.2,0.5-0.3,1-0.4,1.5c0,0.1-0.1,0.2-0.2,0.2c-0.4,0-0.8-0.1-1.1,0.1c-0.5,0.2-0.9,0.3-1.4,0.3
		c-0.2,0-0.5,0.2-0.6,0.4c-0.4,0.6-1,1.2-1.5,1.7c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.3-0.1,0.4c-0.2,0.2-0.5,0.5-0.7,0.7
		c-0.2,0.2-0.2,0.4-0.2,0.7c0,0.3,0.1,0.4,0.3,0.6l0,0c0,0,0,0,0.1,0c0,0,0,0,0-0.1c0,0,0,0,0.1,0c0.2-0.1,0.4,0,0.4,0.2
		c0,0.2-0.1,0.3-0.2,0.4c0,0,0,0,0,0c0,0,0,0,0,0.1l0,0c0.2,0.5,0.3,1,0.5,1.5C370.1,144,370.7,144.1,370.9,144"
            />
            <path
              fill="#003046"
              d="M147.6,51.3c0.2,0.1,0.4,0.2,0.5,0.2c0.4-0.1,0.7,0,1,0.2c0.2,0.1,0.4,0.1,0.5-0.1c0.1-0.1,0.3-0.2,0.4-0.4
		l0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0.3-0.2,0.6-0.3,0.9-0.6c0.2-0.2,0.5-0.4,0.8-0.5c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.3,0.4-0.3,0.7-0.4
		c0,0,0,0,0,0v0c0-0.1,0-0.1,0.1,0c0,0-0.1,0-0.1,0.1c0,0.4,0,0.4,0.3,0.5c0.1,0,0.1,0,0.2,0c0.2-0.3,0.3-0.7,0.6-0.9
		c0.3-0.2,0.6-0.4,0.6-0.7c0-0.5,0-0.9-0.3-1.3c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.1-0.3,0-0.4c0.2-0.2,0.3-0.5,0.6-0.7
		c0-0.3-0.1-0.6,0-1c0.1-0.2,0.2-0.5,0.1-0.7c0-0.4-0.4-0.3-0.6-0.6c-0.2-0.2-0.5-0.3-0.7-0.5c-0.1-0.1-0.2-0.2-0.2-0.4
		c0-0.4,0-0.8,0-1.1c0-0.3,0.1-0.7,0.2-1c0.1-0.5-0.2-0.9-0.6-1.2c-0.3-0.3-0.6-0.2-0.9-0.2c-0.2,0-0.3,0.2-0.4,0.4
		c0,0.1,0.1,0.2,0.2,0.3c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0.1,0.1,0.2,0,0.2c0,0-0.1,0.1-0.1,0.1c-0.2,0-0.5,0-0.7,0
		c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.5,0.4-0.4,0.7c0,0.2,0.1,0.3,0.2,0.5c0,0.1,0,0.2-0.1,0.2c0,0-0.1,0-0.1,0
		c-0.2-0.2-0.4-0.3-0.6-0.1c-0.2,0.2-0.5,0.3-0.8,0.2c-0.3,0-0.4,0.2-0.3,0.5c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.2,0.3,0.4,0.3,0.7
		c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0,0.2,0c-0.1,0.1-0.2,0-0.2,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.3,0-0.5-0.2-0.7-0.3
		c-0.1-0.1-0.3,0-0.4,0.1c-0.4,0.7-0.6,1.6-1,2.4c-0.1,0.3,0,0.5,0.2,0.7c0.2,0.2,0.5,0.3,0.8,0.3c0.1,0,0.3,0.1,0.4,0.2
		c0.1,0,0.1,0.2,0.1,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2,0-0.3,0c-0.7-0.1-1,0.1-1.2,0.7c-0.2,0.5-0.2,0.9-0.3,1.4
		C146.7,50.4,147,50.9,147.6,51.3"
            />
            <path
              fill="#003046"
              d="M151.7,50.1C151.7,50.1,151.7,50.1,151.7,50.1C151.7,50.2,151.7,50.2,151.7,50.1
		C151.7,50.1,151.7,50.1,151.7,50.1C151.7,50.1,151.7,50.1,151.7,50.1"
            />
            <path
              fill="#003046"
              d="M345.4,189.2C345.4,189.2,345.4,189.2,345.4,189.2c-0.1-0.1-0.3-0.2-0.4-0.3c0,0,0-0.1,0-0.1
		c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0.1,0c0,0.1,0,0.1,0,0.2c0,0,0,0,0.1,0.1C345.2,189,345.3,189.1,345.4,189.2
		C345.4,189.2,345.4,189.2,345.4,189.2c0.1,0,0.3,0.1,0.4,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2-0.3,0.2-0.3c-0.3-0.4-0.5-0.9-0.8-1.3
		c0-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.3c-0.1,0.1-0.1,0.2-0.1,0.2c-0.1,0.2-0.4,0.1-0.5,0c-0.1-0.3-0.2-0.5-0.3-0.8
		c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.1-0.5-0.2c-0.1,0-0.2-0.2-0.2-0.2c0.2-0.2-0.1-0.5,0.1-0.8c0.1-0.1,0.1-0.3,0-0.4
		c-0.2-0.3-0.7-0.5-0.8-1c0-0.1-0.2-0.2-0.3-0.3c-0.2-0.1-0.5-0.2-0.8-0.4c-0.1-0.1-0.3-0.1-0.3-0.2c-0.1-0.4-0.5-0.4-0.8-0.6
		c-0.3-0.3-0.6-0.5-1-0.6c-0.2-0.1-0.4-0.2-0.5-0.5c-0.2-0.3-0.4-0.5-0.7-0.7c-0.8-0.4-1.5-1-2-1.8c-0.2-0.3-0.4-0.4-0.7-0.4
		c-0.6-0.1-1.2-0.1-1.8-0.4c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.3-0.1,0.5c0.1,0.2,0.2,0.4,0.3,0.6c0.4,0.4,0.8,0.8,1.3,1.1
		c0.3,0.2,0.5,0.4,0.7,0.6c0.2,0.2,0.4,0.4,0.5,0.7c0.3,0.5,0.7,0.9,1.1,1.3c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.4,0.9
		c0.1,0.2,0.2,0.3,0.3,0.5c0.5,0.5,0.9,1.1,1.1,1.8c0.1,0.3,0.3,0.6,0.4,0.9c0.1,0.3,0.3,0.6,0.5,0.8c0.4,0.5,0.9,0.8,1.3,1.3
		c0.4,0.5,0.9,0.9,1.3,1.3c0.4,0.3,0.8,0.6,1.1,0.9c0.1,0.2,0.3,0.2,0.5,0.2c0.5,0,1-0.1,1.5,0c0-0.5,0.1-1.1,0.1-1.6
		C345.1,190.4,345.4,189.8,345.4,189.2"
            />
            <path
              fill="#003046"
              d="M345.1,192.6C345.1,192.6,345.1,192.6,345.1,192.6C345.1,192.6,345.1,192.6,345.1,192.6
		C345.1,192.6,345.1,192.6,345.1,192.6C345.1,192.6,345.1,192.6,345.1,192.6"
            />
            <path
              fill="#003046"
              d="M107.9,63.2c0,0.4,0,0.8,0,1.1c0,0.1,0.2,0.3,0.3,0.3c0.5,0,1-0.2,1.5-0.5c0,0,0,0,0,0l0.1,0l0,0.1
		c0,0,0,0,0,0c0.1,0.2,0.3,0.3,0.6,0.3c0.2,0,0.5,0,0.7-0.2c0.1-0.1,0.3-0.3,0.4-0.4c0.2-0.2,0.3-0.5,0.5-0.7c0.3-0.3,0.5-0.6,1-0.6
		c0.2,0,0.4-0.2,0.6-0.3c0.2-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0.3-0.2,0.5-0.3c0.5-0.1,1-0.2,1.5-0.4c0.2-0.1,0.4-0.1,0.6-0.1l0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.2,0.5-0.3,0.8-0.3c0.2,0,0.4,0,0.6,0c0.2-0.2,0.5-0.3,0.8-0.3c0.1-0.7,0-1.5,0-2.2
		c0-0.4-0.3-0.7-0.5-1c-0.2-0.2-0.4-0.2-0.7-0.2c-0.4,0-0.7-0.1-1-0.4c-0.2-0.2-0.4-0.3-0.5-0.5c-0.3-0.5-0.8-0.7-1.3-0.6
		c-0.4,0.1-0.9,0-1.3,0c-0.2,0-0.5,0-0.7,0.1c-0.2,0-0.3,0.2-0.4,0.4c-0.1,0.3-0.1,0.6-0.1,0.8c0,0.3-0.1,0.5-0.4,0.7
		c-0.5,0.3-0.9,0.7-1.3,1.1c-0.2,0.2-0.4,0.4-0.4,0.7c0,0.1-0.1,0.2-0.1,0.2c-0.8,0.5-1.4,1.1-2.1,1.7c-0.1,0.1-0.3,0.2-0.4,0.4
		c-0.1,0.3,0.2,0.4,0.3,0.6C107.8,62.6,107.9,62.9,107.9,63.2"
            />
            <path
              fill="#003046"
              d="M212.9,108.7c0.3,0.4,0.4,0.7,0.3,1.2c0,0.1,0,0.3,0.2,0.4c0.2,0.1,0.2,0.3,0.1,0.5
		c-0.1,0.1-0.1,0.2-0.2,0.4c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.3c0.2,0.4,0.2,0.4,0.6,0c0,0,0.1-0.1,0.1-0.1
		c0,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.5-0.1,0.7c0,0.3,0.1,0.4,0.4,0.5c0,0,0.1,0,0.1,0c0.2,0,0.5-0.1,0.7,0c0.3,0.1,0.1,0.4,0.3,0.6
		c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.2,0.3,0.4,0.2,0.6c-0.1,0.7-0.3,0.8-1.1,0.7c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.1
		c0,0.4-0.1,0.8,0.3,1.1c0.1,0.1,0.1,0.4-0.1,0.5c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.1,0.3
		c0,0.1,0.1,0.2,0.2,0.2c0.6,0.1,1.1,0.3,1.6,0.6c0,0,0.1,0.1,0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.5-0.1-0.9,0.1-1.1,0.5
		c-0.3,0.5-0.7,0.8-1,1.2c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0,0.3,0,0.4-0.1c0.3-0.1,0.5-0.3,0.8-0.2c0.5,0.1,0.8,0,1.1-0.4
		c0.1-0.1,0.3-0.2,0.5-0.2c0.5,0,1,0,1.4-0.1c0.6-0.1,1.1-0.2,1.7-0.2c0.5,0,1-0.3,1.4-0.7c0.1-0.1,0.1-0.3,0-0.4
		c-0.1-0.1-0.2-0.1-0.2-0.1c-0.2-0.1-0.2-0.2-0.1-0.3c0.2-0.3,0.4-0.5,0.5-0.8c0.3-0.6,0-1.3-0.7-1.4c-0.2,0-0.5,0-0.7,0
		c0,0,0,0,0,0l0,0.1l0-0.1c0,0,0,0,0,0c0-0.1,0-0.3-0.1-0.4c-0.2-0.4-0.4-0.8-0.6-1.3c-0.1-0.3-0.3-0.5-0.5-0.7
		c-0.5-0.2-0.7-0.6-0.8-1.1c-0.2-0.7-0.6-1.2-1.3-1.4c-0.1,0-0.1-0.1-0.1-0.2c0.1-0.2,0.1-0.5,0.3-0.6c0.3-0.4,0.4-0.8,0.5-1.3
		c0.1-0.2-0.1-0.5-0.4-0.5c-0.1,0-0.2,0-0.3-0.1c-0.3,0-0.7,0-1,0c-0.1,0-0.1-0.1-0.2-0.2c0,0,0-0.1,0-0.1c0.2-0.2,0.4-0.4,0.5-0.7
		c0-0.1,0-0.2,0-0.3c0-0.1-0.2-0.2-0.3-0.2c-0.4,0-0.8,0-1.1,0.1c-0.2,0-0.3,0.2-0.4,0.3c-0.2,0.4-0.4,0.8-0.7,1.3
		c-0.1,0.1-0.1,0.1-0.2,0.1C212.8,108.2,212.6,108.5,212.9,108.7"
            />
            <path
              fill="#003046"
              d="M230.9,57.9L230.9,57.9L230.9,57.9L230.9,57.9L230.9,57.9z M231.3,62.3l-0.1-0.1L231.3,62.3L231.3,62.3z
		 M227.7,58.4c0,0.4,0.2,0.7,0.4,1c0.2,0.3,0.5,0.5,0.5,0.8c0.4,0.3,0.6,0.8,1.1,1v0c0,0,0,0,0.1,0c0.2-0.2,0.3-0.4,0.5-0.6
		c0.2-0.2,0.2-0.4,0.2-0.7c0-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0.2,0,0.2,0.1c0.1,0.3,0.4,0.3,0.7,0.2c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1
		c-0.3,0.2-0.6,0.4-0.9,0.7c-0.3,0.3-0.6,0.3-1,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0.3,0,0.5,0,0.8c0,0.2,0.1,0.3,0.3,0.4
		c0.2,0.1,0.4,0,0.5,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.1,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.2
		c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1,0.2-0.1,0.5,0,0.7c0.4,0.7,0.9,1.2,1.7,1.4c0,0,0,0,0,0l0-0.1l0,0.1
		c0,0,0,0,0,0c0,0.1-0.1,0.2,0,0.2c0.1,0.4,0.3,0.7,0.6,0.9c0.1,0.1,0.4,0.1,0.5-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.6,0.2-1.2,0.2-1.8
		c0-0.3,0.1-0.6,0.3-0.9c0.1-0.2,0.2-0.3,0.2-0.5c0-0.8,0.4-1.5,0.3-2.2c0-0.1,0-0.2,0-0.3c0.1-0.3,0.3-0.6,0.6-0.7
		c0.1,0,0.2,0,0.2,0.1c0,0.2-0.1,0.4,0,0.5c0.3,0.5,0.4,1.1,0.7,1.6c0.1,0.2,0.2,0.4,0.1,0.7c-0.1,0.2-0.1,0.5-0.2,0.7
		c0,0.2,0.2,0.4,0.3,0.3c0.2-0.1,0.4-0.2,0.7-0.3c0.2-0.1,0.2-0.1,0.3,0.2c0,0,0,0.1,0,0.1c0.1,0.2,0.2,0.3,0.4,0.1
		c0.5-0.4,0.8-1,1-1.7c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.5,0-0.7-0.2-0.7-0.7c0-0.4-0.2-0.5-0.7-0.4
		c-0.3,0.1-0.3,0.1-0.2-0.2c0-0.1,0.1-0.3,0.1-0.4c0.1-0.3,0-0.5-0.3-0.6c-0.3-0.1-0.5-0.1-0.7-0.4c-0.2-0.3-0.6-0.5-0.6-0.9
		c0-0.1-0.1-0.1-0.2-0.2c-0.2-0.1-0.4,0-0.6-0.1c-0.4-0.1-0.7-0.3-0.8-0.8c-0.1-0.4-0.3-0.7-0.6-0.9c-0.1-0.1-0.2-0.2-0.3-0.3
		c-0.1-0.3-0.3-0.4-0.6-0.5c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4,0-0.4,0.2c-0.1,0.3-0.1,0.6-0.2,0.9c0,0,0,0,0,0c0,0,0,0.1,0,0.1
		c0.1,0.2,0.2,0.4,0.2,0.6c0,0,0,0.1,0,0.1c-0.1,0.1-0.1,0-0.1-0.1c0-0.2,0-0.4,0-0.6c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0
		c-0.3-0.2-0.6-0.1-0.9,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.2-0.2,0.1c0,0-0.1,0-0.1-0.1
		c-0.1-0.1,0-0.2,0.1-0.2c0.1,0,0.1,0,0.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c-0.1-0.4-0.3-0.5-0.6-0.4
		c-0.4,0.1-0.7,0.2-1.1,0.3c-0.3,0-0.4,0.2-0.5,0.4c0,0.1-0.1,0.3-0.1,0.4c-0.2,0.5-0.1,1,0.3,1.4C227.6,58.2,227.7,58.2,227.7,58.4
		"
            />
            <path
              fill="#003046"
              d="M151.9,61.3c-0.2-0.1-0.3-0.2-0.3-0.4c-0.1-0.3-0.4-0.4-0.7-0.4c-0.4,0-0.9,0-1.2-0.2c-0.1-0.1-0.3,0-0.4,0
		c-0.6,0.1-1.2,0.1-1.8,0.2c-0.4,0.1-0.7,0.2-1-0.3c-0.1-0.2-0.3-0.2-0.5-0.2c-0.6-0.2-1-0.5-1.2-1.1c-0.1-0.3-0.3-0.4-0.5-0.4
		c-0.2,0-0.1-0.1,0-0.1c0.2-0.1,0.3-0.2,0.4-0.3c0,0,0,0,0,0l0,0l0.1,0c0,0,0,0,0,0c0.3,0,0.6,0.1,0.8,0c0.1,0,0.2-0.3,0.2-0.4
		c0-0.1-0.1-0.2-0.2-0.2c-0.3-0.2-0.4-0.5-0.3-0.9c0-0.3-0.3-0.6-0.5-0.6c-0.2,0-0.5,0-0.7,0c-0.2,0-0.4-0.1-0.4-0.3
		c-0.1-0.2-0.1-0.4-0.2-0.5c0-0.1-0.1-0.3-0.2-0.4c-0.4-0.3-0.7-0.7-1.1-0.9c-0.4-0.2-0.7-0.1-1.1,0.1c-0.2,0.2-0.4,0.4-0.3,0.6
		c0.2,0.5,0.3,1.1,0.3,1.7c0,0.1,0.1,0.2,0.2,0.2c0.4,0.2,0.8,0.3,1.2,0.5c0.4,0.1,0.4,0.2,0.4,0.5c0,0.3,0,0.6,0,0.9
		c0,0.4-0.1,0.7-0.4,0.9c-0.2,0.2-0.4,0.4-0.6,0.6c-0.4,0.6-0.5,1.2-0.4,1.9c0,0.5,0.6,0.4,0.8,0.7c0,0,0.1,0.1,0.1,0.1
		c0.3,0.3,0.7,0.3,1.1,0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.1,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0.2,0.3,0.4,0.4
		c0.5,0.1,0.9,0.3,1.4,0.3c0.9,0,1.7,0.5,2.7,0.4c0.2,0,0.3,0.1,0.5,0.2c0.3,0.2,0.7,0.3,1.1,0.2c0.3,0,0.6,0,0.9-0.1
		c0.1,0,0.3-0.1,0.4-0.2c0.2-0.2,0.5-0.4,0.8-0.6c0.2-0.1,0.3-0.3,0.2-0.5c0-0.2,0.1-0.3,0.2-0.5C152.5,62.1,152.4,61.7,151.9,61.3"
            />
            <path
              fill="#003046"
              d="M127.8,55c-0.4-0.1-0.8-0.2-1.3-0.3c-0.1,0-0.1-0.2-0.1-0.2c0.3-0.4,0-0.8,0.1-1.2c0-0.4,0-0.7-0.1-1
		c0-0.3-0.3-0.5-0.6-0.5c-0.3-0.1-0.3-0.1-0.3-0.4c0-0.4-0.2-0.7-0.5-0.7c-0.3,0-0.6-0.1-0.8,0.1c-0.4,0.4-0.9,0.4-1.2,0.7
		c-0.1,0.1-0.3,0.3-0.4,0.4c0.1,0.4-0.2,0.5-0.5,0.6c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.1-0.1,0.2,0,0.3c0.2,0.1,0.3,0.2,0.5,0.3
		c0.1,0.1,0.1,0.1,0.1,0.2c0,0-0.1,0-0.1,0c-0.3,0-0.7-0.1-1-0.1c-0.3,0-0.6,0.3-0.7,0.6c-0.1,0.3,0,0.5,0.2,0.6
		c0.2,0.1,0.4,0.3,0.6,0.4c0.5,0.3,0.9,0.7,1.5,0.4c0.1,0,0.2,0,0.3,0c0.3,0.2,0.7,0.2,1.1,0.2c0,0,0.1,0,0.1,0.1
		c0,0-0.1,0.1-0.1,0.1c-0.6,0.1-1.2,0.2-1.8,0.3c-0.2,0-0.3,0.2-0.3,0.4c0,0.4,0.1,0.8,0.5,1c0.2,0.1,0.4,0.2,0.5,0.2
		c0.3,0,0.6,0,0.9,0c0.5,0.1,1,0,1.5-0.3c0.2-0.1,0.3-0.2,0.5-0.2c0.4,0,0.9,0,1.3-0.2c0.1,0,0.2,0,0.3,0c0.3,0.1,0.6,0.2,0.9,0.4
		c0.2,0.1,0.4,0.1,0.7,0.1c0.2,0,0.4-0.1,0.6-0.1c0.3,0,0.5-0.2,0.7-0.4c0.4-0.4,0.8-0.9,1.3-1.3c0.1-0.1,0.2-0.3,0.3-0.5
		c0.2-0.4,0-0.9-0.3-1.1c-0.1,0-0.2-0.1-0.3,0c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0.1-0.1,0.2-0.2,0.1c-0.2-0.2-0.5-0.3-0.4-0.7
		c-0.2-0.2-0.3-0.4-0.1-0.6c0.1-0.1,0.2-0.3,0.2-0.5c0-0.4,0.1-0.8,0.3-1.2c0.1-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.3-0.1-0.5,0
		c-0.4,0.3-0.8,0.6-1.1,0.8c-0.2,0.2-0.3,0.4-0.2,0.6c0,0.1,0,0.3,0.1,0.4c0.1,0.3-0.1,0.4-0.3,0.6c0,0-0.1,0.1-0.1,0.1
		c-0.2,0.1-0.3,0.4-0.2,0.6c0,0.1,0.1,0.2,0.1,0.3C128.1,54.9,128,55.1,127.8,55"
            />
            <path
              fill="#003046"
              d="M416.2,247.6c-0.2,0-0.4,0.1-0.6,0.1c-0.3,0-0.2-0.4-0.3-0.6c-0.1-0.1-0.1-0.2-0.2-0.2
		c-0.1-0.1-0.3-0.2-0.5-0.1c-0.1,0.1-0.3,0.2-0.3,0.3c-0.3,0.7-0.7,1.3-1.4,1.7c-0.1,0.1-0.3,0.3-0.3,0.4c-0.1,0.2-0.2,0.4-0.3,0.6
		c-0.2,0.5-0.6,0.9-1.1,1.1c-0.5,0.3-1.1,0.6-1.6,0.9c-0.4,0.3-0.8,0.7-1.4,0.7c-0.1,0-0.3,0.1-0.4,0.2c-0.7,0.6-1.4,1.2-2,1.8
		c-0.5,0.5-0.8,1.1-1.2,1.8c-0.1,0.2,0,0.5,0.2,0.6c0.4,0.2,0.9,0.3,1.2,0.8c0.1,0.1,0.1,0.1,0.2,0.2c0.3,0.3,0.8,0.4,1.2,0.2
		c0.7-0.3,1.2-1,1.9-1.3c0.2-0.3,0.4-0.6,0.6-1c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.2,0.4-0.5,0.6-0.8c0.6-1.1,0.4-0.8,1.4-1.3
		c0.3-0.1,0.5-0.1,0.8-0.1c0.3,0,0.5-0.2,0.4-0.4c-0.1-0.5,0-0.8,0.5-1.1c0.2-0.1,0.3-0.2,0.4-0.4c0.5-0.7,1.1-1.2,1.6-1.8
		c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2,0-0.4,0-0.5c0-0.2,0-0.2,0.1-0.2c0-0.1,0-0.2,0.1-0.3c0,0,0-0.1,0-0.1
		C416.5,247.8,416.3,247.6,416.2,247.6"
            />
            <path
              fill="#003046"
              d="M201.3,97c0.7-0.3,1.3-0.7,2.1-0.9c0.1,0,0.2-0.1,0.4-0.2c0.3-0.3,0.8-0.5,1.2-0.7c0.2,0,0.3-0.2,0.4-0.3
		c0.1-0.2,0.3-0.3,0.5-0.5c0.1-0.1,0.1-0.2,0.1-0.3c-0.2-0.4,0-0.7,0.2-1.1c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1
		c-0.2-0.1-0.3-0.2-0.5-0.3c-0.3-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.4-0.3-0.6c0-0.1-0.1-0.1-0.2-0.1c-0.2,0.1-0.4,0-0.5-0.1
		c-0.2-0.3-0.5-0.2-0.7,0c-0.4,0.4-0.9,0.5-1.4,0.4c-0.2-0.1-0.4,0.1-0.6,0c-0.5-0.2-0.7,0-0.9,0.4c0,0-0.2,0-0.2,0
		c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.4,0.1c-0.1,0.2-0.1,0.4-0.1,0.5c0.1,0.3-0.1,0.4-0.3,0.5c-0.1,0-0.2,0.1-0.3,0.1
		c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c-0.2-0.2-0.2-0.5-0.2-0.7c0-0.3-0.1-0.5-0.3-0.7
		c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.3-0.5-0.3-0.9,0l0,0.1l-0.1,0c0,0,0,0,0,0l0,0c-0.2,0.1-0.5,0.1-0.6,0.3c-0.3,0.3-0.5,0.7-0.7,1
		c-0.1,0.1,0.1,0.4,0.3,0.4c0.1,0,0.3,0,0.4-0.1c0.3-0.2,0.6,0,1,0.1c0.1,0.1,0.1,0.1,0.1,0.2c-0.1,0.2-0.2,0.4-0.5,0.5
		c-0.3,0-0.7,0.1-1,0.2c-0.1,0-0.1,0.2-0.1,0.2c0,0.1,0.1,0.2,0.1,0.2c0.2,0.1,0.5,0.1,0.7,0.2c0.1,0,0.3,0.1,0.4,0.2
		c0.1,0.2,0.3,0.4,0.4,0.6c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1c-0.2,0-0.3,0.2-0.3,0.4c0,0.3,0.2,0.4,0.5,0.4
		c0.1,0,0.3,0,0.4,0c0.5,0,1,0,1.3,0.4c0.4,0.1,0.9,0.3,1.3,0.4C200.9,97.1,201.1,97.1,201.3,97"
            />
            <path
              fill="#003046"
              d="M286.1,43.8c0,0.1,0.1,0.2,0.2,0.3c0.1,0,0.3,0,0.4,0.1c0.3,0.2,0.6,0.3,0.9,0.3c0,0,0.1,0,0.1,0.1
		c0.1,0.1,0,0.1-0.1,0.1c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0-0.4,0.1-0.5,0.3c-0.1,0.1,0,0.3,0.2,0.5c0.3,0.3,0.5,0.5,0.8,0.8
		c0.1,0.1,0.2,0.1,0.3,0.1c0.6-0.1,1-0.7,0.8-1.3c0-0.1-0.1-0.2-0.1-0.4c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0
		c0.2,0.3,0.4,0.5,0.4,0.9c0,0.2,0,0.4-0.1,0.6c-0.1,0.5-0.1,0.5,0.4,0.5c0.4,0,0.7,0.1,0.8,0.4c0.3,0.5,0.7,0.6,1.2,0.6
		c0.8,0,1.6,0.1,2.4,0.3c0.1,0,0.3,0,0.4,0c0.4-0.1,0.7-0.3,0.9-0.6c0.1-0.1,0.1-0.4,0-0.5c-0.1-0.1-0.1-0.2-0.2-0.2
		c-0.1-0.1-0.3-0.3-0.5-0.3c-0.2-0.1-0.2-0.3-0.2-0.5c0.1-0.4-0.3-0.7-0.4-1c-0.1-0.7-0.7-1.1-1.3-1.5c-0.3-0.2-0.6-0.2-0.8,0
		c-0.2,0.2-0.4,0.1-0.6,0c-0.3-0.2-0.6-0.2-0.9,0c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0.1c0,0,0,0-0.1,0c-0.2,0.2-0.4,0.4-0.6,0.6
		c-0.1,0.1-0.2,0.1-0.2,0c0,0,0-0.1,0-0.1c0.2-0.3,0.5-0.5,0.8-0.5c0,0,0,0,0,0c0,0,0,0,0,0c0-0.4,0-0.8-0.4-1.1
		c-0.3-0.2-0.4-0.6-0.4-1c0-0.2-0.2-0.4-0.4-0.4c-0.6-0.1-1-0.4-1.4-0.9c-0.2-0.2-0.4-0.3-0.6-0.4c-0.1-0.1-0.4,0-0.5,0.2
		c-0.1,0.4-0.2,0.7-0.4,1c-0.4,0.5-0.4,1.1-0.2,1.6C286.1,42.9,286.2,43.3,286.1,43.8"
            />
            <path
              fill="#003046"
              d="M423.2,241.6c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0-0.2,0.1-0.2,0.2c-0.6,0.3-0.7,0.3-1.1,0
		c-0.2-0.2-0.4-0.4-0.4-0.7c0-0.3-0.1-0.7-0.2-1c0-0.1-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.2-0.1c0,0-0.1,0-0.1,0.1
		c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.3-0.2-0.3-0.3c0.1-0.6,0.1-1.3,0.2-2c0.1-0.4,0.1-0.7-0.2-1.1
		c-0.3-0.4-0.5-0.9-1-1.2c-0.1-0.1-0.1-0.2-0.2-0.4c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.1-0.2,0c0,0-0.1,0.1-0.1,0.1
		c0,0.4,0.3,0.7,0.2,1.1c-0.1,0.3-0.1,0.5,0,0.8c0.1,0.4,0.3,0.8,0.3,1.2c0.1,0.4,0.3,0.8,0.1,1.2c0,0.1,0.1,0.3,0.1,0.4
		c0.1,0.2,0.1,0.4,0.2,0.5c-0.1,0.5-0.1,0.9-0.3,1.3c-0.2,0.4-0.4,0.9-0.5,1.3c-0.1,0.2-0.2,0.4-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.3
		c-0.3,0.2-0.4,0.7-0.2,1c0.2,0.2,0.5,0.5,0.7,0.7c0.2,0.2,0.3,0.5,0.2,0.8c0,0.2-0.1,0.5-0.3,0.7c-0.3,0.3-0.6,0.7-0.8,1
		c0,0.1,0,0.2,0,0.3c0.1,0.2,0.3,0.3,0.5,0.5c0.1,0,0.2,0.1,0.3,0c0.4-0.1,0.7-0.4,0.9-0.7c0.5-0.5,0.9-0.9,1.4-1.4
		c0.4-0.5,0.7-1,0.8-1.6c0-0.3,0.3-0.6,0.7-0.6c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.2-0.1c0.1-0.5,0.5-0.7,0.8-1
		c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.3,0.2-0.5,0.3-0.8C423.5,241.9,423.4,241.7,423.2,241.6"
            />
            <path
              fill="#003046"
              d="M335.1,45.8L335.1,45.8L335.1,45.8c-0.1,0-0.1,0-0.2-0.1C335,45.7,335.1,45.8,335.1,45.8 M337.7,46.7
		L337.7,46.7L337.7,46.7C337.6,46.7,337.6,46.6,337.7,46.7C337.6,46.6,337.6,46.7,337.7,46.7 M332.5,47.4c0.2,0.3,0.5,0.6,0.5,1
		c0,0.1,0.2,0.2,0.3,0.3c0.2,0.1,0.5,0.3,0.8,0.4c0.3,0.1,0.6,0.2,0.8,0.5c0.2,0.2,0.5,0.3,0.8,0.3c0.2,0,0.5,0,0.7,0
		c0.1,0,0.3-0.1,0.3-0.2c0.1-0.2,0.1-0.4,0.4-0.6c0.2-0.1,0.3-0.3,0.4-0.5c0.3-0.4,0.6-0.6,1.1-0.5c0.1,0,0.3,0,0.4-0.1
		c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.4
		c0-0.2,0-0.4,0-0.6c-0.1-0.2-0.2-0.4-0.3-0.7c0.1-0.4-0.1-0.6-0.5-0.7c-0.6-0.1-1.2-0.2-1.8-0.4c0,0-0.1,0-0.1,0
		c-0.1,0.1-0.3,0.1-0.3,0.2c0,0,0,0.2,0,0.3c0.3,0.4,0.4,0.9,0.4,1.3c0,0.1,0,0.2,0.1,0.4c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1
		c0,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.4-0.6-0.9-0.6-1.4c0-0.4-0.3-0.5-0.5-0.6
		c-0.3-0.1-0.6-0.1-0.8-0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.3,0,0.5,0,0.8c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2
		c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c-0.4-0.1-0.8-0.2-1.1-0.5c-0.2-0.3-0.6-0.4-1-0.5c-0.2,0-0.4,0.1-0.4,0.3
		c-0.1,0.4-0.2,0.7-0.4,1.1c-0.1,0.1,0,0.3,0,0.4C332.1,46.6,332.2,47,332.5,47.4"
            />
            <path
              fill="#003046"
              d="M135.1,63.8c0.2-0.1,0.2-0.3,0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.3-0.1-0.7,0-1-0.1
		c-0.2,0-0.3-0.1-0.5-0.2c0,0,0,0,0,0c0,0,0,0,0,0l0,0c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.2-0.4-0.1c-0.2,0-0.5,0.1-0.7,0.2
		c-0.1,0-0.3,0.1-0.3,0.2c-0.1,0.3-0.4,0.5-0.7,0.7c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0.1,0.3,0.1,0.4c0.3,0.5,0.1,0.9-0.1,1.3
		c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2-0.3-0.3-0.6-0.4-0.9c-0.1-0.2-0.3-0.3-0.5-0.3c-0.5,0-0.9,0.3-0.9,0.9c0,0.4-0.1,0.8-0.1,1.1
		c0,0.1,0.1,0.3,0.2,0.3c0.3,0.1,0.5,0.4,0.6,0.7c0.2,0.6,0.4,1.1,0.4,1.8c0,0.3,0.2,0.6,0.4,0.9c0.1,0.1,0.4,0.2,0.5,0.1
		c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.1,0.5-0.2,0.8-0.3c0.3-0.1,0.5-0.2,0.8-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.5,0.4-0.9,0.7-1.2
		c0.2-0.2,0.3-0.6,0.2-0.9c-0.1-0.2-0.2-0.4-0.2-0.7c-0.1-0.3-0.3-0.5-0.5-0.6c-0.1-0.1-0.1-0.2,0-0.2c0.3-0.1,0.5-0.2,0.8-0.3
		c0.2-0.1,0.3-0.2,0.4-0.4C134.7,64,134.9,64,135.1,63.8"
            />
            <path
              fill="#003046"
              d="M364.6,191.2C364.6,191.2,364.6,191.2,364.6,191.2c0.1-0.1,0.2-0.2,0.3-0.1c0.1,0,0.1,0.1,0.1,0.2
		c0,0-0.1,0.1-0.1,0.1C364.8,191.2,364.7,191.2,364.6,191.2C364.6,191.2,364.6,191.2,364.6,191.2
		C364.6,191.2,364.5,191.2,364.6,191.2C364.5,191.2,364.6,191.2,364.6,191.2 M362.6,185.2c1-0.1,2.1-0.1,3.1,0.1
		c1,0.1,1.7-0.2,2.2-1.2c0.1-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.3-0.2-0.4-0.1c-0.2,0.2-0.3,0.3-0.5,0.5c-0.3,0.2-0.6,0.4-1,0.3
		c-0.6-0.1-1.1-0.1-1.7-0.2c-0.4-0.1-0.7-0.2-1.1-0.2c-0.5,0-0.6,0.6-1.1,0.6c0,0-0.1,0-0.1,0.1c-0.3,0.2-0.4,0.4-0.5,0.7
		c0,0.2,0,0.4,0,0.6c0,0.2-0.1,0.4-0.2,0.5c-0.3,0.3-0.4,0.7-0.4,1c0,0.3-0.1,0.7-0.3,1c-0.1,0.2-0.2,0.4-0.3,0.7
		c-0.1,0.3,0,0.6,0.2,0.8c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.2,0.3,0.5,0.3,0.8c-0.1,0.3-0.2,0.6-0.2,1c0,0.1,0.1,0.3,0.2,0.4
		c0.3,0.3,0.6,0.2,0.9,0.1c0.2-0.1,0.1-0.3,0.1-0.5c0-0.9,0.2-1.7,0.3-2.5c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.2
		c0,0.5,0,0.9,0.4,1.3c0.1,0.1,0.1,0.4,0.2,0.5c0.1,0.3,0.2,0.4,0.6,0.4c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0
		c0,0,0,0,0,0.1c0,0,0,0,0,0c0.1,0.3,0.1,0.5,0.4,0.6c0,0,0.1,0.1,0.1,0.1c0.2,0.1,0.5,0,0.6-0.2c0.1-0.4,0.1-0.8,0.1-1.1
		c0-0.2-0.1-0.4-0.3-0.4c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2-0.1-0.3-0.3-0.2-0.5c0.1-0.2,0-0.5-0.2-0.7c-0.2-0.2-0.3-0.5-0.5-0.7
		c-0.1-0.1,0-0.3,0.1-0.4c0.2-0.2,0.5-0.3,0.7-0.5c0.2-0.1,0.4-0.1,0.5,0c0.1,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.2-0.1
		c0-0.3,0.1-0.6,0-0.8c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.4-0.1-0.6,0c-0.4,0.1-0.7,0.2-1.1,0.3c-0.5,0-0.8,0.1-1.1,0.5
		c0,0-0.2,0.1-0.3,0c-0.4-0.2-0.7-0.5-0.8-0.9C361.8,185.7,362.2,185.2,362.6,185.2"
            />
            <path
              fill="#003046"
              d="M162.3,122.5C162.3,122.5,162.3,122.5,162.3,122.5C162.3,122.5,162.3,122.5,162.3,122.5
		C162.3,122.5,162.3,122.5,162.3,122.5"
            />
            <path
              fill="#003046"
              d="M161.9,120.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.2-0.4-0.1
		c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.2-0.6,0.1-1,0.1c-0.2,0-0.3-0.2-0.2-0.4c0,0,0-0.1,0-0.1c0-0.3-0.1-0.5-0.4-0.5
		c-0.1,0-0.3,0-0.4,0c0,0,0,0,0,0l0,0l0-0.1c0,0,0,0,0,0c0.1-0.4,0.4-0.7,0.7-1c0.3-0.3,0.4-0.6,0.6-0.9c0-0.1,0-0.2,0-0.3
		c0-0.1-0.2-0.1-0.4-0.1c-0.5,0.1-0.9,0.3-1.2,0.7c-0.7,0.8-1.2,1.7-1.7,2.6c-0.3,0.5-0.6,0.8-1.1,1c-0.2,0.1-0.2,0.2-0.1,0.3
		c0.1,0.2,0.1,0.3-0.1,0.5c-0.2,0.2-0.3,0.3-0.5,0.5c-0.1,0.1,0,0.3,0.1,0.4c0.1,0,0.3,0.1,0.4,0.1c0.5,0,1-0.1,1.4,0.1
		c0.4,0.1,0.7,0.1,1.1,0c0.4-0.1,0.7,0.2,1.1,0.3c0,0,0,0.1,0,0.1c-0.1,0.1-0.3,0.3-0.4,0.4c0,0.1,0,0.2,0,0.2
		c0,0.1,0.2,0.1,0.2,0.1c0.2,0,0.4-0.1,0.5-0.3c0.2-0.4,0.7-0.5,1-0.8c0,0,0.1,0.1,0.1,0.1c0,0.2-0.1,0.5-0.1,0.7
		c0,0.1,0,0.2,0.1,0.2c0.3,0.2,0.5,0.4,0.9,0.4c0.2,0,0.4-0.1,0.5-0.2c0.1-0.3,0.2-0.6,0.4-0.9c0.1-0.3,0-0.4-0.2-0.6c0,0,0,0-0.1,0
		c0,0,0,0.1-0.1,0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c-0.2-0.2-0.3-0.2-0.6-0.2c-0.1,0-0.2-0.1-0.1-0.2
		c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.3,0.4-0.4c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.3,0.1-0.4,0
		c-0.1-0.1,0-0.3,0.1-0.4C161.7,120.6,161.9,120.5,161.9,120.4"
            />
            <path
              fill="#003046"
              d="M118,50.3c0.5,0.1,0.7,0.2,1,0.4c0.2,0.1,0.4,0.3,0.5,0.5c0.2,0.2,0.5,0.2,0.7,0.1c0.2-0.2,0.4-0.4,0.7-0.5
		c0.1,0,0.2,0,0.3,0c0.1,0.1,0.3,0.1,0.4-0.1c0.2-0.3,0.4-0.6,0.7-0.9c0.2-0.3,0.5-0.5,0.8-0.8c0.1-0.1,0.2-0.1,0.2-0.1
		c0.1,0,0.1,0.2,0,0.4c-0.2,0.3-0.4,0.6-0.6,1c-0.1,0.2-0.1,0.4,0,0.6c0.1,0.2,0.3,0.3,0.5,0.2c0.1-0.1,0.3-0.2,0.3-0.3
		c0.2-0.3,0.4-0.5,0.8-0.5c0.1,0,0.3-0.1,0.4-0.1c0.1-0.1,0.3-0.1,0.3-0.2c0.2-0.5,0.8-0.8,0.9-1.4c0-0.1,0.2-0.2,0.3-0.3
		c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2,0-0.4,0-0.6c0-0.2-0.2-0.5-0.3-0.5c-0.1,0-0.3,0.1-0.4,0.2
		c-0.2,0.2-0.4,0.3-0.5,0.2c-0.4-0.4-0.8-0.3-1.2-0.4c-0.3-0.1-0.6,0-0.8,0.2c-1,0.7-2,1.3-3.1,1.6c-0.3,0.1-0.5,0.3-0.8,0.3
		c-0.5,0.1-0.9,0.3-1.3,0.6c-0.4,0.2-0.4,0.6-0.5,1C117.8,50.2,117.9,50.3,118,50.3"
            />
            <path
              fill="#003046"
              d="M232.6,55c0.2,0.7,0.4,0.9,1,0.9c0.2,0,0.4,0,0.6-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.3-0.1
		c0,0,0.1,0,0.1,0c0.1,0.1,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0.1-0.2,0.2-0.2,0.3
		c-0.2,0.2-0.2,0.4,0,0.5c0.2,0.1,0.5,0.3,0.7,0.4c0.3,0.2,0.5,0.2,0.8,0.1c0.3-0.1,0.6,0,0.9,0.3c0.3,0.3,0.6,0.3,1,0
		c0.1-0.1,0.3-0.3,0.5-0.3c0.5,0,0.8-0.2,0.9-0.7c0.1-0.3,0.2-0.5,0.4-0.7c0.4-0.4,0.4-0.9,0.4-1.4c-0.3-0.1-0.5-0.3-0.7-0.3
		c-0.5-0.1-1-0.2-1.5-0.4c-0.2-0.1-0.4,0-0.6,0.2c-0.2,0.2-0.3,0.2-0.4-0.1c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.2-0.2-0.4-0.1
		c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0,0.3-0.1,0.4c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.2-0.1-0.3-0.1c0,0-0.1-0.1-0.1-0.1
		c-0.5,0-0.8-0.2-1.1-0.6c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.4,0-0.4,0.2c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.2-0.1,0.2
		c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0-0.2,0-0.2,0c-0.1,0.2-0.3,0.4-0.2,0.6C232.5,54.6,232.6,54.8,232.6,55"
            />
            <path
              fill="#003046"
              d="M133.9,52.9c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1,0.3-0.1,0.7-0.3,0.9c-0.1,0.1-0.1,0.2-0.1,0.3
		c0.1,0.5,0.3,0.9,0.1,1.4c0,0,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.1,0.2,0.2c-0.1,0.5,0.2,0.6,0.6,0.6c0.4,0.1,0.8,0.1,1.3,0.1
		c0,0,0.1,0.1,0.1,0.1c0,0.1-0.1,0.2-0.1,0.2c-0.2,0.1-0.4,0.1-0.5,0.2c-0.3,0.1-0.4,0.3-0.4,0.5c0,0.2-0.1,0.4-0.1,0.6
		c0,0.2,0.1,0.4,0.3,0.4c0.4,0.1,0.7,0.2,1.1,0.3c0.4,0.1,0.8-0.1,0.9-0.5c0-0.1,0.1-0.2,0.1-0.3c0.1-0.4,0.4-0.5,0.7-0.5
		c0.1-0.3,0.2-0.6,0.3-0.8c0.2-0.3,0.4-0.5,0.6-0.8c0,0,0.1-0.1,0.1-0.1c0.2-0.4,0.4-0.8,0.6-1.2c0-0.1,0-0.2,0-0.3
		c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.2-0.2-0.3-0.2c-0.4,0.1-0.6-0.1-0.8-0.3c-0.4-0.1-0.8-0.1-1.2,0c-0.2,0.2-0.3,0.5-0.3,0.8
		c0,0.3,0,0.7,0,1c0,0,0,0.1,0,0.1c-0.1,0-0.1,0-0.2-0.1c-0.1-0.3-0.3-0.7-0.4-1c0-0.1-0.2-0.1-0.2-0.1c-0.1,0-0.2,0-0.2,0.1
		c-0.2,0.4-0.4,0.8-0.6,1.3c0,0,0,0,0,0l-0.1,0l0,0c0,0,0,0,0,0c-0.2-0.2-0.3-0.5-0.1-0.7c0.1-0.1,0.1-0.3,0.1-0.4
		c-0.1-0.4-0.2-0.7-0.1-1.1c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.1-0.3-0.2-0.4C134.3,52.8,134,52.8,133.9,52.9"
            />
            <path
              fill="#003046"
              d="M210.8,112.5c-0.1,0.2-0.2,0.4-0.3,0.7c0,0.1-0.1,0.1-0.2,0.1c-0.3,0-0.6,0-0.8,0c-0.2,0-0.3,0.1-0.3,0.3
		c0,0.3,0,0.6,0,0.8c-0.1,0.3,0,0.4,0.2,0.6c0.2,0.2,0.2,0.5,0.1,0.8c-0.1,0.3-0.3,0.5-0.5,0.7c-0.1,0.1-0.1,0.1-0.2,0.2
		c-0.3,0.3-0.2,0.7,0.2,1c0.4,0.3,0.9,0.4,1.4,0.2c0.3-0.1,0.5-0.2,0.8-0.4c0.4-0.3,0.8-0.4,1.3-0.5c0.2,0,0.3-0.2,0.4-0.3
		c0.4-0.5,0.5-1.1,0.4-1.8c0-0.1-0.1-0.3-0.1-0.4c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.1-0.1,0.2-0.2c0.3-0.3,0.3-0.7,0.1-1
		c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.1-0.2-0.2-0.3-0.2c-0.4-0.1-0.7-0.1-1.1-0.2c-0.1,0-0.2,0-0.3,0
		C211.2,112,210.9,112.2,210.8,112.5"
            />
            <path
              fill="#003046"
              d="M376.3,124.3c0,0.5,0.4,0.6,0.5,1c0.1,0.3,0.2,0.5,0.3,0.8c0,0.1,0.2,0.1,0.2,0.1c0.3-0.2,0.6-0.4,0.9-0.6
		c0.1-0.1,0.1-0.3,0-0.4c-0.3-0.2-0.6-0.4-1-0.6c0,0,0-0.1,0-0.1c0.2-0.1,0.4-0.1,0.5-0.2c0.6-0.4,1.1-0.3,1.6,0.1
		c0.3,0.2,0.6,0.3,0.9,0.4c0.1,0,0.2,0,0.2-0.1c0.1-0.3,0.1-0.6,0.2-0.8c0.1-0.3,0.3-0.6,0.6-0.7c0.3-0.1,0.6-0.2,0.9-0.4
		c0.3-0.1,0.3-0.5,0.2-0.7c-0.1-0.2-0.3-0.4-0.4-0.6c-0.2-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.2-0.3-0.3-0.2c-0.4,0.3-0.9,0.3-1.3,0.1
		c-0.6-0.3-1.1-0.7-1.6-1.2c-0.3-0.4-0.7-0.6-1.1-0.8c-0.1-0.1-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.3-0.1,0.4c0.1,0.2,0.2,0.4,0.3,0.7
		c0.1,0.2,0.2,0.4,0.2,0.7c0.1,0.7,0.1,1.3,0.1,2c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0-0.3,0c-0.3,0-0.3,0-0.3,0.4c0,0.1,0,0.2,0,0.3
		C376.6,123.7,376.3,124,376.3,124.3"
            />
            <path
              fill="#003046"
              d="M138,47.6c0.3,0,0.5,0.1,0.8,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0.1,0.3,0.2,0.4,0.3c0.6,0.4,0.9,0.9,0.5,1.7
		c-0.1,0.2,0,0.5,0.2,0.6c0.2,0.2,0.6,0.2,0.8,0.1c0.4-0.2,0.6-0.6,0.7-1c0.1-0.6,0.3-1.1,0.8-1.5c0.1-0.1,0.2-0.3,0.3-0.5
		c0.1-0.4,0.2-0.7-0.1-1c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.2,0-0.3c0-0.7-0.3-0.9-0.9-0.6c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0,0,0
		c0,0.1,0,0.1-0.1,0c0,0,0.1,0,0.1-0.1c0-0.3,0.1-0.6,0.1-0.8c0-0.5-0.4-0.8-0.8-0.8c-0.3,0-0.6,0.1-0.8,0c-0.1,0-0.2,0.1-0.2,0.1
		c-0.3,0.3-0.6,0.5-0.4,1c0,0.1,0,0.3-0.1,0.4c-0.1,0.3-0.1,0.3,0.3,0.5c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0.1,0,0.1,0,0,0.1
		c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0.2,0.1,0.4,0.1,0.5c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.2-0.2,0.2-0.3,0.2c-0.2-0.1-0.3-0.2-0.5-0.3
		c-0.1-0.1-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.2-0.2,0.3C137.5,47.2,137.7,47.5,138,47.6"
            />
            <path
              fill="#003046"
              d="M352.7,195.8c0.6,0,1.1,0.1,1.6,0.4c0.2,0.1,0.4,0.1,0.6-0.1c0.1-0.1,0.3-0.1,0.4-0.1
		c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.4-0.2,0.4-0.4c0-0.1-0.1-0.3-0.3-0.3c-0.5,0-1-0.1-1.4-0.5c-0.1-0.1-0.2,0-0.3-0.1
		c-0.2,0-0.4,0-0.6,0c-0.1,0-0.3-0.1-0.3-0.2c-0.1-0.1,0.1-0.2,0.3-0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.3-0.1,0.3-0.2
		c0-0.1-0.2-0.2-0.4-0.2c-0.6,0-1.1-0.1-1.7-0.3c-0.3-0.1-0.5-0.2-0.8-0.3c-0.4-0.2-0.7-0.2-1,0.2c-0.1,0.2-0.3,0.2-0.5,0.2
		c-0.2,0-0.5-0.1-0.7-0.1c-0.4,0-0.8-0.1-1.1-0.5c-0.3-0.4-0.9-0.5-1.4-0.5c-0.5,0-1-0.2-1.5-0.2c0,0.4-0.2,0.7-0.4,1
		c-0.1,0.1,0,0.3,0.1,0.3c0.3,0.1,0.6,0.1,0.8,0.5c0.1,0.2,0.3,0.2,0.5,0.2c0.4,0,0.7,0.1,1.1,0.3c0.3,0.1,0.5,0.1,0.8,0.1
		c0.6-0.1,1.2,0,1.8,0.3c0.6,0.3,1.2,0.4,1.8,0.4C352,195.8,352.3,195.8,352.7,195.8"
            />
            <path
              fill="#003046"
              d="M363.1,162.2c-0.3,0-0.7,0-1-0.1c-0.5-0.2-0.7,0.1-0.9,0.5c-0.1,0.2-0.1,0.4-0.1,0.6
		c-0.1,0.5-0.1,1-0.2,1.6c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.1,0.2-0.1,0.3c0.1,0.5,0.2,0.9,0.4,1.4c0.1,0.3,0.3,0.6,0.7,0.7
		c0,0,0,0,0,0c0-0.1,0-0.1,0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0.4,0.1,0.8,0.6,0.9c0.1,0,0.2,0.1,0.3,0.2c0,0,0,0,0,0c0,0,0,0,0,0
		l0,0c0.2-0.1,0.4-0.2,0.6-0.3c0.1-0.1,0.3,0,0.4,0c0.1,0.1,0.2,0.2,0.2,0.3c0.3,0.2,0.6,0.3,0.9,0l-0.1,0l0.1,0c0,0,0,0,0,0l0,0
		c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.3,0.2,0.6,0.6,0.7c0.1,0,0.1,0.2,0,0.3c-0.4,0.3-0.4,0.5,0.1,0.7c0.1,0.1,0.3,0.1,0.4,0.1
		c0.1,0,0.2-0.1,0.2-0.1c0-0.3,0.1-0.6,0.1-1c0-0.1-0.1-0.3-0.2-0.4c-0.2-0.2-0.4-0.3-0.4-0.6c0-0.5-0.3-0.7-0.7-0.8
		c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.1-0.5-0.1c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3-0.1
		c-0.1,0-0.1-0.2,0-0.4c0.3-0.4,0.3-0.4-0.2-0.5c-0.2,0-0.3-0.1-0.3-0.3c0-0.3,0-0.6,0.2-0.8c0.1-0.1,0.2-0.2,0.3-0.3
		c0.4-0.5,0.5-1.1,0.2-1.7c-0.2-0.4-0.3-0.7-0.2-1.1C363.4,162.3,363.3,162.2,363.1,162.2"
            />
            <path
              fill="#003046"
              d="M386.6,244.2c-0.5,0.1-0.9,0.1-1.4,0.3c-0.4,0.1-0.7,0-1.1-0.2c-0.2-0.2-0.4-0.4-0.7-0.5
		c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3,0.2-0.3,0.4c0,0.1,0,0.2,0,0.3c0,0.8,0.1,1.7-0.2,2.5c0,0,0,0.1,0,0.4c0,0.2,0.2,0.7,0.2,1.2
		c0.1,0.5,0.4,0.7,0.9,0.8c0.1,0,0.2,0,0.3,0c0.2-0.1,0.4-0.3,0.5-0.4c0.2-0.2,0.4-0.3,0.8-0.3c0.2,0,0.4-0.1,0.4-0.3
		c0.2-0.6,0.3-1.2,0.8-1.6c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.5,0.3-0.9,0.4-1.4c0-0.1,0-0.2,0-0.3C387.1,244.4,386.9,244.2,386.6,244.2
		"
            />
            <path
              fill="#003046"
              d="M133.4,160.8c0.2,0,0.2-0.3,0.1-0.5c-0.1-0.1-0.3-0.3-0.4-0.4c-0.9-0.4-1.9-0.9-2.9-1.3
		c-0.3-0.1-0.6-0.3-0.9-0.4c-0.3-0.2-0.7-0.4-1-0.5c-0.2,0-0.4-0.1-0.5-0.3c-0.4-0.4-0.9-0.5-1.4-0.5c-0.4,0-0.7-0.1-1.1-0.2
		c-0.2-0.1-0.5-0.2-0.7-0.1c-0.6,0.2-1.3,0.2-1.9,0.5c-0.6,0.3-0.9,0.8-1.3,1.3c0,0,0.1,0.1,0.1,0.1c0.2,0,0.4,0,0.6,0
		c0.7-0.4,1.4-0.5,2.1-0.9c0.1-0.1,0.4,0,0.5,0.1c0.1,0.2,0.3,0.4,0.5,0.4c0.3,0.1,0.7,0.1,1,0.2c0.2,0,0.5,0.1,0.7,0.2
		c0.4,0.2,0.9,0.3,1.4,0.4c0.3,0,0.4,0.2,0.5,0.4c0.2,0.5,0.6,0.7,1.1,0.8c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.2,0.1,0.3
		c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.2,0,0.4,0.2,0.4c0.3,0,0.7,0,1.2,0C131.9,161.4,132.7,161,133.4,160.8"
            />
            <path
              fill="#003046"
              d="M296.5,46.9c0,0.1,0,0.3,0,0.2c0,0.3-0.1,0.5,0,0.6c0.1,0.5,0.2,1,0.4,1.5c0.1,0.4,0.2,0.7,0.6,1
		c0.1,0,0.2,0.1,0.3,0c0.1,0,0.2-0.1,0.2-0.2c0.6-0.7,0.6-0.7,1.6-1.4c0.3-0.2,0.5-0.4,0.8-0.6c0.2-0.2,0.3-0.4,0.4-0.6
		c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.2-0.3-0.4-0.6-0.7-0.6c-0.5-0.1-0.9-0.4-1.3-0.6c-0.4-0.2-0.9-0.3-1.3-0.6
		c-0.1-0.1-0.4-0.1-0.5-0.2c-0.1,0-0.2,0-0.2,0.1c-0.2,0.4-0.4,0.8-0.1,1.3C296.3,46.3,296.4,46.6,296.5,46.9"
            />
            <path
              fill="#003046"
              d="M368.1,173c0,0,0,0.1-0.1,0.1c-0.1,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.4,0.4-0.6,0.6c-0.1,0.1-0.2,0.2-0.3,0.3
		c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.2,0-0.3-0.1c-0.3-0.3-0.5-0.5-1,0c-0.2,0.3-0.5,0.4-0.8,0.5c-0.3,0.2-0.5,0.5-0.5,0.9
		c0,0,0,0,0,0.1c0,0.1,0,0.2,0.1,0.3c0,0,0.2,0,0.2-0.1c0.1-0.1,0.3-0.3,0.4-0.4c0.4,0,0.8,0,1.3-0.1c0.1,0,0.4,0.2,0.4,0.3
		c0,0.2,0,0.4,0,0.6c0,0.6,0.4,1.1,1,1.2c0.2,0.1,0.5,0.1,0.7,0.1c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.1-0.5
		c-0.1-0.3-0.3-0.5-0.2-0.8c0-0.1,0.2-0.1,0.2-0.1c0.1,0,0.1,0.1,0.2,0.2c0.2,0.3,0.2,0.2,0.4,0.1c0.3-0.3,0.4-0.6,0.3-1
		c-0.1-0.4-0.3-0.9-0.4-1.4c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.5-0.6-0.8C368.2,173,368.2,173,368.1,173"
            />
            <path
              fill="#003046"
              d="M134,163.3c0.3,0,0.6-0.1,0.8-0.1c0.3,0,0.7,0.1,1,0.1c0.3,0.1,0.4,0.4,0.7,0.5c0.1,0,0.2,0,0.3,0
		c0.1,0,0.2-0.1,0.2-0.2c0.2-0.4,0.5-0.5,1-0.5c0.1,0,0.3,0,0.4,0c0.5-0.3,1.1-0.3,1.7-0.1c0.1,0,0.2,0,0.3-0.1
		c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1,0-0.2-0.1-0.2c-0.5-0.3-1-0.7-1.5-1.1c-0.1-0.1-0.3-0.2-0.5-0.2c-0.6-0.2-1.3-0.3-2-0.1
		c-0.2,0.1-0.5,0-0.7-0.2c-0.1-0.1-0.2-0.1-0.2-0.2c-0.2-0.1-0.4,0-0.6,0.3c-0.1,0.1-0.1,0.3,0,0.4c0.3,0.2,0.3,0.6,0.4,0.9
		c0,0.2,0,0.2-0.3,0.1c-0.2-0.1-0.3,0.1-0.5,0.1c-0.4,0.1-0.7,0.1-1.1,0.2c0,0-0.1,0.2-0.1,0.2C133.5,163.1,133.8,163.3,134,163.3"
            />
            <path
              fill="#003046"
              d="M148.8,68.1c0.1,0.3,0.1,0.6,0,0.9c-0.1,0.5,0.4,1,0.9,1c0.3,0,0.5-0.1,0.8-0.1c0.2,0,0.4,0,0.6,0.2
		c0.3,0,0.7,0.1,1,0.1c0.1,0,0.3-0.2,0.3-0.3c0-0.5-0.1-1-0.2-1.5c-0.2-0.6-0.5-0.9-1.1-1c-0.5-0.1-1-0.3-1.5-0.5
		c-0.1-0.1-0.4,0-0.5,0.2c-0.1,0.2-0.2,0.4-0.3,0.6C148.8,67.7,148.8,68,148.8,68.1"
            />
            <path
              fill="#003046"
              d="M148.9,67.6C148.9,67.6,148.9,67.6,148.9,67.6C148.9,67.6,148.8,67.6,148.9,67.6L148.9,67.6
		C148.8,67.6,148.9,67.6,148.9,67.6"
            />
            <path
              fill="#003046"
              d="M314.8,173.7c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1,0-0.2,0-0.2,0.1c0,0.1,0.1,0.3,0.1,0.4
		c-0.3,0.6-0.2,1.2-0.4,1.6c0,0.9,0.1,1.5,0.4,2.2c0.1,0.3,0.5,0.5,0.8,0.5c1-0.1,1.7-1.1,1.3-2.1
		C316.1,175.3,315.5,174.4,314.8,173.7"
            />
            <path
              fill="#003046"
              d="M142.6,50.7c0,0.2,0.2,0.3,0.4,0.4c0.5,0.1,1,0.1,1.5,0.1c0.1,0,0.2-0.2,0.3-0.3c0.2-0.2,0.1-0.5,0.3-0.7
		c0,0,0,0,0,0c0,0,0,0,0-0.1l0,0c0.2-0.3,0.3-0.7,0-1.1c-0.3-0.4-0.5-0.8-0.7-1.3c-0.1-0.1-0.1-0.2-0.2-0.4
		c-0.1-0.2-0.5-0.2-0.6-0.1c-0.5,0.5-0.9,1.1-0.9,1.9c0,0.3-0.1,0.5-0.2,0.8C142.4,50.2,142.5,50.5,142.6,50.7"
            />
            <path
              fill="#003046"
              d="M253.8,50.4c-0.4,0-0.5,0.3-0.5,0.6c0.4,0.1,0.4,0.1,0.3,0.4c0,0.1-0.1,0.3-0.1,0.4c0,0.4,0.2,0.6,0.6,0.7
		c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.4-0.1,0.5-0.2c0.1-0.2,0.3-0.4,0.3-0.6c0-0.2,0-0.4,0.2-0.5c0.4-0.3,0.4-0.7,0.8-1
		c0.2-0.2,0.2-0.5,0-0.6c-0.2-0.3-0.4-0.5-0.6-0.8c0-0.1-0.2-0.1-0.3,0c-0.4,0.1-0.6,0.2-0.6,0.7c0,0.4-0.2,0.6-0.5,0.8l0,0
		c0,0,0,0,0,0c0,0,0,0,0,0C254.2,50.3,254,50.4,253.8,50.4"
            />
            <path
              fill="#003046"
              d="M361.4,152.8c-0.1,0-0.2,0-0.3,0c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.3-0.3,0.6-0.4,0.9
		c-0.2,0.4-0.2,0.7-0.2,1.1c0,0.7,0.1,1.3,0.7,1.7c0.1,0.1,0.2,0.3,0.4,0.4c0.1,0.1,0.2,0,0.2-0.1c0.1-0.2,0.1-0.5,0.2-0.7
		c0.3-0.6,0.4-1.2,0.4-1.8c0-0.5,0.1-0.9,0.2-1.4C362,153.2,361.7,152.9,361.4,152.8"
            />
            <path
              fill="#003046"
              d="M259.8,47.9L259.8,47.9L259.8,47.9L259.8,47.9L259.8,47.9z M258.8,48.2c0.1,0,0.1,0.1,0.2,0.2
		c0,0,0,0,0.1,0l0,0.1l-0.1,0c0,0,0,0,0,0c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1,0-0.1,0.1-0.2,0.2c0,0.1,0.1,0.2,0.1,0.2
		c0.4,0.2,0.8,0.1,1.2,0.4c0.1,0.1,0.3,0,0.4,0c0.1,0,0.2-0.1,0.2-0.1c0.1-0.5,0.5-0.7,0.8-0.9c0.2-0.2,0.2-0.3,0-0.6
		c0,0-0.1-0.1-0.1-0.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0.1-0.2,0-0.3,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c-0.2-0.1-0.3-0.1-0.5-0.2
		c-0.1,0-0.2-0.2-0.2-0.2c0.1-0.6-0.3-1.1-0.1-1.7c0,0-0.1-0.2-0.2-0.2c-0.4-0.2-0.8-0.1-0.8,0.4c0,0.5,0,0.9-0.3,1.4
		c-0.1,0.1,0,0.3,0.1,0.3c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1-0.2,0.1c-0.2,0-0.5,0-0.6,0.3C258.1,48.2,258.5,48.1,258.8,48.2"
            />
            <path
              fill="#003046"
              d="M137.9,59.5c-0.3,0.2-0.3,0.6,0,0.9c0.3,0.3,0.7,0.6,1.1,0.9c0.1,0.1,0.2,0.1,0.3,0.1
		c0.3,0.1,0.6,0,0.7-0.3c0.1-0.3,0.3-0.7,0.4-1.1c0.1-0.2,0.1-0.4,0.1-0.6c0-0.4-0.1-0.7-0.4-1c-0.2-0.2-0.4-0.2-0.7-0.2
		c-0.1,0-0.3,0-0.4,0C138.6,58.9,138.2,59.2,137.9,59.5"
            />
            <path
              fill="#003046"
              d="M138.9,58.4C138.9,58.4,138.9,58.4,138.9,58.4C138.9,58.4,138.8,58.4,138.9,58.4
		C138.8,58.4,138.8,58.4,138.9,58.4C138.9,58.4,138.9,58.4,138.9,58.4"
            />
            <path
              fill="#003046"
              d="M341.9,45.6c0.7,0.3,1.4,0.4,2.1,0.5c0.2,0,0.4,0,0.6-0.1c0.4-0.1,0.7-0.3,0.8-0.7c0-0.1,0.1-0.3,0-0.4
		c-0.2-0.4-0.6-0.6-1-0.6c-0.3,0-0.6,0.2-1,0.2c-0.4,0-0.7,0-1.1-0.1c-0.1,0-0.2,0-0.3,0.1c-0.2,0.1-0.4,0.1-0.7,0.1
		c-0.1,0-0.2,0.2-0.1,0.3C341.4,45.2,341.6,45.5,341.9,45.6"
            />
            <path
              fill="#003046"
              d="M399.9,190.6c-0.4-0.1-0.6,0-0.7,0.4c-0.2,0.5-0.5,0.8-1,1c-0.2,0.1-0.5,0.1-0.7-0.2l0-0.1l0,0l0,0
		c-0.5,0.1-0.9,0.3-1.4,0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0,0.2,0.1,0.3
		c0.9,0.7,1.9,0.8,2.9,0.6c0.1,0,0.2-0.1,0.3-0.1c0.4-0.2,0.7-0.7,1.1-0.8c0.1,0,0.1-0.2,0.1-0.2c0-0.3,0.3-0.5,0.4-0.7
		C400.4,191,400.2,190.7,399.9,190.6"
            />
            <path
              fill="#003046"
              d="M131.2,45.2c0.4,0.1,0.7,0.2,0.9,0.5c0.4,0,0.5,0.4,0.8,0.5c0.6,0.3,1.3,0,1.4-0.6c0-0.2,0-0.4,0.1-0.6
		c0,0,0-0.1,0-0.1c0-0.5-0.2-0.7-0.7-0.7c-0.4,0.1-0.8,0.1-1.3,0.1c-0.3,0-0.7,0.1-1,0.1c-0.1,0-0.3,0.1-0.4,0.2
		c-0.1,0.1-0.1,0.2-0.1,0.2C131,45,131.1,45.1,131.2,45.2"
            />
            <path
              fill="#003046"
              d="M340.2,53.4c0,0,0.1,0,0.1,0c0.4-0.3,0.9-0.3,1.3-0.3c0.5,0,1-0.1,1.6-0.2c0.2,0,0.3-0.3,0.2-0.6
		c-0.3-0.4-0.6-0.8-1.1-0.9c-0.2-0.1-0.4-0.2-0.7-0.2c-0.2,0-0.5,0-0.7,0c-0.2,0-0.5,0.2-0.5,0.4c-0.1,0.3-0.1,0.6-0.2,1.1
		c0,0-0.1,0.3-0.1,0.5C340.1,53.3,340.2,53.4,340.2,53.4"
            />
            <path
              fill="#003046"
              d="M349.9,160.7c0-0.1-0.1-0.3-0.2-0.3c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.2,0-0.2,0c-0.7,0-1.3,0.3-1.7,0.8
		c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.5,0.2,1.1,0.7,1.3c0.5,0.2,1.2-0.1,1.5-0.5C349.6,161.6,349.8,161.2,349.9,160.7"
            />
            <path
              fill="#003046"
              d="M228.4,134.2c0.2,0.5,0.2,1.1,0.2,1.7c0,0.8,0.4,1,1.2,0.5c0.2-0.1,0.3-0.3,0.3-0.4c0-0.3,0-0.6,0-0.8h0
		c0-0.2,0-0.4,0-0.6c0-0.1,0-0.3,0-0.4c0-0.5-0.5-1-1.1-0.6c-0.2,0.1-0.3,0.2-0.5,0.3C228.4,133.9,228.4,134.1,228.4,134.2"
            />
            <path
              fill="#003046"
              d="M146.3,83.2c-0.1,0.4,0.2,0.8,0.6,0.8c0.8,0,1.4-0.3,1.7-1c0.1-0.2,0.2-0.4,0.3-0.7
		c0.1-0.2-0.1-0.5-0.3-0.6c-0.4-0.2-0.8-0.2-1.1,0c-0.5,0.4-0.9,0.8-1.2,1.4C146.3,83.1,146.3,83.2,146.3,83.2"
            />
            <path
              fill="#003046"
              d="M78.4,103.4c-0.1-0.1-0.4,0-0.5,0.2c-0.1,0.2-0.2,0.4-0.2,0.7c0,0.3,0,0.6,0.1,0.8c0,0,0,0,0,0
		c0,0.2,0.1,0.5,0.1,0.7c0,0.6,0.2,1.1,0.5,1.6c0,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.1
		c-0.3-0.8-0.1-1.5,0.2-2.2c0.1-0.4,0.3-0.8,0.4-1.2c0-0.1,0-0.2-0.1-0.2C78.9,103.6,78.6,103.5,78.4,103.4"
            />
            <path
              fill="#003046"
              d="M365.9,198.3c0.5-0.2,1-0.3,1.4-0.7c0.4-0.4,0.9-0.7,1.5-0.8c0.2,0,0.4-0.2,0.7-0.3
		c0.2-0.1,0.4-0.2,0.4-0.4c0-0.1-0.2-0.2-0.5-0.2c-0.8,0-1.5,0-2.2,0.5c-0.2,0.1-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4
		c-0.3,0.3-0.5,0.7-0.4,1.1C365.6,198.3,365.7,198.4,365.9,198.3"
            />
            <path
              fill="#003046"
              d="M48.6,72.3c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.5,0.1,0.6,0.4c0.1,0.3,0.2,0.5,0.3,0.8c0,0.1,0.2,0.2,0.4,0.1
		c0.3-0.1,0.5-0.2,0.8-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c-0.2-0.3-0.4-0.7-0.5-1c-0.1-0.5-0.4-0.7-0.9-0.8
		c-0.2,0-0.4-0.2-0.6-0.4c-0.1,0-0.2,0-0.2,0c-0.2,0.2-0.4,0.4-0.5,0.7C48.5,72,48.5,72.2,48.6,72.3"
            />
            <path
              fill="#003046"
              d="M144.4,53.3c0.2,0,0.4-0.1,0.6-0.3c0.1-0.1,0.1-0.3,0-0.4c-0.5-0.5-1.2-0.9-1.9-1c-0.3,0-0.6,0.2-0.8,0.5
		c-0.1,0.2,0,0.4,0.1,0.5c0.5,0.4,1.2,0.6,1.8,0.7C144.3,53.3,144.4,53.3,144.4,53.3"
            />
            <path
              fill="#003046"
              d="M366.8,169.8c-0.2,0.1-0.3,0.3-0.2,0.4c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1
		c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0.1,0,0.1c-0.1,0.1-0.1,0.1-0.2,0c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1
		c-0.2,0.3-0.3,0.7,0,1c0.2,0.2,0.3,0.5,0.5,0.7c0,0.1,0.2,0.1,0.2,0.1c0.1,0,0.3-0.2,0.2-0.3c0-0.1-0.1-0.3-0.1-0.4
		c0-0.1,0-0.2,0.1-0.2c0.5-0.2,0.5-0.5,0.4-0.9c0-0.3-0.1-0.6-0.2-0.8C367.6,169.8,367.2,169.6,366.8,169.8"
            />
            <path
              fill="#003046"
              d="M262.5,47.8c-0.1,0-0.2,0.1-0.2,0.2c0,0.4-0.1,0.9,0.1,1.2c0.1,0.2,0.2,0.3,0.4,0.4c0.4,0.1,0.8,0,1.2-0.3
		c0.1-0.1,0.1-0.3,0.1-0.4c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.1-0.2,0.1-0.5c0.2-0.3,0.2-0.4,0.1-0.6
		c-0.2-0.2-0.4-0.2-0.6-0.1c-0.1,0.1-0.3,0.1-0.4,0.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0C262.8,47.6,262.7,47.7,262.5,47.8"
            />
            <path
              fill="#003046"
              d="M251.8,51.1c0.4,0.1,0.7,0.2,1.1,0.2c0.3,0,0.2-0.2,0.3-0.4c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.3-0.1-0.5,0-0.6
		c0.2-0.1,0.4-0.2,0.6-0.1c0.2,0.1,0.4,0.1,0.6,0.1c0-0.1,0-0.2-0.1-0.2c-0.3-0.3-0.7-0.5-1.1-0.6c-0.2,0-0.4,0-0.5,0.1
		c-0.5,0.3-0.8,0.6-1.1,1.1C251.4,50.7,251.6,51.1,251.8,51.1"
            />
            <path
              fill="#003046"
              d="M253.3,51C253.3,51,253.3,51,253.3,51C253.3,51,253.3,51,253.3,51C253.3,51,253.3,51,253.3,51
		C253.3,51,253.3,51,253.3,51"
            />
            <path
              fill="#003046"
              d="M260.2,50.2c-0.1,0-0.1,0.1-0.1,0.2c0,0.4,0.2,0.6,0.4,0.9c0.1,0.1,0.4,0.2,0.5,0.1
		c0.2-0.1,0.3-0.2,0.5-0.3c0.3,0,0.4-0.2,0.5-0.4c0.1-0.2,0.2-0.3,0.2-0.4c0.1-0.3,0.1-0.4-0.1-0.6c-0.2-0.1-0.3-0.2-0.5-0.2
		c-0.1,0-0.2,0-0.3,0C261,50,260.6,50.1,260.2,50.2"
            />
            <path
              fill="#003046"
              d="M264.6,47.4c0.1,0,0.2,0,0.2-0.1c0.2-0.3,0.3-0.7,0.6-0.9c0.1,0,0.1-0.2,0.1-0.2c-0.2-0.4-0.4-0.8-0.7-1.2
		c-0.1-0.1-0.3-0.1-0.4,0c-0.2,0.2-0.4,0.4-0.5,0.7c-0.1,0.1-0.1,0.3-0.1,0.4C263.8,46.7,264,47.1,264.6,47.4"
            />
            <path
              fill="#003046"
              d="M371.5,189.9c0.9-0.2,1.8-0.2,2.6,0.2c0,0,0.1,0,0.1,0c0.1,0,0.3-0.1,0.3-0.3c0-0.1,0-0.2-0.1-0.3
		c-0.1-0.1-0.2-0.2-0.3-0.3c-0.8-0.4-1.6-0.5-2.5-0.3c-0.2,0-0.4,0.2-0.5,0.5C371.1,189.8,371.3,190,371.5,189.9"
            />
            <path
              fill="#003046"
              d="M414.9,214.8c0.1,0,0.3,0,0.4,0c0,0,0-0.1,0.1-0.1c0-0.1,0-0.2-0.1-0.3c-0.5-0.6-1.1-1.2-1.6-1.8
		c-0.2-0.2-0.4-0.4-0.6-0.6c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0.2-0.1,0.2c0.2,0.6,0.6,1.1,1,1.5
		C413.9,214.1,414.3,214.6,414.9,214.8"
            />
            <path
              fill="#003046"
              d="M356.8,196.3c0.3,0.1,0.5,0.2,0.8,0.3c0.2,0,0.4,0.1,0.5,0.1c0.8-0.1,1.5-0.2,2.2-0.4c0.1,0,0.1-0.1,0-0.2
		c-0.4-0.4-0.8-0.6-1.4-0.5c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.1-0.1,0.2c-0.5-0.3-1,0-1.5-0.1c-0.2-0.1-0.4,0.1-0.5,0.2
		C356.7,196.1,356.7,196.3,356.8,196.3"
            />
            <path
              fill="#003046"
              d="M364.5,196.2c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0-0.2-0.1-0.2c0,0-0.1-0.1-0.1-0.1
		c-0.4,0.1-0.8,0.3-1.2,0.2c-0.4-0.1-0.8-0.1-1.3-0.2c0,0-0.1,0-0.1,0c-0.3,0-0.7,0.1-0.9,0.3c-0.1,0.1-0.1,0.2-0.1,0.3
		c0,0.1,0.1,0.2,0.2,0.2c0.4,0.1,0.7,0.1,1.1,0.2C363.2,196.4,363.8,196.4,364.5,196.2"
            />
            <path
              fill="#003046"
              d="M263.8,82.8c0,0.1,0,0.3-0.1,0.5c0,0.2,0.1,0.4,0.2,0.6c0.1,0.3,0.4,0.4,0.7,0.3c0.6-0.2,0.8-0.6,0.9-1.1
		c0-0.1,0-0.2-0.1-0.3c-0.3-0.3-0.7-0.5-1.1-0.5C264.1,82.4,263.9,82.6,263.8,82.8"
            />
            <path
              fill="#003046"
              d="M138,93.3c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.3,0-0.3,0c-0.3,0-0.5,0-0.7,0
		c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0.2-0.4,0.4-0.6,0.6c-0.1,0.1-0.1,0.3,0,0.4c0.1,0.1,0.1,0.3,0.3,0.3c0.2,0,0.4,0.1,0.6,0
		C137.2,94.1,137.7,93.7,138,93.3"
            />
            <path
              fill="#003046"
              d="M314.6,58.4c0,0.3,0.2,0.5,0.5,0.6c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.1,0,0.1,0c0.4,0,0.6-0.3,0.6-0.6
		c0-0.3-0.1-0.5-0.3-0.8c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.3,0.3-0.2,0.5
		C314.6,58.3,314.6,58.3,314.6,58.4"
            />
            <path
              fill="#003046"
              d="M150.7,118.6c0,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.2,0,0.4c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.3,0.5,0.6,0.9,0.7
		c0.3,0.1,0.7,0.2,1.1,0.3c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.2c0,0,0-0.1,0-0.1C152.4,119.1,151.6,118.7,150.7,118.6"
            />
            <path
              fill="#003046"
              d="M257,142.5c0.3-0.1,0.5-0.2,0.8-0.3c0.4-0.2,0.5-0.6,0.6-0.9c0,0-0.1-0.1-0.1-0.1c-0.4,0-0.7,0.1-1.1,0.2
		c-0.3,0-0.6,0.2-0.9,0.4c-0.1,0.1-0.2,0.4,0,0.5C256.5,142.5,256.8,142.6,257,142.5"
            />
            <path
              fill="#003046"
              d="M122,51.5c0,0,0-0.1-0.1-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.8,0.1-1.4,0.5-2,0.9c-0.2,0.2-0.1,0.4,0.1,0.6
		c0.2,0.1,0.4,0.2,0.5,0.1c0.6-0.4,1.1-0.8,1.7-1.3C122,51.6,122,51.5,122,51.5"
            />
            <path
              fill="#003046"
              d="M229.6,132.9c0.1-0.1,0.2-0.2,0.2-0.4c0.1-0.3,0.1-0.6,0.1-0.7c0-0.2,0-0.3,0-0.4c0,0,0-0.1,0-0.1
		c0-0.2-0.2-0.3-0.4-0.3c-0.2,0.1-0.3,0.1-0.5,0.2c-0.2,0.1-0.3,0.4-0.3,0.6c0,0.4,0.2,0.7,0.4,1
		C229.2,133.1,229.5,133.1,229.6,132.9"
            />
            <path
              fill="#003046"
              d="M358.5,174.6c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0.5-0.2,0.9-0.6,1.1-1
		c0.3-0.5,0.7-0.9,1.2-1.2c0.2-0.1,0.1-0.1,0-0.3c-0.3-0.4-0.5-0.3-0.6-0.1c-0.6,0.8-1.1,1.5-1.8,2.2
		C358.5,174.4,358.5,174.5,358.5,174.6"
            />
            <path
              fill="#003046"
              d="M365.7,172.3c0-0.4-0.3-0.4-0.6-0.6l0,0c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.3-0.1,0.7-0.3,1
		c-0.3,0.4-0.3,0.5,0.1,0.8c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.3,0,0.3-0.2c0.1-0.3,0.2-0.5,0.2-0.8l0,0v0h0
		C365.5,172.9,365.5,172.6,365.7,172.3"
            />
            <path
              fill="#003046"
              d="M129.6,162.7c-0.2,0-0.5,0.1-0.7,0.1c-0.1,0-0.1,0.1-0.1,0.2c0.4,0.6,1,0.9,1.7,0.6c0.1,0,0.3-0.1,0.4-0.1
		c0.1,0,0.2-0.3,0.1-0.4C130.7,162.8,130.2,162.7,129.6,162.7"
            />
            <path
              fill="#003046"
              d="M365.1,171.7c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2-0.1-0.2c-0.4-0.3-0.8-0.4-1.3-0.5c0,0-0.1,0-0.1,0.1
		c0,0.5,0,0.9,0.1,1.4c0,0.2,0.2,0.2,0.3,0.2c0.2,0.1,0.4,0.1,0.5-0.1c0.1-0.1,0.2-0.2,0.3-0.3l0,0c0,0,0,0,0,0
		C364.9,171.9,365,171.8,365.1,171.7"
            />
            <path
              fill="#003046"
              d="M49.6,82.9c0.3-0.1,0.5-0.5,0.5-0.8c0-0.1-0.3-0.4-0.5-0.4c-0.3,0.1-0.7,0.1-1.1,0.2
		c-0.1,0-0.1,0.1-0.1,0.2c0,0.2,0.1,0.4,0.2,0.5C48.9,82.9,49.2,83,49.6,82.9"
            />
            <path
              fill="#003046"
              d="M280.3,70.7c0.4,0.2,1,0,1.2-0.4c0,0,0-0.1,0-0.1c-0.1-0.3-0.3-0.6-0.6-0.7c-0.1,0-0.2-0.1-0.3,0
		c-0.6,0.1-0.8,0.5-0.5,1C280.2,70.5,280.2,70.6,280.3,70.7"
            />
            <path
              fill="#003046"
              d="M246.6,141.3c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.2c0.5,0.1,0.9,0.2,1.4,0.4
		c0,0,0.1,0,0.1,0c0.4-0.1,0.8-0.1,1.1-0.2c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2-0.1-0.2c-0.3-0.2-0.7-0.4-1.1-0.3
		C247.4,141.4,247,141.4,246.6,141.3"
            />
            <path
              fill="#003046"
              d="M143.3,162.8c-0.2-0.1-0.4,0-0.6-0.1c-0.2,0-0.5,0-0.8,0.1c-0.1,0-0.2,0.2-0.2,0.3c0,0.1,0.1,0.3,0.2,0.3
		c0.1,0,0.2,0.1,0.3,0.1c0.5,0.1,1,0,1.5-0.3c0.1-0.1,0.1-0.2,0-0.2C143.6,163,143.5,162.9,143.3,162.8"
            />
            <path
              fill="#003046"
              d="M361.9,168.6c-0.1,0-0.1,0.2-0.1,0.2c0.1,0.5,0.3,0.9,0.6,1.3c0.1,0.2,0.5,0.2,0.6,0c0.2-0.3,0.2-0.6,0-0.9
		c-0.1-0.2-0.3-0.4-0.4-0.5C362.3,168.5,362.1,168.5,361.9,168.6"
            />
            <path
              fill="#003046"
              d="M360.3,197.4c0.5,0.1,0.8,0.4,1.3,0.6c0.1,0.1,0.3,0,0.4,0c0.2,0,0.3-0.3,0.2-0.4c0-0.1-0.1-0.2-0.2-0.2
		c-0.3-0.4-0.8-0.5-1.4-0.5c0,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.1,0.2C360.2,197.3,360.3,197.4,360.3,197.4"
            />
            <path
              fill="#003046"
              d="M135,51.3c0.1,0.1,0.3,0,0.4,0c0.3-0.5,0.3-1,0.4-1.5c0-0.1,0-0.2,0-0.4c0-0.1-0.1-0.2-0.2-0.2
		c-0.1,0-0.2,0-0.2,0.1c-0.2,0.4-0.4,0.8-0.5,1.1c-0.1,0.2-0.1,0.4,0,0.6C134.8,51.1,134.9,51.2,135,51.3"
            />
            <path
              fill="#003046"
              d="M403.2,192.2c0,0-0.1,0-0.2,0c0,0.1-0.1,0.3-0.1,0.4c0.1,0.4,0.3,0.8,0.6,1.1c0.1,0.1,0.5,0.1,0.7,0
		c0.2-0.1,0.2-0.4,0.1-0.6C403.9,192.7,403.5,192.5,403.2,192.2"
            />
            <path
              fill="#003046"
              d="M139.6,94.1c-0.5,0.3-0.7,0.8-0.5,1.3c0,0.1,0.2,0.2,0.3,0.3c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.3-0.4,0.5-0.7
		c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0-0.4-0.1-0.5C140,94.1,139.8,94,139.6,94.1"
            />
            <path
              fill="#003046"
              d="M389.2,85.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.3,0.4-0.2,0.6c0.1,0.3,0.2,0.6,0.3,0.9c0,0,0.1,0,0.1,0.1
		c0,0,0.1-0.1,0.1-0.1c0.1-0.4,0.4-0.6,0.6-1c0.1-0.2,0.1-0.5-0.2-0.8C389.5,85.1,389.3,85.1,389.2,85.1"
            />
            <path
              fill="#003046"
              d="M150,124.5C150,124.5,150,124.5,150,124.5C150,124.5,150,124.5,150,124.5C150,124.5,150,124.5,150,124.5
		C150,124.5,150,124.5,150,124.5"
            />
            <path
              fill="#003046"
              d="M150.9,125.1c0.2,0,0.4,0,0.6,0c0.1-0.9,0.1-0.7-0.6-1c-0.4-0.2-0.8-0.3-1.1-0.6c0,0-0.2,0.1-0.2,0.1
		c0,0,0,0.1,0,0.1c0.2,0.3,0.3,0.5,0.5,0.8c0.2,0,0.3,0.1,0.5,0.1C150.7,124.7,150.8,124.9,150.9,125.1"
            />
            <path
              fill="#003046"
              d="M150.9,125.1L150.9,125.1C150.8,125.1,150.9,125.1,150.9,125.1C150.9,125.1,150.9,125.1,150.9,125.1
		C150.9,125.1,150.9,125.1,150.9,125.1"
            />
            <path
              fill="#003046"
              d="M383.5,119.7c0.4-0.6,0.8-1.1,1.3-1.6c0,0,0-0.2,0-0.3c0,0-0.1-0.1-0.1-0.1c-0.2,0-0.3,0.2-0.5,0.2
		c-0.3,0.1-0.5,0.2-0.6,0.5c-0.1,0.3-0.3,0.5-0.4,0.8c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.1,0.1,0.2C383.4,119.7,383.5,119.7,383.5,119.7
		"
            />
            <path
              fill="#003046"
              d="M370.2,190c0.1-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.2c-0.4-0.4-1-0.4-1.3,0c-0.1,0.1-0.1,0.3,0,0.4
		c0.3,0.3,0.6,0.4,1,0.3C370,190.2,370.1,190.1,370.2,190"
            />
            <path
              fill="#003046"
              d="M203.6,69.3c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.5,0.3,0.6c0.3,0.1,0.5,0.2,0.8,0c0,0,0.1-0.1,0.1-0.1
		c0,0,0.1-0.1,0-0.1c-0.1-0.3-0.2-0.5-0.4-0.8C204.1,69,203.7,69,203.6,69.3"
            />
            <path
              fill="#003046"
              d="M41.6,97.1c-0.1,0-0.2,0-0.3,0.1c-0.4,0.4-0.7,0.8-1,1.1c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1
		c0.5,0,1-0.2,1.4-0.6c0.1-0.1,0.1-0.2,0.1-0.3C42,97.3,41.8,97.1,41.6,97.1"
            />
            <path
              fill="#003046"
              d="M145.5,43.7c0.1,0.1,0.3,0.1,0.4,0c0.2-0.3,0.4-0.5,0.5-0.8c0-0.1,0-0.2-0.1-0.3c-0.1-0.2-0.3-0.2-0.5-0.2
		c-0.4,0.1-0.8,0.5-0.5,0.9C145.4,43.4,145.4,43.6,145.5,43.7"
            />
            <path
              fill="#003046"
              d="M146,54.5c0,0,0,0.1,0.1,0.1c0.2,0.1,0.4,0.2,0.7,0c0.2-0.1,0.4-0.3,0.4-0.5c0-0.4-0.3-0.7-0.6-0.7
		c-0.1,0-0.2,0.1-0.2,0.1C146,53.8,146,54.2,146,54.5"
            />
            <path
              fill="#003046"
              d="M39.6,98c-0.1,0-0.3,0-0.4,0.1c-0.4,0.3-0.7,0.6-1,1c-0.1,0.1-0.1,0.2,0,0.2c0,0,0.1,0,0.1,0
		c0.5-0.2,1-0.5,1.4-1c0,0,0-0.1,0-0.1C39.7,98.1,39.6,98,39.6,98"
            />
            <path
              fill="#003046"
              d="M339.2,50.4c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.4,0.3,0.7,0.6,0.8c0.1,0,0.3,0,0.4-0.1
		c0.1-0.1,0.1-0.3,0.1-0.4C339.8,50.8,339.5,50.6,339.2,50.4"
            />
            <path
              fill="#003046"
              d="M138,49.9c0.1,0.3,0.4,0.5,0.7,0.5c0.2,0,0.5-0.2,0.4-0.4c0-0.4-0.4-0.7-0.7-0.7
		C138.1,49.3,137.9,49.6,138,49.9"
            />
            <path
              fill="#003046"
              d="M129.1,47C129.1,47,129.1,47,129.1,47L129.1,47c-0.1,0.2-0.1,0.4-0.2,0.5c-0.1,0.2-0.1,0.5,0.1,0.7
		c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.3,0.2,0.5,0.3c0.2,0,0.4,0.1,0.6,0.1c0.3,0,0.6-0.1,0.9-0.4c0.2-0.2,0.4-0.4,0.4-0.7
		c0-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.2,0.6-0.4c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.1-0.2-0.2-0.2c-0.6-0.2-1.2-0.3-1.8-0.4
		c-0.5,0-0.9,0.1-1.4,0.3l0,0c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0.1-0.1,0.1-0.1c0-0.3,0-0.5-0.1-0.8c0-0.2-0.3-0.3-0.4-0.2
		c-0.2,0-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5,0,0.7C128.7,46.9,128.9,46.9,129.1,47"
            />
            <path
              fill="#003046"
              d="M389,107.7c0.3-0.4,0.5-0.9,0.4-1.4c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.2,0c0,0-0.1,0-0.1,0.1
		c-0.2,0.4-0.4,0.7-0.5,1.1c0,0.1,0.1,0.2,0.1,0.4c0,0,0.1,0,0.1,0C388.8,107.8,388.9,107.8,389,107.7"
            />
            <path
              fill="#003046"
              d="M136.5,107.1c0.3-0.1,0.6-0.2,0.8-0.5c0-0.1,0-0.2,0-0.3c-0.1-0.2-0.3-0.3-0.4-0.3
		c-0.2,0.1-0.4,0.1-0.5,0.2c-0.2,0.1-0.2,0.3-0.2,0.4c0,0.1,0,0.1,0,0.1C136.1,107,136.3,107.1,136.5,107.1"
            />
            <path
              fill="#003046"
              d="M331.7,48.5c0,0,0.1,0,0.1,0c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0-0.3-0.1-0.4c-0.1-0.4-0.3-0.7-0.7-0.8
		c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.2,0c0,0,0,0.1,0,0.1C331,47.6,331.3,48.1,331.7,48.5"
            />
            <path
              fill="#003046"
              d="M134.6,62.8c0.2,0,0.3-0.1,0.5-0.3c0.1,0,0.1-0.2,0.1-0.2c0,0-0.1-0.1-0.1-0.1c-0.4-0.1-0.7,0-1.1,0
		c-0.3,0-0.5,0.2-0.5,0.5C133.8,62.8,134.2,62.9,134.6,62.8"
            />
            <path
              fill="#003046"
              d="M131.3,57.8c0.2,0,0.5-0.1,0.6-0.2c0.1-0.2,0.1-0.4,0-0.6c-0.1-0.1-0.1-0.1-0.2-0.1
		c-0.3,0.1-0.6,0.3-0.7,0.6C131,57.5,131.2,57.8,131.3,57.8"
            />
            <path
              fill="#003046"
              d="M227.4,59.1c0,0.1-0.1,0.1-0.1,0.2c0.1,0.7,0.4,1.1,1,1.5c0,0,0.1,0,0.1-0.1c0,0,0-0.1,0-0.1
		c-0.2-0.5-0.6-0.9-0.8-1.4C227.5,59.1,227.4,59.1,227.4,59.1"
            />
            <path
              fill="#003046"
              d="M141.9,92.2c-0.1,0.1-0.1,0.3,0.1,0.6c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.1,0.4,0
		c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2-0.1-0.4-0.3-0.5C142.3,92.1,142.1,92,141.9,92.2"
            />
            <path
              fill="#003046"
              d="M132.9,111.9c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.1,0,0.2,0.1,0.2c0.2,0.1,0.4,0.1,0.6,0.2c0.1,0,0.2,0,0.2,0
		c0.1,0,0.2-0.1,0.2-0.1c0.1-0.1,0.1-0.3,0-0.4C133.5,111.8,133.1,111.7,132.9,111.9"
            />
            <path
              fill="#003046"
              d="M365.6,100.4c-0.2-0.1-0.5,0-0.7,0.3c-0.1,0.2,0,0.4,0.3,0.5c0.1,0,0.2,0,0.3,0.1c0.1-0.1,0.2-0.1,0.3-0.2
		C366,100.8,365.9,100.5,365.6,100.4"
            />
            <path
              fill="#003046"
              d="M376.2,234.3c-0.1,0.1-0.1,0.2-0.1,0.3c0,0,0.1,0.1,0.1,0.1c0.2,0.1,0.4,0.1,0.5,0.1c0.2,0,0.3,0,0.4,0
		c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.2-0.1c-0.1-0.1-0.1-0.3-0.2-0.3C376.9,234,376.5,234,376.2,234.3"
            />
            <path
              fill="#003046"
              d="M288.6,69.1c-0.1,0-0.2,0-0.3,0c-0.3,0.1-0.4,0.6-0.2,0.8c0.2,0.2,0.4,0.3,0.6,0.2c0.1,0,0.1-0.1,0.2-0.2
		C289,69.6,288.9,69.3,288.6,69.1"
            />
            <path
              fill="#003046"
              d="M378.8,192.4c0,0-0.1,0-0.1,0c-0.2,0.3-0.4,0.6-0.4,1c0,0.1,0.1,0.2,0.1,0.2c0,0,0.1,0,0.1,0
		c0.4-0.2,0.6-0.6,0.4-1.2C379,192.4,379,192.4,378.8,192.4"
            />
            <path
              fill="#003046"
              d="M374.3,200.2c0.2,0.1,0.4-0.1,0.5-0.3c0.1-0.1,0-0.3-0.1-0.3c-0.1,0-0.3,0-0.4-0.1c-0.2,0-0.4,0-0.6,0.1
		c-0.1,0-0.1,0.1-0.2,0.2c0,0.1,0,0.2,0.1,0.2C373.8,200,374.1,200.1,374.3,200.2"
            />
            <path
              fill="#003046"
              d="M61,89.7c-0.2,0.2-0.5,0.4-0.6,0.7c0.3,0.1,0.6,0.1,0.9,0c0.1,0,0.2-0.1,0.2-0.1c0.1-0.1,0-0.4-0.1-0.5
		C61.4,89.7,61.2,89.6,61,89.7"
            />
            <path
              fill="#003046"
              d="M60.4,90.5c-0.2,0.1-0.4,0.3-0.7,0.2c-0.3-0.1-0.5,0-0.6,0.3c0,0.1-0.1,0.1-0.2,0.2c0,0-0.1,0-0.1,0
		c-1,0.2-1.2,0.4-1.2,1.4c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.3,0.2,0.3c0,0.1,0.2,0.1,0.3,0.1c0.5-0.5,1-0.8,1.6-1
		c0.3-0.1,0.5-0.4,0.8-0.5c0.2-0.1,0.3-0.4,0.2-0.6c0-0.1-0.1-0.2-0.3-0.3C60.6,90.7,60.5,90.6,60.4,90.5
		C60.4,90.5,60.5,90.5,60.4,90.5C60.4,90.4,60.4,90.4,60.4,90.5L60.4,90.5z"
            />
            <path
              fill="#003046"
              d="M405.4,258.3c-0.2,0.2-0.4,0.5-0.4,0.8c0,0,0.1,0.1,0.1,0.1c0.2,0,0.4-0.1,0.5-0.1c0.3-0.1,0.3-0.4,0.2-0.7
		C405.7,258.2,405.4,258.2,405.4,258.3"
            />
            <path
              fill="#003046"
              d="M147.9,172.8c0,0.2,0.1,0.4,0.3,0.4c0.3,0,0.5-0.2,0.5-0.4c0-0.2-0.1-0.5-0.4-0.5c-0.2,0-0.4,0.1-0.4,0.3
		C147.9,172.7,147.9,172.8,147.9,172.8"
            />
            <path
              fill="#003046"
              d="M263,47.6c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.2-0.1-0.5-0.3-0.5c-0.2,0-0.4,0-0.5,0.1c-0.2,0.1-0.2,0.4,0,0.6
		C262.5,47.5,262.8,47.6,263,47.6"
            />
            <path
              fill="#003046"
              d="M283.2,170c-0.2,0-0.3,0-0.3,0c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1,0.1,0.4,0.3,0.4c0.3,0,0.5,0,0.7-0.3
		c0.1-0.1,0-0.2-0.1-0.2C283.4,170,283.2,170,283.2,170"
            />
            <path
              fill="#003046"
              d="M146.5,79c-0.1,0.1-0.2,0.1-0.2,0.1c-0.2,0.2-0.6,0.2-0.7,0.4c0,0.1,0,0.2,0,0.3c0,0,0.1,0.1,0.1,0.1
		c0.1,0,0.3,0,0.4,0c0.2-0.1,0.4-0.3,0.7-0.5c0.1-0.1,0.1-0.3,0-0.4C146.7,78.9,146.6,78.9,146.5,79"
            />
            <path
              fill="#003046"
              d="M416.7,204.8c-0.1-0.3-0.3-0.5-0.5-0.6c-0.1,0.1-0.2,0.1-0.2,0.2c0,0.2,0,0.5,0,0.7c0,0.2,0.3,0.3,0.5,0.2
		C416.7,205.2,416.8,205,416.7,204.8"
            />
            <path
              fill="#003046"
              d="M149.9,83.2c0.1,0,0.1-0.1,0.2-0.2c0-0.1,0-0.2-0.1-0.3c-0.1-0.2-0.3-0.3-0.6-0.3c-0.2,0-0.3,0.1-0.3,0.2
		c-0.1,0.1,0,0.3,0.1,0.4C149.4,83.2,149.7,83.2,149.9,83.2"
            />
            <path
              fill="#003046"
              d="M410.6,197.2c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0-0.1c-0.1-0.2-0.2-0.3-0.3-0.5c0,0,0,0,0,0
		c-0.1-0.2-0.3-0.4-0.4-0.6c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0.3-0.1,0.4C410,196.7,410.3,197,410.6,197.2"
            />
            <path
              fill="#003046"
              d="M258.9,50c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0.1,0,0.2,0.1,0.2
		c0.4,0,0.7,0.2,1,0.3c0-0.1,0-0.2,0-0.4C259.3,50,259.2,50,258.9,50"
            />
            <path
              fill="#003046"
              d="M259.3,50.7C259.3,50.7,259.3,50.7,259.3,50.7C259.3,50.7,259.3,50.7,259.3,50.7
		C259.3,50.7,259.3,50.7,259.3,50.7C259.3,50.7,259.3,50.7,259.3,50.7"
            />
            <path
              fill="#003046"
              d="M296,54.4c0.4,0,0.7-0.2,0.8-0.6c0-0.1-0.1-0.2-0.1-0.2c0,0-0.1,0-0.1,0c-0.2,0.1-0.4,0.3-0.6,0.4
		c-0.1,0-0.1,0.2-0.1,0.2C295.9,54.2,296,54.4,296,54.4"
            />
            <path
              fill="#003046"
              d="M303.2,47.8c0,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.2-0.1-0.2c-0.3-0.1-0.7,0.2-1.1-0.1c0,0-0.1,0.1-0.1,0.1
		c0,0,0,0.1,0,0.1C302.3,48,302.8,48.1,303.2,47.8"
            />
            <path
              fill="#003046"
              d="M162.3,223.5C162.3,223.5,162.3,223.5,162.3,223.5C162.3,223.5,162.3,223.5,162.3,223.5
		C162.3,223.5,162.3,223.5,162.3,223.5C162.3,223.5,162.3,223.5,162.3,223.5"
            />
            <path
              fill="#003046"
              d="M161.4,224.1c-0.1,0.2-0.3,0.3-0.4,0.5c-0.1,0.1-0.1,0.2-0.1,0.3c0.8-0.1,1.2-0.6,1.4-1.3c0,0,0-0.1,0-0.1
		c-0.1,0-0.2,0.1-0.3,0.1C161.8,223.8,161.6,224,161.4,224.1"
            />
            <path
              fill="#003046"
              d="M161.4,224.1C161.4,224.1,161.5,224.1,161.4,224.1C161.5,224.1,161.4,224.1,161.4,224.1
		C161.4,224.1,161.4,224.1,161.4,224.1C161.4,224.1,161.4,224.1,161.4,224.1"
            />
            <path
              fill="#003046"
              d="M188.7,41.5c0.1,0,0.2,0,0.3,0.1c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.2,0-0.5-0.3-0.5c-0.2,0-0.3,0-0.4,0.3
		C188.4,41.2,188.5,41.4,188.7,41.5"
            />
            <path
              fill="#003046"
              d="M367.9,188c0.2,0,0.4,0,0.6,0c0.1,0,0.1-0.1,0.2-0.1c0,0-0.1-0.1-0.1-0.1c-0.4-0.1-0.8-0.1-1.2-0.1
		c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0,0.2,0.1,0.2C367.4,188,367.6,188,367.9,188L367.9,188z"
            />
            <path
              fill="#003046"
              d="M409.5,197.7c0.1,0,0.2-0.1,0.2-0.1c0-0.1,0-0.2-0.1-0.2c-0.2-0.2-0.4-0.4-0.7-0.3c-0.1,0-0.2,0.1-0.2,0.1
		c-0.1,0.1,0,0.3,0.1,0.4C409,197.6,409.3,197.6,409.5,197.7"
            />
            <path
              fill="#003046"
              d="M382,121.3C382,121.4,382,121.4,382,121.3C382,121.4,382,121.4,382,121.3C382,121.4,382,121.4,382,121.3
		C382,121.4,382.1,121.4,382,121.3"
            />
            <path
              fill="#003046"
              d="M382.7,120.1c-0.1,0-0.2-0.1-0.2,0c-0.1,0-0.1,0.1-0.2,0.2c-0.2,0.3-0.2,0.7-0.2,1c0.4-0.4,0.6-0.7,0.7-1.1
		C382.8,120.2,382.7,120.2,382.7,120.1"
            />
            <path
              fill="#003046"
              d="M256.3,52.5c0,0.1,0,0.2,0.1,0.2c0.2,0.2,0.6,0.1,0.7-0.1c0,0,0-0.1,0-0.1c-0.1-0.2-0.2-0.3-0.3-0.4
		c-0.2-0.1-0.3,0-0.5,0.1C256.4,52.3,256.3,52.4,256.3,52.5"
            />
            <path
              fill="#003046"
              d="M262.8,51.4c0,0.2,0.2,0.3,0.4,0.3c0.3,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.3-0.4-0.3
		C262.9,51,262.7,51.2,262.8,51.4"
            />
            <path
              fill="#003046"
              d="M335.2,183.8c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.2c0,0.3,0.2,0.5,0.4,0.6c0,0,0.1,0,0.1,0
		c0.1-0.1,0.1-0.1,0.2-0.2C335.7,184.2,335.5,184,335.2,183.8"
            />
            <path
              fill="#003046"
              d="M258.4,51.7c0.3-0.1,0.5-0.4,0.8-0.6c-0.3,0-0.6,0-0.9,0c-0.2,0-0.3,0.2-0.3,0.4c0,0.1,0.1,0.2,0.1,0.2
		C258.2,51.7,258.3,51.7,258.4,51.7"
            />
            <path
              fill="#003046"
              d="M68.7,86.8C68.7,86.8,68.8,86.7,68.7,86.8C68.8,86.7,68.7,86.7,68.7,86.8C68.7,86.7,68.7,86.8,68.7,86.8
		C68.7,86.8,68.7,86.8,68.7,86.8"
            />
            <path
              fill="#003046"
              d="M385.8,117.2c0.4-0.2,0.5-0.5,0.5-0.9c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.3,0.2-0.4,0.5-0.5,0.8
		c0,0,0,0.1,0.1,0.1C385.7,117.2,385.7,117.2,385.8,117.2"
            />
            <path
              fill="#003046"
              d="M212.4,107.9C212.4,107.9,212.4,107.9,212.4,107.9L212.4,107.9L212.4,107.9
		C212.4,107.9,212.4,107.9,212.4,107.9"
            />
            <path
              fill="#003046"
              d="M213.1,107.1c0-0.1,0-0.2-0.1-0.2c0,0-0.1,0-0.1,0c-0.2,0.1-0.4,0.2-0.5,0.5c0,0.2,0,0.3,0,0.5
		c0.1,0,0.2,0,0.2-0.1C212.9,107.7,213,107.4,213.1,107.1"
            />
            <path
              fill="#003046"
              d="M347.3,188.7c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c0.1,0.2,0.3,0.3,0.5,0.2c0.1,0,0.2-0.1,0.3-0.2
		c0-0.1,0-0.1,0-0.2C347.9,188.8,347.5,188.6,347.3,188.7"
            />
            <path
              fill="#003046"
              d="M408.3,195.7c0.1,0.1,0.2,0,0.2-0.1c0-0.1,0-0.2,0-0.2c-0.2-0.3-0.5-0.5-0.8-0.5c0,0-0.1,0-0.1,0.1
		c0,0,0,0.1,0,0.1C407.8,195.3,408,195.5,408.3,195.7"
            />
            <path
              fill="#003046"
              d="M126,75.5c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.1,0.5c0,0.1,0.1,0.2,0.2,0.1
		c0.3-0.2,0.5-0.4,0.6-0.7c0,0,0-0.1-0.1-0.1C126.1,75.5,126,75.5,126,75.5"
            />
            <path
              fill="#003046"
              d="M336.9,186.9c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0.1-0.1,0.2c0.1,0.2,0.2,0.4,0.4,0.5c0.1,0,0.1,0,0.1,0
		c0.1-0.1,0.1-0.1,0.1-0.2C337.3,187.2,337.1,187,336.9,186.9"
            />
            <path
              fill="#003046"
              d="M150.7,99.2c-0.1,0.1-0.2,0.2-0.3,0.3c0,0,0,0.1,0,0.1c0.1,0.1,0.1,0.1,0.2,0.1c0.1-0.1,0.2-0.1,0.3-0.2
		c0.1-0.1,0.1-0.2,0.1-0.3C151,99.2,150.8,99.1,150.7,99.2"
            />
            <path
              fill="#003046"
              d="M410.5,198.4c0,0,0,0.1,0,0.1c0.1,0.2,0.3,0.4,0.5,0.4c0.1,0,0.2-0.1,0.3-0.1c0-0.1,0-0.1,0-0.2
		c-0.1-0.2-0.4-0.3-0.6-0.3C410.6,198.2,410.6,198.3,410.5,198.4"
            />
            <path
              fill="#003046"
              d="M137.4,53.4c0,0-0.1-0.1-0.1-0.1c-0.3-0.1-0.5-0.1-0.8,0.1c0,0-0.1,0.2,0,0.2c0.1,0.1,0.2,0.1,0.3,0.2
		c0.2-0.1,0.3-0.2,0.5-0.3C137.4,53.5,137.4,53.4,137.4,53.4"
            />
            <path
              fill="#003046"
              d="M136.8,53.8C136.8,53.9,136.8,53.9,136.8,53.8C136.8,53.9,136.8,53.9,136.8,53.8
		C136.8,53.9,136.8,53.9,136.8,53.8C136.8,53.9,136.8,53.9,136.8,53.8"
            />
            <path
              fill="#003046"
              d="M138.5,85.8c-0.1,0-0.1,0.1-0.2,0.1c0,0.1,0,0.2,0,0.3c0.1,0.1,0.2,0.3,0.4,0.4c0,0,0.1,0,0.1,0
		c0.1,0,0.1-0.1,0.1-0.2C138.8,86.2,138.7,85.9,138.5,85.8"
            />
            <path
              fill="#003046"
              d="M154.5,97.1c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.2,0.1,0.2,0.1
		c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2-0.1-0.3-0.1-0.4C154.6,97.2,154.5,97.1,154.5,97.1"
            />
            <path
              fill="#003046"
              d="M400.4,189.8c0,0.5,0.1,0.9,0.3,1.3c0,0,0.1,0,0.1,0c0.1,0,0.2-0.2,0.2-0.3
		C401,190.3,400.8,190,400.4,189.8"
            />
            <path
              fill="#003046"
              d="M259.6,44.4c0.1,0,0.1,0,0.2,0c0.2,0,0.3-0.3,0.3-0.4c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0-0.4,0.2-0.3,0.3
		C259.4,44.2,259.5,44.3,259.6,44.4"
            />
            <path
              fill="#003046"
              d="M263.1,44.4c0.2,0,0.3-0.2,0.3-0.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3,0.2-0.2,0.3
		C263,44.3,263.1,44.3,263.1,44.4"
            />
            <path
              fill="#003046"
              d="M126.3,49.7c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.3-0.2c0-0.1-0.1-0.2-0.1-0.2
		c-0.1-0.1-0.3-0.1-0.4-0.1C126.5,49.6,126.3,49.6,126.3,49.7"
            />
            <path
              fill="#003046"
              d="M343.8,184.4c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.5,0
		c0.2,0.1,0.4,0.3,0.7,0.4C343.6,184.5,343.7,184.4,343.8,184.4"
            />
            <path
              fill="#003046"
              d="M374.5,185.7c0,0-0.1,0.1,0,0.1c0,0.1,0.1,0.2,0.1,0.2c0.2,0,0.3,0,0.5,0c0,0,0.1-0.1,0.1-0.1
		c0,0,0-0.1,0-0.1C375,185.5,374.7,185.5,374.5,185.7"
            />
            <path
              fill="#003046"
              d="M287.6,62.3c0.1,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.4,0.1-0.4,0.3
		C287.4,62.1,287.5,62.2,287.6,62.3"
            />
            <path
              fill="#003046"
              d="M138.5,58.5c0.1,0,0.2-0.1,0.3-0.1c-0.3-0.3-0.7-0.3-1.1-0.2C137.9,58.7,137.9,58.7,138.5,58.5"
            />
            <path
              fill="#003046"
              d="M137.7,58.2C137.7,58.2,137.7,58.1,137.7,58.2C137.7,58.1,137.7,58.1,137.7,58.2
		C137.7,58.1,137.7,58.2,137.7,58.2C137.7,58.2,137.7,58.2,137.7,58.2"
            />
            <path
              fill="#003046"
              d="M366.5,172.9c-0.2,0.1-0.3,0.2-0.4,0.2c0.2,0.4,0.4,0.5,0.6,0.3c0.2-0.1,0.3-0.3,0.2-0.5
		C366.8,172.9,366.6,172.9,366.5,172.9"
            />
            <path
              fill="#003046"
              d="M366.3,172.2c0,0,0-0.1,0-0.1c-0.1-0.1-0.1-0.1-0.2,0c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.3,0,0.6,0,0.9
		c0.1,0,0.2,0,0.3,0c0.1-0.2,0.2-0.4,0.2-0.6C366.2,172.4,366.3,172.3,366.3,172.2"
            />
            <path
              fill="#003046"
              d="M365.7,172.3L365.7,172.3C365.7,172.3,365.7,172.3,365.7,172.3C365.7,172.3,365.7,172.3,365.7,172.3
		C365.7,172.3,365.7,172.3,365.7,172.3"
            />
            <path
              fill="#003046"
              d="M366,173.1C366,173.2,366,173.2,366,173.1C366,173.2,366,173.2,366,173.1C366,173.2,366,173.2,366,173.1
		L366,173.1z"
            />
            <path
              fill="#003046"
              d="M148.8,167.4c0-0.1-0.2-0.1-0.2-0.1c-0.1,0-0.1,0.2-0.1,0.2c0.1,0.1,0.1,0.2,0.3,0.3c0,0,0.1-0.1,0.3-0.1
		C149,167.6,148.9,167.5,148.8,167.4"
            />
            <path
              fill="#003046"
              d="M148.7,165.5c-0.1-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.4,0.1-0.5,0.3c0,0.1,0,0.2,0.1,0.2
		C148.3,165.8,148.6,165.7,148.7,165.5"
            />
            <path
              fill="#003046"
              d="M143.3,91.9c-0.1,0-0.2,0.1-0.1,0.2c0.1,0.2,0.2,0.3,0.4,0.4c0.1,0,0.2-0.1,0.3-0.1c0-0.1,0-0.1,0-0.2
		C143.8,91.9,143.6,91.8,143.3,91.9"
            />
            <path
              fill="#003046"
              d="M129.8,154.5c-0.1,0-0.3,0.2-0.3,0.4c0,0.1,0.1,0.2,0.3,0.2c0.1,0,0.3-0.1,0.3-0.2
		C130,154.6,129.9,154.5,129.8,154.5"
            />
            <path
              fill="#003046"
              d="M131.1,152.9c0.2-0.2,0.2-0.6-0.1-0.8c0,0-0.1,0-0.1,0c-0.1,0.3-0.1,0.5,0.1,0.7
		C130.9,152.9,131,152.9,131.1,152.9"
            />
            <path
              fill="#003046"
              d="M129.3,152.1c0,0,0,0.2,0,0.2c0,0,0.1,0.1,0.1,0.1c0.2,0,0.4-0.1,0.5-0.2c0,0,0-0.1,0-0.1
		c0,0-0.1-0.1-0.1-0.1C129.7,152,129.5,152,129.3,152.1"
            />
            <path
              fill="#003046"
              d="M134.9,159.7C135,159.7,135,159.6,134.9,159.7c0.1-0.2,0.1-0.3,0-0.3c-0.1-0.1-0.3-0.1-0.4,0
		c-0.1,0.1-0.1,0.2-0.1,0.2c0,0.1,0.1,0.1,0.2,0.1C134.8,159.7,134.8,159.7,134.9,159.7"
            />
            <path
              fill="#003046"
              d="M399.7,189.4C399.7,189.5,399.7,189.5,399.7,189.4C399.8,189.5,399.8,189.5,399.7,189.4
		C399.8,189.5,399.8,189.5,399.7,189.4C399.7,189.5,399.7,189.4,399.7,189.4"
            />
            <path
              fill="#003046"
              d="M398.7,188.9c0.3,0.3,0.6,0.5,1,0.5C399.5,189,399.1,188.9,398.7,188.9"
            />
            <path
              fill="#003046"
              d="M370.5,186.4c0.1,0.1,0.2,0.2,0.3,0.1c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0,0,0,0l0,0c0.1,0,0.2,0,0.3,0
		c-0.1-0.2-0.1-0.4-0.2-0.7c0-0.1,0-0.3,0.2-0.3c0,0,0.1,0,0.1,0c0.3-0.1,0.4-0.1,0.3-0.5c0-0.1,0-0.3,0.1-0.4
		c0.1-0.1,0.3-0.3,0.3-0.5c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.3,0-0.3-0.2c-0.1-0.1,0-0.2,0.2-0.3
		c0.1,0,0.2-0.1,0.2-0.1c0,0,0-0.2,0-0.2c0,0-0.1-0.1-0.1-0.1c-1,0.3-1.2,0.9-1.2,1.8c0,0.2,0.1,0.4,0.1,0.6c0.1,0.3,0.1,0.6,0.2,1
		l0,0c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.2,0.1C370.5,186.2,370.4,186.3,370.5,186.4"
            />
            <path
              fill="#003046"
              d="M329.3,169.1c-0.1,0-0.1,0-0.2,0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.2
		c0-0.1,0.1-0.1,0.1-0.2c0-0.2,0-0.4,0-0.6C329.4,169.2,329.3,169.2,329.3,169.1"
            />
            <path
              fill="#003046"
              d="M374.9,194.9c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0,0.1,0c0.2-0.1,0.3-0.2,0.3-0.4c0-0.1-0.1-0.2-0.1-0.2
		c-0.1,0-0.2,0-0.2,0.1C375,194.7,374.9,194.8,374.9,194.9"
            />
            <path
              fill="#003046"
              d="M284.5,70.6c0,0,0-0.1,0.1-0.1c0-0.1,0-0.3-0.1-0.4c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.2,0.1
		c-0.1,0.2,0,0.4,0.1,0.5C284.4,70.6,284.5,70.6,284.5,70.6"
            />
            <path
              fill="#003046"
              d="M148.2,79.2c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.3,0.1-0.3,0.3c0,0.1,0.1,0.2,0.1,0.3c0.1,0,0.3,0,0.3-0.1
		C148.2,79.5,148.3,79.3,148.2,79.2"
            />
            <path
              fill="#003046"
              d="M365.3,140.5c0-0.1-0.1-0.3-0.2-0.2c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1-0.2,0.3-0.1,0.3
		c0.1,0.1,0.2,0.1,0.2,0.1C365.2,140.8,365.3,140.7,365.3,140.5"
            />
            <path
              fill="#003046"
              d="M351.9,218.3c0,0-0.2-0.1-0.2-0.1c-0.1,0-0.1,0.2-0.1,0.2c0,0.2,0.1,0.3,0.2,0.5c0.1,0,0.2,0,0.3,0
		C352,218.7,352,218.5,351.9,218.3"
            />
            <path
              fill="#003046"
              d="M351.7,218.9L351.7,218.9L351.7,218.9C351.7,219,351.7,218.9,351.7,218.9
		C351.7,218.9,351.7,218.9,351.7,218.9"
            />
            <path
              fill="#003046"
              d="M256.4,52.1C256.4,52.1,256.4,52.1,256.4,52.1C256.4,52.2,256.4,52.2,256.4,52.1
		C256.4,52.1,256.4,52.1,256.4,52.1C256.4,52.1,256.4,52.1,256.4,52.1"
            />
            <path
              fill="#003046"
              d="M256,51.6c-0.1,0-0.2,0.1-0.2,0.1c0,0.1,0,0.3,0.1,0.3c0.1,0.1,0.3,0,0.5,0.1c-0.1-0.2-0.1-0.3-0.2-0.5
		C256.2,51.6,256.1,51.6,256,51.6"
            />
            <path
              fill="#003046"
              d="M361.5,170.4c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.2,0.1-0.2,0.3c0,0.1,0,0.2,0.1,0.2
		C361.2,170.8,361.5,170.6,361.5,170.4"
            />
            <path
              fill="#003046"
              d="M368.9,195.2c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1,0-0.1,0
		c0,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.3,0.2C368.6,195.1,368.7,195.2,368.9,195.2"
            />
            <path
              fill="#003046"
              d="M206.1,48.1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.2c-0.1-0.1-0.3,0-0.3,0.2
		C205.8,47.9,205.9,48,206.1,48.1"
            />
            <path
              fill="#003046"
              d="M270.6,199.2c0.1-0.2,0-0.3-0.1-0.4c-0.1,0-0.3,0.1-0.3,0.2c0,0.1,0.1,0.2,0.1,0.2
		C270.5,199.4,270.6,199.3,270.6,199.2"
            />
            <path
              fill="#003046"
              d="M205.5,57.5c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0.1,0.1,0.1c0.1,0,0.2,0,0.2,0c0.1-0.1,0.1-0.2,0.1-0.3
		C205.7,57.5,205.6,57.5,205.5,57.5"
            />
            <path
              fill="#003046"
              d="M242.8,137.6c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.2-0.1-0.2,0c-0.1,0.1-0.1,0.2-0.1,0.2c0,0.1,0.1,0.2,0.2,0.2
		c0.2,0,0.3,0,0.5,0c0-0.1,0-0.2,0-0.3C242.9,137.7,242.8,137.6,242.8,137.6"
            />
            <path
              fill="#003046"
              d="M243,138L243,138C243,138,243,138,243,138L243,138C243,138,243,138,243,138"
            />
            <path
              fill="#003046"
              d="M382.8,242.3c0-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.2c-0.1,0-0.2,0-0.2,0.1
		c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0.1,0.1,0.2,0.2C382.7,242.4,382.8,242.4,382.8,242.3"
            />
            <path
              fill="#003046"
              d="M371.1,187.7c0.1,0,0.1-0.1,0.2-0.1c0,0,0-0.1,0-0.1c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3,0.2-0.2,0.3
		C370.8,187.7,370.9,187.8,371.1,187.7"
            />
            <path
              fill="#003046"
              d="M138.3,244.2c0,0,0.1-0.1,0.1-0.1c0-0.2-0.1-0.3-0.2-0.4c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.2,0,0.3,0.2,0.4
		C138.2,244.3,138.2,244.3,138.3,244.2"
            />
            <path
              fill="#003046"
              d="M380.6,203c0.1,0.1,0.2,0.2,0.3,0.2c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1,0-0.2-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.1
		C380.6,202.7,380.5,202.8,380.6,203"
            />
            <path
              fill="#003046"
              d="M186.5,43.3C186.5,43.3,186.6,43.2,186.5,43.3c0.2-0.3,0-0.5-0.1-0.5c0,0-0.1,0-0.1,0
		c-0.1,0.1-0.1,0.2,0,0.4C186.3,43.3,186.4,43.3,186.5,43.3"
            />
            <path
              fill="#003046"
              d="M417.2,206.3c-0.1-0.1-0.1-0.2-0.2-0.3c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.1,0.3,0,0.5,0.2,0.6
		c0.1,0,0.2-0.1,0.2-0.1C417.2,206.4,417.2,206.4,417.2,206.3"
            />
            <path
              fill="#003046"
              d="M266.8,77.6c-0.1,0-0.2,0.1-0.2,0.1c0,0,0,0.1,0,0.1c0.1,0.1,0.2,0.3,0.4,0.4c0.1,0,0.2,0,0.2,0
		c0.1,0,0.1-0.2,0-0.4C267.1,77.7,267,77.6,266.8,77.6"
            />
            <path
              fill="#003046"
              d="M348.1,180.4c-0.1-0.1-0.2-0.2-0.2-0.2c-0.2,0-0.3,0.2-0.2,0.3c0,0.1,0.1,0.1,0.2,0.2
		C348,180.8,348.1,180.6,348.1,180.4"
            />
            <path
              fill="#003046"
              d="M366.1,168.2c-0.1,0.1,0,0.3,0.1,0.3c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0-0.2-0.1-0.2
		C366.3,168.1,366.2,168.1,366.1,168.2"
            />
            <path
              fill="#003046"
              d="M148.9,168.6c0-0.1-0.1-0.2-0.2-0.1c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.2,0.1,0.2
		C148.8,168.8,148.9,168.7,148.9,168.6"
            />
            <path
              fill="#003046"
              d="M405.2,193.8C405.2,193.8,405.2,193.8,405.2,193.8C405.2,193.7,405.2,193.7,405.2,193.8
		C405.2,193.8,405.2,193.8,405.2,193.8C405.2,193.8,405.2,193.8,405.2,193.8"
            />
            <path
              fill="#003046"
              d="M405.7,194.3c0.1,0,0.2-0.1,0.2-0.1c0,0,0-0.1-0.1-0.1c-0.2-0.3-0.4-0.4-0.7-0.3
		C405.2,194.1,405.4,194.3,405.7,194.3"
            />
            <path
              fill="#003046"
              d="M140.4,57.5c-0.2,0-0.3,0.2-0.3,0.4c0,0,0.1,0.1,0.1,0.1C140.3,58,140.6,57.8,140.4,57.5
		C140.4,57.6,140.4,57.5,140.4,57.5"
            />
            <path
              fill="#003046"
              d="M370,148.2c0,0,0-0.1,0-0.1c-0.1-0.1-0.2-0.1-0.3,0c-0.2,0.1-0.2,0.3,0,0.3c0.1,0,0.2,0,0.2-0.1
		C370,148.3,370,148.3,370,148.2"
            />
            <path
              fill="#003046"
              d="M139.2,75.6c-0.1,0.1,0.1,0.3,0.2,0.3c0.3,0,0.4-0.1,0.5-0.3c-0.2,0-0.3-0.1-0.5-0.1
		C139.4,75.5,139.3,75.6,139.2,75.6"
            />
            <path
              fill="#003046"
              d="M140,75.6C140,75.6,140,75.6,140,75.6C140,75.6,140,75.6,140,75.6C140,75.6,140,75.6,140,75.6
		C140,75.6,140,75.6,140,75.6"
            />
            <path
              fill="#003046"
              d="M161.7,57.4c-0.1-0.1-0.2-0.1-0.4-0.1c0,0-0.1,0-0.1,0.1c0,0,0,0.1,0,0.1c0.1,0.1,0.2,0.2,0.3,0.2
		c0.1,0,0.1-0.1,0.2-0.1C161.8,57.5,161.8,57.4,161.7,57.4"
            />
            <path
              fill="#003046"
              d="M380.7,187.7c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.2,0
		c0,0-0.1,0.1-0.1,0.1C380.6,187.6,380.7,187.7,380.7,187.7"
            />
            <path
              fill="#003046"
              d="M247.7,135.2c0-0.1,0-0.2-0.1-0.2c0,0-0.1,0-0.1,0c-0.1,0.1-0.1,0.1,0,0.2
		C247.5,135.3,247.6,135.3,247.7,135.2"
            />
            <path
              fill="#003046"
              d="M290.9,54.8c0.1-0.1,0-0.3-0.1-0.3c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1,0,0.3,0.1,0.3
		C290.7,54.9,290.8,54.9,290.9,54.8"
            />
            <path
              fill="#003046"
              d="M406.1,195.7c0.1,0,0.2,0,0.2,0c0,0,0-0.2,0-0.2c-0.1-0.2-0.3-0.2-0.5-0.2
		C405.8,195.4,405.9,195.6,406.1,195.7"
            />
            <path
              fill="#003046"
              d="M78.8,101.9c0-0.2-0.1-0.3-0.2-0.3c-0.1,0-0.1,0.2-0.1,0.3c0,0.1,0.1,0.1,0.2,0.1
		C78.8,102,78.8,101.9,78.8,101.9"
            />
            <path
              fill="#003046"
              d="M335.1,52.7c0.1,0.3,0.3,0.4,0.6,0.3c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1-0.1-0.1
		C335.6,52.6,335.3,52.7,335.1,52.7"
            />
            <path
              fill="#003046"
              d="M380.9,186.8c0,0,0-0.1,0.1-0.1c0-0.2-0.2-0.3-0.4-0.3c0,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.2,0.2,0.3
		C380.7,186.8,380.8,186.8,380.9,186.8"
            />
            <path
              fill="#003046"
              d="M153.7,60.4C153.7,60.4,153.7,60.4,153.7,60.4C153.7,60.4,153.7,60.4,153.7,60.4
		C153.7,60.4,153.7,60.4,153.7,60.4C153.7,60.4,153.7,60.4,153.7,60.4"
            />
            <path
              fill="#003046"
              d="M153.4,60.9c0.1,0,0.2,0,0.2-0.1c0-0.1,0.1-0.2,0.1-0.4c-0.1,0-0.2,0-0.3,0.1
		C153.2,60.7,153.3,60.9,153.4,60.9"
            />
            <path
              fill="#003046"
              d="M394.1,188c0,0,0-0.1,0-0.1c-0.1-0.1-0.2-0.1-0.4-0.1c0,0-0.1,0-0.1,0.1c0,0.1,0,0.2,0.1,0.2
		C393.8,188.2,394,188.2,394.1,188"
            />
            <path
              fill="#003046"
              d="M416.1,212.9c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.1-0.2-0.1c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.2,0.1,0.2
		C416,213.1,416.1,213.1,416.1,212.9"
            />
            <path
              fill="#003046"
              d="M172.5,81.2c0-0.1-0.1-0.2-0.2-0.1c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.2,0,0.2c0.1,0.1,0.2,0.1,0.3,0.2
		c0-0.1,0-0.1,0-0.2C172.6,81.3,172.5,81.3,172.5,81.2"
            />
            <path
              fill="#003046"
              d="M138.8,245c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.1,0.2c0,0.2,0.1,0.3,0.1,0.5c0.1-0.1,0.2-0.2,0.2-0.3
		C138.9,245.2,138.8,245.1,138.8,245"
            />
            <path
              fill="#003046"
              d="M138.6,245.5C138.6,245.5,138.6,245.5,138.6,245.5C138.6,245.6,138.6,245.6,138.6,245.5
		C138.6,245.6,138.6,245.6,138.6,245.5C138.6,245.6,138.6,245.5,138.6,245.5"
            />
            <path
              fill="#003046"
              d="M305.1,50.4c0.1-0.1,0-0.3-0.1-0.3c-0.1-0.1-0.2,0-0.2,0.1c-0.1,0.1,0,0.3,0.1,0.4
		C305,50.5,305.1,50.5,305.1,50.4"
            />
            <path
              fill="#003046"
              d="M187.4,41.4c0.1,0,0.1-0.1,0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c0,0-0.1,0-0.1,0c-0.1,0.1-0.1,0.2,0,0.2
		C187.2,41.4,187.3,41.4,187.4,41.4"
            />
            <path
              fill="#003046"
              d="M255.4,48C255.4,48,255.4,47.9,255.4,48c0.1-0.2,0.1-0.3,0-0.3c-0.1,0-0.2,0.1-0.2,0.2
		C255.2,48,255.2,48,255.4,48"
            />
            <path
              fill="#003046"
              d="M387.1,242.5c-0.1,0-0.1,0.1-0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0,0.2-0.1c0-0.1,0-0.2,0-0.3
		C387.3,242.5,387.2,242.4,387.1,242.5"
            />
            <path
              fill="#003046"
              d="M139,253.1c-0.1,0.1-0.1,0.3,0,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0-0.2,0-0.4,0-0.5
		C139.3,253,139.1,253,139,253.1"
            />
            <path
              fill="#003046"
              d="M398,197.4c-0.1-0.4-0.2-0.4-0.6-0.3c-0.1,0-0.1,0.1-0.2,0.1C397.5,197.2,397.7,197.3,398,197.4
		C398,197.4,398,197.4,398,197.4"
            />
            <path
              fill="#003046"
              d="M397.2,197.1C397.2,197.1,397.1,197.1,397.2,197.1C397.1,197.1,397.1,197.2,397.2,197.1L397.2,197.1
		C397.2,197.2,397.2,197.2,397.2,197.1"
            />
            <path
              fill="#003046"
              d="M123.3,76.4c0,0,0.1,0.1,0.1,0.1c0.1,0,0.2,0,0.2-0.1c0-0.1-0.1-0.2-0.2-0.1
		C123.3,76.2,123.3,76.3,123.3,76.4"
            />
            <path
              fill="#003046"
              d="M203.4,151c0,0,0.1,0,0.1,0c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1,0.1-0.2,0.1-0.2,0.2
		C203.3,150.9,203.3,151,203.4,151"
            />
            <path
              fill="#003046"
              d="M368.7,150.8C368.7,150.7,368.6,150.7,368.7,150.8c-0.2-0.1-0.3,0-0.3,0.2c0,0,0,0.1,0.1,0.1
		c0,0,0.1,0,0.1,0C368.6,150.9,368.7,150.8,368.7,150.8"
            />
            <path
              fill="#003046"
              d="M132.9,155.5c-0.1-0.1-0.1-0.1-0.2-0.2c0,0.1-0.1,0.1-0.1,0.2c0,0,0.1,0.1,0.1,0.1
		C132.8,155.6,132.9,155.6,132.9,155.5"
            />
            <path
              fill="#003046"
              d="M130.1,156c0.1,0,0.1-0.1,0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0.1-0.1,0.1-0.2,0.2
		C129.9,155.9,130.1,156.1,130.1,156"
            />
            <path
              fill="#003046"
              d="M134,157.5C134,157.4,134,157.4,134,157.5C134,157.4,134,157.4,134,157.5
		C133.9,157.4,133.9,157.4,134,157.5C134,157.5,134,157.5,134,157.5"
            />
            <path
              fill="#003046"
              d="M134.3,157.7C134.3,157.7,134.3,157.7,134.3,157.7c0.1-0.2,0.1-0.3-0.1-0.3c-0.1,0-0.1,0-0.2,0
		c0,0.1,0,0.2,0.1,0.2C134.1,157.8,134.2,157.8,134.3,157.7"
            />
            <path
              fill="#003046"
              d="M171.1,77.7c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1,0,0.2,0.1,0.2
		C171,77.8,171.1,77.8,171.1,77.7"
            />
            <path
              fill="#003046"
              d="M123.6,158.6c0,0.1,0,0.2,0.1,0.2c0.1,0,0.2,0,0.2-0.1c0-0.1,0-0.2-0.1-0.2
		C123.8,158.4,123.7,158.5,123.6,158.6"
            />
            <path
              fill="#003046"
              d="M150.5,169.3c0,0.1,0,0.2,0.1,0.2c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2-0.1-0.2
		C150.6,169.2,150.5,169.3,150.5,169.3"
            />
            <path
              fill="#003046"
              d="M147.3,78.2c-0.1-0.1-0.1-0.1-0.2,0c0,0,0,0.1-0.1,0.1c0.1,0,0.1,0.1,0.2,0.1
		C147.3,78.4,147.3,78.3,147.3,78.2"
            />
            <path
              fill="#003046"
              d="M363.9,170.1c0-0.1-0.1-0.1-0.1-0.2c-0.1,0.1-0.1,0.1-0.2,0.2C363.7,170.2,363.8,170.2,363.9,170.1
		C363.9,170.2,363.9,170.2,363.9,170.1"
            />
            <path
              fill="#003046"
              d="M328.9,170.9c-0.1,0.1-0.1,0.3,0.1,0.3c0,0,0.1-0.1,0.1-0.1C329.1,171,329,170.9,328.9,170.9
		C329,170.9,328.9,170.9,328.9,170.9"
            />
            <path
              fill="#003046"
              d="M364.2,177.3c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.1,0.2c0,0.1,0.1,0.1,0.2,0.1
		C364.2,177.5,364.2,177.4,364.2,177.3"
            />
            <path
              fill="#003046"
              d="M373.8,187.7c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1,0,0.2,0.1,0.2c0.2,0,0.3-0.1,0.3-0.2
		C373.9,187.8,373.8,187.7,373.8,187.7"
            />
            <path
              fill="#003046"
              d="M204.9,62.6c0.1,0,0.1-0.1,0.2-0.1c0,0,0-0.1,0-0.1C205,62.4,204.9,62.4,204.9,62.6
		C204.9,62.5,204.9,62.6,204.9,62.6"
            />
            <path
              fill="#003046"
              d="M367.3,195.6C367.3,195.6,367.3,195.6,367.3,195.6c-0.2-0.1-0.4-0.1-0.4,0c0,0.1,0.1,0.1,0.1,0.2
		C367.2,195.9,367.4,195.7,367.3,195.6"
            />
            <path
              fill="#003046"
              d="M398.4,197.7c0,0,0-0.1-0.1-0.1c-0.1,0-0.2,0-0.3-0.1c0.1,0.1,0.1,0.2,0.2,0.3
		C398.4,197.9,398.4,197.8,398.4,197.7"
            />
            <path
              fill="#003046"
              d="M398.1,197.5C398.1,197.5,398,197.5,398.1,197.5C398,197.5,398,197.5,398.1,197.5
		C398,197.5,398,197.5,398.1,197.5C398,197.5,398,197.5,398.1,197.5"
            />
            <path
              fill="#003046"
              d="M383.5,206.6C383.5,206.6,383.4,206.6,383.5,206.6C383.4,206.6,383.4,206.6,383.5,206.6
		C383.4,206.6,383.4,206.6,383.5,206.6C383.5,206.6,383.5,206.6,383.5,206.6"
            />
            <path
              fill="#003046"
              d="M383.8,206.3c0,0-0.1,0-0.1,0c-0.1,0.1-0.1,0.2-0.2,0.3c0.1,0,0.2,0,0.3-0.1
		C383.9,206.5,383.9,206.4,383.8,206.3"
            />
            <path
              fill="#003046"
              d="M351.6,219c-0.3,0.1-0.3,0.3-0.1,0.5c0.1,0,0.1,0.1,0.2,0.1c0-0.2,0-0.4,0-0.5
		C351.7,218.9,351.6,218.9,351.6,219"
            />
            <path
              fill="#003046"
              d="M207.2,47.4c0-0.1-0.1-0.1-0.1-0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0.1,0.1,0.1,0.2
		C207.1,47.6,207.2,47.5,207.2,47.4"
            />
            <path
              fill="#003046"
              d="M260.9,47.1c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0.1-0.1,0.2s0.1,0.2,0.2,0.3c0.1,0,0.2,0,0.3,0
		C261,47.4,261,47.2,260.9,47.1"
            />
            <path
              fill="#003046"
              d="M134.5,65.3c-0.1,0-0.2,0.1-0.2,0.1c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.3-0.1,0.3-0.2
		C134.6,65.4,134.5,65.4,134.5,65.3"
            />
            <path
              fill="#003046"
              d="M284.7,68.7c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1,0,0.2,0.1,0.2
		C284.6,68.9,284.6,68.8,284.7,68.7"
            />
            <path
              fill="#003046"
              d="M69.7,86.6c0,0-0.1,0.1-0.1,0.1c0,0.2,0.1,0.3,0.2,0.3c0,0,0.1-0.1,0.1-0.1C70,86.7,69.8,86.5,69.7,86.6"
            />
            <path
              fill="#003046"
              d="M232,87.4c0,0,0.1,0,0.1,0c0.1-0.1,0.1-0.2,0.2-0.3c-0.1,0-0.2,0.1-0.3,0.1C231.9,87.2,231.9,87.3,232,87.4
		"
            />
            <path
              fill="#003046"
              d="M232.3,87.1C232.4,87.1,232.4,87.1,232.3,87.1C232.4,87,232.4,87,232.3,87.1C232.4,87,232.4,87,232.3,87.1
		C232.4,87,232.4,87.1,232.3,87.1"
            />
            <path
              fill="#003046"
              d="M253.4,92.9c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0,0.1-0.1c0-0.1,0-0.2-0.2-0.2
		C253.5,92.9,253.5,92.9,253.4,92.9"
            />
            <path
              fill="#003046"
              d="M149.5,94.5c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.1,0.2c0.1,0.1,0.2,0,0.2-0.1c0-0.1,0-0.2,0-0.2
		C149.6,94.5,149.5,94.5,149.5,94.5"
            />
            <path
              fill="#003046"
              d="M297.4,55.2c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.2,0.1,0.2c0.1,0,0.2-0.1,0.1-0.2
		C297.5,55.2,297.5,55.2,297.4,55.2"
            />
            <path
              fill="#003046"
              d="M388.4,106.3C388.4,106.2,388.3,106.2,388.4,106.3c-0.1-0.1-0.1-0.1-0.2-0.1c0,0,0,0.1,0,0.1
		C388.3,106.4,388.4,106.4,388.4,106.3"
            />
            <path
              fill="#003046"
              d="M377,132.6c0-0.1,0.1-0.1,0.2-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1,0.1-0.1,0.1-0.1,0.2
		C376.9,132.5,377,132.5,377,132.6"
            />
            <path
              fill="#003046"
              d="M222.9,135.7c0-0.1-0.1-0.1-0.2-0.2c-0.2,0-0.3,0.2-0.2,0.3c0.1,0.1,0.2,0.1,0.2,0.1
		C222.8,135.9,222.9,135.8,222.9,135.7"
            />
            <path
              fill="#003046"
              d="M250.9,140.7c0,0,0.1,0,0.1,0c0.1-0.1,0.1-0.1,0.2-0.2c-0.1,0-0.1-0.1-0.2-0.1
		C250.9,140.3,250.8,140.5,250.9,140.7C250.8,140.7,250.9,140.7,250.9,140.7"
            />
            <path
              fill="#003046"
              d="M116.8,148.6C116.8,148.6,116.8,148.6,116.8,148.6C116.8,148.5,116.8,148.5,116.8,148.6
		C116.8,148.6,116.8,148.6,116.8,148.6C116.8,148.6,116.8,148.6,116.8,148.6"
            />
            <path
              fill="#003046"
              d="M117,148.9c0.1,0.1,0.2,0,0.2-0.1c0,0,0-0.1,0-0.1c-0.1,0-0.2-0.1-0.4-0.1
		C116.8,148.7,116.9,148.8,117,148.9"
            />
            <path
              fill="#003046"
              d="M137.9,61.3C137.9,61.3,137.9,61.3,137.9,61.3c0-0.1,0-0.1,0-0.1C137.8,61.3,137.8,61.3,137.9,61.3"
            />
            <path
              fill="#003046"
              d="M125.4,61.5C125.4,61.5,125.4,61.5,125.4,61.5C125.5,61.4,125.5,61.4,125.4,61.5
		C125.3,61.4,125.3,61.4,125.4,61.5"
            />
            <path
              fill="#003046"
              d="M364.3,100.9C364.3,100.9,364.4,100.9,364.3,100.9c0.2,0.1,0.2,0,0.2-0.1c0,0-0.1-0.1-0.1-0.1
		C364.4,100.7,364.4,100.8,364.3,100.9"
            />
            <path
              fill="#003046"
              d="M291.5,63.4c0.1-0.1,0-0.1,0-0.1C291.4,63.2,291.4,63.3,291.5,63.4C291.4,63.3,291.5,63.4,291.5,63.4"
            />
            <polygon
              fill="#003046"
              points="291.3,64.3 291.4,64.3 291.3,64.3 	"
            />
            <polygon
              fill="#003046"
              points="145.5,81.3 145.6,81.3 145.6,81.2 	"
            />
            <polygon fill="#003046" points="273.9,81.8 274,81.7 273.9,81.7 	" />
            <polygon
              fill="#003046"
              points="248.1,139.3 248.2,139.3 248.2,139.2 	"
            />
            <polygon fill="#003046" points="357.9,175.6 358,175.6 358,175.6 	" />
            <polygon
              fill="#003046"
              points="418.5,209.8 418.5,209.7 418.5,209.7 	"
            />
            <polygon fill="#003046" points="235.6,141 235.7,141 235.7,140.9 	" />
            <polygon
              fill="#003046"
              points="146.4,163.3 146.5,163.3 146.4,163.2 	"
            />
            <polygon
              fill="#003046"
              points="221.3,136.7 221.5,136.7 221.4,136.6 	"
            />
            <polygon
              fill="#003046"
              points="368.4,181.3 368.5,181.3 368.4,181.3 	"
            />
            <polygon
              fill="#003046"
              points="333.2,182.2 333.3,182.2 333.2,182.1 	"
            />
            <polygon
              fill="#003046"
              points="261.1,49.2 261.2,49.1 261.1,49.1 	"
            />
            <polygon
              fill="#003046"
              points="298.1,54.7 298.3,54.8 298.2,54.7 298.1,54.7 	"
            />
            <polygon
              fill="#003046"
              points="135.8,60.9 135.9,60.9 135.8,60.8 	"
            />
            <polygon
              fill="#003046"
              points="289.7,65.7 289.8,65.8 289.8,65.7 	"
            />
            <path
              fill="#003046"
              d="M144,259.9C144,259.9,144,259.9,144,259.9L144,259.9C144,259.8,144,259.8,144,259.9
		C144,259.8,144,259.9,144,259.9"
            />
            <path
              fill="#003046"
              d="M144,259.9h-0.2C143.8,260,143.8,260,144,259.9"
            />
            <path
              fill="#003046"
              d="M237.9,108.9c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0,0.3-0.2c0.3-0.4,0.3-0.8,0.3-1.3c-0.2,0-0.4,0-0.6,0.2
		c-0.2,0.2-0.3,0.5-0.2,0.8C237.8,108.8,237.8,108.8,237.9,108.9"
            />
            <path
              fill="#003046"
              d="M239.3,103.4c0,0,0.1,0,0.1,0c0.1-0.1,0.1-0.3,0.1-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.1,0-0.2,0.1
		c-0.1,0.1-0.1,0.2-0.1,0.4C239,103.3,239.1,103.4,239.3,103.4"
            />
            <path
              fill="#003046"
              d="M234.8,112.4C234.8,112.4,234.8,112.3,234.8,112.4c0-0.2,0-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.2,0
		c0,0.1,0,0.2,0,0.2C234.6,112.4,234.7,112.4,234.8,112.4"
            />
            <path
              fill="#003046"
              d="M242.2,105.8c0.1,0.1,0.2,0,0.3-0.1c0,0,0-0.1,0-0.1c0-0.1-0.2-0.2-0.3-0.1c-0.1,0-0.1,0.1-0.1,0.2
		C242,105.6,242.1,105.7,242.2,105.8"
            />
            <path
              fill="#003046"
              d="M238.7,107.6L238.7,107.6C238.7,107.6,238.7,107.7,238.7,107.6C238.7,107.7,238.7,107.7,238.7,107.6
		L238.7,107.6z"
            />
            <path
              fill="#003046"
              d="M241.8,107.1L241.8,107.1C241.8,107.1,241.8,107.1,241.8,107.1C241.8,107,241.8,107,241.8,107.1
		L241.8,107.1z"
            />
          </g>
          <g id="chile">
            <path
              fill="#A08FBC"
              d="M138.9,227.7c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3
		C136.6,226.7,137.7,227.7,138.9,227.7"
            />
            <g>
              <path
                fill="#FFFFFF"
                d="M114.5,226.2c-0.6,0-1-0.2-1.3-0.5c-0.3-0.3-0.4-0.8-0.4-1.5v-1.9c0-0.7,0.1-1.2,0.4-1.5
			c0.3-0.3,0.7-0.5,1.3-0.5c0.6,0,1,0.2,1.2,0.5c0.3,0.3,0.4,0.8,0.4,1.5l-1.1,0.1c0-0.5-0.1-0.8-0.2-1c-0.1-0.2-0.2-0.3-0.4-0.3
			c-0.2,0-0.4,0.1-0.5,0.3c-0.1,0.2-0.1,0.5-0.1,1v1.8c0,0.4,0,0.8,0.1,0.9s0.3,0.3,0.5,0.3c0.2,0,0.4-0.1,0.4-0.3
			c0.1-0.2,0.1-0.5,0.2-1l1.1,0.1c0,0.7-0.2,1.2-0.4,1.5C115.4,226,115,226.2,114.5,226.2z"
              />
              <path
                fill="#FFFFFF"
                d="M117,226.1v-5.8h1.1v2.2h1.1v-2.2h1.1v5.8h-1.1v-2.8h-1.1v2.8H117z"
              />
              <path
                fill="#FFFFFF"
                d="M121.2,226.1v-0.8h1.1v-4.1h-1.1v-0.8h3.3v0.8h-1.1v4.1h1.1v0.8H121.2z"
              />
              <path fill="#FFFFFF" d="M125.7,226.1v-5.8h1.1v5h1.9v0.8H125.7z" />
              <path
                fill="#FFFFFF"
                d="M129.7,226.1v-5.8h3.3v0.8h-2.2v1.4h1.9v0.8h-1.9v1.9h2.2v0.8H129.7z"
              />
            </g>
            <a href="#divchile">
              <rect
                x="106.3"
                y="213.4"
                fill="transparent"
                width="39.7"
                height="20.7"
              />
            </a>
          </g>
          <g id="uruguai">
            <path
              fill="#A08FBC"
              d="M160.2,227.7c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3
		C157.9,226.7,158.9,227.7,160.2,227.7"
            />
            <g>
              <path
                fill="#FFFFFF"
                d="M167.7,231.6c-0.6,0-1-0.2-1.2-0.5s-0.4-0.8-0.4-1.5v-3.8h1.1v3.9c0,0.4,0,0.7,0.1,0.9
			c0.1,0.2,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2c0.1-0.2,0.1-0.5,0.1-0.9v-3.9h1.1v3.8c0,0.7-0.1,1.2-0.4,1.5
			C168.6,231.5,168.2,231.6,167.7,231.6z"
              />
              <path
                fill="#FFFFFF"
                d="M171.4,231.6h-1.1v-5.8h1.8c0.5,0,0.8,0.1,1.1,0.4c0.2,0.3,0.4,0.7,0.4,1.2v0.5c0,0.4-0.1,0.7-0.2,1
			s-0.3,0.4-0.6,0.5l0.9,2.3h-1.2l-0.9-2.2h-0.2V231.6z M171.4,228.6h0.5c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.3,0.1-0.6v-0.5
			c0-0.3,0-0.5-0.1-0.6c-0.1-0.1-0.2-0.2-0.4-0.2h-0.5V228.6z"
              />
              <path
                fill="#FFFFFF"
                d="M176.2,231.6c-0.6,0-1-0.2-1.2-0.5s-0.4-0.8-0.4-1.5v-3.8h1.1v3.9c0,0.4,0,0.7,0.1,0.9
			c0.1,0.2,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2c0.1-0.2,0.1-0.5,0.1-0.9v-3.9h1.1v3.8c0,0.7-0.1,1.2-0.4,1.5
			C177.1,231.5,176.7,231.6,176.2,231.6z"
              />
              <path
                fill="#FFFFFF"
                d="M180.4,231.6c-0.5,0-1-0.2-1.2-0.5s-0.4-0.8-0.4-1.4v-2c0-0.6,0.1-1.1,0.4-1.5c0.3-0.3,0.7-0.5,1.2-0.5
			c0.5,0,0.9,0.1,1.2,0.4c0.3,0.3,0.4,0.8,0.4,1.4l-1.1,0.1c0-0.4-0.1-0.7-0.1-0.8c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.4,0.3
			s-0.1,0.5-0.1,0.9v1.9c0,0.4,0,0.7,0.1,0.9s0.2,0.3,0.5,0.3c0.2,0,0.4-0.1,0.4-0.3c0.1-0.2,0.1-0.5,0.1-0.9h-0.5v-0.8h1.6v1
			c0,0.6-0.1,1.1-0.4,1.5S181,231.6,180.4,231.6z"
              />
              <path
                fill="#FFFFFF"
                d="M184.7,231.6c-0.6,0-1-0.2-1.2-0.5s-0.4-0.8-0.4-1.5v-3.8h1.1v3.9c0,0.4,0,0.7,0.1,0.9
			c0.1,0.2,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2c0.1-0.2,0.1-0.5,0.1-0.9v-3.9h1.1v3.8c0,0.7-0.1,1.2-0.4,1.5
			C185.7,231.5,185.2,231.6,184.7,231.6z"
              />
              <path
                fill="#FFFFFF"
                d="M189.8,231.6l-0.2-1.5h-1.2l-0.3,1.5h-1l1.2-5.8h1.4l1.2,5.8H189.8z M189.4,229.2l-0.4-2.6l-0.5,2.6H189.4
			z"
              />
              <path
                fill="#FFFFFF"
                d="M191.5,231.6v-0.8h1.1v-4.1h-1.1v-0.8h3.3v0.8h-1.1v4.1h1.1v0.8H191.5z"
              />
            </g>
            <a href="#divuruguai">
              <rect
                x="153"
                y="218.9"
                fill="transparent"
                width="48.3"
                height="18.6"
              />
            </a>
          </g>
          <g id="portugal">
            <path
              fill="#A08FBC"
              d="M211.2,139.8c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3
		C208.9,138.8,209.9,139.8,211.2,139.8"
            />
            <g>
              <path
                fill="#FFFFFF"
                d="M172.5,140.4v-5.8h1.7c0.5,0,0.9,0.1,1.2,0.4s0.4,0.6,0.4,1.2v0.9c0,0.5-0.1,0.9-0.4,1.1
			c-0.3,0.2-0.7,0.4-1.2,0.4h-0.6v1.9H172.5z M173.6,137.6h0.5c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.3,0.1-0.6v-0.7
			c0-0.3,0-0.5-0.1-0.6c-0.1-0.1-0.2-0.2-0.4-0.2h-0.5V137.6z"
              />
              <path
                fill="#FFFFFF"
                d="M178.4,140.5c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.4-0.8-0.4-1.4v-2c0-0.6,0.1-1.1,0.4-1.5
			c0.3-0.3,0.7-0.5,1.2-0.5c0.5,0,0.9,0.2,1.2,0.5s0.4,0.8,0.4,1.5v2c0,0.6-0.1,1.1-0.4,1.4C179.4,140.3,179,140.5,178.4,140.5z
			 M178.4,139.6c0.2,0,0.3-0.1,0.4-0.2c0.1-0.2,0.1-0.5,0.1-0.9v-1.9c0-0.5,0-0.8-0.1-0.9c-0.1-0.2-0.2-0.3-0.4-0.3
			s-0.3,0.1-0.4,0.3c-0.1,0.2-0.1,0.5-0.1,0.9v1.9c0,0.5,0,0.8,0.1,0.9C178.1,139.5,178.2,139.6,178.4,139.6z"
              />
              <path
                fill="#FFFFFF"
                d="M182.2,140.4H181v-5.8h1.8c0.5,0,0.8,0.1,1.1,0.4c0.2,0.3,0.4,0.7,0.4,1.2v0.5c0,0.4-0.1,0.7-0.2,1
			s-0.3,0.4-0.6,0.5l0.9,2.3h-1.2l-0.9-2.2h-0.2V140.4z M182.2,137.4h0.5c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.3,0.1-0.6v-0.5
			c0-0.3,0-0.5-0.1-0.6c-0.1-0.1-0.2-0.2-0.4-0.2h-0.5V137.4z"
              />
              <path
                fill="#FFFFFF"
                d="M186.4,140.4v-5h-1.2v-0.8h3.6v0.8h-1.2v5H186.4z"
              />
              <path
                fill="#FFFFFF"
                d="M191.2,140.5c-0.6,0-1-0.2-1.2-0.5s-0.4-0.8-0.4-1.5v-3.8h1.1v3.9c0,0.4,0,0.7,0.1,0.9
			c0.1,0.2,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2c0.1-0.2,0.1-0.5,0.1-0.9v-3.9h1.1v3.8c0,0.7-0.1,1.2-0.4,1.5
			C192.2,140.3,191.8,140.5,191.2,140.5z"
              />
              <path
                fill="#FFFFFF"
                d="M195.5,140.5c-0.5,0-1-0.2-1.2-0.5s-0.4-0.8-0.4-1.4v-2c0-0.6,0.1-1.1,0.4-1.5c0.3-0.3,0.7-0.5,1.2-0.5
			c0.5,0,0.9,0.1,1.2,0.4c0.3,0.3,0.4,0.8,0.4,1.4l-1.1,0.1c0-0.4-0.1-0.7-0.1-0.8c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.4,0.3
			s-0.1,0.5-0.1,0.9v1.9c0,0.4,0,0.7,0.1,0.9s0.2,0.3,0.5,0.3c0.2,0,0.4-0.1,0.4-0.3c0.1-0.2,0.1-0.5,0.1-0.9h-0.5v-0.8h1.6v1
			c0,0.6-0.1,1.1-0.4,1.5S196,140.5,195.5,140.5z"
              />
              <path
                fill="#FFFFFF"
                d="M200.5,140.4l-0.2-1.5h-1.2l-0.3,1.5h-1l1.2-5.8h1.4l1.2,5.8H200.5z M200.1,138l-0.4-2.6l-0.5,2.6H200.1z"
              />
              <path fill="#FFFFFF" d="M202.6,140.4v-5.8h1.1v5h1.9v0.8H202.6z" />
            </g>
            <a href="#divportugal">
              {" "}
              <rect
                x="168"
                y="127.5"
                fill="transparent"
                width="49.1"
                height="21.4"
              />
            </a>
          </g>
          <g id="brasil">
            <path
              fill="#A08FBC"
              d="M170.8,212.1c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3
		C168.5,211.1,169.5,212.1,170.8,212.1"
            />
            <g>
              <path
                fill="#FFFFFF"
                d="M178.2,212.9v-5.8h1.7c0.5,0,0.8,0.1,1.1,0.3c0.2,0.2,0.4,0.6,0.4,1v0.3c0,0.3-0.1,0.5-0.2,0.7
			s-0.3,0.3-0.5,0.3v0.2c0.3,0,0.5,0.2,0.6,0.4s0.2,0.5,0.2,0.8v0.3c0,0.5-0.1,0.9-0.4,1.1c-0.3,0.3-0.7,0.4-1.2,0.4H178.2z
			 M179.3,209.5h0.4c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.3,0.1-0.5v-0.3c0-0.2,0-0.4-0.1-0.5c-0.1-0.1-0.2-0.2-0.4-0.2h-0.4V209.5z
			 M179.3,212.1h0.5c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.1-0.3,0.1-0.6V211c0-0.3,0-0.5-0.1-0.6s-0.2-0.2-0.5-0.2h-0.5V212.1z"
              />
              <path
                fill="#FFFFFF"
                d="M183.6,212.9h-1.1v-5.8h1.8c0.5,0,0.8,0.1,1.1,0.4c0.2,0.3,0.4,0.7,0.4,1.2v0.5c0,0.4-0.1,0.7-0.2,1
			s-0.3,0.4-0.6,0.5l0.9,2.3h-1.2l-0.9-2.2h-0.2V212.9z M183.6,209.9h0.5c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.3,0.1-0.6v-0.5
			c0-0.3,0-0.5-0.1-0.6c-0.1-0.1-0.2-0.2-0.4-0.2h-0.5V209.9z"
              />
              <path
                fill="#FFFFFF"
                d="M189.2,212.9l-0.2-1.5h-1.2l-0.3,1.5h-1l1.2-5.8h1.4l1.2,5.8H189.2z M188.8,210.5l-0.4-2.6l-0.5,2.6H188.8
			z"
              />
              <path
                fill="#FFFFFF"
                d="M192.5,212.9c-0.3,0-0.6,0-0.8-0.1c-0.3-0.1-0.5-0.2-0.7-0.4l0.2-0.9c0.2,0.2,0.4,0.3,0.7,0.4
			c0.2,0.1,0.5,0.1,0.7,0.1c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.4-0.3-0.7-0.4
			c-0.5-0.2-0.8-0.4-1-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.1-0.9,0.4-1.2s0.7-0.5,1.2-0.5c0.3,0,0.5,0,0.8,0.1
			c0.2,0.1,0.5,0.2,0.7,0.4l-0.1,0.8c-0.2-0.2-0.4-0.3-0.6-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.5
			c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.4,0.3,0.7,0.4c0.5,0.2,0.8,0.5,1,0.7s0.3,0.6,0.3,1c0,0.5-0.2,1-0.5,1.3
			C193.5,212.8,193.1,212.9,192.5,212.9z"
              />
              <path
                fill="#FFFFFF"
                d="M195.2,212.9V212h1.1v-4.1h-1.1v-0.8h3.3v0.8h-1.1v4.1h1.1v0.8H195.2z"
              />
              <path fill="#FFFFFF" d="M199.7,212.9v-5.8h1.1v5h1.9v0.8H199.7z" />
            </g>
            <a href="#divbrasil">
              <rect
                x="162.5"
                y="200.2"
                fill="transparent"
                width="44.7"
                height="18.1"
              />
            </a>
          </g>
          <g id="mexico">
            <path
              fill="#A08FBC"
              d="M107.3,161.8c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3
		C105,160.8,106.1,161.8,107.3,161.8"
            />
            <g>
              <path
                fill="#FFFFFF"
                d="M114.7,160l-0.5-2.5l0.1,4.1h-1v-5.8h1l0.7,2.9l0.7-2.9h1v5.8h-1l0.1-4l-0.5,2.4H114.7z"
              />
              <path
                fill="#FFFFFF"
                d="M117.6,161.6v-5.8h3.3v0.8h-2.2v1.4h1.9v0.8h-1.9v1.9h2.2v0.8H117.6z M118.7,155.1l0.6-1.5h1.4l-1.2,1.5
			H118.7z"
              />
              <path
                fill="#FFFFFF"
                d="M124.2,161.6l-0.7-1.7l-0.7,1.7h-1.1l1.3-3l-1.1-2.8h1.1l0.6,1.5l0.6-1.5h1.1l-1.2,2.8l1.2,3H124.2z"
              />
              <path
                fill="#FFFFFF"
                d="M126.1,161.6v-0.8h1.1v-4.1h-1.1v-0.8h3.3v0.8h-1.1v4.1h1.1v0.8H126.1z"
              />
              <path
                fill="#FFFFFF"
                d="M132.1,161.7c-0.6,0-1-0.2-1.3-0.5c-0.3-0.3-0.4-0.8-0.4-1.5v-1.9c0-0.7,0.1-1.2,0.4-1.5
			c0.3-0.3,0.7-0.5,1.3-0.5c0.6,0,1,0.2,1.2,0.5c0.3,0.3,0.4,0.8,0.4,1.5l-1.1,0.1c0-0.5-0.1-0.8-0.2-1c-0.1-0.2-0.2-0.3-0.4-0.3
			c-0.2,0-0.4,0.1-0.5,0.3c-0.1,0.2-0.1,0.5-0.1,1v1.8c0,0.4,0,0.8,0.1,0.9s0.3,0.3,0.5,0.3c0.2,0,0.4-0.1,0.4-0.3
			c0.1-0.2,0.1-0.5,0.2-1l1.1,0.1c0,0.7-0.2,1.2-0.4,1.5C133.1,161.6,132.7,161.7,132.1,161.7z"
              />
              <path
                fill="#FFFFFF"
                d="M136.3,161.7c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.4-0.8-0.4-1.4v-2c0-0.6,0.1-1.1,0.4-1.5
			c0.3-0.3,0.7-0.5,1.2-0.5c0.5,0,0.9,0.2,1.2,0.5s0.4,0.8,0.4,1.5v2c0,0.6-0.1,1.1-0.4,1.4C137.2,161.5,136.8,161.7,136.3,161.7z
			 M136.3,160.9c0.2,0,0.3-0.1,0.4-0.2c0.1-0.2,0.1-0.5,0.1-0.9v-1.9c0-0.5,0-0.8-0.1-0.9c-0.1-0.2-0.2-0.3-0.4-0.3
			s-0.3,0.1-0.4,0.3c-0.1,0.2-0.1,0.5-0.1,0.9v1.9c0,0.5,0,0.8,0.1,0.9C135.9,160.8,136.1,160.9,136.3,160.9z"
              />
            </g>
            <a href="#divmexico">
              <rect
                x="99"
                y="148.9"
                fill="transparent"
                width="46.9"
                height="19.7"
              />
            </a>
          </g>
        </svg>
      </div>
      <div className={classes.container}>
        <div id="divbrasil"></div>
        <div className={classes.section} style={{ paddingTop: "8em" }}>
          <GridContainer justify="center">
            <GridItem cs={12} sm={12} md={12}>
              <div className={"titulotexto"} cs={12} sm={12} md={6}>
                <svg
                  version="1.1"
                  id="titulobrasil"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 203.9 123"
                  enable-background="new 0 0 203.9 123"
                >
                  <path
                    fill="#aa96c0"
                    d="M22.2,99.1c0,3.5-2.9,6.4-6.4,6.4H0v-88h15.8c3.5,0,6.4,2.8,6.4,6.2v31.3c0,3.4-2.8,6-5.8,6.4
	c1.6,0.2,2.9,1,4,2c1.2,1.2,1.8,2.6,1.8,4.2V99.1z M8.8,26.3v30.8h4.7V26.3H8.8z M13.4,96.7V65.9H8.8v30.8H13.4z"
                  />
                  <path
                    fill="#aa96c0"
                    d="M43,17.5c3.5,0,6.2,2.9,6.2,6.2v31.4c0,3.4-2.6,5.9-5.6,6.2c1.6,0.2,2.9,1,4,2.2c1.1,1.2,1.7,2.6,1.7,4.2
	v37.7h-8.8V65.9h-4.7v39.6H27v-88h8.8v5.3c0.2-1.4,0.8-2.6,1.9-3.6c1.2-1.1,2.6-1.7,4.3-1.7H43z M40.4,26.3h-4.7v30.8h4.7V26.3z"
                  />
                  <path
                    fill="#aa96c0"
                    d="M70,17.5c3.5,0,6.2,2.8,6.2,6.2v81.7h-8.8V65.9h-4.7v39.6H54V23.8c0-3.5,2.9-6.2,6.4-6.2H70z M67.4,57.1
	V26.3h-4.7v30.8H67.4z"
                  />
                  <path
                    fill="#aa96c0"
                    d="M97,17.5c3.5,0,6.2,2.8,6.2,6.2v18.5h-8.8v-16h-4.7v13.3c0,1.9,0.4,3.2,0.7,4.1l11.3,31.9
	c0.4,0.8,1.3,3.5,1.4,6.8v16.8c0,3.5-2.8,6.2-6.2,6.2h-9.6c-3.5,0-6.4-2.8-6.4-6.2V80.8h8.8v16h4.7v-13c0-2.4-0.5-3.7-0.8-4.6
	L82.7,47.6C82.3,46.8,81,43,81,38.5V23.8c0-3.5,2.9-6.2,6.4-6.2H97z"
                  />
                  <path
                    fill="#aa96c0"
                    d="M123.7,17.5v8.8h-4.3v70.4h4.3v8.8h-17.5v-8.8h4.4V26.3h-4.4v-8.8H123.7z"
                  />
                  <path
                    fill="#aa96c0"
                    d="M142.6,105.5h-15.5v-88h8.8v79.2h6.7V105.5z"
                  />
                </svg>
              </div>
            </GridItem>
            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>26/01/2019 A 24/03/2019</span>
                <br />
                OI FUTURO FLAMENGO (BR) RIO DE JANEIRO
              </h5>
            </GridItem>
            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>01/06/2019 A 29/06/2019</span>
                <br />
                TEATRO FASHION MALL (BR) RIO DE JANEIRO
              </h5>
            </GridItem>
          </GridContainer>
        </div>
        <div id="divportugal"></div>
        <div className={classes.section} style={{ paddingTop: "8em" }}>
          <GridContainer justify="center">
            <GridItem cs={12} sm={12} md={12}>
              <div className={"titulotexto"} cs={12} sm={12} md={6}>
                <svg
                  version="1.1"
                  id="tituloportugal"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 203.9 123"
                  enable-background="new 0 0 203.9 123"
                >
                  <path
                    fill="#aa96c0"
                    d="M19.1,17.5c3.5,0,6.2,2.8,6.2,6.2v35.9c0,3.5-2.8,6.2-6.2,6.2h-7.2v39.6H3.2v-88H19.1z M16.6,26.3h-4.7v30.8
	h4.7V26.3z"
                  />
                  <path
                    fill="#aa96c0"
                    d="M45.5,17.5c3.5,0,6.2,2.8,6.2,6.2v75.5c0,3.5-2.8,6.2-6.2,6.2h-9.6c-3.5,0-6.4-2.9-6.4-6.2V23.8
	c0-3.5,2.9-6.2,6.4-6.2H45.5z M43,96.7V26.3h-4.7v70.4H43z"
                  />
                  <path
                    fill="#aa96c0"
                    d="M72.2,17.5c3.5,0,6.2,2.9,6.2,6.2v31.4c0,3.4-2.6,5.9-5.6,6.2c1.6,0.2,2.9,1,4,2.2c1.1,1.2,1.7,2.6,1.7,4.2
	v37.7h-8.8V65.9H65v39.6h-8.8v-88H65v5.3c0.2-1.4,0.8-2.6,1.9-3.6c1.2-1.1,2.6-1.7,4.3-1.7H72.2z M69.6,26.3H65v30.8h4.7V26.3z"
                  />
                  <path
                    fill="#aa96c0"
                    d="M97.1,105.5h-8.8V26.3h-6.7v-8.8h22.2v8.8h-6.7V105.5z"
                  />
                  <path
                    fill="#aa96c0"
                    d="M129.6,99.2c0,3.5-2.8,6.2-6.2,6.2h-9.7c-3.4,0-6.2-2.8-6.2-6.2V17.5h8.8v79.2h4.7V17.5h8.8V99.2z"
                  />
                  <path
                    fill="#aa96c0"
                    d="M156.6,57.1v48.4h-8.8V100c-0.2,1.6-1.1,2.9-2,3.8c-1.2,1.1-2.5,1.7-4.1,1.7h-1c-3.5,0-6.4-2.9-6.4-6.2V23.8
	c0-3.5,2.9-6.2,6.4-6.2h9.6c3.5,0,6.2,2.8,6.2,6.2v18.4h-8.8V26.3h-4.7v70.4h4.7V65.9h-2.3v-8.8H156.6z"
                  />
                  <path
                    fill="#aa96c0"
                    d="M177.4,17.5c3.5,0,6.2,2.8,6.2,6.2v81.7h-8.8V65.9h-4.7v39.6h-8.8V23.8c0-3.5,2.9-6.2,6.4-6.2H177.4z
	 M174.9,57.1V26.3h-4.7v30.8H174.9z"
                  />
                  <path
                    fill="#aa96c0"
                    d="M203.9,105.5h-15.5v-88h8.8v79.2h6.7V105.5z"
                  />
                </svg>
              </div>
            </GridItem>
            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>21 A 22/01/2017</span>
                <br />
                CULTURGEST (PT) LISBOA
              </h5>
            </GridItem>

            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>01 E 02/02/2017</span>
                <br />
                TEATRO DAS FIGURAS (PT) FARO
              </h5>
            </GridItem>

            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>12/02/2017</span>
                <br />
                QUARTEL DAS ARTES (PT) OLIVEIRA DO BAIRRO
              </h5>
            </GridItem>

            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>22,23,24/02/2017</span>
                <br />
                CENTRO DE ARTES DO ESPECTÁCULO (PT) SEVER DO VOUGA
              </h5>
            </GridItem>

            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>8, 9 E 10/09/2017</span>
                <br />
                FESTIVAL TODOS (PT) LISBOA
              </h5>
            </GridItem>

            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>29 E 30 /09/2017 </span>
                <br />
                COIMBRA (PT) CONVENTO DE SÃO FRANCISCO (PT) COIMBRA
              </h5>
            </GridItem>

            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>18/10/2017</span>
                <br />
                FESTIVAL VERÃO AZUL (PT) LAGOS
              </h5>
            </GridItem>

            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>07 E 08/03/2018</span>
                <br />
                TEATRO VIRIATO (PT) VISEU
              </h5>
            </GridItem>

            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>12/04/2018</span>
                <br />
                TEATRO MUNICIPAL (PT) CASTELO BRANCO
              </h5>
            </GridItem>

            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>29/09/2018</span>
                <br />
                MANOBRAS FESTIVAL INTERNACIONAL DE MARIONETAS E FORMAS ANIMADAS
                (PT) MOITA
              </h5>
            </GridItem>

            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>07/10/2018</span>
                <br />
                MANOBRAS FESTIVAL INTERNACIONAL DE MARIONETAS E FORMAS ANIMADAS
                (PT) SOBRAL DE MONTE ABRAÇO
              </h5>
            </GridItem>

            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>09/10/2018</span>
                <br />
                FESTIVAL Y (PT) COVILHÃ
              </h5>
            </GridItem>

            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>13/10/2018</span>
                <br />
                MUSEU DO DINHEIRO (PT) LISBOA
              </h5>
            </GridItem>

            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>01/06/2019 A 29/06/2019</span>
                <br />
                TEATRO FASHION MALL (BR) RIO DE JANEIRO
              </h5>
            </GridItem>

            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>16/10/2018</span>
                <br />
                MANOBRAS FESTIVAL INTERNACIONAL DE MARIONETAS E FORMAS ANIMADAS
                (PT) ABRANTES
              </h5>
            </GridItem>

            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>18/10/2018</span>
                <br />
                MANOBRAS FESTIVAL INTERNACIONAL DE MARIONETAS E FORMAS ANIMADAS
                (PT) ALCANENA
              </h5>
            </GridItem>

            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>24 A 25/11/2018</span>
                <br />
                MUSEU DA MARIONETA (PT) LISBOA
              </h5>
            </GridItem>
          </GridContainer>
        </div>
        <div id="divchile"></div>
        <div className={classes.section} style={{ paddingTop: "8em" }}>
          <GridContainer justify="center">
            <GridItem cs={12} sm={12} md={12}>
              <div className={"titulotexto"} cs={12} sm={12} md={6}>
                <svg
                  version="1.1"
                  id="titulochile"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 203.9 123"
                  enable-background="new 0 0 203.9 123"
                >
                  <path
                    fill="#aa96c0"
                    d="M15.8,17.5c3.5,0,6.2,2.8,6.4,6.2v30.5h-8.8v-28H8.8v70.4h4.7V65.9h8.8v33.2c0,3.5-3,6.4-6.4,6.4H6.4
	c-3.5,0-6.4-2.9-6.4-6.4V23.8c0-3.5,3-6.2,6.4-6.2H15.8z"
                  />
                  <path
                    fill="#aa96c0"
                    d="M49.2,17.5v88h-8.8V65.9h-4.7v39.6H27v-88h8.8v39.6h4.7V17.5H49.2z"
                  />
                  <path
                    fill="#aa96c0"
                    d="M69.8,17.5v8.8h-4.3v70.4h4.3v8.8H52.3v-8.8h4.4V26.3h-4.4v-8.8H69.8z"
                  />
                  <path
                    fill="#aa96c0"
                    d="M88.7,105.5H73.2v-88H82v79.2h6.7V105.5z"
                  />
                  <path
                    fill="#aa96c0"
                    d="M107.8,17.5v8.8H101v30.8h6.7v8.8H101v30.8h6.7v8.8H92.3v-88H107.8z"
                  />
                </svg>
              </div>
            </GridItem>
            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>05 A 08/05/2018</span>
                <br />
                11° FESTIVAL INTERNACIONAL DE TEATRO Y DANZA FINTDAZ (CL) CHILE
              </h5>
            </GridItem>
          </GridContainer>
        </div>
        <div id="divuruguai"></div>
        <div className={classes.section} style={{ paddingTop: "8em" }}>
          <GridContainer justify="center">
            <GridItem cs={12} sm={12} md={12}>
              <div className={"titulotexto"} cs={12} sm={12} md={6}>
                <svg
                  version="1.1"
                  id="titulouruguai"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 203.9 123"
                  enable-background="new 0 0 203.9 123"
                >
                  <g>
                    <path
                      fill="#aa96c0"
                      d="M22.2,99.2c0,3.5-2.8,6.2-6.2,6.2H6.2c-3.4,0-6.2-2.8-6.2-6.2V17.5h8.8v79.2h4.7V17.5h8.8V99.2z"
                    />
                    <path
                      fill="#aa96c0"
                      d="M43,17.5c3.5,0,6.2,2.9,6.2,6.2v31.4c0,3.4-2.6,5.9-5.6,6.2c1.6,0.2,2.9,1,4,2.2c1.1,1.2,1.7,2.6,1.7,4.2
		v37.7h-8.8V65.9h-4.7v39.6H27v-88h8.8v5.3c0.2-1.4,0.8-2.6,1.9-3.6c1.2-1.1,2.6-1.7,4.3-1.7H43z M40.4,26.3h-4.7v30.8h4.7V26.3z"
                    />
                    <path
                      fill="#aa96c0"
                      d="M76.3,99.2c0,3.5-2.8,6.2-6.2,6.2h-9.7c-3.4,0-6.2-2.8-6.2-6.2V17.5h8.8v79.2h4.7V17.5h8.8V99.2z"
                    />
                    <path
                      fill="#aa96c0"
                      d="M103.3,57.1v48.4h-8.8V100c-0.2,1.6-1.1,2.9-2,3.8c-1.2,1.1-2.5,1.7-4.1,1.7h-1c-3.5,0-6.4-2.9-6.4-6.2
		V23.8c0-3.5,2.9-6.2,6.4-6.2h9.6c3.5,0,6.2,2.8,6.2,6.2v18.4h-8.8V26.3h-4.7v70.4h4.7V65.9h-2.3v-8.8H103.3z"
                    />
                    <path
                      fill="#aa96c0"
                      d="M130.3,99.2c0,3.5-2.8,6.2-6.2,6.2h-9.7c-3.4,0-6.2-2.8-6.2-6.2V17.5h8.8v79.2h4.7V17.5h8.8V99.2z"
                    />
                    <path
                      fill="#aa96c0"
                      d="M151.1,17.5c3.5,0,6.2,2.8,6.2,6.2v81.7h-8.8V65.9h-4.7v39.6h-8.8V23.8c0-3.5,2.9-6.2,6.4-6.2H151.1z
		 M148.6,57.1V26.3h-4.7v30.8H148.6z"
                    />
                    <path
                      fill="#aa96c0"
                      d="M178.4,17.5v8.8h-4.3v70.4h4.3v8.8h-17.5v-8.8h4.4V26.3h-4.4v-8.8H178.4z"
                    />
                  </g>
                </svg>
              </div>
            </GridItem>
            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>09 A 12/05/2018</span>
                <br />
                MONTEVIDEO (URU) URUGUAI
              </h5>
            </GridItem>
          </GridContainer>
        </div>
        <div id="divmexico"></div>
        <div className={classes.section} style={{ paddingTop: "8em", marginBottom: "8em" }}>
          <GridContainer justify="center">
            <GridItem cs={12} sm={12} md={12}>
              <div className={"titulotexto"} cs={12} sm={12} md={6}>
                <svg
                  version="1.1"
                  id="titulomexico"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 203.9 123"
                  enable-background="new 0 0 203.9 123"
                >
                  <path
                    fill="#aa96c0"
                    d="M29.3,24.6c3.5,0,6.4,2.8,6.4,6.2v81.7h-8.8V33.4h-4.7v79.2h-8.8V33.4H8.8v79.2H0V30.8
	c0-3.5,2.8-6.2,6.2-6.2h5.3c1.6,0,3,0.6,4.2,1.7c1.1,1.1,1.8,2.4,2,4c0.4-3,3-5.6,6.4-5.6H29.3z"
                  />
                  <path
                    fill="#aa96c0"
                    d="M48.7,64.2h6.7V73h-6.7v30.7h6.7v8.9h-9.2c-3.5,0-6.4-2.9-6.4-6.4V30.8c0-3.5,2.9-6.2,6.4-6.2h9.2v8.8h-6.7
	V64.2z M45.2,19.2v-8.8h13.4l-8.8,8.8H45.2z"
                  />
                  <path
                    fill="#aa96c0"
                    d="M83.3,112.6h-8.8l-2.6-14.9l-0.7-6.6l-0.7,6.6l-2.6,14.9H59l7.8-46.7l-7.7-41.3h8.8l2.5,14.8l0.7,6.6
	l0.7-6.6l2.6-14.8h8.8l-7.7,41.3L83.3,112.6z"
                  />
                  <path fill="#aa96c0" d="M95,112.6h-8.8v-88H95V112.6z" />
                  <path
                    fill="#aa96c0"
                    d="M115.7,24.6c3.5,0,6.4,2.8,6.4,6.2v18.4h-8.9V33.4h-4.7v70.3h4.7V87.8h8.9v18.4c0,3.5-2.9,6.4-6.4,6.4H106
	c-3.5,0-6.2-2.9-6.2-6.4V30.8c0-3.5,2.8-6.2,6.2-6.2H115.7z"
                  />
                  <path
                    fill="#aa96c0"
                    d="M142.6,24.6c3.5,0,6.2,2.8,6.2,6.2v75.5c0,3.5-2.8,6.2-6.2,6.2h-9.7c-3.5,0-6.4-2.9-6.4-6.2V30.8
	c0-3.5,2.9-6.2,6.4-6.2H142.6z M140,103.8V33.4h-4.7v70.4H140z"
                  />
                </svg>
              </div>
            </GridItem>
            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>10/07/2019</span>
                <br />
                CENTRO CULTURAL EL RULE (MX) CIDADE DO MÉXICO
              </h5>
            </GridItem>
            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>12/07/2019</span>
                <br />
                EL JARDÍN PROYECTO SERPIENTE (MX) MORELIA
              </h5>
            </GridItem>
            <GridItem cs={12} sm={12} md={12}>
              <h5 className={classes.description + " descricao"}>
                <span className={"descricao1"}>10/07/2019</span>
                <br />
                CENTRO CULTURAL CLAVIJERO (MX) MORELIA
              </h5>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
