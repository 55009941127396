/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import barragustavo from "./gustavologo.png";
import barrabomba from "./bombacriativagustavo-02-02-02.png";
import barragov from "./BARRA.png";
import cultpres from "./culturapresente-03-03-03.png";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js"; 

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function logos(props) {
const classes = useStyles();
  return (
    <div style={{ marginRight: "auto", marginLeft: "auto", width:"100%", paddingRight:"15px", paddingLeft:"15px" }}>
    <GridContainer style={{ justify:"flex-start",
    alignItems:"center"}}>
   <GridItem xs={12} sm={12} md={3} style={{ textAlign: "justify" }}>
    <h5 className={classes.description} style={{ color:"#9580b2", float:"left",marginRight:"10px"}}>
        Realização:
      </h5>
      <img src={barrabomba} style={{width: "100%", marginBottom:"1em"}} className={'logosReal'}/>
    </GridItem> 
   <GridItem xs={12} sm={12} md={2} style={{ textAlign: "justify" }}>
    <h5 className={classes.description} style={{ color:"#9580b2", float:"left",marginRight:"10px"}}>
        Apoio Cultural:
      </h5>
      <img src={cultpres} style={{width: "100%", marginBottom:"1em"}} className={'logosApoio'}/>
    </GridItem> 
    <GridItem xs={12} sm={12} md={7} style={{ textAlign: "left", paddingLeft:"1em" }}>
    <h5 className={classes.description} style={{ color:"#9580b2",marginRight:"105px"}}>
        Patrocínio:
      </h5>
      <img src={barragov} style={{width: "100%", marginBottom:"1em"}}/>
    </GridItem>
  
  </GridContainer>
  </div>
  );
}




logos.propTypes = {
  whiteFont: PropTypes.bool
};
