import React, { useState, useEffect, useCallback } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {
  LightgalleryProvider,
  LightgalleryItem,
  withLightgallery,
  useLightgallery
} from "react-lightgallery";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import PT from "prop-types";
import "lightgallery.js/dist/css/lightgallery.css";
import { BoxLoading } from "react-loadingg";
import Brasil from "../Elenco/001-brasil.svg";
import Espanhol from "../Elenco/espanhol.svg";
import Ingles from "../Elenco/ingles.svg";
import NavPills from "components/NavPills/NavPills.js";

import "./Painel.style.css";
import Footer from "components/Footer/Footer.js";
import painel from "./PAINEL_PANGEIA.jpg";

import p1 from "./objetos/01.png";
import p2 from "./objetos/02.png";
import p3 from "./objetos/03.png";
import p4 from "./objetos/04.png";
import p5 from "./objetos/05.png";
import p6 from "./objetos/06.png";
import p7 from "./objetos/07.png";
import p8 from "./objetos/08.png";
import p9 from "./objetos/09.png";
import p10 from "./objetos/10.png";

import p11 from "./objetos/11.png";
import p12 from "./objetos/12.png";
import p13 from "./objetos/13.png";
import p14 from "./objetos/14.png";
import p15 from "./objetos/15.png";
import p16 from "./objetos/16.png";
import p17 from "./objetos/17.png";
import p18 from "./objetos/18.png";
import p19 from "./objetos/19.png";
import p20 from "./objetos/20.png";

import p21 from "./objetos/21.png";
import p22 from "./objetos/22.png";
import p23 from "./objetos/23.png";
import p24 from "./objetos/24.png";
import p25 from "./objetos/25.png";
import p26 from "./objetos/26.png";
import p27 from "./objetos/27.png";
import p28 from "./objetos/28.png";
import p29 from "./objetos/29.png";
import p30 from "./objetos/30.png";

import p31 from "./objetos/31.png";
import p32 from "./objetos/32.png";
import p33 from "./objetos/33.png";
import p34 from "./objetos/34.png";
import p35 from "./objetos/35.png";
import p36 from "./objetos/36.png";
import p37 from "./objetos/37.png";
import p38 from "./objetos/38.png";
import p39 from "./objetos/39.png";
import p40 from "./objetos/40.png";

import p41 from "./objetos/41.png";
import p42 from "./objetos/42.png";
import p43 from "./objetos/43.png";
import p44 from "./objetos/44.png";
import p45 from "./objetos/45.png";
import p46 from "./objetos/46.png";
import p47 from "./objetos/47.png";
import p48 from "./objetos/48.png";
import p49 from "./objetos/49.png";
import p50 from "./objetos/50.png";

import p51 from "./objetos/51.png";
import p52 from "./objetos/52.png";
import p53 from "./objetos/53.png";
import p54 from "./objetos/54.png";
import p55 from "./objetos/55.png";
import p56 from "./objetos/56.png";
import p57 from "./objetos/57.png";
import p58 from "./objetos/58.png";
import p59 from "./objetos/59.png";
import p60 from "./objetos/60.png";

import p61 from "./objetos/61.png";
import p62 from "./objetos/62.png";
import p63 from "./objetos/63.png";
import p64 from "./objetos/64.png";
import p65 from "./objetos/65.png";
import p66 from "./objetos/66.png";
import p67 from "./objetos/67.png";
import p68 from "./objetos/68.png";
import p69 from "./objetos/69.png";
import p70 from "./objetos/70.png";

import p71 from "./objetos/71.png";
import p72 from "./objetos/72.png";
import p73 from "./objetos/73.png";
import p74 from "./objetos/74.png";
import p75 from "./objetos/75.png";
import p76 from "./objetos/76.png";
import p77 from "./objetos/77.png";
import p78 from "./objetos/78.png";
import p79 from "./objetos/79.png";
import p80 from "./objetos/80.png";

import p81 from "./objetos/81.png";
import p82 from "./objetos/82.png";
import p83 from "./objetos/83.png";
import p84 from "./objetos/84.png";
import p85 from "./objetos/85.png";
import p86 from "./objetos/86.png";
import p87 from "./objetos/87.png";
import p88 from "./objetos/88.png";
import p89 from "./objetos/89.png";
import p90 from "./objetos/90.png";

import p91 from "./objetos/91.png";
import p92 from "./objetos/92.png";
import p93 from "./objetos/93.png";
import p94 from "./objetos/94.png";
import p95 from "./objetos/95.png";
import p96 from "./objetos/96.png";
import p97 from "./objetos/97.png";
import p98 from "./objetos/98.png";
import p99 from "./objetos/99.png";
import p100 from "./objetos/100.png";

import p101 from "./objetos/101.png";
import p102 from "./objetos/102.png";
import p103 from "./objetos/103.png";
import p104 from "./objetos/104.png";
import p105 from "./objetos/105.png";
import p106 from "./objetos/106.png";
import p107 from "./objetos/107.png";
import p108 from "./objetos/108.png";
import p109 from "./objetos/109.png";
import p110 from "./objetos/110.png";

import p111 from "./objetos/111.png";
import p112 from "./objetos/112.png";
import p113 from "./objetos/113.png";
import p114 from "./objetos/114.png";
import p115 from "./objetos/115.png";
import p116 from "./objetos/116.png";
import p117 from "./objetos/117.png";
import p118 from "./objetos/118.png";
import p119 from "./objetos/119.png";
import p120 from "./objetos/120.png";

import p121 from "./objetos/121.png";
import p122 from "./objetos/122.png";
import p123 from "./objetos/123.png";
import p124 from "./objetos/124.png";
import p125 from "./objetos/125.png";
import p126 from "./objetos/126.png";
import p127 from "./objetos/127.png";
import p128 from "./objetos/128.png";
import p129 from "./objetos/129.png";
import p130 from "./objetos/130.png";

import p131 from "./objetos/131.png";
import p132 from "./objetos/132.png";
import p133 from "./objetos/133.png";
import p134 from "./objetos/134.png";
import p135 from "./objetos/135.png";
import p136 from "./objetos/136.png";
import p137 from "./objetos/137.png";
import p138 from "./objetos/138.png";
import p139 from "./objetos/139.png";
import p140 from "./objetos/140.png";

import p141 from "./objetos/141.png";
import p142 from "./objetos/142.png";
import p143 from "./objetos/143.png";
import p144 from "./objetos/144.png";
import p145 from "./objetos/145.png";
import p146 from "./objetos/146.png";
import p147 from "./objetos/147.png";
import p148 from "./objetos/148.png";
import p149 from "./objetos/149.png";
import p150 from "./objetos/150.png";

import p151 from "./objetos/151.png";
import p152 from "./objetos/152.png";
import p153 from "./objetos/153.png";
import p154 from "./objetos/154.png";
import p155 from "./objetos/155.png";
import p156 from "./objetos/156.png";
import p157 from "./objetos/157.png";
import p158 from "./objetos/158.png";
import p159 from "./objetos/159.png";
import p160 from "./objetos/160.png";

import p161 from "./objetos/161.png";
import p162 from "./objetos/162.png";
import p163 from "./objetos/163.png";
import p164 from "./objetos/164.png";
import p165 from "./objetos/165.png";
import p166 from "./objetos/166.png";
import p167 from "./objetos/167.png";
import p168 from "./objetos/168.png";
import p169 from "./objetos/169.png";
import p170 from "./objetos/170.png";

import p171 from "./objetos/171.png";
import p172 from "./objetos/172.png";
import p173 from "./objetos/173.png";
import p174 from "./objetos/174.png";
import p175 from "./objetos/175.png";
import p176 from "./objetos/176.png";
import p177 from "./objetos/177.png";
import p178 from "./objetos/178.png";
import p179 from "./objetos/179.png";
import p180 from "./objetos/180.png";

import p181 from "./objetos/181.png";
import p182 from "./objetos/182.png";
import p183 from "./objetos/183.png";
import p184 from "./objetos/184.png";
import p185 from "./objetos/185.png";
import p186 from "./objetos/186.png";
import p187 from "./objetos/187.png";
import p188 from "./objetos/188.png";
import p189 from "./objetos/189.png";
import p190 from "./objetos/190.png";

import p191 from "./objetos/191.png";
import p192 from "./objetos/192.png";
import p193 from "./objetos/193.png";
import p194 from "./objetos/194.png";
import p195 from "./objetos/195.png";
import p196 from "./objetos/196.png";
import p197 from "./objetos/197.png";
import p198 from "./objetos/198.png";
import p199 from "./objetos/199.png";
import p200 from "./objetos/200.png";

const logoPangeia = require("./AF-PANGEIA-Logo.png");

const useStyles = makeStyles(styles);
const objetos1 = [
  {
    image: p1,
    title: "1 - O Rei dos sapos ou henrique de ferro {bola de ouro}",
    titleen: "1 - The Frog King, or Iron Heinrich",
    titlees: "1 - El Rey Rana o Enrique el Férreo",
    description: "jhasjhsa",
    sound: undefined,
  },
  {
    image: p2,
    title: "2 - Gato e rato em companhia {panela de banha}",
    titleen: "2 - Cat and Mouse in Partnership",
    titlees: "2 - El gato y el ratón hacen vida en común",
    description: "jhasjhsa1",
    sound: undefined,
  },
  {
    image: p3,
    title: "3 - A protegida de Maria {chave}",
    titleen: "3 - Mary’s Child",
    titlees: "4 - La hija de la Virgen María",
    description: "jhasjhsa2",
    sound:
      "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/03-chaves.mp3",
  },
  {
    image: p4,
    title: "4 - A história do jovem em busca de saber o que é o medo {balde}",
    titleen: "4 - The Story of the Youth Who Went Forth to Learn What Fear Was",
    titlees: "4 - Historia de uno que hizo un viaje para saber lo que era miedo",
    description: "jhasjhsa3",
    sound: undefined,
  },
  {
    image: p5,
    title: "5 - o lobo e os sete cabritinhos {tesoura}",
    titleen: "5 - The Wolf and the Seven Young Kids",
    titlees: "5 - El lobo y la siete cabritillas",
    description: "jhasjhsa4",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/05-tesoura.mp3",
  },
  {
    image: p6,
    title: "6 - João Fiel {retrato da princesa}",
    titleen: "6 - Faithful John or Trusty John",
    titlees: "6 - El fiel Juan",
    description: "jhasjhsa5",
    sound: undefined,
  },
  {
    image: p7,
    title: "7 - O bom negócio {casaco}",
    titleen: "7 - The Good Bargain",
    titlees: "7 - Un buen negocio",
    description: "jhasjhsa6",
    sound: undefined,
  },
  {
    image: p8,
    title: "8 - O músico maravilhoso {violino}",
    titleen: "8 - The Wonderful Musician or The Strange Musician",
    titlees: "8 - El músico prodigioso",
    description: "jhasjhsa7",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/08-violino.mp3",
  },
];

const objetos2 = [
  {
    image: p9,
    title: "9 - Os doze Irmãos {bandeira vermelha}",
    titleen: "9 - The Twelve Brothers",
    titlees: "9 - Los doce hermanos",
    description: "jhasjhsa8",
    sound: undefined,
  },
  {
    image: p10,
    title: "10 - O bando de maltrapilhos {carruagem casca de nozes}",
    titleen: "10 - The Pack of Ragamuffins",
    titlees: "10 - La chusma",
    description: "jhasjhsa9",
    sound: undefined,
  },
  {
    image: p11,
    title: "11 - Irmãozinho e Irmãzinha {urna}",
    titleen: "11 - Little Brother and Little Sister",
    titlees: "11 - Los dos hermanitos",
    description: "jhasjhsa",
    sound: undefined,
  },
  {
    image: p12,
    title: "12 - Rapunzel {trança}",
    titleen: "12 - Rapunzel",
    titlees: "12 - Rapunzel",
    description: "jhasjhsa1",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/12-tranca.mp3",
  },
  {
    image: p13,
    title: "13 - Os três homenzinhos na floresta {vestido de papel}",
    titleen: "13 - The Three Little Men in the Woods",
    titlees: "13 -Los tres enanitos del bosque",
    description: "jhasjhsa2",
    sound: undefined,
  },
  {
    image: p14,
    title: "14 - As três fiadeiras {linho fiado}",
    titleen: "14 - The Three Spinning Women",
    titlees: "14 - Las tres hilanderas",
    description: "jhasjhsa3",
    sound: undefined,
  },
  {
    image: p15,
    title: "15 - João e Maria (Hansel e Gretel) {migalhas}",
    titleen: "15 - Hansel and Grethel",
    titlees: "15 - Hansel y Gretel",
    description: "jhasjhsa4",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/15-migalhas.mp3",
  },
  {
    image: p16,
    title: "16 - As três Folhas da serpente {três folhas}",
    titleen: "16 - The Three Snake-Leaves",
    titlees: "16 - Las tres hojas de la serpiente",
    description: "jhasjhsa5",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/16-3folhas.mp3",
  },
];

const objetos3 = [
  {
    image: p17,
    title: "17 - A Serpente Branca {maçã dourada}",
    titleen: "17 - The White Snake",
    titlees: "17 - La serpiente blanca",
    description: "jhasjhsa6",
    sound: undefined,
  },
  {
    image: p18,
    title: "18 - Palha, carvão e feijão {palha, carvão e feijão}",
    titleen: "18 - The Straw, the Coal, and the Bean",
    titlees: "18 - La paja, la brasa y la alubia",
    description: "jhasjhsa7",
    sound: undefined,
  },
  {
    image: p19,
    title: "19 - O Pescador e a sua Mulher {vara de pesca}",
    titleen: "19 - The Fisherman and His Wife",
    titlees: "19 - El pescador y su mujer",
    description: "jhasjhsa8",
    sound: undefined,
  },
  {
    image: p20,
    title: "20 - O alfaiatezinho valente {cinto com bordado “sete de uma vez”}",
    titleen: "20 - The Brave Little Tailor or The Valiant Little Tailor or The Gallant Tailor",
    titlees: "20 - El sastrecillo valiente (Siete de un golpe)",
    description: "jhasjhsa9",
    sound: undefined,
  },
  {
    image: p21,
    title: "21 - Cinderela ou Gata Borralheira {sapatinho}",
    titleen: "21 - Cinderella",
    titlees: "21 - La Cenicienta",
    description: "jhasjhsa",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/21-sapato.mp3",
  },
  {
    image: p22,
    title: "22 - O enigma {capa bordada a ouro}",
    titleen: "22 - The Riddle",
    titlees: "22 - El acertijo",
    description: "jhasjhsa1",
    sound: undefined,
  },
  {
    image: p23,
    title: "23 - O ratinho, o passarinho e a salsicha {lenha, fogo e panelas}",
    titleen: "23 - The Mouse, the Bird, and the Sausage",
    titlees: "23 - El ratoncillo, el pajarito y la salchicha",
    description: "jhasjhsa2",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/23-fogo.mp3",
  },
  {
    image: p24,
    title: "24 - Dona Ola {fuso}",
    titleen: "24 - Mother Holle or Mother Hulda or Old Mother Frost",
    titlees: "24 - Madre Nieve (Frau Holle)",
    description: "jhasjhsa3",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/24-fuso.mp3",
  },
  {
    image: p25,
    title: "25 - Os sete corvos {anel}",
    titleen: "25 - The Seven Ravens",
    titlees: "25 - Los siete cuervos",
    description: "jhasjhsa4",
    sound: undefined,
  },
  {
    image: p26,
    title: "26 - Chapeuzinho Vermelho {capuz vermelho}",
    titleen: "26 - Little Red Cap",
    titlees: "26 - Caperucita Roja",
    description: "jhasjhsa5",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/26-chapeu-vermelho.mp3",
  },
  {
    image: p27,
    title: "27 - Os músicos de Bremen {mesa}",
    titleen: "27 - The Bremen Town Musicians",
    titlees: "27 - Los músicos de Brema",
    description: "jhasjhsa6",
    sound: undefined,
  },
  {
    image: p28,
    title: "28 - O Osso Cantandor {bocal em osso}",
    titleen: "28 - The Singing Bone",
    titlees: "28 - El hueso cantor",
    description: "jhasjhsa7",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/28-osso.mp3",
  },
  {
    image: p29,
    title: "29 - Os Três Cabelos de Ouro do Diabo {três cabelos dourados}",
    titleen: "29 - The Devil With the Three Golden Hairs",
    titlees: "29 - Los tres pelos de oro del diablo",
    description: "jhasjhsa8",
    sound: undefined,
  },
  {
    image: p30,
    title: "30 - O piolho e a pulga {casca de ovo}",
    titleen: "30 - The Louse and the Flea",
    titlees: "30 - El piojito y la pulguita",
    description: "jhasjhsa9",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/30-homem-pequeno.mp3",
  },
  {
    image: p31,
    title: "31 - A moça sem mãos {mãos de prata}",
    titleen: "31 - The Girl Without Hands or The Handless Maiden",
    titlees: "31 - La doncella sin manos",
    description: "jhasjhsa",
    sound: undefined,
  },
  {
    image: p32,
    title: "32 - João, o sensato {olhinhos de bezerro}",
    titleen: "32 - Clever Hans",
    titlees: "32 - Juan el listo",
    description: "jhasjhsa1",
    sound: undefined,
  },
  {
    image: p33,
    title: "33 - As três linguas {pombas brancas}",
    titleen: "33 - The Three Languages",
    titlees: "33 - Las tres lenguas",
    description: "jhasjhsa2",
    sound: undefined,
  },
  {
    image: p34,
    title: "34 - A Elsa esperta {rede com guizos}",
    titleen: "34 - Clever Elsie",
    titlees: "34 - Elsa la Lista",
    description: "jhasjhsa3",
    sound: undefined,
  },
  {
    image: p35,
    title: "35 - O alfaiate no céu {banquinho}",
    titleen: "35 - The Tailor in Heaven",
    titlees: "35 - El sastre en el cielo",
    description: "jhasjhsa4",
    sound: undefined,
  },
  {
    image: p36,
    title: "36 - A mesa, o burro e o cacete {cacete}",
    titleen: "36 - The Magic Table, the Gold-Donkey, and the Club in the Sack",
    titlees: "36 - La mesa, el asno y el bastón maravillosos",
    description: "jhasjhsa5",
    sound: undefined,
  },
  {
    image: p37,
    title: "37 - O pequeno Polegar {roupinhas pequeninas}",
    titleen: "37 - Thumbling",
    titlees: "37 - Pulgarcito",
    description: "jhasjhsa6",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/37-polegar.mp3",
  },
  {
    image: p38,
    title: "38 - O Casamento da Dona Raposa {meias vermelhas}",
    titleen: "38 - The Wedding of Mrs. Fox",
    titlees: "38 - La boda de Dama Raposa",
    description: "jhasjhsa7",
    sound: undefined,
  },
  {
    image: p39,
    title: "39 - Histórias de anões {roupinhas pequeninas}",
    titleen: "39 - The Elves",
    titlees: "39 - The Elves",
    description: "jhasjhsa8",
    sound: undefined,
  },
  {
    image: p40,
    title: "40 - A noiva do ladrão {ervilhas e lentilhas germinadas}",
    titleen: "40 - The Robber Bridegroom",
    titlees: "40 - La novia del bandolero",
    description: "jhasjhsa9",
    sound: undefined,
  },
  {
    image: p41,
    title: "41 - O Senhor Korbes {carruagem com rodas vermelhas}",
    titleen: "41 - Herr Korbes",
    titlees: "41 - El señor Korbes",
    description: "jhasjhsa",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/41-carruagem-com-rodas-vermelhas.mp3",
  },
  {
    image: p42,
    title: "42 - o senhor compadre {copo de água milagrosa}",
    titleen: "42 - The Godfather",
    titlees: "42 - El señor padrino",
    description: "jhasjhsa1",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/42-copo.mp3",
  },
  {
    image: p43,
    title: "43 - Dona Trudes {bloco de lenha}",
    titleen: "43 - Frau Trude",
    titlees: "43 - La dama duende",
    description: "jhasjhsa2",
    sound: undefined,
  },
  {
    image: p44,
    title: "44 - A Madrinha Morte {vela e erva da cura}",
    titleen: "44 - Godfather Death",
    titlees: "44 - La Muerte Madrina",
    description: "jhasjhsa3",
    sound: undefined,
  },
  {
    image: p45,
    title: "45 - As Viagens do Pequeno Polegar {agulha}",
    titleen: "45 - Thumbling’s Travels",
    titlees: "45 - Las correrías de Pulgarcito",
    description: "jhasjhsa4",
    sound: undefined,
  },
  {
    image: p46,
    title: "46 - O Pássaro emplumado {ovo com sangue}",
    titleen: "46 - Fitcher’s Bird",
    titlees: "46 - El pájaro del brujo",
    description: "jhasjhsa5",
    sound: undefined,
  },
  {
    image: p47,
    title: "47 - A amoeira {lenço de seda com ossinhos}",
    titleen: "47 - The Juniper Tree",
    titlees: "47 - El enebro",
    description: "jhasjhsa6",
    sound: undefined,
  },
  {
    image: p48,
    title: "48 - o velho sultão {pão}",
    titleen: "48 - Old Sultan",
    titlees: "48 - El viejo Sultán",
    description: "jhasjhsa7",
    sound: undefined,
  },
  {
    image: p49,
    title: "49 - os seis cisnes {novelo mágico}",
    titleen: "49 - The Six Swans",
    titlees: "49 - Los seis cisnes",
    description: "jhasjhsa8",
    sound: undefined,
  },
  {
    image: p50,
    title: "50 - A Bela Adormecida {fuso}",
    titleen: "50 - Little Briar-Rose",
    titlees: "50 - La Bella Durmiente",
    description: "jhasjhsa9",
    sound: undefined,
  },
  {
    image: p51,
    title: "51 - pÁssaro Achado {balde de água}",
    titleen: "51 - Foundling-Bird",
    titlees: "51 - Piñoncito",
    description: "jhasjhsa",
    sound: undefined,
  },
  {
    image: p52,
    title: "52 - Rei Barba-de-Tordo {caco de barro}",
    titleen: "52 - King Thrushbeard",
    titlees: "52 - El rey Pico de Tordo",
    description: "jhasjhsa1",
    sound: undefined,
  },
  {
    image: p53,
    title: "53 - Branca de Neve {espelho}",
    titleen: "53 - Little Snow White",
    titlees: "53 - Blancanieves",
    description: "jhasjhsa2",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/53-espelho.mp3",
  },
  {
    image: p54,
    title: "54 - A mochila, o chapeuzinho e a corneta {sacola}",
    titleen: "54 - The Knapsack, the Hat, and the Horn",
    titlees: "54 - El morral, el sombrerillo y el cuerno",
    description: "jhasjhsa3",
    sound: undefined,
  },
  {
    image: p55,
    title: "55 - O anão saltador (Rumpelstilzchen) {fuso com fio de ouro}",
    titleen: "55 - Rumpelstiltskin",
    titlees: "55 - El Enano Saltarín (Rumpelstiltskin)",
    description: "jhasjhsa4",
    sound: undefined,
  },
  {
    image: p56,
    title: "56 - O querido Rolando {botas das sete léguas}",
    titleen: "56 - Sweetheart Roland",
    titlees: "56 - El amadísimo Rolando",
    description: "jhasjhsa5",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/56-bota.mp3",
  },
  {
    image: p57,
    title: "57 - O Pássaro De ouro {pena de ouro}",
    titleen: "57 - The Golden Bird",
    titlees: "57 - El pájaro de oro",
    description: "jhasjhsa6",
    sound: undefined,
  },
  {
    image: p58,
    title: "58 - O Cão e o pardal {picareta}",
    titleen: "58 - The Dog and the Sparrow",
    titlees: "58 - El perro y el gorrión",
    description: "jhasjhsa7",
    sound: undefined,
  },
  {
    image: p59,
    title: "59 - Frederico e Catarina {foice}",
    titleen: "50 - Little Briar-Rose",
    titlees: "50 La Bella Durmiente",
    description: "jhasjhsa8",
    sound: undefined,
  },
  {
    image: p60,
    title: "60 - Os Dois Irmãos {raiz}",
    titleen: "60 - The Two Brothers",
    titlees: "60 - Los dos hermanos",
    description: "jhasjhsa9",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/60-raiz.mp3",
  },
  {
    image: p61,
    title: "61 - O pequeno camponês {vitelo de madeira}",
    titleen: "61 - The Little Peasant",
    titlees: "61 - El destripaterrones",
    description: "jhasjhsa",
    sound: undefined,
  },
  {
    image: p62,
    title: "62 - A rainha das abelhas {frasco com pérolas}",
    titleen: "62 - The Queen Bee",
    titlees: "62 - La reina de las abejas",
    description: "jhasjhsa1",
    sound: undefined,
  },
  {
    image: p63,
    title: "63 - As Três Plumas {pedaço de belo tapete}",
    titleen: "63 - The Three Feathers",
    titlees: "63 - Las tres plumas",
    description: "jhasjhsa2",
    sound: undefined,
  },
  {
    image: p64,
    title: "64 - O Ganso de ouro {pena de ouro}",
    titleen: "64 - The Golden Goose",
    titlees: "64 - La oca de oro",
    description: "jhasjhsa3",
    sound: undefined,
  },
  {
    image: p65,
    title: "65 - Pele de bicho {pedaço de pele de animal com pelo}",
    titleen: "65 - All-Kinds-of-Fur",
    titlees: "65 - Bestia peluda",
    description: "jhasjhsa4",
    sound: undefined,
  },
  {
    image: p66,
    title: "66 - A Noiva do coelho {boneca de palha}",
    titleen: "66 - The Hare’s Bride",
    titlees: "66 - La novia del conejillo",
    description: "jhasjhsa5",
    sound: undefined,
  },
  {
    image: p67,
    title: "67 - Os Doze Caçadores {anel}",
    titleen: "67 - The Twelve Huntsmen",
    titlees: "67 - Los doce cazadores",
    description: "jhasjhsa6",
    sound: undefined,
  },
  {
    image: p68,
    title: "68 - O ladrão e o seu mestre {cesto de pássaro}",
    titleen: "68 - The Thief and His Master",
    titlees: "68 - El ladrón fullero y su maestro",
    description: "jhasjhsa7",
    sound: undefined,
  },
  {
    image: p69,
    title: "69 - Jorinda e Jorindo {flor vermelha com pérola no centro}",
    titleen: "69 - Jorinde and Joringel",
    titlees: "69 - Yorinda y Yoringuel",
    description: "jhasjhsa8",
    sound: undefined,
  },
  {
    image: p70,
    title: "70 - Os três filhos afortunados {foice}",
    titleen: "70 - The Three Sons of Fortune",
    titlees: "70 - Los tres favoritos de la fortuna",
    description: "jhasjhsa9",
    sound: undefined,
  },
  {
    image: p71,
    title: "71 - Seis atravessam o mundo inteiro {chapéu congelado}",
    titleen: "71 - How Six Men got on in the World",
    titlees: "71 - Seis que salen de todo",
    description: "jhasjhsa",
    sound: undefined,
  },
  {
    image: p72,
    title: "72 - O Lobo e o Homem {espingarda}",
    titleen: "72 - The Wolf and the Man",
    titlees: "72 - El lobo y el hombre",
    description: "jhasjhsa1",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/72-arma.mp3",
  },
  {
    image: p73,
    title: "73 - O lobo e a Raposa {carne salgada}",
    titleen: "73 - The Wolf and the Fox",
    titlees: "73 - El lobo y la zorra",
    description: "jhasjhsa2",
    sound: undefined,
  },
  {
    image: p74,
    title: "74 - A Raposa e a Comadre {penas de galinha}",
    titleen: "74 - Gossip Wolf and the Fox75 - The Fox and the Cat",
    titlees: "74 - El zorro y su comadre",
    description: "jhasjhsa3",
    sound: undefined,
  },
  {
    image: p75,
    title: "75 - A Raposa e o Gato {saco de tecido}",
    titleen: "75 - The Fox and the Cat",
    titlees: "75 - La zorra y el gato",
    description: "jhasjhsa4",
    sound: undefined,
  },
  {
    image: p76,
    title: "76 - O Cravo {cravo}",
    titleen: "76 - The Pink",
    titlees: "76 - El clavel",
    description: "jhasjhsa5",
    sound: undefined,
  },
  {
    image: p77,
    title: "77 - Margarida, a espertalhona {copo de vinho}",
    titleen: "77 - Clever Grethel",
    titlees: "77 - La pícara cocinera",
    description: "jhasjhsa6",
    sound: undefined,
  },
  {
    image: p78,
    title: "78 - O avô e o netinho {tigela de barro}",
    titleen: "78 - The Old Man and his Grandson",
    titlees: "78 - El abuelo y el nieto",
    description: "jhasjhsa7",
    sound: undefined,
  },
  {
    image: p79,
    title: "79 - A Odina {pente}",
    titleen: "79 - The Water Nixie",
    titlees: "79 - La ondina",
    description: "jhasjhsa8",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/79-escova.mp3",
  },
  {
    image: p80,
    title: "80 - Da morte da franguinha {miolo de noz}",
    titleen: "80 - The Death of the Little Hen",
    titlees: "80 - La muerte de la gallinita",
    description: "jhasjhsa9",
    sound: undefined,
  },
  {
    image: p81,
    title: "81 - O irmão Folgazão {sacola}",
    titleen: "81 - Brother Lustig",
    titlees: "81 - Hermano Alegre",
    description: "jhasjhsa",
    sound: undefined,
  },
  {
    image: p82,
    title: "82 - João Jogatudo {baralho de cartas antigo}",
    titleen: "82 - Gambling Hansel",
    titlees: "82 - El jugador",
    description: "jhasjhsa1",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/82-baralho-de-cartas.mp3",
  },
  {
    image: p83,
    title: "83 - João, o Felizardo {pepita de ouro}",
    titleen: "83 - Hans in Luck",
    titlees: "83 - Juan con suerte",
    description: "jhasjhsa2",
    sound: undefined,
  },
  {
    image: p84,
    title: "84 - O casamento de João {remendo de roupa}",
    titleen: "84 - Hans Married",
    titlees: "84 - Juan se casa",
    description: "jhasjhsa3",
    sound: undefined,
  },
  {
    image: p85,
    title: "85 - Os filhos de ouro {espinha de peixe dourada cortada em dois}",
    titleen: "85 - The Gold-Children",
    titlees: "85 - Los niños de oro",
    description: "jhasjhsa4",
    sound: undefined,
  },
  {
    image: p86,
    title: "86 - A raposa e os gansos {pele de raposa}",
    titleen: "86 - The Fox and the Geese",
    titlees: "86 - La zorra y los gansos",
    description: "jhasjhsa5",
    sound: undefined,
  },
  {
    image: p87,
    title: "87 - O pobre e o rico {sela de cavalo}",
    titleen: "87 - The Poor Man and the Rich Man",
    titlees: "87 - El pobre y el rico",
    description: "jhasjhsa6",
    sound: undefined,
  },
  {
    image: p88,
    title: "88 - Uma andorinha que canta e pula {noz}",
    titleen: "88 - The Singing, Springing Lark",
    titlees: "88 - La alondra cantarina y saltarina",
    description: "jhasjhsa7",
    sound: undefined,
  },
  {
    image: p89,
    title: "89 - A pastorinha de gansos {lenço branco com três gotas de sangue}",
    titleen: "89 - The Goose Girl",
    titlees: "89 - La pastora de ocas",
    description: "jhasjhsa8",
    sound: undefined,
  },
  {
    image: p90,
    title: "90 - O Jovem gigante {barra de ferro}",
    titleen: "90 - The Young Giant",
    titlees: "90 - El joven gigante",
    description: "jhasjhsa9",
    sound: undefined,
  },
  {
    image: p91,
    title: "91 - O Gnomo {flauta}",
    titleen: "91 - The Gnome",
    titlees: "91 - El gnomo",
    description: "jhasjhsa",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/91-flauta.mp3",
  },
  {
    image: p92,
    title: "92 - O Rei Da Montanha de Ouro {tratado}",
    titleen: "92 - The King of the Gold Mountain",
    titlees: "92 - El rey de la montaña de oro",
    description: "jhasjhsa1",
    sound: undefined,
  },
  {
    image: p93,
    title: "93 - O corvo {mapa}",
    titleen: "93 - The Raven",
    titlees: "93 - El cuervo",
    description: "jhasjhsa2",
    sound: undefined,
  },
  {
    image: p94,
    title: "94 - A esperta filha do camponês {pilão}",
    titleen: "94 - The Peasant’s Wise Daughter",
    titlees: "94 - La campesina prudente",
    description: "jhasjhsa3",
    sound: undefined,
  },
  {
    image: p95,
    title: "95 - O Velho Hidebrand {folhas de louro}",
    titleen: "95 - Old Hildebrand",
    titlees: "95 - El viejo Hildebrando",
    description: "jhasjhsa4",
    sound: undefined,
  },
  {
    image: p96,
    title: "96 - Os Três Passarinhos {vara}",
    titleen: "96 - The Three Little Birds",
    titlees: "96 - Los tres pajarillos",
    description: "jhasjhsa5",
    sound: undefined,
  },
  {
    image: p97,
    title: "97 - A água da vida {água da vida}",
    titleen: "97 - The Water of Life",
    titlees: "97 - El agua de la vida",
    description: "jhasjhsa6",
    sound: undefined,
  },
  {
    image: p98,
    title: "98 - Doutor sabetudo {caranguejo}",
    titleen: "98 - Doctor Know-all",
    titlees: "98 - El doctor Sabelotodo",
    description: "jhasjhsa7",
    sound: undefined,
  },
  {
    image: p99,
    title: "99 - O gênio da garrafa {garrafa}",
    titleen: "99 - The Spirit in the Bottle",
    titlees: "99 - El espíritu embotellado",
    description: "jhasjhsa8",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/99-genio.mp3",
  },
  {
    image: p100,
    title: "100 - O fuliginoso irmão do diabo {sacola de ouro}",
    titleen: "100 - The Devil’s Sooty Brother",
    titlees: "100 - El mugriento hermano del diablo",
    description: "jhasjhsa9",
    sound: undefined,
  },
  {
    image: p101,
    title: "101 - Pele de Urso {anel}",
    titleen: "101 - Bearskin",
    titlees: "101 - Piel de Oso",
    description: "jhasjhsa",
    sound: undefined,
  },
  {
    image: p102,
    title: "102 - O Urso e a carriça {mosquito}",
    titleen: "102 - The Willow Wren and the Bear",
    titlees: "102 - El reyezuelo y el oso",
    description: "jhasjhsa1",
    sound: undefined,
  },
  {
    image: p103,
    title: "103 - O Mingau Doce {panelinha}",
    titleen: "103 - Sweet Porridge",
    titlees: "103 - Gachas dulces",
    description: "jhasjhsa2",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/103-panela.mp3",
  },
  {
    image: p104,
    title: "104 - Os espertalhões {vara de madeira}",
    titleen: "104 - Wise Folks",
    titlees: "104 - Gente lista",
    description: "jhasjhsa3",
    sound: undefined,
  },
  {
    image: p105,
    title: "105 - Contos de rãs {lenço de seda azul}",
    titleen: "105 - Tales of the Paddock",
    titlees: "105 - Cuentos del sapo",
    description: "jhasjhsa4",
    sound: undefined,
  },
  {
    image: p106,
    title: "106 - O pobre aprendiz de moleiro e a gatinha {machado de prata}",
    titleen: "106 - The Poor Miller’s Boy and the Cat",
    titlees: "106 - El pobre mozo molinero y la gatita",
    description: "jhasjhsa5",
    sound: undefined,
  },
  {
    image: p107,
    title: "107 - Os dois viajantes {trouxa com bebe}",
    titleen: "107 - The Two Travelers",
    titlees: "107 - Los dos caminantes",
    description: "jhasjhsa6",
    sound: undefined,
  },
  {
    image: p108,
    title: "108 - João ouriço {pele de ouriço}",
    titleen: "108 - Hans My Hedgehog",
    titlees: "108 - Juan-mi-erizo (Juan Erizo)",
    description: "jhasjhsa7",
    sound: undefined,
  },
  {
    image: p109,
    title: "109 - A mortalha do menino {mortalha}",
    titleen: "109 - The Shroud",
    titlees: "109 - La camisita del muerto",
    description: "jhasjhsa8",
    sound: undefined,
  },
  {
    image: p110,
    title: "110 - O judeu no meio dos espinhos {espinhos}",
    titleen: "110 - The Jew Among Thorns",
    titlees: "110 - El judío en el espino",
    description: "jhasjhsa9",
    sound: undefined,
  },
  {
    image: p111,
    title: "111 - O caçador habilidoso {sabre de prata com estrela dourada}",
    titleen: "111 - The Skillful Huntsman",
    titlees: "111 - El hábil cazador",
    description: "jhasjhsa",
    sound: undefined,
  },
  {
    image: p112,
    title: "112 - O mangual do céu {malho}",
    titleen: "112 - The Flail from Heaven",
    titlees: "112 - El mayal del cielo",
    description: "jhasjhsa1",
    sound: undefined,
  },
  {
    image: p113,
    title: "113 - O Príncipe e a Princesa {três nozes com vestidos}",
    titleen: "113 - The Two Kings’ Children",
    titlees: "113 - Los dos príncipes",
    description: "jhasjhsa2",
    sound: undefined,
  },
  {
    image: p114,
    title: "114 - O alfaiatezinho esperto {moeda}",
    titleen: "114 - The Cunning Little Tailor or The Story of a Clever Tailor",
    titlees: "114 - El sastrecillo listo",
    description: "jhasjhsa3",
    sound: undefined,
  },
  {
    image: p115,
    title: "115 - A luz do sol há de espantar a escuridão {xícara de café}",
    titleen: "115 - The Bright Sun Brings it to Light",
    titlees: "115 - El sol revelador",
    description: "jhasjhsa4",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/115-xicara-de-cafe.mp3",
  },
  {
    image: p116,
    title: "116 - A Lâmpada Azul {cachimbo}",
    titleen: "116 - The Blue Light",
    titlees: "116 - La lámpara azul",
    description: "jhasjhsa5",
    sound: undefined,
  },
  {
    image: p117,
    title: "117 - O menino teimoso {açoite}",
    titleen: "117 - The Willful Child",
    titlees: "117 - El chiquillo testarudo",
    description: "jhasjhsa6",
    sound: undefined,
  },
  {
    image: p118,
    title: "118 - Os três cirurgiões {unguento}",
    titleen: "118 - The Three Army Surgeons",
    titlees: "118 - Los tres cirujanos",
    description: "jhasjhsa7",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/118-panaceia.mp3",
  },
  {
    image: p119,
    title: "119 - Os sete suábios {lança}",
    titleen: "119 - The Seven Swabians",
    titlees: "119 - Los siete suabos",
    description: "jhasjhsa8",
    sound: undefined,
  },
  {
    image: p120,
    title: "120 - Os três artesãos {bolsa com dinheiro}",
    titleen: "120 - The Three Apprentices",
    titlees: "120 - Los tres operarios",
    description: "jhasjhsa9",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/120-bolsa-com-moedas.mp3",
  },
  {
    image: p121,
    title: "121 - O príncipe sem medo {anel}",
    titleen: "121 - The King’s Son Who Feared Nothing",
    titlees: "121 - El príncipe intrépido",
    description: "jhasjhsa",
    sound: undefined,
  },
  {
    image: p122,
    title: "122 - A alface mágica {alface}",
    titleen: "122 - Donkey Cabbages",
    titlees: "122 - La lechuga prodigiosa",
    description: "jhasjhsa1",
    sound: undefined,
  },
  {
    image: p123,
    title: "123 - A velha do bosque {anel simples}",
    titleen: "123 - The Old Woman in the Wood",
    titlees: "123 - La vieja del bosque",
    description: "jhasjhsa2",
    sound: undefined,
  },
  {
    image: p124,
    title: "124 - Os três irmãos {casa}",
    titleen: "124 The Three Brothers",
    titlees: "124 - Los tres hermanos",
    description: "jhasjhsa3",
    sound: undefined,
  },
  {
    image: p125,
    title: "125 - O diabo e sua avó {chicote}",
    titleen: "125 - The Devil and His Grandmother",
    titlees: "125 - El diablo y su abuela",
    description: "jhasjhsa4",
    sound: undefined,
  },
  {
    image: p126,
    title: "126 - Fernando fiel e Fernando infiel {chave}",
    titleen: "126 - Ferdinand the Faithful and Ferdinand the Unfaithful",
    titlees: "126 - Fernando Leal y Fernando Desleal",
    description: "jhasjhsa5",
    sound: undefined,
  },
  {
    image: p127,
    title: "127 - O Fogão de Ferro {três nozes}",
    titleen: "127 - The Iron Stove",
    titlees: "127 - El horno de hierro",
    description: "jhasjhsa6",
    sound: undefined,
  },
  {
    image: p128,
    title: "128 - A fiadeira preguiçosa {estopa}",
    titleen: "128 - The Lazy Spinner",
    titlees: "128 - La hilandera holgazana",
    description: "jhasjhsa7",
    sound: undefined,
  },
  {
    image: p129,
    title: "129 - Os Quatro Irmãos habilidosos {ovos de tentilhão}",
    titleen: "129 - The Four Skillful Brothers",
    titlees: "129 - Los cuatro hermanos ingeniosos",
    description: "jhasjhsa8",
    sound: undefined,
  },
  {
    image: p130,
    title: "130 - um olhinho dois olhinhos três olhinhos {três frascos com olhos}",
    titleen: "130 - Little One-Eye, Little Two-Eyes, and Little Three-Eyes",
    titlees: "130 - Un Ojito, Dos Ojitos y Tres Ojitos",
    description: "jhasjhsa9",
    sound: undefined,
  },
  {
    image: p131,
    title: "131 - A bela Catarinela e Poldo Pif Paf {dote}",
    titleen: "131 - Fair Katrinelje and Pif-Paf-Poltrie",
    titlees: "131 - La bella Catalinita y Pif Paf Poltri",
    description: "jhasjhsa",
    sound: undefined,
  },
  {
    image: p132,
    title: "132 - A raposa e o cavalo {cauda do cavalo}",
    titleen: "132 - The Fox and the Horse",
    titlees: "132 - La zorra y el caballo",
    description: "jhasjhsa1",
    sound: undefined,
  },
  {
    image: p133,
    title: "133 - Os sapatos estragados na dança {manto invisível}",
    titleen: "133 - The Shoes that were Danced to Pieces",
    titlees: "133 - Las princesas bailadoras",
    description: "jhasjhsa2",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/133-manto-invisivel.mp3",
  },
  {
    image: p134,
    title: "134 - Os Seis Criados {anel}",
    titleen: "134 - The Six Servants",
    titlees: "134 - Los seis criados",
    description: "jhasjhsa3",
    sound: undefined,
  },
  {
    image: p135,
    title: "135 - A noiva branca e a noiva preta {retrato da mulher branca}",
    titleen: "135 - The White and the Black Bride",
    titlees: "135 - La novia blanca y la novia negra",
    description: "jhasjhsa4",
    sound: undefined,
  },
  {
    image: p136,
    title: "136 - João de Ferro {bola dourada}",
    titleen: "136 - Iron John",
    titlees: "136 - Juan de Hierro",
    description: "jhasjhsa5",
    sound: undefined,
  },
  {
    image: p137,
    title: "137 - as três princesas negras {moedas antigas}",
    titleen: "137 - The Three Black Princesses",
    titlees: "137 - Las tres princesas negras",
    description: "jhasjhsa6",
    sound: undefined,
  },
  {
    image: p138,
    title: "138 - Nicolau e seus três filhos {água benta}",
    titleen: "138 - Knoist and his Three Sons",
    titlees: "138 - Knoist y sus tres hijos",
    description: "jhasjhsa7",
    sound: undefined,
  },
  {
    image: p139,
    title: "139 - A donzela de Brakel {estátua de Santa Ana}",
    titleen: "139 - The Maid of Brakel",
    titlees: "139 - La muchacha de Brakel",
    description: "jhasjhsa8",
    sound: undefined,
  },
  {
    image: p140,
    title: "140 - Os empregados da família {berço}",
    titleen: "140 - My Household",
    titlees: "140 - Los fámulos",
    description: "jhasjhsa9",
    sound: undefined,
  },
  {
    image: p141,
    title: "141 - O cordeirinho e o peixinho {faca}",
    titleen: "141 - The Lambkin and the Little Fish",
    titlees: "141 - El corderillo y el pececillo",
    description: "jhasjhsa",
    sound: undefined,
  },
  {
    image: p142,
    title:
      "142 - O Monte Simeli {medida de alqueiro com moeda de ouro colada no fundo}",
      titleen: "142 - Simeli Mountain",
      titlees: "142 - Monte Simeli",
    description: "jhasjhsa1",
    sound: undefined,
  },
  {
    image: p143,
    title: "143 - O vagamundo {chicote}",
    titleen: "143 - Going a Traveling",
    titlees: "143 - Inconvenientes de correr mundo",
    description: "jhasjhsa2",
    sound: undefined,
  },
  {
    image: p144,
    title: "144 - O Burrinho {pele de burro}",
    titleen: "144 - The Donkey",
    titlees: "144 - El borriquillo",
    description: "jhasjhsa3",
    sound: undefined,
  },
  {
    image: p145,
    title: "145 - O Filho ingrato {frango assado}",
    titleen: "145 - The Ungrateful Son",
    titlees: "145 - El hijo ingrato",
    description: "jhasjhsa4",
    sound: undefined,
  },
  {
    image: p146,
    title: "146 - A Beterraba {beterraba}",
    titleen: "146 - The Turnip",
    titlees: "146 - La zanahoria",
    description: "jhasjhsa5",
    sound: undefined,
  },
  {
    image: p147,
    title: "147 - O velho que voltou a ser jovem {recipiente da têmpera}",
    titleen: "147 - The Old Man Made Young Again",
    titlees: "147 - El hombrecillo rejuvenecido",
    description: "jhasjhsa6",
    sound: undefined,
  },
  {
    image: p148,
    title: "148 - Os animais de Deus e os do diabo {rabo de bode}",
    titleen: "148 - The Lord’s Animals and the Devil’s",
    titlees: "148 - Nuestro Señor y el ganado del diablo",
    description: "jhasjhsa7",
    sound: undefined,
  },
  {
    image: p149,
    title: "149 - O poleiro {trevo de quatro folhas}",
    titleen: "149 - The Beam",
    titlees: "149 - La viga",
    description: "jhasjhsa8",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/149-trevo.mp3",
  },
  {
    image: p150,
    title: "150 - A velha mendiga {fossil de osso da velha}",
    titleen: "150 - The Old Beggar Woman",
    titlees: "150 - La vieja pordiosera",
    description: "jhasjhsa9",
    sound: undefined,
  },
  {
    image: p151,
    title: "151 - Os Três Preguiçosos {corda de forca}",
    titleen: "151 - The Three Sluggards",
    titlees: "151 - Los tres haraganes",
    description: "jhasjhsa",
    sound: undefined,
  },
  {
    image: p152,
    title: "152 - O pastorzinho {folha de papel cheia de pontos}",
    titleen: "152 - The Shepherd Boy",
    titlees: "152 - El zagalillo",
    description: "jhasjhsa1",
    sound: undefined,
  },
  {
    image: p153,
    title: "153 - As moedas-estrelas {pão}",
    titleen: "153 - The Star Money",
    titlees: "153 - El dinero llovido del cielo",
    description: "jhasjhsa2",
    sound: undefined,
  },
  {
    image: p154,
    title: "154 - As moedas roubadas {moedas}",
    titleen: "154 - The Stolen Farthings",
    titlees: "154 - Los ochavos robados",
    description: "jhasjhsa3",
    sound: undefined,
  },
  {
    image: p155,
    title: "155 - A escolha da noiva {queijo}",
    titleen: "155 - Looking for a Bride",
    titlees: "155 - Elección de novia",
    description: "jhasjhsa4",
    sound: undefined,
  },
  {
    image: p156,
    title: "156 - A desperdiçada {sobras de linho}",
    titleen: "156 - The Hurds",
    titlees: "156 - Una muchacha hacendosa",
    description: "jhasjhsa5",
    sound: undefined,
  },
  {
    image: p157,
    title: "157 - O Pardal e os seus Quatro Filhos {pena de pardal}",
    titleen: "157 - The Sparrow and His Four Children",
    titlees: "157 - El gorrión y sus cuatro gurriatos",
    description: "jhasjhsa6",
    sound: undefined,
  },
  {
    image: p158,
    title: "158 - No país do arco-da-velha {espada}",
    titleen: "158 - The Story of Schlauraffen Land",
    titlees: "158 - El cuento de los despropósitos",
    description: "jhasjhsa7",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/158-espada.mp3",
  },
  {
    image: p159,
    title: "159 - Uma porção de mentiras juntas {asa de mosca gigante}",
    titleen: "159 - The Ditmarsch Tale of Lies",
    titlees: "159 - El cuento de las mentiras",
    description: "jhasjhsa8",
    sound: undefined,
  },
  {
    image: p160,
    title: "160 - Adivinhação {três flores do campo}",
    titleen: "160 - A Riddling Tale",
    titlees: "160 - Un cuento enigmático",
    description: "jhasjhsa9",
    sound: undefined,
  },
  {
    image: p161,
    title: "161 - Branca de Neve e Rosa Vermelha {rosa branca e vermelha}",
    titleen: "161 - Snow-White and Rose-Red",
    titlees: "161 - Blancanieve y Rojaflor",
    description: "jhasjhsa",
    sound: undefined,
  },
  {
    image: p162,
    title: "162 - O criado esperto {três passarinhos}",
    titleen: "162 - The Wise Servant",
    titlees: "162 - El listo Juan",
    description: "jhasjhsa1",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/162-tres-passarinhos.mp3",
  },
  {
    image: p163,
    title: "163 - O esquife de vidro {cubo de vidro com castelo}",
    titleen: "163 - The Glass Coffin",
    titlees: "163 - El féretro de cristal",
    description: "jhasjhsa2",
    sound: undefined,
  },
  {
    image: p164,
    title: "164 - Henrique, o preguiçoso {pote de barro}",
    titleen: "164 - Lazy Henry",
    titlees: "164 - Enrique el holgazán",
    description: "jhasjhsa3",
    sound: undefined,
  },
  {
    image: p165,
    title: "165 - O grifo {pena de Grifo}",
    titleen: "165 - The Griffin",
    titlees: "165 - El Grifo",
    description: "jhasjhsa4",
    sound: undefined,
  },
  {
    image: p166,
    title: "166 - João, o destemido {anel}",
    titleen: "166 - Strong Hans",
    titlees: "166 - El fornido Juan",
    description: "jhasjhsa5",
    sound: undefined,
  },
  {
    image: p167,
    title: "167 - O camponesinho no céu {chave grande}",
    titleen: "167 - The Peasant in Heaven",
    titlees: "167 - El pobre campesino, en el cielo",
    description: "jhasjhsa6",
    sound: undefined,
  },
  {
    image: p168,
    title: "168 - A magra Elisa {moeda}",
    titleen: "168 - Lean Lisa",
    titlees: "168 - Elisa, la flaca",
    description: "jhasjhsa7",
    sound: undefined,
  },
  {
    image: p169,
    title: "169 - A Casa do bosque {panela}",
    titleen: "169 - The Hut in the Forest",
    titlees: "169 - La casa del bosque",
    description: "jhasjhsa8",
    sound: undefined,
  },
  {
    image: p170,
    title: "170 - Compartilhando alegrias e tristezas {tesoura}",
    titleen: "170 - Sharing Joy and Sorrow",
    titlees: "170 - Hay que compartir las penas y las alegrías",
    description: "jhasjhsa9",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/170-tesoura.mp3",
  },
  {
    image: p171,
    title: "171 - A carriça {pena de carriça}",
    titleen: "171 - The Willow Wren ",
    titlees: "171 - El reyezuelo",
    description: "jhasjhsa",
    sound: undefined,
  },
  {
    image: p172,
    title: "172 - A Solha {espinha de solha}",
    titleen: "172 - The Sole",
    titlees: "172 - La platija",
    description: "jhasjhsa1",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/172-piranha.mp3",
  },
  {
    image: p173,
    title: "173 - A pega e o alcaravão {penas}",
    titleen: "173 - The Bittern and the Hoopoe",
    titlees: "173 - El alcaraván y la abubilla",
    description: "jhasjhsa2",
    sound: undefined,
  },
  {
    image: p174,
    title: "174 - A Coruja {pena de coruja bufo-real}",
    titleen: "174 - The Owl",
    titlees: "174 - El búho",
    description: "jhasjhsa3",
    sound: undefined,
  },
  {
    image: p175,
    title: "175 - A Lua {pedra da Lua branca com luz lá dentro}",
    titleen: "175 - The Moon (Brothers Grimm)",
    titlees: "175 - La luna",
    description: "jhasjhsa4",
    sound: undefined,
  },
  {
    image: p176,
    title: "176 - A duração da vida {ampulheta}",
    titleen: "176 - The Duration of Life",
    titlees: "176 - La duración de la vida",
    description: "jhasjhsa5",
    sound: undefined,
  },
  {
    image: p177,
    title: "177 - Os mensageiros da morte {resto de pano preto}",
    titleen: "177 - Death’s Messengers",
    titlees: "177 - Los mensajeros de la muerte",
    description: "jhasjhsa6",
    sound: undefined,
  },
  {
    image: p178,
    title: "178 - Mestre Remendão {forma de sapato}",
    titleen: "178 - Master Pfreim",
    titlees: "178 - Cascarrabias",
    description: "jhasjhsa7",
    sound: undefined,
  },
  {
    image: p179,
    title: "179 - A Guardadora de gansos no regato {pele de cara humana}",
    titleen: "179 - The Goose-Girl at the Well",
    titlees: "179 - La pastora de ocas en la fuente",
    description: "jhasjhsa8",
    sound: undefined,
  },
  {
    image: p180,
    title: "180 - Os filhos desiguais de eva {fechadura}",
    titleen: "180 - Eve’s Various Children",
    titlees: "180 - Los desiguales hijos de Eva",
    description: "jhasjhsa9",
    sound: undefined,
  },
  {
    image: p181,
    title: "181 - A Ondina do Lago {flauta dourada}",
    titleen: "181 - The Nixie of the Mill-Pond",
    titlees: "181 - La ondina del estanque",
    description: "jhasjhsa",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/181-flauta.mp3",
  },
  {
    image: p182,
    title: "182 - Os presentes do povo pequeno {saco com ouro e carvão}",
    titleen: "182 - The Little Folks’ Presents",
    titlees: "182 - Los regalos de los gnomos",
    description: "jhasjhsa1",
    sound: undefined,
  },
  {
    image: p183,
    title: "183 - O Gigante e o Alfaiate {ramo de salgueiro}",
    titleen: "183 - The Giant and the Tailor",
    titlees: "183 - El gigante y el sastre",
    description: "jhasjhsa2",
    sound: undefined,
  },
  {
    image: p184,
    title: "184 - O prego {ferradura}",
    titleen: "184 - The Nail (Brothers Grimm)",
    titlees: "184 - El clavo",
    description: "jhasjhsa3",
    sound: undefined,
  },
  {
    image: p185,
    title: "185 - O Rapaz pobre na sepultura {pote e garrafa de vidro chamuscada}",
    titleen: "185 - The Poor Boy in the Grave",
    titlees: "185 - El pobre niño en la tumba",
    description: "jhasjhsa4",
    sound: undefined,
  },
  {
    image: p186,
    title: "186 - A verdadeira noiva {tília verde}",
    titleen: "186 - The True Bride",
    titlees: "186 - La novia verdadera",
    description: "jhasjhsa5",
    sound: undefined,
  },
  {
    image: p187,
    title: "187 - O coelho e o porco-espinho {garrafa de brande}",
    titleen: "187 - The Hare and the Hedgehog",
    titlees: "187 - El erizo y el esposo de la liebre",
    description: "jhasjhsa6",
    sound: undefined,
  },
  {
    image: p188,
    title: "188 - Fuso, lançadeira e agulha {fuso, lançadeira e agulha}",
    titleen: "188 - Spindle, Shuttle, and Needle",
    titlees: "188 - El huso, la lanzadera y la aguja",
    description: "jhasjhsa7",
    sound: undefined,
  },
  {
    image: p189,
    title: "189 - O camponês e o Diabo {carvão}",
    titleen: "189 - The Peasant and the Devil",
    titlees: "189 - El labrador y el diablo",
    description: "jhasjhsa8",
    sound: undefined,
  },
  {
    image: p190,
    title: "190 - As migalhas sobre a mesa {migalhas}",
    titleen: "190 - The Crumbs on the Table",
    titlees: "190 - Las migajas en la mesa",
    description: "jhasjhsa9",
    sound: undefined,
  },
  {
    image: p191,
    title: "191 - O ouriço-do-mar {desenho de doze janelas}",
    titleen: "191 - The Sea-Hare",
    titlees: "191 - El lebrato marino",
    description: "jhasjhsa",
    sound: undefined,
  },
  {
    image: p192,
    title: "192 - O ladrão-mestre {árvore torta (bonsai)}",
    titleen: "192 - The Master Thief",
    titlees: "192 - El rey de los ladrones",
    description: "jhasjhsa1",
    sound: undefined,
  },
  {
    image: p193,
    title: "193 - O tocador de tambor {três retalhos de linho branco}",
    titleen: "193 - The Drummer",
    titlees: "193 - El tambor",
    description: "jhasjhsa2",
    sound: undefined,
  },
  {
    image: p194,
    title: "194 - As espigas de trigo {espiga}",
    titleen: "194 - The Ear of Corn",
    titlees: "194 - La espiga de trigo",
    description: "jhasjhsa3",
    sound: undefined,
  },
  {
    image: p195,
    title: "195 - O túmulo do rico fazendeiro {bota sem sola}",
    titleen: "195 - The Grave Mound",
    titlees: "195 - La tumba",
    description: "jhasjhsa4",
    sound: undefined,
  },
  {
    image: p196,
    title: "196 - O velho Rink Rank {mini escada}",
    titleen: "196 - Old Rinkrank",
    titlees: "196 - El viejo Rinkrank",
    description: "jhasjhsa5",
    sound: undefined,
  },
];

const objetos4 = [
  {
    image: p197,
    title: "197 - A Bola de cristaL {bola de cristal}",
    titleen: "197 - The Crystal Ball",
    titlees: "197 - La bola de cristal",
    description: "jhasjhsa6",
    sound: "https://pangeia-espetaculo.s3-sa-east-1.amazonaws.com/sound/197-bola-de-cristal.mp3",
  },
  {
    image: p198,
    title: "198 - A donzela Malvina {pedra de torre}",
    titleen: "198 - Maid Maleen",
    titlees: "198 - La doncella Maleen",
    description: "jhasjhsa7",
    sound: undefined,
  },
  {
    image: p199,
    title: "199 - As botas de couro de búfalo {garrafa de vinho}",
    titleen: "199 - The Boots of Buffalo Leather",
    titlees: "199 - La bota de piel de búfalo",
    description: "jhasjhsa8",
    sound: undefined,
  },
  {
    image: p200,
    title: "200 - A chave de ouro {chave dourada}",
    titleen: "200 - The Golden Key",
    titlees: "200 - La llave de oro",
    description: "jhasjhsa9",
    sound: undefined,
  },
];

export default function Painel(props) {
  const classes = useStyles();
  const [imgsLoaded, setImgsLoaded] = useState(false);



  useEffect(() => {
    const loadImage = image => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image()
        loadImg.src = image.image
        // wait 2 seconds to simulate loading time
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(image.image)
          }, 3000)

        loadImg.onerror = err => reject(err)
      })
    }

    Promise.all(objetos3.map(image => loadImage(image)))
      .then(() => setImgsLoaded(true))
      .catch(err => console.log("Failed to load images", err))
  }, [])  

const PhotoItem = ({ image, thumb, group, title, description, sound }) => {
  var audio = "";
  var bord = "";
  if (sound) {
    audio =
      "<audio controls autoplay><source src='" +
      sound +
      "' type='audio/mpeg'></audio>" +
      "<div class='loader'><svg id='wave' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 38.05'>" +
      "<title>Audio Wave</title>" +
      "<path id='Line_1' data-name='Line 1' d='M0.91,15L0.78,15A1,1,0,0,0,0,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H0.91Z'></path>" +
      "<path id='Line_2' data-name='Line 2' d='M6.91,9L6.78,9A1,1,0,0,0,6,10V28a1,1,0,1,0,2,0s0,0,0,0V10A1,1,0,0,0,7,9H6.91Z'></path>" +
      "<path id='Line_3' data-name='Line 3' d='M12.91,0L12.78,0A1,1,0,0,0,12,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H12.91Z'></path>" +
      "<path id='Line_4' data-name='Line 4' d='M18.91,10l-0.12,0A1,1,0,0,0,18,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H18.91Z'></path>" +
      "<path id='Line_5' data-name='Line 5' d='M24.91,15l-0.12,0A1,1,0,0,0,24,16v6a1,1,0,0,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H24.91Z'></path>" +
      "<path id='Line_6' data-name='Line 6' d='M30.91,10l-0.12,0A1,1,0,0,0,30,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H30.91Z'></path>" +
      "<path id='Line_7' data-name='Line 7' d='M36.91,0L36.78,0A1,1,0,0,0,36,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H36.91Z'></path>" +
      "<path id='Line_8' data-name='Line 8' d='M42.91,9L42.78,9A1,1,0,0,0,42,10V28a1,1,0,1,0,2,0s0,0,0,0V10a1,1,0,0,0-1-1H42.91Z'></path>" +
      "<path id='Line_9' data-name='Line 9' d='M48.91,15l-0.12,0A1,1,0,0,0,48,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H48.91Z'></path>" +
      "</svg></div>";
    bord = "5px solid #C0C0C0";

  }
  return (
    <div className={"item explode"} style={{ border: "1px solid #aa96c0" }}>
      <LightgalleryItem
        group={group}
        src={image}
        thumb={thumb}
        subHtml={"<h4>" + title + "</h4>" + audio}
      >
        <img src={image} className="image" style={{width:'100%'}}/>
        <div className="overlay">
          <span></span>
          <span>{title}</span>
          <span></span>
        </div>
      </LightgalleryItem>
    </div>
  );
};


PhotoItem.propTypes = {
  image: PT.string.isRequired,
  thumb: PT.string,
  group: PT.string.isRequired,
  title: PT.string.isRequired,
  description: PT.string.isRequired,
  sound: PT.string,
};

const OpenButtonWithHoc = withLightgallery(({ title, idx, openGallery, ...props }) => {
  return (
    <h5 
    {...props}
      onClick={() => {
        openGallery("objetos", idx);
      }}
              className={classes.description + " descricao"}
              style={{ fontFamily: "InputMonoCBold", cursor:"pointer" }}
            >{title}
   </h5>
  );
});


  return (
    <div style={{ backgroundColor: "#aa96c0" }}>
      <div>
        <GridContainer style={{minHeight:'1080px'}}>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            className={classes.marginAuto + " pad"}
            style={{ padding: "10em" }}
          >
           {imgsLoaded ? ( <h5
              className={classes.description }
              style={{ textTransform: "uppercase",
                marginBottom: "3em",
                fontFamily: 'NSW',
                fontWeight: "100",
                fontSize: "70px",
                textAlign: "center",
                margin: "auto",
              textAlign:"center !important",
            color:"white" }}
            >
              Clique nos objetos coloridos
            </h5>):""}

            <LightgalleryProvider
              onAfterOpen={() => {
                return (document.getElementsByTagName(
                  "header"
                )[0].style.display = "none");
              }}
              onCloseAfter={() => {
                return (document.getElementsByTagName(
                  "header"
                )[0].style.display = "");
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4} style={{ padding: "0px" }}>
                    <GridContainer>
                    {imgsLoaded ? (
                      objetos1.map((p, idx) => {
                        return (
                          <>
                            {/* <Logo idx={idx} /> */}
                            <GridItem
                              xs={3}
                              sm={3}
                              md={3}
                              style={{ padding: "0px" }}
                            >
                              <PhotoItem
                                key={idx}
                                image={p.image}
                                title={p.title}
                                description={p.description}
                                sound={p.sound}
                                group="objetos"
                              />
                            </GridItem>
                          </>
                        );
                      })) : (
                        <></>
                      )}
                    </GridContainer>
                  </GridItem>

                  <GridItem
                    xs={4}
                    sm={4}
                    md={4}
                    style={{
                      width: "60%",
                      border: "1px solid #aa96c0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderTop: "1px solid #aa96c0",
                    }}
                    className="logo"
                  >
                    <img
                      src={logoPangeia}
                      align="center"
                      style={{ width: "inherit" }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={4} md={4} style={{ padding: "0px" }}>
                    <GridContainer>
                    {imgsLoaded ? (
                      objetos2.map((p, idx) => {
                        return (
                          <>
                            {/* <Logo idx={idx} /> */}
                            <GridItem
                              xs={3}
                              sm={3}
                              md={3}
                              style={{ padding: "0px" }}
                            >
                              <PhotoItem
                                key={idx}
                                image={p.image}
                                title={p.title}
                                description={p.description}
                                sound={p.sound}
                                group="objetos"
                              />
                            </GridItem>
                          </>
                        );
                      })) : (<></>)}
                    </GridContainer>
                  </GridItem>
                  {imgsLoaded ? (
                  objetos3.map((p, idx) => {
                    return (
                      <>
                        {/* <Margen idx={idx} /> */}
                        <GridItem
                          xs={3}
                          sm={1}
                          md={1}
                          style={{ padding: "0px" }}
                        >
                          <PhotoItem
                            key={idx}
                            image={p.image}
                            title={p.title}
                            description={p.description}
                            sound={p.sound}
                            group="objetos"
                          />
                        </GridItem>
                      </>
                    );
                  })) : (<BoxLoading/>)}

                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={4}
                      md={4}
                      style={{ padding: "0px" }}
                    />
                    <GridItem xs={12} sm={4} md={4} style={{ padding: "0px" }}>
                      <GridContainer>
                      {imgsLoaded ? 
                      (objetos4.map((p, idx) => {
                          return (
                            <>
                              {/* <Logo idx={idx} /> */}
                              <GridItem
                                xs={3}
                                sm={3}
                                md={3}
                                style={{ padding: "0px" }}
                              >
                                <PhotoItem
                                  key={idx}
                                  image={p.image}
                                  title={p.title}
                                  description={p.description}
                                  sound={p.sound}
                                  group="objetos"
                                />
                              </GridItem>
                            </>
                          );
                        })) : (<></>)}
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GridContainer>
              </div>

        {imgsLoaded ? (                
        <GridContainer justify="center" >
           <GridContainer xs={12} sm={12} md={12} lg={12} justify="center" >
          <GridItem xs={12} sm={12} md={12} lg={3} style={{backgroundColor: "#002c47", padding: "2em", borderRadius: "8px", textAlign: "center",  margin: "3em"}}>
        
          <a className="btn" href={painel} download style={{left: "-15%"}}>
            <span className="btn__circle"></span>
            <span className="btn__white-circle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="icon-arrow-right"
                viewBox="0 0 21 12"
              >
                <path
                  fill="#ffffff"
                  d="M17.104 5.072l-4.138-4.014L14.056 0l6 5.82-6 5.82-1.09-1.057 4.138-4.014H0V5.072h17.104z"
                ></path>
              </svg>
            </span>
            <span className="btn__text">Baixar Painel</span>
          </a>
          </GridItem>
          </GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={6}>
       
          <NavPills
            color="rose"
            horizontal={{
              tabsGrid: { xs: 12, sm: 2, md: 2 },
              contentGrid: { xs: 12, sm: 8, md: 8 }
            }}
            tabs={[
              {
                tabButton: "",
                tabIcon: Brasil,
                tabContent: (
                  <span>
                    
                     <div className={classes.title} style={{margin: "auto"}} >
            <h2 style={{color: "#002c47", fontWeight: "100", fontSize: "5em", fontFamily: "NSW", margin: "0px"}}>OBJETOS E CONTOS</h2> 
            </div>

          {     objetos1.map((p, idx) => {
                        return (
                          <>
                          <OpenButtonWithHoc title={p.title} idx={idx}/>
                            
                          </>
                        );
                      })
            }
                    {     objetos2.map((p, idx) => {
                        return (
                          <>
                          <OpenButtonWithHoc title={p.title} idx={idx+8}/>
                          </>
                        );
                      })
            }
                    {     objetos3.map((p, idx) => {
                        return (
                          <>
                          <OpenButtonWithHoc title={p.title} idx={idx+16}/>
                          </>
                        );
                      })
            }
                    {     objetos4.map((p, idx) => {
                        return (
                          <>
                            <OpenButtonWithHoc title={p.title} idx={idx+196}/>
                          </>
                        );
                      })
            }
         
                   
                  </span>
                )
              },
              {
                tabButton: "",
                tabIcon: Ingles,
                tabContent: (
                  <span>
                  <div className={classes.title} style={{margin: "auto"}}>
         <h2 style={{color: "#002c47", fontWeight: "100", fontSize: "5em", fontFamily: "NSW", margin: "0px"}}>OBJECTS & TALES </h2>
       </div>
       {     objetos1.map((p, idx) => {
                     return (
                       <>
                      <OpenButtonWithHoc title={p.titleen} idx={idx}/>
                       </>
                     );
                   })
         }
                 {     objetos2.map((p, idx) => {
                     return (
                       <>
                      <OpenButtonWithHoc title={p.titleen} idx={idx+8}/>
                       </>
                     );
                   })
         }
                 {     objetos3.map((p, idx) => {
                     return (
                       <>
                      <OpenButtonWithHoc title={p.titleen} idx={idx+16}/>    
                       </>
                     );
                   })
         }
                 {     objetos4.map((p, idx) => {
                     return (
                       <>
                      <OpenButtonWithHoc title={p.titleen} idx={idx+196}/>
                       </>
                     );
                   })
         }
      
                
               </span>
                )
              },{
                tabButton: "",
                tabIcon: Espanhol,
                tabContent: (
                  <span>
                  <div className={classes.title} style={{margin: "auto"}}>
         <h2 style={{color: "#002c47", fontWeight: "100", fontSize: "5em", fontFamily: "NSW", margin: "0px"}}>OBJETOS Y CUENTOS</h2>
       </div>
       {     objetos1.map((p, idx) => {
                     return (
                       <>
                    <OpenButtonWithHoc title={p.titlees} idx={idx}/>
                       </>
                     );
                   })
         }
                 {     objetos2.map((p, idx) => {
                     return (
                       <>
                      <OpenButtonWithHoc title={p.titlees} idx={idx+8}/>
                       </>
                     );
                   })
         }
                 {     objetos3.map((p, idx) => {
                     return (
                       <>
                      <OpenButtonWithHoc title={p.titlees} idx={idx+16}/>
                       </>
                     );
                   })
         }
                 {     objetos4.map((p, idx) => {
                     return (
                       <>
                      <OpenButtonWithHoc title={p.titlees} idx={idx+196}/>
                       </>
                     );
                   })
         }
      
                
               </span>
                )
              }
            ]}
          />
        </GridItem>
        </GridContainer>) : ""}
            </LightgalleryProvider>
            
          </GridItem>
        </GridContainer>

  







        <Footer />
        {/* <iframe
          width="620px"
          height="567px"
          src="https://www.yumpu.com/pt/embed/view/maBWDu54LpHEh6cy"
          frameBorder="0"
          allowFullScreen="true"
          allowtransparency="true"
        ></iframe>
        <br />
        <a
          href="https://www.yumpu.com/pt/document/view/64005439/revista-qual-viagem-edicao-81"
          title="Revista Qual Viagem Edição 81"
          target="_blank"
        >
          Revista Qual Viagem Edição 81
        </a> */}
      </div>
    </div>
  );
}

