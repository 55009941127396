import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Parallax from "../Parallax/Parallax.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/landingPage";
import "./carousel.css";
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function Example() {
  var items = [
    {
      name: "Peça Interativa",
      description:
        "Na versão interativa do espetáculo Pangeia, é você que escolhe o caminho da peça, basta clicar nas opções que vão aparecer na tela. Esta experiência tem mais de 100 possibilidades. A escolha está nas suas mãos. Vamos começar?",
      image: "pangeia3.jpg",
      href: "/espetaculo",
    },
    {
      name: "Desafio Pangeia",
      description:
        "Todos os 200 objetos dos contos dos irmãos Grimm reunidos num painel interativo. Alguns objetos contam a sua versão da história, basta clicar e escutar. Vamos começar?",
      image: "pangeiafolheto.jpg",
      href: "/painel",
    },
    {
      name: "Conversa com o Elenco",
      description:
        "Quer saber como criamos o Pangeia? Nesta conversa com o diretor e elenco português e brasileiro, revelamos as ideias por detrás do espetáculo. Vamos começar?",
      image: "pangeiamesa.jpeg",
      href: "/entrevistas",
    },


  ];

  return (
    <Carousel
      animation={"slide"}
      navButtonsAlwaysVisible={true}
      autoPlay={true}
      indicators={false}
      interval={6000}
    >
      {items.map((item, i) => (
        <Item key={i} item={item}></Item>
      ))}
    </Carousel>
  );
}

function Item(props) {
  const classes = useStyles();
  return (
    <Paper>
      <Parallax filter image={props.item.image}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title + " titulocust"}>
                {props.item.name}
              </h1>
              <h4 className={" descricaocust"}>{props.item.description}</h4>
              <br />
              {/* <Button
                color="danger"
                size="lg"
                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-play" />
                Watch video
              </Button> */}
              {/*  */}
              <Link className="btn" to={props.item.href}>
                <span className="btn__circle"></span>
                <span className="btn__white-circle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="icon-arrow-right"
                    viewBox="0 0 21 12"
                  >
                    <path
                      fill="#ffffff"
                      d="M17.104 5.072l-4.138-4.014L14.056 0l6 5.82-6 5.82-1.09-1.057 4.138-4.014H0V5.072h17.104z"
                    ></path>
                  </svg>
                </span>
                <span className="btn__text">Saiba Mais</span>
              </Link>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
    </Paper>
  );
}
