import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

import foto from "./galeria/Foto13.png";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        {
          <GridItem xs={12} sm={12} md={4} style={{ textAlign: "left" }}>
            <img
              src={foto}
              style={{ width: "80%", marginTop: "41px", position: "absolute" }}
            />
          </GridItem>
        }
        <GridItem xs={12} sm={12} md={6}>
          <h2 className={classes.title}>SOBRE</h2>
          <h5 className={classes.description}>
            PANGEIA é uma viagem sonora e visual pelo universo dos irmãos Grimm,
            em que o palco se transforma em museu vivo de objetos curiosos
            através de sons escutados em headphones. Os objetos remetem ao
            imaginário dos contos fantásticos, como a floresta cheia de
            armadilhas, a magia da bola de cristal ou os feitiços da bruxa má.
            Nesta viagem acompanhada pelos dois cientistas vamos descobrir o
            ponto de vista dos objetos que ilustram os contos. Para isso teremos
            de seguir as pistas, como fizeram Hansel e Gretel com as migalhas
            que deixaram no caminho para poderem depois voltar a casa.
          </h5>
          <h5 className={classes.description}>
            PANGEIA é um espetáculo para o público juvenil que reúne em palco
            várias linguagens como o teatro, a dança e as artes visuais,
            recuperando, assim a ideia dos Gabinetes de Curiosidades criados no
            século XVI que reuniam objetos raros e artefatos da biologia, sendo
            considerados os percursores dos museus de arte.
          </h5>
          <h5 className={classes.description}>
            As histórias que ouvimos durante a nossa infância surgem no PANGEIA
            de um outro ponto de vista, questionando o espectador sobre sua
            origem e de que forma o universo dos irmãos Grimm foi sofrendo
            alterações tanto na tradição oral como na escrita dos contos.
          </h5>
        </GridItem>
        <GridItem>
          {/* //href="/sobre" */}
          <Link className="btn" to="/sobre">
            <span className="btn__circle"></span>
            <span className="btn__white-circle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="icon-arrow-right"
                viewBox="0 0 21 12"
              >
                <path
                  fill="#ffffff"
                  d="M17.104 5.072l-4.138-4.014L14.056 0l6 5.82-6 5.82-1.09-1.057 4.138-4.014H0V5.072h17.104z"
                ></path>
              </svg>
            </span>
            <span className="btn__text">Saiba Mais</span>
          </Link>
        </GridItem>
      </GridContainer>
    </div>
  );
}
