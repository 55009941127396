import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product

import LandingPage from "views/LandingPage/LandingPage.js";
import About from "views/About/About.js";
import Elenco from "views/Elenco/Elenco.js";
import Entrevistas from "views/Entrevistas/Entrevistas";
import Agenda from "views/Agenda/Agenda";
import Espetaculo from "views/Espetaculo/Espetaculo";
import Painel from "views/Painel/Painel";
import Premios from "views/Premios/Premios.js";
import Livrinho from "views/Livrinho/Livrinho.js";
import Galeria from "views/Galeria/Galeria.js";
import Contato from "views/Contato/Contato.js";
import Header from "./components/Header/Header.js";
import HeaderLinks from "./components/Header/HeaderLinks.js";

var hist = createBrowserHistory();
const dashboardRoutes = [];

ReactDOM.render(
  <Router history={hist}>
    <Header
      color="transparent"
      routes={dashboardRoutes}
      brand="PANGEIA"
      rightLinks={<HeaderLinks />}
      fixed
      changeColorOnScroll={{
        height: 100,
        color: "greenPangeia",
      }}
      {...hist}
    />
    <Switch>
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/contato" component={Contato} />
      <Route path="/sobre" component={About} />
      <Route path="/elenco" component={Elenco} />
      <Route path="/entrevistas" component={Entrevistas} />
      <Route path="/espetaculo" component={Espetaculo} />
      <Route path="/agenda" component={Agenda} />
      <Route path="/painel" component={Painel} />
      <Route path="/galeria" component={Galeria} />
      <Route path="/premios" component={Premios} />
      <Route path="/livrinho" component={Livrinho} />
      <Route path="/" component={LandingPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
