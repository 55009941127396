import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import studio1 from "assets/img/examples/studio-1.jpg";
import studio2 from "assets/img/examples/studio-2.jpg";
import studio3 from "assets/img/examples/studio-3.jpg";
import studio4 from "assets/img/examples/studio-4.jpg";
import studio5 from "assets/img/examples/studio-5.jpg";
import work1 from "assets/img/examples/olu-eletu.jpg";
import work2 from "assets/img/examples/clem-onojeghuo.jpg";
import work3 from "assets/img/examples/cynthia-del-rio.jpg";
import work4 from "assets/img/examples/mariya-georgieva.jpg";
import work5 from "assets/img/examples/clem-onojegaw.jpg";

import foto1 from "./galeria/Foto1.jpeg";
import foto2 from "./galeria/Foto2.jpeg";
import foto3 from "./galeria/Foto7.jpeg";
import foto4 from "./galeria/Foto9.jpeg";
import foto5 from "./galeria/Foto10.jpeg";
import foto6 from "./galeria/Foto11.jpeg";
import foto7 from "./galeria/Foto8.jpeg";
import foto8 from "./galeria/Foto3.jpeg";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import NavPills from "components/NavPills/NavPills.js";

import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div
      className={classes.section}
      style={{ backgroundColor: "black", textAlign: "center" }}
    >
      <h2 className={classes.title} style={{ color: "#9580b2" }}>
        GALERIA
      </h2>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <img alt="..." src={foto1} className={navImageClasses} />
              <img alt="..." src={foto5} className={navImageClasses} />
              <img alt="..." src={foto8} className={navImageClasses} />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <img alt="..." src={foto3} className={navImageClasses} />
              <img alt="..." src={foto4} className={navImageClasses} />
             
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <img alt="..." src={foto6} className={navImageClasses} />
              <img alt="..." src={foto7} className={navImageClasses} />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
          {/* //href="/sobre" */}
          <Link className="btn" to="/galeria">
            <span className="btn__circle"></span>
            <span className="btn__white-circle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="icon-arrow-right"
                viewBox="0 0 21 12"
              >
                <path
                  fill="#ffffff"
                  d="M17.104 5.072l-4.138-4.014L14.056 0l6 5.82-6 5.82-1.09-1.057 4.138-4.014H0V5.072h17.104z"
                ></path>
              </svg>
            </span>
            <span className="btn__text">Ver Mais</span>
          </Link>
        </GridItem>
      </GridContainer>
    </div>
  );
}
