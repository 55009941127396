import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Parallax from "components/Parallax/Parallax.js";
import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";
import Quote from "components/Typography/Quote.js";
import Card from "components/Card/Card.js";
import Footer from "components/Footer/Footer.js";

import Carousel from "react-slick";
const useStyles = makeStyles(styles);

export default function Espetaculo() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: false,
    prevArrow: false,
  };

  return (
    <div style={{ backgroundColor: "black" }}>
      {/* <Parallax
        filter
        image={"Foto9.jpg"}
        style={{ backgroundPosition: "center" }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div
                className={classes.brand + " bread"}
                style={{ width: "320px" }}
              >
                <svg
                  version="1.1"
                  id="assista"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 480.3 123"
                  enable-background="new 0 0 480.3 123"
                >
                  <g>
                    <path
                      fill="#FFFFFF"
                      d="M16,24.6c3.5,0,6.2,2.8,6.2,6.2v81.7h-8.8V73H8.8v39.6H0V30.8c0-3.5,2.9-6.2,6.4-6.2H16z M13.4,64.2V33.4
		H8.8v30.8H13.4z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M43,24.6c3.5,0,6.2,2.8,6.2,6.2v18.5h-8.8v-16h-4.7v13.3c0,1.9,0.4,3.2,0.7,4.1l11.3,31.9
		c0.4,0.8,1.3,3.5,1.4,6.8v16.8c0,3.5-2.8,6.2-6.2,6.2h-9.6c-3.5,0-6.4-2.8-6.4-6.2V87.8h8.8v16h4.7v-13c0-2.4-0.5-3.7-0.8-4.6
		L28.7,54.7C28.3,53.9,27,50,27,45.6V30.8c0-3.5,2.9-6.2,6.4-6.2H43z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M70,24.6c3.5,0,6.2,2.8,6.2,6.2v18.5h-8.8v-16h-4.7v13.3c0,1.9,0.4,3.2,0.7,4.1l11.3,31.9
		c0.4,0.8,1.3,3.5,1.4,6.8v16.8c0,3.5-2.8,6.2-6.2,6.2h-9.6c-3.5,0-6.4-2.8-6.4-6.2V87.8h8.8v16h4.7v-13c0-2.4-0.5-3.7-0.8-4.6
		L55.7,54.7C55.3,53.9,54,50,54,45.6V30.8c0-3.5,2.9-6.2,6.4-6.2H70z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M96.7,24.6v8.8h-4.3v70.4h4.3v8.8H79.2v-8.8h4.4V33.4h-4.4v-8.8H96.7z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M116.2,24.6c3.5,0,6.2,2.8,6.2,6.2v18.5h-8.8v-16H109v13.3c0,1.9,0.4,3.2,0.7,4.1L121,82.7
		c0.4,0.8,1.3,3.5,1.4,6.8v16.8c0,3.5-2.8,6.2-6.2,6.2h-9.6c-3.5,0-6.4-2.8-6.4-6.2V87.8h8.8v16h4.7v-13c0-2.4-0.5-3.7-0.8-4.6
		l-10.9-31.6c-0.4-0.8-1.7-4.7-1.7-9.1V30.8c0-3.5,2.9-6.2,6.4-6.2H116.2z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M141.2,112.6h-8.8V33.4h-6.7v-8.8H148v8.8h-6.7V112.6z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M167.5,24.6c3.5,0,6.2,2.8,6.2,6.2v81.7H165V73h-4.7v39.6h-8.8V30.8c0-3.5,2.9-6.2,6.4-6.2H167.5z
		 M165,64.2V33.4h-4.7v30.8H165z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M210.1,24.6c3.5,0,6.2,2.8,6.2,6.2v75.5c0,3.5-2.8,6.2-6.2,6.2h-9.7c-3.5,0-6.4-2.9-6.4-6.2V30.8
		c0-3.5,2.9-6.2,6.4-6.2H210.1z M207.6,103.8V33.4h-4.7v70.4H207.6z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M252.2,24.6v8.8h-6.7v30.8h6.7V73h-6.7v30.8h6.7v8.8h-15.5v-88H252.2z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M272.6,24.6c3.5,0,6.2,2.8,6.2,6.2v18.5h-8.8v-16h-4.7v13.3c0,1.9,0.4,3.2,0.7,4.1l11.3,31.9
		c0.4,0.8,1.3,3.5,1.4,6.8v16.8c0,3.5-2.8,6.2-6.2,6.2H263c-3.5,0-6.4-2.8-6.4-6.2V87.8h8.8v16h4.7v-13c0-2.4-0.5-3.7-0.8-4.6
		l-10.9-31.6c-0.4-0.8-1.7-4.7-1.7-9.1V30.8c0-3.5,2.9-6.2,6.4-6.2H272.6z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M299.6,24.6c3.5,0,6.2,2.8,6.2,6.2v35.9c0,3.5-2.8,6.2-6.2,6.2h-7.2v39.6h-8.8v-88H299.6z M297.1,33.4h-4.7
		v30.8h4.7V33.4z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M325.8,24.6v8.8h-6.7v30.8h6.7V73h-6.7v30.8h6.7v8.8h-15.5v-88H325.8z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M344.5,112.6h-8.8V33.4H329v-8.8h22.2v8.8h-6.7V112.6z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M370.8,24.6c3.5,0,6.2,2.8,6.2,6.2v81.7h-8.8V73h-4.7v39.6h-8.8V30.8c0-3.5,2.9-6.2,6.4-6.2H370.8z
		 M363.6,19.2v-8.8H377l-8.8,8.8H363.6z M368.3,64.2V33.4h-4.7v30.8H368.3z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M397.7,24.6c3.5,0,6.2,2.8,6.4,6.2v30.5h-8.8v-28h-4.7v70.4h4.7V73h8.8v33.2c0,3.5-3,6.4-6.4,6.4h-9.5
		c-3.5,0-6.4-2.9-6.4-6.4V30.8c0-3.5,3-6.2,6.4-6.2H397.7z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M431,106.3c0,3.5-2.8,6.2-6.2,6.2h-9.7c-3.4,0-6.2-2.8-6.2-6.2V24.6h8.8v79.2h4.7V24.6h8.8V106.3z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M451.3,112.6h-15.5v-88h8.8v79.2h6.7V112.6z"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M470.6,24.6c3.5,0,6.2,2.8,6.2,6.2v75.5c0,3.5-2.8,6.2-6.2,6.2H461c-3.5,0-6.4-2.9-6.4-6.2V30.8
		c0-3.5,2.9-6.2,6.4-6.2H470.6z M468.1,103.8V33.4h-4.7v70.4H468.1z"
                    />
                  </g>
                </svg>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax> */}
      <GridContainer justify="center">
        <GridItem
          xs={12}
          sm={12}
          md={8}
          className={classes.marginAuto}
          style={{ paddingTop: "10em", paddingBottom: "10em" }}
        >
              <div
            className="video"
            style={{
              position: "relative",
              paddingBottom: "56.25%" /* 16:9 */,
              paddingTop: 25,
              height: 0,
            }}
          >
          {/* <iframe width="100%" height="auto" src="https://www.youtube.com/embed/Iz1BykqD6Hk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
       
          <div class='embed-container' data-page-width='451' data-page-height='640' id='ypembedcontainer' >
            <iframe   src="https://www.yumpu.com/pt/embed/view/7Jruv6iFTeASMDwg" frameborder="0" allowfullscreen="allowfullscreen"  allowtransparency="true"></iframe>
            <a href="https://www.yumpu.com/pt/document/view/65357386/pangeia" title="Pangeia" target="_blank"> </a>
            </div>
            <script src='https://players.yumpu.com/modules/embed/yp_r_iframe.js' ></script>
            </div>
        </GridItem>
      </GridContainer>
     
      <Footer />
    </div>
  );
}