import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";

const useStyles = makeStyles(styles);

export default function Contato() {
  const classes = useStyles();

  return (
    <div style={{ backgroundColor: "#002c47" }}>
      <Parallax small filter image={"Foto7.jpg"}>
        <div className={classes.container} style={{ textAlign: "center" }}>
          <GridContainer>
            <GridItem>
              <div className={classes.title + " bread"}>
                <svg
                  version="1.1"
                  id="contato"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 203.9 123"
                  enableBackground="new 0 0 203.9 123"
                >
                  <path
                    fill="#FFFFFF"
                    d="M16.9,17.5c3.5,0,6.2,2.8,6.4,6.2v30.5h-8.8v-28H9.8v70.4h4.7V65.9h8.8v33.2c0,3.5-3,6.4-6.4,6.4H7.4
	c-3.5,0-6.4-2.9-6.4-6.4V23.8c0-3.5,3-6.2,6.4-6.2H16.9z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M44,17.5c3.5,0,6.2,2.8,6.2,6.2v75.5c0,3.5-2.8,6.2-6.2,6.2h-9.6c-3.5,0-6.4-2.9-6.4-6.2V23.8
	c0-3.5,2.9-6.2,6.4-6.2H44z M41.5,96.7V26.3h-4.7v70.4H41.5z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M77,105.5h-8.6l-4.8-39.2v39.2h-8.8v-88h8.6l4.8,35.8V17.5H77V105.5z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M96.1,105.5h-8.8V26.3h-6.7v-8.8h22.2v8.8h-6.7V105.5z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M122.4,17.5c3.5,0,6.2,2.8,6.2,6.2v81.7h-8.8V65.9h-4.7v39.6h-8.8V23.8c0-3.5,2.9-6.2,6.4-6.2H122.4z
	 M119.9,57.1V26.3h-4.7v30.8H119.9z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M147.5,105.5h-8.8V26.3H132v-8.8h22.2v8.8h-6.7V105.5z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M173.3,17.5c3.5,0,6.2,2.8,6.2,6.2v75.5c0,3.5-2.8,6.2-6.2,6.2h-9.6c-3.5,0-6.4-2.9-6.4-6.2V23.8
	c0-3.5,2.9-6.2,6.4-6.2H173.3z M170.8,96.7V26.3h-4.7v70.4H170.8z"
                  />
                </svg>
                {/* <h1 className={classes.title} style={{color: "white", zIndex:"1"}}>Elenco</h1> */}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>


        <div className={classes.section} style={{marginTop:"6em"}}>
          <div className={classes.container}>
            <GridContainer style={{marginTop:"8em"}}>
              {/* <GridItem xs={12} sm={12} md={8} style={{ textAlign: "center", margin:"auto"}}>
           
              <h2
              className={classes.title}
              style={{ textAlign: "center", color: "#aa96c0" }}
            >
              ENVIE UMA MENSAGEM:
            </h2>
            <form>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="NOME"
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="EMAIL"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="MENSAGEM"
                    id="message"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.textArea,
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} style={{textAlign:"right"}}>
                  <Button style={{ backgroundColor: "#aa96c0"}}>Enviar</Button>
                </GridItem>
              </GridContainer>
            </form>
           
              </GridItem> */}
              <GridItem xs={12} sm={12} md={8} style={{marginTop:"8em", textAlign:"center", margin:"auto"}} >
          
              
            <Card>
           
                <CardHeader color="primary" className={classes.cardHeader}>
                
                  <div className={classes.socialLine}>
                  <h4 >Redes Sociais: </h4>
                    <Button
                      justIcon
                      href="https://www.instagram.com/pangeiaespetaculo/"
                      target="_blank"
                      color="transparent"
                     
                    >
                      <i className={classes.socialIcons + " fab fa-instagram"} />
                    </Button>
                    <Button
                      justIcon
                      href="https://www.facebook.com/pangeiaespetaculo"
                      target="_blank"
                      color="transparent"
                    
                    >
                      <i className={classes.socialIcons + " fab fa-facebook"} />
                    </Button>
                  </div>
                </CardHeader>
                {/* <h2
              className={classes.title}
              style={{ textAlign: "center", color: "#aa96c0", clear:"both"}}
            >
             EMAIL
            </h2> */}
           
            </Card>
           



              </GridItem>
              {/* <GridItem xs={12} sm={12} md={8} style={{marginTop:"1em", textAlign:"center", margin:"auto"}} >
              <a className={"descricao1"}
                href="mailto:contato@pangeiaespetaculo.com"
                target="_blank"
              >
               contato@pangeiaespetaculo.com
              </a>
              </GridItem> */}
            </GridContainer>
        
          </div>
        </div>
     


      <Footer />
    </div>
  );
}
